import React, { useEffect, useState } from 'react';
import api from '../config/axiosConfig';
import UserGroupContext from '../contexts/UserGroupContext';
import { exportDashBoardDataToExcel } from '../utils/exportUtils';
import { Autocomplete, Box, Button, Card, CardContent, Container, TextField, Tooltip, Typography } from '@mui/material';
import { Info, LocalAtmOutlined, MonetizationOn, PaymentsOutlined, PriceChange, RequestQuote, SaveAlt } from '@mui/icons-material';
import styles from './css/DashBoard.module.css';
import AdminTab from './DashBoardTabs/AdminTab';
import AccountingTab from './DashBoardTabs/AccountingTab';

function DashBoard() {
    const userGroups = React.useContext(UserGroupContext);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [month, setMonth] = useState((new Date()).getMonth() + 1);
    const [filterSalesRep, setFilterSalesRep] = useState(null);
    const [filterCreatedDate, setFilterCreatedDate] = useState('');
    const [salesRepList, setSalesRepList] = useState([]);
    const [currentMonthData, setCurrentMonthData] = useState([]);
    const [lastMonthData, setLastMonthData] = useState([]);
    const [currentMonthDataJPStaff, setCurrentMonthDataJPStaff] = useState({});
    const [lastMonthDataJPStaff, setLastMonthDataJPStaff] = useState({});
    const [tabValue, setTabValue] = useState(0);

    const years = Array.from({ length: new Date().getFullYear() - 2022 + 1 }, (_, i) => 2023 + i);

    useEffect(() => {
        document.title = '數據台';

        if (userGroups.includes('管理員') || userGroups.includes('會計人員')) {
            const fetchSalesRepList = async () => {
                try {
                    const response = await api.get('/core/api/user_basic_info/');
                    setSalesRepList(response.data);
                }
                catch (error) {
                    console.error(error);
                }
            };

            fetchSalesRepList();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userGroups.includes('日本線管理')) {
            const fetchSalesRepList = async () => {
                try {
                    const dataResponse = await api.get(`/japan/api/dashboard/?type=order_data_by_month_for_japan_staff&year=${year}&month=${month}`);
                    setCurrentMonthDataJPStaff(dataResponse.data.current_month);
                    setLastMonthDataJPStaff(dataResponse.data.last_month);
                } catch (error) {
                    console.error(error);
                }
            };

            fetchSalesRepList();
        }

        // eslint-disable-next-line
    }, [year, month]);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
        setFilterSalesRep(null);
        setFilterCreatedDate('');
    };

    const handleOrderDataRecieved = (currentMonthData, lastMonthData) => {
        setCurrentMonthData(currentMonthData);
        setLastMonthData(lastMonthData);
    };

    const renderTabContent = () => {
        switch (tabValue) {
            case 0:
                return(<AdminTab onOrderDataSend={handleOrderDataRecieved} year={year} month={month} filterSalesRep={filterSalesRep} filterCreatedDate={filterCreatedDate} />);
            case 1:
                return(<AccountingTab year={year} month={month} />);
            default:
                return null;
        }
    };

    return (
        <Container maxWidth={false}>
            <Box className={styles['dataCard-box']}>
                <Box className={styles['dataCard-title-box']}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#1a224c' }}>
                        當前數據源<br /><span style={{ fontSize: '15px', color: 'GrayText', paddingLeft: '14px' }}>{year}年{month}月</span>
                        {filterSalesRep && (
                            <span style={{ fontSize: '15px', color: 'GrayText', paddingLeft: '14px' }}>業務: {salesRepList.find(user => user.id === filterSalesRep)?.profile?.nickname || salesRepList.find(user => user.id === filterSalesRep)?.username}</span>
                        )}
                    </Typography>
                    <Box></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flexShrink: 0 }}>
                        <Autocomplete
                            options={years}
                            getOptionLabel={(option) => String(option)}
                            disableClearable
                            value={year}
                            sx={{ width: '75px' }}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    size='small'
                                    label="年份選擇"
                                    variant='standard'
                                />
                            }
                            onChange={(event, newValue) => {
                                setYear(newValue || (new Date()).getFullYear());
                                setFilterSalesRep(null);
                                setFilterCreatedDate('');
                            }}
                        />
                        <Autocomplete
                            options={Array.from({ length: 12 }, (_, i) => i + 1)}
                            getOptionLabel={(option) => String(option)}
                            disableClearable
                            value={month}
                            sx={{ width: '55px' }}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    size='small'
                                    label="月份選擇"
                                    variant='standard'
                                />
                            }
                            onChange={(event, newValue) => {
                                setMonth(newValue || (new Date()).getMonth() + 1);
                                setFilterSalesRep(null);
                                setFilterCreatedDate('');
                            }}
                        />
                    </Box>
                </Box>
                <Box className={styles['dataCard-details-box']}>
                    {(userGroups.includes('管理員') || userGroups.includes('會計人員')) && (
                        <>
                        {/* 外幣報價 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <LocalAtmOutlined className={styles['details-icon']} sx={{ backgroundColor: '#fa5a7d' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥
                                    {currentMonthData
                                        .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                        .reduce((acc, order) => acc + Number(order.orderfortable__fc_price), 0) || '0'
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    外幣報價總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                {
                                    (() => {
                                        const currentMonthPrice = currentMonthData
                                            .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                            .reduce((acc, order) => acc + Number(order.orderfortable__fc_price), 0) || 0;

                                        const lastMonthPrice = lastMonthData
                                            .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                            .reduce((acc, order) => acc + Number(order.orderfortable__fc_price), 0) || 0;

                                        if (lastMonthPrice === 0) {
                                            return '上月無數據，無法對比';
                                        }

                                        const differencePercentage = ((currentMonthPrice - lastMonthPrice) / lastMonthPrice) * 100;

                                        return (
                                            <span>較上月 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                        );
                                    })()
                                }
                                </Typography>
                                <Typography className={styles['details-description']}>
                                    註: 外幣報價總和為所有訂單的外幣報價總和，包含所有狀態之訂單。
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* 預估外幣報價 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <PriceChange className={styles['details-icon']} sx={{ backgroundColor: '#749cf0' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥
                                    {currentMonthData
                                        .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                        .reduce((acc, order) => acc + Number(order.orderfortable__fc_price_estimated), 0) || '0'
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    預估外幣報價總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                    {
                                        (() => {
                                            const currentMonthPrice = currentMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.orderfortable__fc_price), 0) || 0;

                                            const currentMonthPriceEstimated = currentMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.orderfortable__fc_price_estimated), 0) || 0;

                                            if (currentMonthPriceEstimated === 0) {
                                                return '無法計算實際報價';
                                            }

                                            const differencePercentage = ((currentMonthPrice - currentMonthPriceEstimated) / currentMonthPriceEstimated) * 100;

                                            return (
                                                <span>實際報價 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                            );
                                        })()
                                    }
                                </Typography>
                                <Typography className={styles['details-description']}>
                                    註: 實際報價 = (外幣報價總和 - 預估外幣報價總和) / 預估外幣報價總和
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* 外站報價 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <RequestQuote className={styles['details-icon']} sx={{ backgroundColor: '#96d174' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥
                                    {currentMonthData
                                        .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                        .reduce((acc, order) => acc + Number(order.charterbooking__booking_price), 0) || '0'
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    外站報價總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                    {
                                        (() => {
                                            const currentMonthPrice = currentMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.charterbooking__booking_price), 0) || 0;

                                            const lastMonthPrice = lastMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.charterbooking__booking_price), 0) || 0;

                                            if (lastMonthPrice === 0) {
                                                return '上月無數據，無法對比';
                                            }

                                            const differencePercentage = ((currentMonthPrice - lastMonthPrice) / lastMonthPrice) * 100;

                                            return (
                                                <span>較上月 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                            );
                                        })()
                                    }
                                </Typography>
                                <Typography className={styles['details-description']}>
                                    註: 外站報價總和為所有訂單的外站報價總和，包含所有狀態之訂單。
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* 獲利總和 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <MonetizationOn className={styles['details-icon']} sx={{ backgroundColor: '#fe947a' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥
                                    {currentMonthData
                                        .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                        .reduce((acc, order) => acc + Number(order.orderfortable__fc_price - order.charterbooking__booking_price - order.total_advance_amount), 0) || '0'
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    獲利總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                    {
                                        (() => {
                                            const currentMonthPrice = currentMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.orderfortable__fc_price - order.charterbooking__booking_price - order.total_advance_amount), 0) || 0;

                                            const lastMonthPrice = lastMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.orderfortable__fc_price - order.charterbooking__booking_price - order.total_advance_amount), 0) || 0;

                                            if (lastMonthPrice === 0) {
                                                return '上月無數據，無法對比';
                                            }

                                            if (lastMonthPrice < 0 && currentMonthPrice >= 0) {
                                                return (
                                                    <span style={{ color: '#27b044' }}>本月由虧轉盈</span>
                                                );
                                            }

                                            const differencePercentage = ((currentMonthPrice - lastMonthPrice) / lastMonthPrice) * 100;

                                            return (
                                                <span>較上月 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                            );
                                        })()
                                    }
                                </Typography>
                                <Typography className={styles['details-description']}>
                                    註: 獲利總和 = 外幣報價總和 - (外站報價總和 + 預收款總和)
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* 外站代收總和 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <PaymentsOutlined className={styles['details-icon']} sx={{ backgroundColor: '#bf83ff' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥
                                    {currentMonthData
                                        .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                        .reduce((acc, order) => acc + Number(order.orderfortable__jp_recive_price), 0) || '0'
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    外站代收總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                    {/* 代收佔獲利比 */}
                                    {
                                        (() => {
                                            const currentMonthPrice = currentMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.orderfortable__fc_price - order.charterbooking__booking_price - order.total_advance_amount), 0) || 0;

                                            const currentMonthPriceJP = currentMonthData
                                                .filter(order => filterSalesRep ? order.sales_rep === filterSalesRep : order)
                                                .reduce((acc, order) => acc + Number(order.orderfortable__jp_recive_price), 0) || 0;

                                            if (currentMonthPrice === 0) {
                                                return '無法計算代收佔獲利比';
                                            }

                                            const percentage = (currentMonthPriceJP / currentMonthPrice) * 100;

                                            return (
                                                <span>外站代收佔比 <span style={{ color: '#fc1a01' }}>{percentage.toFixed(2)}%</span></span>
                                            );
                                        })()
                                    }
                                </Typography>
                                <Typography className={styles['details-description']}>
                                    註: 外站代收占比 = 外站代收總和 / 獲利總和
                                </Typography>
                            </CardContent>
                        </Card>
                        </>
                    )}

                    {userGroups.includes('日本線管理') && (
                        <>
                        {/* 外幣報價 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <LocalAtmOutlined className={styles['details-icon']} sx={{ backgroundColor: '#fa5a7d' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥ {currentMonthDataJPStaff?.fc_price_sum || '0'}
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    外幣報價總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                {
                                    (() => {
                                        
                                        if (!lastMonthDataJPStaff.fc_price_sum || lastMonthDataJPStaff.fc_price_sum === 0) {
                                            return '上月無數據，無法對比';
                                        }

                                        const differencePercentage = ((currentMonthDataJPStaff.fc_price_sum - lastMonthDataJPStaff.fc_price_sum) / lastMonthDataJPStaff.fc_price_sum) * 100;

                                        return (
                                            <span>較上月 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                        );
                                    })()
                                }
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* 預估外幣報價 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <PriceChange className={styles['details-icon']} sx={{ backgroundColor: '#749cf0' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥ {currentMonthDataJPStaff?.fc_price_estimated_sum || '0'}
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    預估外幣報價總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                    {
                                        (() => {

                                            if (!currentMonthDataJPStaff.fc_price_estimated_sum || currentMonthDataJPStaff.fc_price_estimated_sum === 0) {
                                                return '無法計算實際報價';
                                            }

                                            const differencePercentage = ((currentMonthDataJPStaff.fc_price_sum - currentMonthDataJPStaff.fc_price_estimated_sum) / currentMonthDataJPStaff.fc_price_estimated_sum) * 100;

                                            return (
                                                <span>實際報價 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                            );
                                        })()
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* 外站報價 */}
                        <Card elevation={3} className={styles['details-card']}>
                            <CardContent className={styles['details-cardcontent']}>
                                <RequestQuote className={styles['details-icon']} sx={{ backgroundColor: '#96d174' }}/>
                                <Typography className={styles['details-number']}>
                                    ￥ {currentMonthDataJPStaff?.booking_price_sum || '0'}
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#7a7b8c' }}>
                                    外站報價總和
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#9cabe9' }}>
                                    {
                                        (() => {

                                            if (!lastMonthDataJPStaff.booking_price_sum || lastMonthDataJPStaff.booking_price_sum === 0) {
                                                return '上月無數據，無法對比';
                                            }

                                            const differencePercentage = ((currentMonthDataJPStaff.booking_price_sum - lastMonthDataJPStaff.booking_price_sum) / lastMonthDataJPStaff.booking_price_sum) * 100;

                                            return (
                                                <span>較上月 <span style={{ color: differencePercentage >= 0 ? '#fc1a01' : '#27b044' }}>{differencePercentage >= 0 ? '+ ' : '- '}{Math.abs(differencePercentage).toFixed(2)}%</span></span>
                                            );
                                        })()
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                        </>
                    )}
                </Box>
            </Box>

            {(userGroups.includes('管理員') || userGroups.includes('會計人員')) && (
                <Box className={styles['dataTable-box']}>
                    <Box className={styles['dataTable-header-box']}>
                        {tabValue === 0 && (
                            <>
                            <Box sx={{ display: 'flex', gap: '10px', position: 'absolute', left: 0, marginLeft: '15px' }}>
                                <Tooltip
                                    placement='top-start'
                                    title={
                                        <Typography sx={{ fontSize: '15px' }}>
                                            外幣報價、預估外幣報價、外站報價等數據皆可透過滑鼠連點進行編輯、修改
                                        </Typography>
                                    }
                                >
                                    <Info className={styles['info-icon']} />
                                </Tooltip>
                                {(filterSalesRep ? currentMonthData.filter(order => order.sales_rep === filterSalesRep) : currentMonthData).length > 0 &&
                                    <Button
                                        className={styles['export-button']}
                                        disableElevation
                                        onClick={() => exportDashBoardDataToExcel(filterSalesRep ? currentMonthData.filter(order => order.sales_rep === filterSalesRep) : currentMonthData, filterSalesRep ? { year: year, month: month, salesRep: salesRepList.find(user => user.id === filterSalesRep)?.profile?.nickname || salesRepList.find(user => user.id === filterSalesRep)?.username } : { year: year, month: month })}
                                    >
                                        <SaveAlt sx={{ width: '16px', height: '16px' }}/>
                                        導出{year}年{month}月數據{filterSalesRep ? ` - ${salesRepList.find(user => user.id === filterSalesRep)?.profile?.nickname || salesRepList.find(user => user.id === filterSalesRep)?.username}` : ''}
                                    </Button>
                                }
                            </Box>
                            <Box className={styles['filter-box']}>
                                <Autocomplete
                                    options={salesRepList.filter(user => user.profile.tags === '台灣業務' || user.profile.tags === '台灣管理' || user.profile.tags === '管理人員')}
                                    getOptionLabel={(option) => option?.profile?.nickname || option?.username}
                                    value={filterSalesRep ? salesRepList.find(user => user.id === filterSalesRep) : null}
                                    sx={{
                                        minWidth: '180px',
                                        '& .MuiInput-root.MuiInputBase-sizeSmall': {
                                            '& .MuiInput-input': {
                                                paddingTop: '4px',
                                                paddingBottom: '1px',
                                            },
                                        }
                                    }}
                                    renderInput={(params) => 
                                        <TextField {...params} 
                                            size='small'
                                            variant='standard'
                                            placeholder='業務篩選'
                                            InputProps={{
                                                ...params.InputProps,
                                                style: { color: 'white', fontWeight: 'bold' },
                                            }}
                                        />
                                    }
                                    onChange={(event, newValue) => {
                                        setFilterSalesRep(newValue?.id);
                                    }}
                                />

                                <TextField
                                    value={filterCreatedDate}
                                    type='date'
                                    size='small'
                                    variant='standard'
                                    InputProps={{ style: { color: 'white', fontWeight: 'bold' } }}
                                    sx={{
                                        minWidth: '180px',
                                        '& .MuiInput-input': {
                                            paddingTop: '5px',
                                            paddingBottom: '1px',
                                        },
                                    }}
                                    onChange={(event) => {
                                        setFilterCreatedDate(event.target.value);
                                    }}
                                />
                            </Box>
                            </>
                        )}
                        <Box className={styles['options-box']}>
                            <Button
                                className={styles['options-button-1']}
                                disableRipple
                                sx={tabValue === 0 ? { backgroundColor: '#4b6ab9', ':hover': { backgroundColor: '#4b6ab9' } } : {}}
                                onClick={() => handleTabChange(0)}
                            >
                                收益
                            </Button>
                            <Button
                                className={styles['options-button-2']}
                                disableRipple
                                sx={tabValue === 1 ? { backgroundColor: '#4b6ab9', ':hover': { backgroundColor: '#4b6ab9' } } : {}}
                                onClick={() => handleTabChange(1)}
                            >
                                款項
                            </Button>
                        </Box>
                    </Box>
                    {renderTabContent()}
                </Box>
            )}
        </Container>
    );
}

export default DashBoard;