import React, { useState, useEffect } from 'react';
import api from '../../config/axiosConfig';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import { Autocomplete, Avatar, Box, Button, Card, CardContent, Chip, Divider, Grid, Link, TextField, Tooltip, Typography } from '@mui/material';
import styles from './css/EmployeeProfileTab.module.css';
import { Add, AddCircleOutlined, CloudUpload, DoDisturbOn, OpenInNew } from '@mui/icons-material';

const EmployeeProfileTab = () => {
    const [employeeProfile, setEmployeeProfile] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [newEmployee, setNewEmployee] = useState({});
    const [addingEmployee, setAddingEmployee] = useState(false);
    const [errors, setErrors] = useState({});
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            console.log('Before Unload Event', event);
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);
        
        const fetchData = async () => {
            try {

                const employeeProfileResponse = await api.get('/core/api/EmployeeProfile/');
                setEmployeeProfile(employeeProfileResponse.data);

                const employeeOptionsResponse = await api.get('/core/api/employee-options/');
                setEmployeeOptions(employeeOptionsResponse.data);

            } catch (error) {
                console.error(error);
            }
        }

        fetchData();

        // eslint-disable-next-line
    }, []);

    const handleAddingEmployee = () => {
        setAddingEmployee(true);
        setHasUnsavedChanges(true);
    };

    const handleNewEmployeeSubmit = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        const formData = new FormData();
        
        for (const key in newEmployee) {
            if (key === 'employee_photo_url') {
                continue;
            }
            formData.append(key, newEmployee[key]);
        }

        try {
            const response = await api.post('/core/api/EmployeeProfile/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log(response.data);
            setEmployeeProfile([...employeeProfile, response.data]);
            setHasUnsavedChanges(false);
            setAddingEmployee(false);
            setNewEmployee({});
            setErrors({});
        } catch (error) {
            console.error(error);
            setErrors(error.response.data);
        }
    };

    return (
        <Box className={styles['main-box']}>

            <Card className={styles['card']}>
                <CardContent className={styles['cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

                    {!addingEmployee ? (
                        <>
                            <Box className={styles['view-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            姓名
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            使用者帳號
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            部門職位
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            入職日期
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            工作狀態
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography className={styles['title-label']}>
                                            查看
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['view-cardcontent-content-box']}>
                                {/* {employeeProfile.flatMap((employee) => [employee, employee, employee, employee, employee, employee]).map((employee) => ( */}
                                {employeeProfile.map((employee) => (
                                    <>
                                        <Grid container key={employee.employee_number}>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar className={styles['employee-avatar']} src={employee.employee_photo} />
                                                <Typography>
                                                    <span style={{ fontSize: '15px', paddingLeft: '8px' }}>{employee.employee_name}</span> <span style={{ fontSize: '13px', color: 'GrayText'}}>#{employee.employee_number}</span>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {employee?.employee_account ? (
                                                        <Chip
                                                            avatar={
                                                                <Avatar
                                                                    alt={employee?.employee_account?.username}
                                                                    src={employee?.employee_account?.profile?.photo}
                                                                />
                                                            }
                                                            label={employee?.employee_account?.username}
                                                            variant="outlined"
                                                            sx={{ '&:hover': { backgroundColor: '#e0e0e0' } }}
                                                        />
                                                    ) : (
                                                        <span style={{ color: 'GrayText' }}>未綁定帳號</span>
                                                    )}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {employee.employee_department} / {employee.employee_position}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {employee.employee_hire_date ? new Date(employee.employee_hire_date).toLocaleDateString() : <span style={{ color: 'GrayText' }}>未入職</span>}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {employee.employee_status}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Link href={`/EmployeeInfo/${employee.employee_number}`} target="_blank" rel="noopener" underline='none' color={false}>
                                                    <OpenInNew className={styles['view-icon']} />
                                                </Link>
                                            </Grid>

                                        </Grid>
                                        {employeeProfile.indexOf(employee) !== employeeProfile.length - 1 && <Divider />}
                                    </>
                                ))}
                            </Box>

                            <Box className={styles['view-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className={styles['add-button']} startIcon={<Add />} disableRipple onClick={handleAddingEmployee}>
                                            新增員工資料卡
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box className={styles['adding-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在新增員工資料卡. . .請完整填寫以下資料
                                            <span style={{ color: '#d32f2f' }}> (帶*為必填欄位)</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={12} sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                        <input
                                            type="file"
                                            accept='image/*'
                                            hidden
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    setNewEmployee({ 
                                                        ...newEmployee,
                                                        employee_photo: file,
                                                        employee_photo_url: reader.result
                                                    });
                                                }
                                                reader.readAsDataURL(file);
                                            }}
                                        />
                                        <Avatar src={newEmployee?.employee_photo_url} className={styles['adding-employee-avatar']} />
                                        <Tooltip title="點擊上傳照片" placement='top'>
                                            <CloudUpload className={styles['upload-icon']} onClick={() => document.querySelector('input[type="file"]').click()} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.employee_name || ''}
                                            helperText={errors?.employee_name || ''}
                                            required
                                            label="姓名"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={employeeOptions?.employee_profile?.employee_gender || []}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.employee_gender || ''}
                                                    helperText={errors?.employee_gender || ''}
                                                    required
                                                    label="性別"
                                                    variant="standard"
                                                    size='small'
                                                    fullWidth
                                                />
                                            }
                                            onChange={(e, value) => setNewEmployee({ ...newEmployee, employee_gender: value?.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.employee_birthday || ''}
                                            helperText={errors?.employee_birthday || ''}
                                            required
                                            label="生日"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            type='date'
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_birthday: e.target.value })}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.employee_id || ''}
                                            helperText={errors?.employee_id || ''}
                                            required
                                            label="身份證字號"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_id: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={employeeOptions?.employee_profile?.employee_marriage || []}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.employee_marriage || ''}
                                                    helperText={errors?.employee_marriage || ''}
                                                    required
                                                    label="婚姻狀況"
                                                    variant="standard"
                                                    size='small'
                                                    fullWidth
                                                />
                                            }
                                            onChange={(e, value) => setNewEmployee({ ...newEmployee, employee_marriage: value?.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.employee_phone || ''}
                                            helperText={errors?.employee_phone || ''}
                                            required
                                            label="聯絡電話"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_phone: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.employee_email || ''}
                                            helperText={errors?.employee_email || ''}
                                            label="電子信箱"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_email: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField
                                            error={errors?.employee_address || ''}
                                            helperText={errors?.employee_address || ''}
                                            required
                                            label="通訊地址"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_address: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.emergency_contact_name || ''}
                                            helperText={errors?.emergency_contact_name || ''}
                                            required
                                            label="緊急聯絡人姓名"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, emergency_contact_name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.emergency_contact_relationship || ''}
                                            helperText={errors?.emergency_contact_relationship || ''}
                                            required
                                            label="與緊急聯絡人關係"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, emergency_contact_relationship: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.emergency_contact_phone || ''}
                                            helperText={errors?.emergency_contact_phone || ''}
                                            required
                                            label="緊急聯絡人電話"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, emergency_contact_phone: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            options={employeeOptions?.employee_profile?.employee_education || []}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.employee_education || ''}
                                                    helperText={errors?.employee_education || ''}
                                                    required
                                                    label="最高學歷"
                                                    variant="standard"
                                                    size='small'
                                                    fullWidth
                                                />
                                            }
                                            onChange={(e, value) => setNewEmployee({ ...newEmployee, employee_education: value?.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.employee_school || ''}
                                            helperText={errors?.employee_school || ''}
                                            required
                                            label="畢業學校"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_school: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.employee_major || ''}
                                            helperText={errors?.employee_major || ''}
                                            required
                                            label="就讀科系"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_major: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <TextField
                                            error={errors?.employee_number || ''}
                                            helperText={errors?.employee_number || ''}
                                            required
                                            label="員工編號"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_number: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <TextField
                                            error={errors?.employee_position || ''}
                                            helperText={errors?.employee_position || ''}
                                            required
                                            label="職位"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_position: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <TextField
                                            error={errors?.employee_department || ''}
                                            helperText={errors?.employee_department || ''}
                                            required
                                            label="部門"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_department: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <TextField
                                            error={errors?.employee_hire_date || ''}
                                            helperText={errors?.employee_hire_date || ''}
                                            label="入職日期"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            type='date'
                                            onChange={(e) => setNewEmployee({ ...newEmployee, employee_hire_date: e.target.value })}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <Autocomplete
                                            options={employeeOptions?.employee_profile?.employee_status || []}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.employee_status || ''}
                                                    helperText={errors?.employee_status || ''}
                                                    label="工作狀態"
                                                    variant="standard"
                                                    size='small'
                                                    fullWidth
                                                />
                                            }
                                            onChange={(e, value) => setNewEmployee({ ...newEmployee, employee_status: value?.value })}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button
                                            className={styles['cancel-button']}
                                            disableRipple
                                            onClick={() => {
                                                setAddingEmployee(false);
                                                setHasUnsavedChanges(false);
                                                setNewEmployee({});
                                                setErrors({});
                                            }}
                                        >
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Button className={styles['submit-button']} disableRipple onClick={handleNewEmployeeSubmit}>
                                            提交
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                </CardContent>
            </Card>

        </Box>
    );
}

export default EmployeeProfileTab;