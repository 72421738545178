import axios from 'axios';

// export const BACKEND_URL = 'http://127.0.0.1:8000';
export const BACKEND_URL = 'https://shoman-workspace.com';

const api = axios.create({
    baseURL: BACKEND_URL,
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 401 && !error.config.url.endsWith('/core/api/token/')) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.replace('/login');
    }
    return Promise.reject(error);
});

export default api;