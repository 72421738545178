import React, { useState, useEffect } from 'react';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import api from '../../config/axiosConfig';
import { Autocomplete, Box, Button, Card, CardContent, Divider, Grid, TextField, Typography, IconButton,
    Avatar, Chip, Tooltip, Dialog, DialogContent, Alert
} from '@mui/material';
import { Add, Delete, Edit, DoDisturbOn, AddCircleOutlined, NotificationImportant } from '@mui/icons-material';
import styles from './css/UserTab.module.css';

const UserTab = () => {
    const [group, setGroup] = useState([]);
    const [user, setUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [editingUser, setEditingUser] = useState(false);
    const [addingUser, setAddingUser] = useState(false);
    const [newUser, setNewUser] = useState({});
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [deleteUser, setDeleteUser] = useState({});
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState({});
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            try {
                const groupResponse = await api.get('/core/api/Group/');
                setGroup(groupResponse.data);

                const userResponse = await api.get('/core/api/User/');
                setUser(userResponse.data);
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [setHasUnsavedChanges]);

    useEffect(() => {
        let timer;
        if (status === 'NewUserSuccess' || status === 'EditUserSuccess' || status === 'DeleteUserSuccess') {
            timer = setTimeout(() => {
                if (status === 'NewUserSuccess' || status === 'EditUserSuccess' || status === 'DeleteUserSuccess') {
                    setStatus('initial');
                }
            }, 11500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleAddingUser = () => {
        setAddingUser(true);
        setHasUnsavedChanges(true);
    };

    const handleNewUserSubmit = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const updateNewUser = { 
                ...newUser,
                password: 'defaultpassword',
                confirm_password: 'defaultpassword',
                profile: { ...newUser.profile, account_type: '全站使用' }
            };
            const response = await api.post('/core/api/User/', updateNewUser);
            setUser([...user, response.data]);
            setHasUnsavedChanges(false);
            setAddingUser(false);
            setStatus('NewUserSuccess');
            setNewUser({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('NewUserError');
        }
    };

    const handleEditUser = (user) => {
        setErrors({});
        setSelectedUser(user);
        setEditingUser(true);
        setHasUnsavedChanges(true);
    };

    const handleEditUserSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            delete selectedUser.groups;
            const { profile: { photo, ...restProfile }, ...restUser } = selectedUser;
            const userWithoutPhoto = { ...restUser, profile: restProfile };
            const response = await api.patch(`/core/api/User/${selectedUser.id}/`, userWithoutPhoto);
            setUser(user.map((user) => user.id === selectedUser.id ? response.data : user));
            setHasUnsavedChanges(false);
            setEditingUser(false);
            setStatus('EditUserSuccess');
            setSelectedUser({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('EditUserError');
        }
    };

    const handleDeleteUser = async (user, id) => {
        setDeleteCheck(true);
        setDeleteUser({ user: user, id: id });
    }

    const handleDeleteUserConfirm = async (id) => {
        try {
            await api.delete(`/core/api/User/${id}/`);
            setUser(user.filter((u) => u.id !== id));
            setDeleteCheck(false);
            setStatus('DeleteUserSuccess');
            setDeleteUser({});
            setErrors({});
        } catch (error) {
            console.error(error);
            setErrors(error.response?.data);
            setStatus('DeleteUserError');
        }
    }

    return (
        <Box className={styles['main-box']}>

            <Card className={styles['card']}>
                <CardContent className={styles['cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

                    {!addingUser && !editingUser && (
                        <>
                            <Box className={styles['view-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            使用者帳號
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            暱稱/別名
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            權限群組
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            創建時間
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2.2}>
                                        <Typography className={styles['title-label']}>
                                            最後登入
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography className={styles['title-label']}>
                                            操作
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['view-cardcontent-content-box']}>
                                {user.filter((users) => users.profile.account_type === "全站使用").map((users) => (
                                    <>
                                        <Grid container key={users.id}>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']} sx={{ padding: '10px 0 10px 0' }}>
                                                    <Chip label={users.username} avatar={<Avatar src={users.profile.photo} alt={users.username} />} />
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {users.profile.nickname ? users.profile.nickname : <span style={{ color: 'GrayText'}}>未設定</span>}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {users.groups.map((group) => (
                                                        <div key={group.id} className={styles['groups-area']}>
                                                            {group.name}
                                                        </div>
                                                    ))}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {new Date(users.date_joined).toLocaleDateString()}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {users.last_login ? new Date(users.last_login).toLocaleString() : <span style={{ color: 'GrayText' }}>無記錄</span>}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="編輯" arrow placement="left">
                                                    <IconButton className={styles['edit-button']} disableRipple onClick={() => handleEditUser(users)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="刪除" arrow placement="right">
                                                    <IconButton className={styles['delete-button']} disableRipple onClick={() => handleDeleteUser(users.username, users.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>

                                        </Grid>

                                        {user.filter((users) => users.profile.account_type === "全站使用").indexOf(users) !== user.filter((users) => users.profile.account_type === "全站使用").length - 1 && (
                                            <Divider />
                                        )}
                                    </>
                                ))}
                            </Box>

                            <Box className={styles['view-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddingUser}>新增使用者帳號</Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <div style={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                                {status === 'NewUserSuccess' && (
                                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                                        成功新增使用者帳號
                                    </Alert>
                                )}
                                {status === 'EditUserSuccess' && (
                                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                                        成功保存使用者帳號資料
                                    </Alert>
                                )}
                                {status === 'DeleteUserSuccess' && (
                                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                                        成功刪除使用者帳號
                                    </Alert>
                                )}
                            </div>
                        </>
                    )}

                    {addingUser && (
                        <>
                            <Box className={styles['adding-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在新增使用者帳號. . .
                                            <span style={{ color: '#d32f2f' }}> (帶*為必填欄位，註: 密碼將自動設定為 "defaultpassword"，請使用者登入後自行修改密碼)</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.username}
                                            helperText={errors?.username}
                                            required
                                            label="使用者帳號"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            onChange={(event) => setNewUser({ ...newUser, username: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.profile?.nickname}
                                            helperText={errors?.profile?.nickname}
                                            label="暱稱/別名"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            onChange={(event) => setNewUser({ ...newUser, profile: { ...newUser.profile, nickname: event.target.value } })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.profile?.phone}
                                            helperText={errors?.profile?.phone}
                                            label="聯絡電話"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            onChange={(event) => setNewUser({ ...newUser, profile: { ...newUser.profile, phone: event.target.value } })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.email}
                                            helperText={errors?.email}
                                            label="電子郵件"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            onChange={(event) => setNewUser({ ...newUser, email: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={group.filter(option => option.info.group_type === "全站使用")}
                                            getOptionLabel={(option) => option.name}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="權限群組"
                                                    placeholder="選擇權限群組(可複選)"
                                                    variant="standard"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                const groupIds = value.map(group => group.id);
                                                setNewUser({ ...newUser, group_ids: groupIds });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button className={styles['cancel-button']} onClick={() => {
                                            setAddingUser(false);
                                            setHasUnsavedChanges(false);
                                            setStatus('initial');
                                            setNewUser({});
                                            setErrors({});
                                        }}>
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '14px' }}>
                                            {status === 'NewUserError' && <span>提交失敗，請檢查所有欄位是否正確填寫</span>}
                                        </Typography>
                                        <Button className={styles['submit-button']} onClick={handleNewUserSubmit}>
                                            提交
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                    {editingUser && (
                        <>
                            <Box className={styles['editing-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在編輯使用者帳號 {selectedUser.username} . . .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['editing-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.username}
                                            helperText={errors?.username}
                                            required
                                            label="使用者帳號"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            value={selectedUser.username}
                                            onChange={(event) => setSelectedUser({ ...selectedUser, username: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.profile?.nickname}
                                            helperText={errors?.profile?.nickname}
                                            label="暱稱/別名"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            value={selectedUser.profile?.nickname}
                                            onChange={(event) => setSelectedUser({ ...selectedUser, profile: { ...selectedUser.profile, nickname: event.target.value } })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.profile?.phone}
                                            helperText={errors?.profile?.phone}
                                            label="聯絡電話"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            value={selectedUser.profile?.phone}
                                            onChange={(event) => setSelectedUser({ ...selectedUser, profile: { ...selectedUser.profile, phone: event.target.value } })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.email}
                                            helperText={errors?.email}
                                            label="電子郵件"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            value={selectedUser.email}
                                            onChange={(event) => setSelectedUser({ ...selectedUser, email: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={group.filter(option => option.info.group_type === "全站使用")}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            defaultValue={selectedUser.groups}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="權限群組"
                                                    placeholder="選擇權限群組(可複選)"
                                                    variant="standard"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                const groupIds = value.map(group => group.id);
                                                setSelectedUser({ ...selectedUser, group_ids: groupIds });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['editing-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button className={styles['cancel-button']} onClick={() => {
                                            setEditingUser(false);
                                            setHasUnsavedChanges(false);
                                            setStatus('initial');
                                            setSelectedUser({});
                                            setErrors({});
                                        }}>
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '14px' }}>
                                            {status === 'EditUserError' && <span>保存失敗，請仔細檢查所有數據資料</span>}
                                        </Typography>
                                        <Button className={styles['submit-button']} onClick={handleEditUserSave}>
                                            保存
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                </CardContent>
            </Card>

            <Dialog
                open={deleteCheck}
                onClose={() => setDeleteCheck(false)}
            >
                <DialogContent className={styles['delete-dialog-content']}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: '30px'}}>
                        <Typography className={styles['delete-dialog-title']}>
                            <NotificationImportant sx={{ fontSize: '40px' }} />
                            確定要刪除使用者帳號 {deleteUser.user} 嗎？
                        </Typography>
                        <Typography className={styles['delete-dialog-subtitle']}>
                            註: 刪除後將無法復原，請謹慎操作
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {status === 'DeleteUserError' && (
                            <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '15px' }}>
                                {errors?.error}
                            </Typography>
                        )}
                        <Box sx={{ position: 'absolute', bottom: '20px', display: 'flex', gap: '20px' }}>
                            <Button className={styles['delete-cancel-button']} disableRipple onClick={() => {
                                setDeleteCheck(false);
                                setStatus('initial');
                                setDeleteUser({});
                                setErrors({});
                            }}>
                                <DoDisturbOn sx={{ width: '20px', height: '20px' }}/>
                                取消
                            </Button>
                            <Button className={styles['delete-confirm-button']} disableRipple onClick={() => handleDeleteUserConfirm(deleteUser.id)}>
                                刪除
                                <Delete sx={{ width: '20px', height: '20px' }}/>
                            </Button>
                        </Box>
                    </Box>

                </DialogContent>
            </Dialog>

        </Box>
    );
}

export default UserTab;