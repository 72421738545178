import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../config/axiosConfig';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import { Autocomplete, Avatar, Box, Button, Card, CardContent, Container, Dialog, DialogContent, Divider, Grid, IconButton, Tab, Tabs, TextField, Tooltip, Typography, Link } from '@mui/material';
import { ArticleOutlined, CalendarMonthOutlined, DoneOutline, Female, Male, PaidOutlined, Link as LinkIcon, LinkOff, Edit, ToggleOff, ToggleOn, Style, Done } from '@mui/icons-material';
import styles from './css/EmployeeInfo.module.css';
import { ReactComponent as UnLinkImg } from './img/UnLink.svg';
import BasicInfo from './EmployeeInfoTabs/BasicInfoTab';
import Orders from './EmployeeInfoTabs/OrdersTab';

function EmployeeInfo() {
    const { id } = useParams();
    const [avatarCardData, setAvatarCardData] = useState({});
    const [employeePhoto, setEmployeePhoto] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [order_count, setOrderCount] = useState(0);
    const [order_completion_rate, setOrderCompletionRate] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [employee, setEmployee] = useState({});
    const [user, setUser] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [accountToggle, setAccountToggle] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { hasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {

        document.title = `員工資料卡 #${id}`;

        const fetchEmployee = async () => {
            try {
                const employeeResponse = await api.get(`/core/api/EmployeeProfile/${id}/`);
                setEmployee(employeeResponse.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchUser = async () => {
            try {
                const userResponse = await api.get('/core/api/User/');
                setUser(userResponse.data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchUser();
        fetchEmployee();
        // eslint-disable-next-line
    }, []);

    const fetchEmployees = async () => {
        try {
            const response = await api.get('/core/api/EmployeeProfile/');
            setAllEmployees(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAccountToggleIconClick = () => {
        setAccountToggle(true);
        fetchEmployees();
    };

    const handleAvatarCardClick = () => {
        setIsEditing(true);
        setAvatarCardData({
            employee_number: employee.employee_number,
            employee_account_id: employee.employee_account?.id || null,
        });
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        setEmployeePhoto(file);
        setPhotoPreview(URL.createObjectURL(file));
    };

    const handleAvatarCardSubmit = async () => {
        // event.preventDefault();
        const formData = new FormData();
        formData.append('employee_number', avatarCardData.employee_number);
        formData.append('employee_account_id', avatarCardData?.employee_account_id || '');
        if (employeePhoto) {
            formData.append('employee_photo', employeePhoto);
        }

        try {
            const response = await api.patch(`/core/api/EmployeeProfile/${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setEmployee(response.data);
            setIsEditing(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAccountActive = async (status) => {

        try {
            await api.patch(`/core/api/User/${employee?.employee_account?.id}/`, {
                'is_active': status
            });
            setEmployee({
                ...employee,
                employee_account: {
                    ...employee.employee_account,
                    is_active: status,
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleEmployeeChange = (updatedEmployee) => {
        setEmployee(updatedEmployee);
    };

    const handleOrderInfo = (orderCount, orderCompletionRate) => {
        setOrderCount(orderCount);
        setOrderCompletionRate(orderCompletionRate);
    };

    const renderTabContent = () => {
        switch (tabValue) {
            case 0:
                return <BasicInfo employee={employee} onEmployeeChange={handleEmployeeChange} />;
            case 1:
                return <Orders employee={employee} OrderInfo={handleOrderInfo} />;
            // case 2:
            //     return <SalaryHistory employee={employee} />;
            // case 3:
            //     return <Others employee={employee} />;
            default:
                return null;
        }
    };

    return (
        <Container maxWidth={false} className={styles['container']}>
            <Grid container>
                
                <Grid item xs={3}>
                    <Card className={styles['left-card']}>
                        
                        <Style className={styles['options-icon']} onClick={handleAccountToggleIconClick} />

                        <Card elevation={0} className={styles['avatar-card']} sx={{ position: 'relative', overflow: 'visible' }}>
                            <Avatar src={employee.employee_photo} className={styles['avatar']} />
                            {employee?.employee_account ? (
                                <Tooltip title='已綁定帳號' placement='top' arrow>
                                    <LinkIcon className={styles['account-linked']} />
                                </Tooltip>
                            ) : (
                                <Tooltip title='未綁定帳號' placement='top' arrow>
                                    <LinkOff className={styles['account-unlinked']} />
                                </Tooltip>
                            )}
                            <Tooltip title='點擊編輯資料卡' placement='top' arrow>
                                <Edit className={styles['avatar-card-edit']} onClick={handleAvatarCardClick} />
                            </Tooltip>
                            <CardContent sx={{ mt: 4 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography gutterBottom variant='h5'>
                                        {employee.employee_name || ''}
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: '#999999', fontSize: '20px', marginLeft: '10px' }}>
                                        #{id}
                                    </Typography>
                                    <Typography gutterBottom>
                                        {employee.employee_gender === '男' && (
                                            <Male className={styles['gender-male']} />
                                        )}
                                        {employee.employee_gender === '女' && (
                                            <Female className={styles['gender-female']} />
                                        )}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Card className={styles['status-card']}>
                                        <CardContent className={styles['status-cardcontent']}>
                                            {employee?.employee_account?.profile.is_online && (
                                                <>
                                                    <div className={styles['status-indicator-online']} />
                                                    <Typography className={styles['status-text-online']}>
                                                        上線中
                                                    </Typography>
                                                </>
                                            )}
                                            {employee?.employee_account?.profile.is_online === false && (
                                                <>
                                                    <div className={styles['status-indicator-offline']} />
                                                    <Typography className={styles['status-text-offline']}>
                                                        離線
                                                    </Typography>
                                                </>
                                            )}
                                            {!employee?.employee_account && (
                                                <>
                                                    <div className={styles['status-indicator-none']} />
                                                    <Typography className={styles['status-text-none']}>
                                                        未綁定
                                                    </Typography>
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </CardContent>
                        </Card>

                        <Card elevation={0} className={styles['account-card']}>
                            <CardContent sx={{ height: '100%', padding: 0, overflow: 'auto', '&:last-child': { paddingBottom: 0 } }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 'bold', color: '#000000', padding: '10px 0' }}>
                                    系統帳號資訊
                                </Typography>
                                {employee.employee_account ? (
                                    <>
                                        <Box className={styles['account-photo-box']}>
                                            <Avatar src={employee.employee_account?.profile?.photo} sx={{ width: '60px', height: '60px'}} />
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                使用者帳號
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.username || ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                暱稱/別名
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.profile?.nickname || ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                公開電話(業務)
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.profile?.phone || ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                電子郵件
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.email || ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                帳號用途
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.profile?.account_type || ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                帳號標籤
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.profile?.tags || ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                Line系統
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.profile?.line_uuid && '已綁定'}
                                                {employee.employee_account?.profile?.line_uuid === '' && '未綁定'}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                權限群組
                                            </Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                {employee.employee_account?.groups.map((group) => (
                                                    <Typography key={group.id} sx={{ fontSize: '16px', color: '#3371ff'}}>
                                                        {group.name}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                最後登入
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.last_login ? new Date(employee.employee_account.last_login).toLocaleString() : ''}
                                            </Typography>
                                        </Box>
                                        <Box className={styles['account-info-box']}>
                                            <Typography sx={{ fontSize: '14px', color: 'GrayText' }}>
                                                註冊時間
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px' }}>
                                                {employee.employee_account?.date_joined ? new Date(employee.employee_account.date_joined).toLocaleString() : ''}
                                            </Typography>
                                        </Box>
                                        {/* 帳號啟用狀態設計 */}
                                        <Box className={styles['account-active-box']}>
                                            <Typography sx={{ position: 'absolute', top: '15px', fontSize: '14px', color: 'GrayText' }}>
                                                帳 號 當 前 狀 態
                                            </Typography>
                                            {employee.employee_account?.is_active ? (
                                                <>
                                                    <ToggleOn className={styles['account-active-button']} onClick={() => handleAccountActive(false)} />
                                                    <div className={styles['account-active-text']} />
                                                </>
                                            ) : (
                                                <>
                                                    <ToggleOff className={styles['account-inactive-button']} onClick={() => handleAccountActive(true)} />
                                                    <div className={styles['account-inactive-text']} />
                                                </>
                                            )}
                                        </Box>
                                    </>
                                ) : (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px 0' }}>
                                        <UnLinkImg style={{ width: '300px'}} />
                                        <Typography sx={{ fontSize: '16px', color: 'GrayText', mt: 2 }}>
                                            未綁定帳號
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>

                    </Card>
                </Grid>

                <Grid item xs={9}>
                    <Grid container sx={{ justifyContent: 'center' }}>

                        <Box className={styles['info-box']}>
                            {tabValue === 0 && (
                                <Card className={styles['info-card']}>
                                    <CardContent className={styles['info-cardcontent']}>
                                        <Box className={styles['icon-box-4']}>
                                            <CalendarMonthOutlined style={{ fontSize: 80 }} />
                                        </Box>
                                        <Box>
                                            <Typography className={styles['info-description']}>
                                                入職天數
                                            </Typography>
                                            <Typography className={styles['info-description']}>
                                                <span style={{ fontSize: '35px', fontWeight: '800', paddingRight: '3px' }}>
                                                    {employee.employee_hire_date ? Math.floor((new Date(new Date().setHours(0, 0, 0, 0)) - new Date(new Date(employee.employee_hire_date).setHours(0, 0, 0, 0))) / (1000 * 60 * 60 * 24)) : 0}
                                                </span>
                                                <span style={{ fontSize: '15px', color: 'GrayText' }}>天</span>
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            )}
                            {tabValue === 1 && (
                                <>
                                    <Card className={styles['info-card']}>
                                        <CardContent className={styles['info-cardcontent']}>
                                            <Box className={styles['icon-box-1']}>
                                                <ArticleOutlined style={{ fontSize: 80, color: '#3371ff' }} />
                                            </Box>
                                            <Box>
                                                <Typography className={styles['info-description']}>
                                                    訂單數量
                                                </Typography>
                                                <Typography className={styles['info-description']}>
                                                    <span style={{ fontSize: '35px', fontWeight: '800', paddingRight: '3px' }}>{order_count}</span><span style={{ fontSize: '15px', color: 'GrayText' }}>筆</span>
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Card className={styles['info-card']}>
                                        <CardContent className={styles['info-cardcontent']}>
                                            <Box className={styles['icon-box-2']}>
                                                <DoneOutline style={{ fontSize: 70, color: '#1e6423' }} />
                                            </Box>
                                            <Box>
                                                <Typography className={styles['info-description']}>
                                                    訂單完成率
                                                </Typography>
                                                <Typography className={styles['info-description']}>
                                                    <span style={{ fontSize: '35px', fontWeight: '800', paddingRight: '3px' }}>{order_completion_rate}</span><span style={{ fontSize: '15px', color: 'GrayText' }}>%</span>
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </>
                            )}
                            {tabValue === 2 && (
                                <Card className={styles['info-card']}>
                                    <CardContent className={styles['info-cardcontent']}>
                                        <Box className={styles['icon-box-3']}>
                                            <PaidOutlined style={{ fontSize: 80, color: '#ffbf30' }} />
                                        </Box>
                                        <Box>
                                            <Typography className={styles['info-description']}>
                                                當前薪資
                                            </Typography>
                                            <Typography className={styles['info-description']}>
                                                <span style={{ fontSize: '35px', fontWeight: '800', paddingRight: '3px' }}>0</span><span style={{ fontSize: '15px', color: 'GrayText' }}>NT$</span>
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            )}
                        </Box>

                        <Grid item xs={12}>
                            <Card className={styles['details-card']}>
                                <CardContent className={styles['details-cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>
                                    <Box className={styles['details-title-box']}>
                                        <Tabs 
                                            value={tabValue} 
                                            indicatorColor=''
                                            variant="scrollable"
                                            scrollButtons="auto" 
                                            onChange={handleChange}
                                        >
                                            <Tab label="基本資料" disableRipple sx={{ '&.Mui-selected': { fontSize: '16px', fontWeight: 'bold', textShadow: '1px 1px 2px gray', color: '#000000' } }} className={styles['details-title-text']}/>
                                            <Tab label="承辦訂單" disabled={!employee.employee_account || hasUnsavedChanges} disableRipple sx={{ '&.Mui-selected': { fontSize: '16px', fontWeight: 'bold', textShadow: '1px 1px 2px gray', color: '#000000' } }} className={styles['details-title-text']}/>
                                            <Tab label="歷史薪資" disabled disableRipple sx={{ '&.Mui-selected': { fontSize: '16px', fontWeight: 'bold', textShadow: '1px 1px 2px gray', color: '#000000' } }} className={styles['details-title-text']}/>
                                            <Tab label="其他" disabled disableRipple sx={{ '&.Mui-selected': { fontSize: '16px', fontWeight: 'bold', textShadow: '1px 1px 2px gray', color: '#000000' } }} className={styles['details-title-text']}/>
                                        </Tabs>
                                    </Box>
                                    <Box className={styles['details-content-box']}>
                                        {renderTabContent()}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

            <Dialog
                open={accountToggle}
                onClose={() => setAccountToggle(false)}
            >
                <DialogContent className={styles['dialog-content']}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 2 }}>
                        切換員工資料卡
                    </Typography>
                    <Box sx={{ maxHeight: '500px', width: '100%', display: 'flex', flexDirection: 'column', gap: 1, overflow: 'auto' }}>
                        {allEmployees
                            .sort((a, b) => {
                                if (a.employee_number === employee.employee_number) return -1;
                                if (b.employee_number === employee.employee_number) return 1;
                                return a.employee_number - b.employee_number;
                            })
                            .map((employees) => (
                            employees.employee_number === employee.employee_number ? (
                                <Box className={styles['employee-option-box']}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={employees.employee_photo} sx={{ width: 24, height: 24, mr: '5px' }} />
                                        <Typography sx={{ fontSize: '16px', lineHeight: 'normal' }}>
                                            {employees.employee_name}
                                        </Typography>
                                    </Box>
                                    <Tooltip title='當前資料卡' placement='top' arrow>
                                        <Done className={styles['current-employee-icon']} />
                                    </Tooltip>
                                </Box>
                            ) : (
                                <Link key={employees.id} href={`/EmployeeInfo/${employees.employee_number}`} underline='none' color={false}>
                                    <Box className={styles['employee-option-box']}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar src={employees.employee_photo} sx={{ width: 24, height: 24, mr: '5px' }} />
                                            <Typography sx={{ fontSize: '16px', lineHeight: 'normal' }}>
                                                {employees.employee_name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            )
                        ))}
                        <Divider variant='middle' />
                    </Box>
                    <Link href='#' underline='none' color={false} className={styles['add-employeeinfo-card']}>
                        新增員工資料卡
                    </Link>
                </DialogContent>
            </Dialog>

            <Dialog
                open={isEditing}
                onClose={() => setIsEditing(false)}
            >
                    <DialogContent className={styles['dialog-content']}>
                        <Typography>
                            資料卡編輯
                        </Typography>
                        <Typography variant='caption' sx={{ color: 'GrayText', mt: '20px' }}>
                            本人照片
                        </Typography>
                        <Box className={styles['photo-upload-box']}>
                            <input
                                type="file"
                                accept='image/*'
                                hidden
                                onChange={handlePhotoChange}
                            />
                            <Avatar
                                src={photoPreview ? photoPreview : employee.employee_photo || ''}
                                alt="Employee Photo"
                                sx={{ width: 100, height: 100 }}
                            />
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    width: 101,
                                    height: 101,
                                    top: '50%',
                                    right: '50%',
                                    transform: 'translate(50%, -50%)',
                                    opacity: 0,
                                    color: 'white',
                                    '&:hover': {
                                    backgroundColor: 'rgba(22, 28, 36)',
                                    opacity: 0.8,
                                    },
                                }}
                                disableRipple
                                onClick={() => document.querySelector('input[type="file"]').click()}
                            >
                                <Edit />
                            </IconButton>
                        </Box>
                        <TextField
                            label="員工編號"
                            value={avatarCardData.employee_number}
                            variant='standard'
                            fullWidth
                            onChange={(event) => setAvatarCardData({ ...avatarCardData, employee_number: event.target.value })}
                        />
                        <Autocomplete
                            options={user}
                            fullWidth
                            getOptionLabel={(option) => option?.profile?.nickname || option?.username}
                            defaultValue={user.find((user) => user.id === employee.employee_account?.id)}
                            renderInput={(params) => 
                                <TextField {...params}
                                    label="綁定帳號"
                                    margin='normal'
                                    variant='standard'
                                />
                            }
                            onChange={(event, newValue) => setAvatarCardData({ ...avatarCardData, employee_account_id: newValue?.id })}
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                sx={{ mt: 2, mr: 2 }}
                                onClick={() => setIsEditing(false)}
                            >
                                取消
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{ mt: 2 }}
                                onClick={handleAvatarCardSubmit}
                            >
                                儲存
                            </Button>
                        </Box>
                    </DialogContent>
            </Dialog>

        </Container>
    );
};

export default EmployeeInfo;