import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import UserGroupContext from '../../../contexts/UserGroupContext';
import api from '../../../config/axiosConfig';
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { AddCircle, Delete, DeleteForever, DoDisturbOn, Download, EditNote, SaveAs, Warning } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/DepositTab.module.css';

const DepositTab = ({ uuid, options }) => {
    const userGroups = React.useContext(UserGroupContext);
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {
                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExportRequest = (urlPath, fileName) => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            return;
        }
    
        setLastClickTime(now);
    
        handleMenuClose();
        // setIsLoading(true);
    
        const uniqueUrl = `${urlPath}?t=${now}`;
        
        api.get(uniqueUrl, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(error => {
            console.error('下載發生錯誤:', error);
        })
        .finally(() => {
            // setIsLoading(false);
        });
    }

    const handleAddNewDepositSlip = () => {

        setEditData({
            ...editData,
            newDepositslips: [
                ...(editData?.newDepositslips || []),
                {
                    identifier: uuidv4(),
                    deposit_item: null,
                    deposit_method: null,
                    deposit_info: '',
                    deposit_date: null,
                }
            ]
        });
    };

    const handleAddNewItemDescription = (idOrIdentifier, isNew = false) => {
        setEditData(prevEditData => {
            const depositSlip = 
                prevEditData?.depositslips?.find(depositslip => depositslip.id === idOrIdentifier || depositslip.identifier === idOrIdentifier) ||
                prevEditData?.newDepositslips?.find(depositslip => depositslip.identifier === idOrIdentifier);
            
            // console.log(depositSlip || '找不到對應的入款單');
    
            if (depositSlip && !isNew) {
                return {
                    ...prevEditData,
                    depositslips: prevEditData?.depositslips.map(depositslip => {
                        if (depositslip.id === depositSlip.id) {
                            return {
                                ...depositslip,
                                newItemDescriptions: [
                                    ...(depositslip.newItemDescriptions || []), // 確保初始化 newAdditionalItemItineraries
                                    {
                                        identifier: uuidv4(),
                                        unit_price: '',
                                        quantity: '',
                                        note: ''
                                    }
                                ]
                            };
                        }
                        return depositslip;
                    })
                };
            } else if (!depositSlip && !isNew) {
                return {
                    ...prevEditData,
                    depositslips: [
                        ...(prevEditData?.depositslips || []),
                        {
                            id: idOrIdentifier, // 使用 idOrIdentifier 作為 id
                            newItemDescriptions: [
                                {
                                    identifier: uuidv4(),
                                    unit_price: '',
                                    quantity: '',
                                    note: ''
                                }
                            ]
                        }
                    ]
                };
            } else if (isNew) {
                return {
                    ...prevEditData,
                    newDepositslips: prevEditData?.newDepositslips.map(depositslip => {
                        if (depositslip.identifier === idOrIdentifier) {
                            return {
                                ...depositslip,
                                newItemDescriptions: [
                                    ...(depositslip.newItemDescriptions || []), // 確保初始化 newItemDescriptions
                                    {
                                        identifier: uuidv4(),
                                        unit_price: '',
                                        quantity: '',
                                        note: ''
                                    }
                                ]
                            };
                        }
                        return depositslip;
                    })
                };
            }
    
            return prevEditData;
        });
    };

    const handleFieldChange = (arrayName, fieldName, fieldValue, idOrIdentifier, isParentNew = false, nestedArrayName = null, parentId = null, isNestedNew = false) => {
        setEditData((prevState) => {
            let currentArray = prevState?.[arrayName] ? [...prevState[arrayName]] : [];
    
            if (parentId && nestedArrayName) {
                // 查找父級項目，無論父級是否是新的
                let parentIndex = currentArray.findIndex(item => item.id === parentId || item.identifier === parentId);
    
                // 如果父級不存在，創建父級數據
                if (parentIndex === -1) {
                    const newParentItem = {
                        [nestedArrayName]: [],
                        ...(isParentNew ? { identifier: parentId } : { id: parentId })
                    };
                    currentArray.push(newParentItem);
                    parentIndex = currentArray.length - 1;
                }
    
                // 獲取父級項目並找到其嵌套數組
                const parentItem = { ...currentArray[parentIndex] };
                let nestedArray = parentItem[nestedArrayName] ? [...parentItem[nestedArrayName]] : [];
    
                // 根據 isNestedNew 和 identifier/id 查找嵌套數據
                const nestedItemIndex = isNestedNew
                    ? nestedArray.findIndex(item => item.identifier === idOrIdentifier)
                    : nestedArray.findIndex(item => item.id === idOrIdentifier);
    
                if (nestedItemIndex !== -1) {
                    nestedArray[nestedItemIndex] = {
                        ...nestedArray[nestedItemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newNestedItem = {
                        [fieldName]: fieldValue
                    };
                    if (isNestedNew) {
                        newNestedItem.identifier = idOrIdentifier;
                    } else {
                        newNestedItem.id = idOrIdentifier;
                    }
                    nestedArray.push(newNestedItem);
                }
    
                parentItem[nestedArrayName] = nestedArray;
                currentArray[parentIndex] = parentItem;
    
            } else {
                // 操作父級數據（非嵌套）
                const itemIndex = currentArray.findIndex(item => item.id === idOrIdentifier || item.identifier === idOrIdentifier);
    
                if (itemIndex !== -1) {
                    currentArray[itemIndex] = {
                        ...currentArray[itemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newItem = {
                        [fieldName]: fieldValue
                    };
                    if (isParentNew) {
                        newItem.identifier = idOrIdentifier;
                    } else {
                        newItem.id = idOrIdentifier;
                    }
                    currentArray.push(newItem);
                }
            }
    
            return {
                ...prevState,
                [arrayName]: currentArray
            };
        });
    };

    const handleEditClick = () => {
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditData(null);
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleDeleteClick = (deleteType, item) => {
        setDeleteItem({ deleteType, ...item });
        setDeleteCheckOpen(true);
    };

    const handleDeletCancelClick = (event, reason) => {
        // console.log(deleteItem);
        if (reason !== 'backdropClick') {
            setDeleteItem(null);
            setDeleteCheckOpen(false);
        }
    };

    const handleDeleteConfirmClick = async () => {
        switch (deleteItem.deleteType) {
            case 'DepositSlip': // 改DepositSlip
                try {
                    await api.delete(`/japan/api/DepositSlip/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        depositslips: prevState.depositslips.filter(depositslip => depositslip.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newDepositSlip': // 改newDepositSlip
                setEditData((prevState) => ({
                    ...prevState,
                    newDepositslips: prevState.newDepositslips.filter(depositslip => depositslip.identifier !== deleteItem.identifier)
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'ItemDescription': //改ItemDescription
                try {
                    await api.delete(`/japan/api/ItemDescription/${deleteItem.id}/`);
                    setData((prevState) => {
                        const depositslips = prevState.depositslips.map(depositslip => {
                            if (depositslip.id === deleteItem.deposit_slip) {
                                return {
                                    ...depositslip,
                                    itemdescriptions: depositslip.itemdescriptions.filter(item => item.id !== deleteItem.id)
                                };
                            }
                            return depositslip;
                        });
                        return {
                            ...prevState,
                            depositslips
                        };
                    });
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'o-ItemDescription': // 改o-ItemDescription
                setEditData((prevState) => ({
                    ...prevState,
                    depositslips: prevState.depositslips.map(depositslip => {
                        const newItemDescriptions = depositslip.newItemDescriptions.filter(item => item.identifier !== deleteItem.identifier);
                        return {
                            ...depositslip,
                            newItemDescriptions
                        };
                    })
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'n-newItemDescription': // 改n-newItemDescription
                setEditData((prevState) => ({
                    ...prevState,
                    newDepositslips: prevState.newDepositslips.map(depositslip => {
                        const newItemDescriptions = depositslip.newItemDescriptions.filter(item => item.identifier !== deleteItem.identifier);
                        return {
                            ...depositslip,
                            newItemDescriptions
                        };
                    })
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            default:
                console.log(`未知的刪除類型: ${deleteItem.deleteType}`);
                break;
        }
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);

            if (!editData) {
                setErrors(null);
                setEditMode(false);
                setEditData(null);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            // 檢查editData中的depositslips中的每筆資料是否包含付款方式(deposit_method)，如果有則檢查是否為"支票號碼"，若是則檢查是否有支票到期日(check_due_date)；反之，若不是"支票號碼"，則檢查是否有入款日期(deposit_date)，有的話則將其設為null

            const updateEditData = {
                ...editData,
                depositslips: [
                    ...(editData?.depositslips || []).map(depositslip => ({
                        ...depositslip,
                        order: uuid,
                        itemdescriptions: [
                            ...(depositslip.itemdescriptions || []),
                            ...(depositslip.newItemDescriptions || [])
                        ]
                    })),
                    ...(editData?.newDepositslips || []).map(depositslip => ({
                        ...depositslip,
                        order: uuid,
                        itemdescriptions: [
                            ...(depositslip.itemdescriptions || []),
                            ...(depositslip.newItemDescriptions || [])
                        ]
                    }))
                ]
            };

            delete updateEditData.newDepositslips;
            updateEditData.depositslips.forEach(depositslip => {
                delete depositslip.newItemDescriptions;
            });
            if (updateEditData.depositslips.length === 0) {
                delete updateEditData.depositslips;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, updateEditData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box sx={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Box className={styles['print-box']}>
                    <Button
                        className={styles['print-button']}
                        sx={{
                            '& .MuiButton-startIcon': {
                                margin: 0,
                                '&>*:nth-of-type(1)': {
                                    fontSize: '18px',
                                }
                            },
                        }}
                        disabled={editMode}
                        startIcon={<Download />}
                        onClick={handleMenuClick}
                    >
                        下載導出
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        {data?.depositslips.length > 0 &&
                            <MenuItem onClick={() => handleExportRequest(`/japan/export_order_deposit_slip/${uuid}/`, `${data?.group_number} - 入款單.pdf`)}>入款單</MenuItem>
                        }
                    </Menu>
                </Box>
            </Box>

            <Box className={styles['content-box']}>
                <Box className={styles['tab-main-box']}>

                    {data?.depositslips?.map((deposit, index) => (
                        <div key={deposit.id}>
                            <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                <Chip
                                    className={styles['chip-text']}
                                    label={"入款單 #" + (index + 1)}
                                    onDelete={editMode ? null : () => handleDeleteClick('DepositSlip', deposit)}
                                    deleteIcon={editMode ? null : <DeleteForever className={styles['delete-icon']}/>}
                                />
                            </Divider>
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.deposit_slip?.deposit_item}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.deposit_slip?.deposit_item.find(option => option.value === editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_item) || options?.deposit_slip?.deposit_item.find(option => option.value === deposit.deposit_item)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_item}
                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_item}
                                                label="款項"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("depositslips", "deposit_item", value?.value, deposit.id)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.deposit_slip?.deposit_method}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.deposit_slip?.deposit_method.find(option => option.value === editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method) || options?.deposit_slip?.deposit_method.find(option => option.value === deposit.deposit_method)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_method}
                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_method}
                                                label="入款方式"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("depositslips", "deposit_method", value?.value, deposit.id)}
                                    />
                                </Grid>
                                <Grid 
                                    item
                                    xs={((editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method !== undefined
                                        ? editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method === '支票號碼'
                                        : deposit?.deposit_method === '支票號碼')) ? 6 : 3
                                    }
                                >
                                    <TextField
                                        error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_info}
                                        helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_info}
                                        label="入款資訊"
                                        variant="standard"
                                        value={
                                            editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_info === ''
                                                ? ''
                                                : editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_info || deposit?.deposit_info
                                        }
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("depositslips", "deposit_info", e.target.value, deposit.id)}
                                    />
                                </Grid>
                                {((editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method !== undefined
                                    ? editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method === '支票號碼'
                                    : deposit?.deposit_method === '支票號碼')) && (
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.check_due_date}
                                            helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.check_due_date}
                                            label="支票到期日"
                                            variant="standard"
                                            value={
                                                editData?.depositslips?.find(a => a.id === deposit.id)?.check_due_date === null
                                                    ? ''
                                                    : editData?.depositslips?.find(a => a.id === deposit.id)?.check_due_date || deposit?.check_due_date
                                            }
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("depositslips", "check_due_date", e.target.value || null, deposit.id)}
                                        />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={((editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method !== undefined
                                        ? editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_method === '支票號碼'
                                        : deposit?.deposit_method === '支票號碼')) ? 6 : 3
                                    }
                                >
                                    <TextField
                                        error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_date}
                                        helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.deposit_date}
                                        label="入款日期"
                                        variant="standard"
                                        value={
                                            editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_date === null
                                                ? ''
                                                : editData?.depositslips?.find(a => a.id === deposit.id)?.deposit_date || deposit?.deposit_date
                                        }
                                        type='date'
                                        size='small'
                                        fullWidth
                                        required
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("depositslips", "deposit_date", e.target.value || null, deposit.id)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={styles['depositslips-itemdescriptions-box']}>
                                        <div>
                                            <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                                                <Chip className={styles['chip-text']} label="費用說明"/>
                                            </Divider>
                                            {deposit?.itemdescriptions?.map((itemdescription, index) => (
                                                <div key={itemdescription.id}>
                                                    <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.id === String(itemdescription.id))?.unit_price}
                                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.id === String(itemdescription.id))?.unit_price}
                                                                label="單價"
                                                                variant="standard"
                                                                value={
                                                                    editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.unit_price === ''
                                                                        ? ''
                                                                        : editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.unit_price || parseFloat(itemdescription?.unit_price).toFixed(2).replace(/\.00$/, '')
                                                                }
                                                                required
                                                                type='number'
                                                                size='small'
                                                                fullWidth
                                                                focused={editMode}
                                                                InputProps={{
                                                                    readOnly: !editMode,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                sx={{
                                                                    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type="number"]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    }
                                                                }}
                                                                onChange={(e) => handleFieldChange("depositslips", "unit_price", e.target.value, itemdescription.id, false, "itemdescriptions", deposit.id)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.id === String(itemdescription.id))?.quantity}
                                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.id === String(itemdescription.id))?.quantity}
                                                                label="數量"
                                                                variant="standard"
                                                                value={
                                                                    editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.quantity === ''
                                                                        ? ''
                                                                        : editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.quantity || itemdescription?.quantity
                                                                }
                                                                required
                                                                type='number'
                                                                size='small'
                                                                fullWidth
                                                                focused={editMode}
                                                                InputProps={{
                                                                    readOnly: !editMode,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                sx={{
                                                                    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type="number"]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    }
                                                                }}
                                                                onChange={(e) => handleFieldChange("depositslips", "quantity", e.target.value, itemdescription.id, false, "itemdescriptions", deposit.id)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="總計小額"
                                                                variant="standard"
                                                                value={
                                                                    editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.unit_price === ''
                                                                        ? ''
                                                                        : (editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.unit_price || itemdescription?.unit_price) * (editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.quantity || itemdescription?.quantity)
                                                                }
                                                                size='small'
                                                                fullWidth
                                                                focused={false}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.id === String(itemdescription.id))?.note}
                                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.id === String(itemdescription.id))?.note}
                                                                label="備註"
                                                                variant="standard"
                                                                value={
                                                                    editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.note === ''
                                                                        ? ''
                                                                        : editData?.depositslips?.find(a => a.id === deposit.id)?.itemdescriptions?.find(i => i.id === itemdescription.id)?.note || itemdescription?.note
                                                                }
                                                                size='small'
                                                                fullWidth
                                                                focused={editMode}
                                                                InputProps={{
                                                                    readOnly: !editMode,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(e) => handleFieldChange("depositslips", "note", e.target.value, itemdescription.id, false, "itemdescriptions", deposit.id)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <IconButton
                                                                className={styles['delete-icon']}
                                                                onClick={() => handleDeleteClick('ItemDescription', itemdescription)}
                                                                disabled={editMode}
                                                            >
                                                                <DeleteForever />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ))}
                                        </div>

                                        {!editMode && (
                                            <Box />
                                        )}

                                        {editData?.depositslips?.find(a => a.id === deposit.id)?.newItemDescriptions?.map((item, index) => (
                                            <div key={item.identifier}>
                                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.identifier === item.identifier)?.unit_price}
                                                            helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.identifier === item.identifier)?.unit_price}
                                                            label="單價"
                                                            variant="standard"
                                                            value={item.unit_price}
                                                            required
                                                            type='number'
                                                            size='small'
                                                            fullWidth
                                                            focused={editMode}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            sx={{
                                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                    '-webkit-appearance': 'none',
                                                                    margin: 0
                                                                },
                                                                '& input[type="number"]': {
                                                                    '-moz-appearance': 'textfield'
                                                                }
                                                            }}
                                                            onChange={(e) => handleFieldChange("depositslips", "unit_price", e.target.value, item.identifier, false, "newItemDescriptions", deposit.id, true)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.identifier === item.identifier)?.quantity}
                                                            helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.identifier === item.identifier)?.quantity}
                                                            label="數量"
                                                            variant="standard"
                                                            value={item.quantity}
                                                            required
                                                            type='number'
                                                            size='small'
                                                            fullWidth
                                                            focused={editMode}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            sx={{
                                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                    '-webkit-appearance': 'none',
                                                                    margin: 0
                                                                },
                                                                '& input[type="number"]': {
                                                                    '-moz-appearance': 'textfield'
                                                                }
                                                            }}
                                                            onChange={(e) => handleFieldChange("depositslips", "quantity", e.target.value, item.identifier, false, "newItemDescriptions", deposit.id, true)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            label="總計小額"
                                                            variant="standard"
                                                            value={item.unit_price * item.quantity}
                                                            size='small'
                                                            fullWidth
                                                            focused={false}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.identifier === item.identifier)?.note}
                                                            helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.itemdescriptions?.find(error => error.identifier === item.identifier)?.note}
                                                            label="備註"
                                                            variant="standard"
                                                            value={item.note}
                                                            size='small'
                                                            fullWidth
                                                            focused={editMode}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleFieldChange("depositslips", "note", e.target.value, item.identifier, false, "newItemDescriptions", deposit.id, true)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <IconButton
                                                            className={styles['delete-icon']}
                                                            onClick={() => handleDeleteClick('o-ItemDescription', item)}
                                                        >
                                                            <DeleteForever />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}

                                        {editMode && (
                                            <Box className={styles['sub-add-item-box']} sx={{ marginTop: '10px'}}>
                                                <Button className={styles['add-item-button']} onClick={() => handleAddNewItemDescription(deposit.id)}>
                                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                                    新增費用說明
                                                </Button>
                                            </Box>
                                        )}

                                        {!editMode && deposit?.itemdescriptions.length === 0 && !editData?.depositslips?.find(a => a.id === deposit.id)?.newItemDescriptions && (
                                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 10px 16px' }}>
                                                <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <SearchingImg style={{ width: '150px', height: '150px' }} />
                                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>該入款單中，沒有任何費用說明</Typography>
                                                </Grid>
                                            </Grid>
                                        )}

                                    </Box>
                                </Grid>
                                <Grid container item xs={6} justifyContent="center" sx={{ position: 'relative' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.accounting_audit}
                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.accounting_audit}
                                                checked={
                                                    editData?.depositslips?.find(it => it.id === deposit.id)?.accounting_audit === false
                                                        ? false
                                                        : editData?.depositslips?.find(it => it.id === deposit.id)?.accounting_audit || deposit?.accounting_audit
                                                }
                                                onChange={(e) => handleFieldChange("depositslips", "accounting_audit", e.target.checked, deposit.id)}
                                                color="primary"
                                                disabled={!editMode || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))}
                                                sx={{
                                                    top: '9px',
                                                    padding: 0,
                                                    margin: '10px',
                                                    '&.Mui-disabled': {
                                                      color: deposit.accounting_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)',
                                                      opacity: 0.6,
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                      color: !editMode || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))
                                                        ? (deposit.accounting_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)') 
                                                        : 'inherit',
                                                    },
                                                }}
                                            />
                                        }
                                        label="會計審核"
                                        labelPlacement='top'
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                              fontSize: '12px',
                                              fontWeight: '400',
                                              color: editMode ? 'primary.main' : 'rgba(0, 0, 0, 0.6)',
                                              position: 'absolute',
                                              top: '10px',
                                            },
                                            '& .MuiFormControlLabel-label.Mui-disabled': {
                                              color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} justifyContent="center" sx={{ position: 'relative' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                error={errors?.depositslips?.find(error => error.id === String(deposit.id))?.manager_audit}
                                                helperText={errors?.depositslips?.find(error => error.id === String(deposit.id))?.manager_audit}
                                                checked={
                                                    editData?.depositslips?.find(it => it.id === deposit.id)?.manager_audit === false
                                                        ? false
                                                        : editData?.depositslips?.find(it => it.id === deposit.id)?.manager_audit || deposit?.manager_audit
                                                }
                                                onChange={(e) => handleFieldChange("depositslips", "manager_audit", e.target.checked, deposit.id)}
                                                color="primary"
                                                disabled={!editMode || !userGroups.includes('管理員')}
                                                sx={{
                                                    top: '9px',
                                                    padding: 0,
                                                    margin: '10px',
                                                    '&.Mui-disabled': {
                                                      color: deposit.manager_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)',
                                                      opacity: 0.6,
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                      color: !editMode || !userGroups.includes('管理員')
                                                        ? (deposit.manager_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)') 
                                                        : 'inherit',
                                                    },
                                                }}
                                            />
                                        }
                                        label="主管審核"
                                        labelPlacement='top'
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                              fontSize: '12px',
                                              fontWeight: '400',
                                              color: editMode ? 'primary.main' : 'rgba(0, 0, 0, 0.6)',
                                              position: 'absolute',
                                              top: '10px',
                                            },
                                            '& .MuiFormControlLabel-label.Mui-disabled': {
                                              color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="會計審核日期"
                                        variant="standard"
                                        value={deposit?.accounting_audit_date ? new Date(deposit.accounting_audit_date).toLocaleDateString() : ''}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="會計審核人員"
                                        variant="standard"
                                        value={deposit?.accounting_staff?.profile?.nickname || deposit?.accounting_staff?.username}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="主管審核日期"
                                        variant="standard"
                                        value={deposit?.manager_audit_date ? new Date(deposit.manager_audit_date).toLocaleDateString() : ''}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="主管審核人員"
                                        variant="standard"
                                        value={deposit?.manager_staff?.profile?.nickname || deposit?.manager_staff?.username}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}

                    {!editMode && (
                        <Box />
                    )}

                    {editData?.newDepositslips?.map((deposit, index) => (
                        <div key={deposit.identifier}>
                            <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                <Chip className={styles['chip-text']} label={"新增入款單 #" + (index + 1)} onDelete={() => handleDeleteClick('newDepositSlip', deposit)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                            </Divider>
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.deposit_slip?.deposit_item}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.deposit_slip?.deposit_item.find(option => option.value === deposit.deposit_item)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_item}
                                                helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_item}
                                                label="款項"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("newDepositslips", "deposit_item", value?.value, deposit.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.deposit_slip?.deposit_method}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.deposit_slip?.deposit_method.find(option => option.value === deposit.deposit_method)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_method}
                                                helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_method}
                                                label="入款方式"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("newDepositslips", "deposit_method", value?.value, deposit.identifier, true)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={((deposit.deposit_method !== undefined ? deposit.deposit_method === '支票號碼' : false)) ? 6 : 3}
                                >
                                    <TextField
                                        error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_info}
                                        helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_info}
                                        label="入款資訊"
                                        variant="standard"
                                        value={deposit.deposit_info}
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newDepositslips", "deposit_info", e.target.value, deposit.identifier, true)}
                                    />
                                </Grid>
                                {((deposit.deposit_method !== undefined ? deposit.deposit_method === '支票號碼' : false)) && (
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.check_due_date}
                                            helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.check_due_date}
                                            label="支票到期日"
                                            variant="standard"
                                            value={deposit.check_due_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newDepositslips", "check_due_date", e.target.value || null, deposit.identifier, true)}
                                        />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={((deposit.deposit_method !== undefined ? deposit.deposit_method === '支票號碼' : false)) ? 6 : 3}
                                >
                                    <TextField
                                        error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_date}
                                        helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.deposit_date}
                                        label="入款日期"
                                        variant="standard"
                                        value={deposit.deposit_date}
                                        type='date'
                                        size='small'
                                        fullWidth
                                        required
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newDepositslips", "deposit_date", e.target.value || null, deposit.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={styles['depositslips-itemdescriptions-box']}>
                                        <div>
                                            <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                                                <Chip className={styles['chip-text']} label="費用說明"/>
                                            </Divider>
                                            {deposit?.newItemDescriptions?.map((itemdescription, index) => (
                                                <div key={itemdescription.identifier}>
                                                    <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.itemdescriptions?.find(error => error.identifier === itemdescription.identifier)?.unit_price}
                                                                helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.itemdescriptions?.find(error => error.identifier === itemdescription.identifier)?.unit_price}
                                                                label="單價"
                                                                variant="standard"
                                                                value={itemdescription.unit_price}
                                                                required
                                                                size='small'
                                                                fullWidth
                                                                focused={editMode}
                                                                InputProps={{
                                                                    readOnly: !editMode,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(e) => handleFieldChange("newDepositslips", "unit_price", e.target.value, itemdescription.identifier, false, "newItemDescriptions", deposit.identifier, true)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.itemdescriptions?.find(error => error.identifier === itemdescription.identifier)?.quantity}
                                                                helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.itemdescriptions?.find(error => error.identifier === itemdescription.identifier)?.quantity}
                                                                label="數量"
                                                                variant="standard"
                                                                value={itemdescription.quantity}
                                                                required
                                                                size='small'
                                                                fullWidth
                                                                focused={editMode}
                                                                InputProps={{
                                                                    readOnly: !editMode,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(e) => handleFieldChange("newDepositslips", "quantity", e.target.value, itemdescription.identifier, false, "newItemDescriptions", deposit.identifier, true)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="總計小額"
                                                                variant="standard"
                                                                value={itemdescription.unit_price * itemdescription.quantity}
                                                                size='small'
                                                                fullWidth
                                                                focused={false}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                error={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.itemdescriptions?.find(error => error.identifier === itemdescription.identifier)?.note}
                                                                helperText={errors?.depositslips?.find(error => error.identifier === deposit.identifier)?.itemdescriptions?.find(error => error.identifier === itemdescription.identifier)?.note}
                                                                label="備註"
                                                                variant="standard"
                                                                value={itemdescription.note}
                                                                size='small'
                                                                fullWidth
                                                                focused={editMode}
                                                                InputProps={{
                                                                    readOnly: !editMode,
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(e) => handleFieldChange("newDepositslips", "note", e.target.value, itemdescription.identifier, false, "newItemDescriptions", deposit.identifier, true)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <IconButton
                                                                className={styles['delete-icon']}
                                                                onClick={() => handleDeleteClick('n-newItemDescription', itemdescription)}
                                                            >
                                                                <DeleteForever />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ))}
                                        </div>
                                        
                                        {editMode && (
                                            <Box className={styles['sub-add-item-box']} sx={{ marginTop: '10px'}}>
                                                <Button className={styles['add-item-button']} onClick={() => handleAddNewItemDescription(deposit.identifier, true)}>
                                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                                    新增費用說明
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    ))}

                    {editMode && (
                        <Box className={styles['add-item-box']}>
                            <Button className={styles['add-item-button']} onClick={handleAddNewDepositSlip}>
                                <AddCircle sx={{ width: '20px', height: '20px' }} />
                                新增入款單
                            </Button>
                        </Box>
                    )}

                    {!editMode && data?.depositslips?.length === 0 && !editData?.newDepositslips && (
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <SearchingImg style={{ width: '300px', height: '300px' }} />
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何入款單</Typography>
                            </Grid>
                        </Grid>
                    )}

                    {!data && (
                        <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {loading ? (
                                <CircularProgress color="info" />
                            ) : (
                                <>
                                    <NoDataImg style={{ width: '300px', height: '300px' }} />
                                    無法取得訂單資料或發生錯誤: {errors?.detail}
                                </>
                            )}
                        </Box>
                    )}

                </Box>
            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Box className={styles['action-box']}>
                {editMode ? (
                    <>
                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                            取消
                        </Button>
                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                            保存
                        </Button>
                    </>
                ) : (
                    <Button className={styles['edit-button']} onClick={handleEditClick}>
                        <EditNote sx={{ width: '22px', height: '22px' }} />
                        編輯
                    </Button>
                )}
            </Box>

            <Dialog disableEscapeKeyDown open={deleteCheckOpen} onClose={handleDeletCancelClick} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {deleteItem?.deleteType === 'DepositSlip' && (
                            <div>
                                <div>款項：{deleteItem?.deposit_item || '未知'}</div>
                                <div>入款方式：{deleteItem?.deposit_method || '未知'}</div>
                                <div>入款資訊：{deleteItem?.deposit_info || '未知'}</div>
                                {deleteItem?.deposit_method === '支票號碼' && (
                                    <div>支票到期日：{deleteItem?.check_due_date ? new Date(deleteItem?.check_due_date).toLocaleDateString() : '未知'}</div>
                                )}
                                <div>入款日期：{deleteItem?.deposit_date ? new Date(deleteItem?.deposit_date).toLocaleDateString() : '未知'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newDepositSlip' && (
                            <div>
                                <div>款項：{deleteItem?.item_name || '未知'}</div>
                                <div>入款方式：{deleteItem?.deposit_method || '未知'}</div>
                                <div>入款資訊：{deleteItem?.deposit_info || '未知'}</div>
                                {deleteItem?.deposit_method === '支票號碼' && (
                                    <div>支票到期日：{deleteItem?.check_due_date ? new Date(deleteItem?.check_due_date).toLocaleDateString() : '未知'}</div>
                                )}
                                <div>入款日期：{deleteItem?.deposit_date ? new Date(deleteItem?.deposit_date).toLocaleDateString() : '未知'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'ItemDescription' && (
                            <div>
                                <div>單價：{deleteItem?.unit_price ? parseFloat(deleteItem?.unit_price).toFixed(2).replace(/\.00$/, '') : '未知'}</div>
                                <div>數量：{deleteItem?.quantity || '未知'}</div>
                                <div>備註：{deleteItem?.note || '無'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'o-ItemDescription' && (
                            <div>
                                <div>單價：{deleteItem?.unit_price ? parseFloat(deleteItem?.unit_price).toFixed(2).replace(/\.00$/, '') : '未知'}</div>
                                <div>數量：{deleteItem?.quantity || '未知'}</div>
                                <div>備註：{deleteItem?.note || '無'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'n-newItemDescription' && (
                            <div>
                                <div>單價：{deleteItem?.unit_price ? parseFloat(deleteItem?.unit_price).toFixed(2).replace(/\.00$/, '') : '未知'}</div>
                                <div>數量：{deleteItem?.quantity || '未知'}</div>
                                <div>備註：{deleteItem?.note || '無'}</div>
                            </div>
                        )}
                        <br />此操作無法復原，請謹慎處理。
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['delete-cancel-button']} onClick={handleDeletCancelClick}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleDeleteConfirmClick}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

export default DepositTab;