import React, { useState, useEffect, useContext } from 'react';
import api from '../config/axiosConfig';
import { BACKEND_URL } from '../config/axiosConfig';
import UserGroupContext from '../contexts/UserGroupContext';
import { useNotification } from '../hooks/useNotification';
import { NotificationContext } from '../contexts/NotificationContext';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import CustomNavLink from '../components/CustomNavLink';
import { Divider, Chip, Card, CardContent, Badge, IconButton, Menu, MenuItem, Drawer, List, ListItem,
  Container, Box, Typography, AppBar, Toolbar, Collapse, ListItemText, Avatar, Button, Tooltip, CardActionArea
} from '@mui/material';
import { FiberNew, Notifications, NotificationsNone, CampaignRounded, PostAdd, PriceCheck, Settings,
  StickyNote2Outlined, TableView, ExitToApp, ExpandMore, ExpandLess, Tune, ChevronLeft, ChevronRight,
  SettingsInputComponent, Assessment, ManageSearch, HelpOutline, Reply, TurnedInNot, InfoOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import logo from './img/Logo.jpg';
import styles from './Layout.module.css';

function Layout({ children, setToken, CurrentUser }) {

  const navigate = useNavigate();
  const userGroups = React.useContext(UserGroupContext);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { websocketNotifications } = useNotification();
  const { apiNotifications, fetchApiNotifications } = useContext(NotificationContext);
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const openNotifMenu = Boolean(notifAnchorEl);
  const [openJapan, setOpenJapan] = useState(false);
  const { hasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    fetchApiNotifications();
  }, [fetchApiNotifications]);

  useEffect(() => {
    if (websocketNotifications.length > 0) {
      const latestNotification = websocketNotifications[websocketNotifications.length - 1];

      enqueueSnackbar('This is a notification', {
        content: (key) => (
          <Card sx={{ borderRadius: '15px', boxShadow: 8, maxWidth: '350px'}}>
            <CardActionArea
              component='a'
              href={`/Japan/order-information/${latestNotification.order_uuid}#communications=${latestNotification.title === '外站提問' || latestNotification.title === '外站回覆' ? 'external' : 'internal'}`}
              onClick={() => {
                api.patch(`/core/api/Notification/${latestNotification.notification_id}/`, {
                  is_read: true
                });
                fetchApiNotifications();
              }}
            >
              <CardContent className={styles['notice-snackbar']}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                  <Chip
                    label={`${latestNotification.title} - ${latestNotification.group_number}`}
                    icon={latestNotification.title === '外站提問' || latestNotification.title === '內站提問' ? <HelpOutline /> : latestNotification.title === '外站回覆' || latestNotification.title === '內站回覆' ? <Reply /> : null}
                    size="small"
                    color={latestNotification.title === '外站提問' || latestNotification.title === '外站回覆' ? 'error' : 'success'}
                    sx={{ lineHeight: '19.5px'}}
                    
                  />
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                  <Avatar sx={{ margin: '4px'}} src={`${BACKEND_URL + latestNotification.user_photo}?${new Date().getTime()}`} />
                  <Box>
                    <Typography className={styles['snackbar-username']}>{latestNotification.user}</Typography>
                    <Typography className={styles['snackbar-content']}>{latestNotification.content}</Typography>
                    {/* {latestNotification.order_uuid} */}
                  </Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        )
      });
    }
  }, [enqueueSnackbar, websocketNotifications, fetchApiNotifications]);

  const handleNotificationClick = async (apiNotif) => {
    try {
      if (!hasUnsavedChanges || window.confirm('偵測到有未保存的更改，確定要跳轉頁面嗎？')) {
        await api.patch(`/core/api/Notification/${apiNotif.id}/`, {
          is_read: true
        });
        
        navigate(
          apiNotif.external_communication 
            ? `/Japan/order-information/${apiNotif.order.uuid}#communications=external`
            : `/Japan/order-information/${apiNotif.order.uuid}#communications=internal`
        );

        fetchApiNotifications();
        setNotifAnchorEl(null);
      }
    } catch (error) {
      console.error('更新為已讀時發生錯誤', error);
    }
  };

  const handleNotifClick = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifClose = () => {
    setNotifAnchorEl(null);
  };

  const handleClickJapan = () => {
    setOpenJapan(!openJapan);
  };

  const handleLogout = () => {
    if (!hasUnsavedChanges || window.confirm('偵測到有未保存的更改，確定要登出嗎？')) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setToken(null);
      navigate('/login');
    }
  };
  
  return (
    // <div style={{ width: '100vw', height: '100vh' }}>
    //   <Box className={styles['main-box']}>

    //     <Box className={styles['header-box']}>

    //       <Box className={styles['header-left']}>
    //         <img src={logo} alt="Logo" className={styles.logo} />
    //         <Typography variant="h6" sx={{ color: '#f1f6fd', whiteSpace: 'nowrap', paddingLeft: '8px' }}>小滿訂單管理系統</Typography>
    //       </Box>

    //       <Box className={styles['header-right']}>
    //         <IconButton sx={{ color: '#f1f6fd' }} onClick={handleNotifClick}>
    //           <Badge
    //             badgeContent={apiNotifications.filter(notif => !notif.is_read).length} 
    //             max={9}
    //             color="error"
    //             anchorOrigin={{
    //               vertical: 'top',
    //               horizontal: 'right',
    //             }}
    //           >
    //             <Badge
    //               badgeContent={apiNotifications.filter(notif => !notif.is_reply && notif.is_read).length}
    //               max={9}
    //               color="primary"
    //               anchorOrigin={{
    //                 vertical: 'bottom',
    //                 horizontal: 'left',
    //               }}
    //             >
    //               {apiNotifications.length > 0 ? <Notifications /> : <NotificationsNone />}
    //             </Badge>
    //           </Badge>
    //         </IconButton>

    //         <Menu
    //           anchorEl={notifAnchorEl}
    //           keepMounted
    //           open={openNotifMenu}
    //           onClose={handleNotifClose}
    //           PaperProps={{
    //             style: {
    //               maxHeight: '60vh',
    //               width: '350px',
    //               overflow: 'auto',
    //             }
    //           }}
    //         >
    //           {apiNotifications.filter(notif => !notif.is_read).length > 0 && (
    //             <>
    //               <Divider variant="middle" style={{ lineHeight: '19.5px' }}>
    //                 <Chip label="未讀通知" color="error" />
    //               </Divider>
    //               {apiNotifications.filter(notif => !notif.is_read).map((apiNotif, index) => (
    //                 <MenuItem className={styles['notice-menu']} key={index} onClick={() => handleNotificationClick(apiNotif)}>
    //                   <Card variant="outlined" sx={{ width: '100%', margin: '4px'}}>
    //                     <CardContent style={{ padding: '12px'}}>
    //                       <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
    //                         <Chip
    //                           label={`${apiNotif.title} - ${apiNotif.order?.group_number}`}
    //                           size="small"
    //                           sx={{ lineHeight: '19.5px'}}
                              
    //                         />
    //                       </Box>
    //                       <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
    //                         <Avatar src={apiNotif.sender.profile?.photo} />
    //                         <Box style={{ width: '100%', maxWidth: '284px', display: 'flex', flexDirection: 'column' }}>
    //                           <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
    //                             <Typography className={styles['notice-menu-username']}>{apiNotif.sender.profile?.nickname || apiNotif.sender.username}</Typography>
    //                             <Typography className={styles['notice-menu-time']}>
    //                               {(() => {
    //                                 const date = new Date(apiNotif.created_at);
    //                                 const today = new Date();
                                    
    //                                 if (date.toDateString() === today.toDateString()) {
    //                                   return date.toLocaleTimeString();
    //                                 } else {
    //                                   return date.toLocaleDateString();
    //                                 }
    //                               })()}
    //                             </Typography>
    //                           </Box>
    //                           <Typography className={styles['notice-menu-content']}>{apiNotif.content}</Typography>
    //                         </Box>
    //                       </Box>
    //                       <FiberNew color="error" className={styles['news-notice']} />
    //                     </CardContent>
    //                     <Divider />
    //                   </Card>
    //                 </MenuItem>
    //               ))}
    //             </>
    //           )}

    //           {apiNotifications.filter(notif => notif.is_read && !notif.is_reply).length > 0 && (
    //             <>
    //               <Divider variant="middle" style={{ lineHeight: '19.5px' }}>
    //                 <Chip label="未回覆通知" color="primary" />
    //               </Divider>
    //               {apiNotifications.filter(notif => notif.is_read && !notif.is_reply).map((apiNotif, index) => (
    //                 <MenuItem className={styles['notice-menu']} key={index} onClick={() => handleNotificationClick(apiNotif)}>
    //                   <Card variant="outlined" sx={{ width: '100%', margin: '4px' }}>
    //                     <CardContent style={{ padding: '12px'}}>
    //                       <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
    //                         <Chip
    //                           label={`${apiNotif.title} - ${apiNotif.order?.group_number}`}
    //                           size="small"
    //                           sx={{ lineHeight: '19.5px'}}
                              
    //                         />
    //                       </Box>
    //                       <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
    //                         <Avatar src={apiNotif.sender.profile?.photo} />
    //                         <Box style={{ width: '100%', maxWidth: '284px', display: 'flex', flexDirection: 'column' }}>
    //                           <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
    //                             <Typography className={styles['notice-menu-username']}>{apiNotif.sender.profile?.nickname || apiNotif.sender.username}</Typography>
    //                             <Typography className={styles['notice-menu-time']}>
    //                               {(() => {
    //                                 const date = new Date(apiNotif.created_at);
    //                                 const today = new Date();
                                    
    //                                 if (date.toDateString() === today.toDateString()) {
    //                                   return date.toLocaleTimeString();
    //                                 } else {
    //                                   return date.toLocaleDateString();
    //                                 }
    //                               })()}
    //                             </Typography>
    //                           </Box>
    //                           <Typography className={styles['notice-menu-content']}>{apiNotif.content}</Typography>
    //                         </Box>
    //                       </Box>
    //                     </CardContent>
    //                     <Divider />
    //                   </Card>
    //                 </MenuItem>
    //               ))}
    //             </>
    //           )}

    //           {apiNotifications.filter(notif => !notif.is_read).length === 0 && apiNotifications.filter(notif => notif.is_read && !notif.is_reply).length === 0 && (
    //             <MenuItem disabled>
    //               <Typography variant="subtitle1" color="InfoText" textAlign='center'>
    //                 目前没有新通知
    //               </Typography>
    //             </MenuItem>
    //           )}

    //         </Menu>

    //       </Box>

    //     </Box>

    //     <Box className={styles['content-box']}>

    //       <Box className={styles['drawer-box']}>
    //       </Box>

    //       <Box className={styles['main-content-box']}>

    //         <Box className={styles['main-content-children']}>
    //           {children}
    //         </Box>

    //         <Box className={styles['footer-box']}>
    //             © 2021 小滿訂單管理系統
    //         </Box>
            
    //         {/* {children} */}
    //       </Box>

    //     </Box>

    //   </Box>
    // </div>
    <div>
      
      <AppBar className={styles.header} elevation={0}>
        <Toolbar style={{ paddingLeft: '8px'}}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <Typography variant="h6" sx={{ paddingLeft: '8px'}}>小滿訂單管理系統</Typography>
          
          <Box flexGrow={1}/>
          <IconButton color="inherit" onClick={handleNotifClick}>
            <Badge
              badgeContent={apiNotifications.filter(notif => !notif.is_read).length} 
              max={9}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Badge
                badgeContent={apiNotifications.filter(notif => !notif.is_reply && notif.is_read).length}
                max={9}
                color="primary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {apiNotifications.length > 0 ? <Notifications /> : <NotificationsNone />}
              </Badge>
            </Badge>
          </IconButton>

          <Menu
            anchorEl={notifAnchorEl}
            keepMounted
            open={openNotifMenu}
            onClose={handleNotifClose}
            PaperProps={{
              style: {
                maxHeight: '60vh',
                width: '350px',
                overflow: 'auto',
              }
            }}
          >
            {apiNotifications.filter(notif => !notif.is_read).length > 0 && (
              <>
                <Divider variant="middle" style={{ lineHeight: '19.5px' }}>
                  <Chip label="未讀通知" color="error" />
                </Divider>
                {apiNotifications.filter(notif => !notif.is_read).map((apiNotif, index) => (
                  <MenuItem className={styles['notice-menu']} key={index} onClick={() => handleNotificationClick(apiNotif)}>
                    <Card variant="outlined" sx={{ width: '100%', margin: '4px'}}>
                      <CardContent style={{ padding: '12px'}}>
                        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                          <Chip
                            label={`${apiNotif.title} - ${apiNotif.order?.group_number}`}
                            size="small"
                            sx={{ lineHeight: '19.5px'}}
                            
                          />
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                          <Avatar src={apiNotif.sender.profile?.photo} />
                          <Box style={{ width: '100%', maxWidth: '284px', display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography className={styles['notice-menu-username']}>{apiNotif.sender.profile?.nickname || apiNotif.sender.username}</Typography>
                              <Typography className={styles['notice-menu-time']}>
                                {(() => {
                                  const date = new Date(apiNotif.created_at);
                                  const today = new Date();
                                  
                                  if (date.toDateString() === today.toDateString()) {
                                    return date.toLocaleTimeString();
                                  } else {
                                    return date.toLocaleDateString();
                                  }
                                })()}
                              </Typography>
                            </Box>
                            <Typography className={styles['notice-menu-content']}>{apiNotif.content}</Typography>
                          </Box>
                        </Box>
                        <FiberNew color="error" className={styles['news-notice']} />
                      </CardContent>
                      <Divider />
                    </Card>
                  </MenuItem>
                ))}
              </>
            )}

            {apiNotifications.filter(notif => notif.is_read && !notif.is_reply).length > 0 && (
              <>
                <Divider variant="middle" style={{ lineHeight: '19.5px' }}>
                  <Chip label="未回覆通知" color="primary" />
                </Divider>
                {apiNotifications.filter(notif => notif.is_read && !notif.is_reply).map((apiNotif, index) => (
                  <MenuItem className={styles['notice-menu']} key={index} onClick={() => handleNotificationClick(apiNotif)}>
                    <Card variant="outlined" sx={{ width: '100%', margin: '4px' }}>
                      <CardContent style={{ padding: '12px'}}>
                        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                          <Chip
                            label={`${apiNotif.title} - ${apiNotif.order?.group_number}`}
                            size="small"
                            sx={{ lineHeight: '19.5px'}}
                            
                          />
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                          <Avatar src={apiNotif.sender.profile?.photo} />
                          <Box style={{ width: '100%', maxWidth: '284px', display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography className={styles['notice-menu-username']}>{apiNotif.sender.profile?.nickname || apiNotif.sender.username}</Typography>
                              <Typography className={styles['notice-menu-time']}>
                                {(() => {
                                  const date = new Date(apiNotif.created_at);
                                  const today = new Date();
                                  
                                  if (date.toDateString() === today.toDateString()) {
                                    return date.toLocaleTimeString();
                                  } else {
                                    return date.toLocaleDateString();
                                  }
                                })()}
                              </Typography>
                            </Box>
                            <Typography className={styles['notice-menu-content']}>{apiNotif.content}</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                      <Divider />
                    </Card>
                  </MenuItem>
                ))}
              </>
            )}

            {apiNotifications.filter(notif => !notif.is_read).length === 0 && apiNotifications.filter(notif => notif.is_read && !notif.is_reply).length === 0 && (
              <MenuItem disabled>
                <Typography variant="subtitle1" color="InfoText" textAlign='center'>
                  目前没有新通知
                </Typography>
              </MenuItem>
            )}

          </Menu>
        </Toolbar>
        {/* <div style={{ fontSize: '25px', fontWeight: 'bold', position: 'absolute', left: '50%', top: '15px', transform: 'translateX(-50%)' }}>站 點 管 理 頁 面</div> */}
      </AppBar>
      
      {drawerOpen && (
        <>
          <Drawer
            variant="permanent"
            PaperProps={{ style: { marginTop: '64px', padding: '0px 10px', display: 'flex', flexDirection: 'column' } }}
          >
            <div style={{ flex: 1 }}>
              <List className={styles.list}>
                <ListItem className={styles['listitem-user']}>
                  <Avatar
                    className={styles.avatar}
                    src={`${BACKEND_URL + CurrentUser.profile.photo}?${new Date().getTime()}`}
                  />
                  <div className={styles['avatar-text']}>
                    你好，{CurrentUser ? CurrentUser.profile?.nickname || CurrentUser.username : '讀取中. . .'}
                  </div>
                </ListItem>
                <CustomNavLink to="/Announcement" activeClassName="active">
                  <ListItem className={styles['listitem-news']} button onClick={() => setOpenJapan(false)}>
                    <CampaignRounded className={styles['Navicon-news']} />
                    最新消息
                  </ListItem>
                </CustomNavLink>
                {(userGroups.includes('管理員') || userGroups.includes('會計人員') || userGroups.includes("日本線管理")) && (
                  <CustomNavLink to="/Dashboard" activeClassName="active">
                    <ListItem className={styles['listitem-news']} button onClick={() => setOpenJapan(false)}>
                      <Assessment className={styles['Navicon-news']} />
                      數據台
                    </ListItem>
                  </CustomNavLink>
                )}
                <ListItem className={styles['part-listitem']} button onClick={handleClickJapan}>
                  <ListItemText  className={styles['part-text']} disableTypography={true}>
                    日本線 / Japan
                  </ListItemText>
                  <Tooltip title="展開 / 收起" placement="right">
                    {openJapan ? <ExpandLess /> : <ExpandMore />}
                  </Tooltip>
                </ListItem>
                  <Collapse in={openJapan} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {(userGroups.includes('日本線業務') || userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/order-create" activeClassName="active">
                          <ListItem className={styles.listitem} button>
                            <PostAdd className={styles.Navicon} />
                            新增訂單
                          </ListItem>
                        </CustomNavLink>
                      )}
                      {(userGroups.includes('日本線業務') || userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/order-table" activeClassName="active">
                          <ListItem className={styles.listitem} button>
                            <TableView className={styles.Navicon} />
                            業務報表
                          </ListItem>
                        </CustomNavLink>
                      )}
                      <CustomNavLink to="/Japan/charterbooking-table" activeClassName="active">
                        <ListItem className={styles.listitem} button>
                          <StickyNote2Outlined className={styles.Navicon} />
                          訂團表
                        </ListItem>
                      </CustomNavLink>
                      {(userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/accounting-table" activeClassName="active">
                          <ListItem className={styles.listitem} button>
                            <PriceCheck className={styles.Navicon} />
                            對帳表
                          </ListItem>
                        </CustomNavLink>
                      )}
                      <CustomNavLink to="/Japan/contact-record" activeClassName="active">
                        <ListItem className={styles.listitem} button>
                          <ManageSearch className={styles.Navicon} />
                          聯絡記錄
                        </ListItem>
                      </CustomNavLink>
                      {(userGroups.includes('日本線業務') || userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/other-related" activeClassName="active">
                          <ListItem className={styles.listitem} button>
                            <TurnedInNot className={styles.Navicon} />
                            其他相關
                          </ListItem>
                        </CustomNavLink>
                      )}
                      {(userGroups.includes('管理員') || userGroups.includes('日本線管理')) && (
                        <CustomNavLink to="/Japan/manage" activeClassName="active">
                          <ListItem className={styles.listitem} button>
                            <Tune className={styles.Navicon} />
                            設定管理
                          </ListItem>
                        </CustomNavLink>
                      )}
                    </List>
                  </Collapse>
              </List>
            </div>

            <Divider />

            <List className={styles.list} style={{ marginBottom: '78px'}}>
              {userGroups.includes('管理員') && (
                <CustomNavLink to="/Global-Setting" activeClassName="active">
                  <ListItem className={styles.listitem} button>
                    <SettingsInputComponent className={styles.Navicon} />
                    站點管理
                  </ListItem>
                </CustomNavLink>
              )}
              <CustomNavLink to="/Setting" activeClassName="active">
                <ListItem className={styles.listitem} button>
                  <Settings className={styles.Navicon} />
                  設定
                </ListItem>
              </CustomNavLink>
              <ListItem className={styles.listitem} button onClick={handleLogout}>
                <ExitToApp className={styles.Navicon} />
                登出
              </ListItem>
            </List>
          </Drawer>

          <Box display="flex">
            <Tooltip title="點擊收起" placement="right">
              <Button
                className={styles['drawer-fc']}
                variant="contained"
                sx={{ 
                  minWidth: '24px',
                  position: 'fixed',
                  left: 'calc(0vw + 250px)',
                  top: 'calc(64px + 62px )',
                  zIndex: '10' 
                }}
                onClick={() => setDrawerOpen(false)}
              >
                <ChevronLeft />
              </Button>
            </Tooltip>
          </Box>
        </>
      )}

      {!drawerOpen && (
        <>
          <Drawer variant="permanent" PaperProps={{ style: { marginTop: '64px', padding: '0px', display: 'flex', flexDirection: 'column' } }}>
            <div style={{ flex: 1 }}>
              <List className={styles['list-closed']}>
                <Tooltip title={`你好，${CurrentUser ? CurrentUser.profile?.nickname || CurrentUser.username : '讀取中. . .'} `} placement="right">
                  <ListItem className={styles['listitem-CurrentUser-closed']} sx={{ height: '40px', padding: '4px 14px', marginTop: '8px', marginBottom: '14px'}}>
                    <Avatar
                      className={styles["avatar-closed"]}
                      src={`${BACKEND_URL + CurrentUser.profile.photo}?${new Date().getTime()}`}
                    />
                  </ListItem>
                </Tooltip>
                <Divider />
                <Tooltip title="點擊展開" placement="right">
                  <ListItem className={styles['listitem-closedicon']} button onClick={() => setDrawerOpen(true)}>
                    <ChevronRight />
                  </ListItem>
                </Tooltip>
                <Divider />
                <CustomNavLink to="/Announcement" activeClassName="active-closed">
                  <Tooltip title="最新消息" placement="right">
                    <ListItem button className={styles['listitem-news-closed']} activeClassName={styles.active} onClick={() => setOpenJapan(false)}>
                      <CampaignRounded />
                    </ListItem>
                  </Tooltip>
                </CustomNavLink>
                {(userGroups.includes('管理員') || userGroups.includes('會計人員') || userGroups.includes("日本線管理")) && (
                  <CustomNavLink to="/Dashboard" activeClassName="active-closed">
                    <Tooltip title="數據台" placement="right">
                      <ListItem button className={styles['listitem-news-closed']} activeClassName={styles.active} onClick={() => setOpenJapan(false)}>
                        <Assessment />
                      </ListItem>
                    </Tooltip>
                  </CustomNavLink>
                )}
                <ListItem className={styles['part-listitem']} button onClick={handleClickJapan}>
                  <Tooltip title="日本線 / Japan" placement="right">
                    <ListItemText className={styles['part-text']} disableTypography='true'>
                      JP
                    </ListItemText>
                  </Tooltip>
                </ListItem>
                  <Collapse in={openJapan} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {(userGroups.includes('日本線業務') || userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/order-create" activeClassName="active-closed">
                          <Tooltip title="新增訂單" placement="right">
                            <ListItem className={styles.listitem} button>
                              <PostAdd />
                            </ListItem>
                          </Tooltip>
                        </CustomNavLink>
                      )}
                      {(userGroups.includes('日本線業務') || userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/order-table" activeClassName="active-closed">
                          <Tooltip title="業務報表" placement="right">
                            <ListItem className={styles.listitem} button>
                              <TableView />
                            </ListItem>
                          </Tooltip>
                        </CustomNavLink>
                      )}
                      <CustomNavLink to="/Japan/charterbooking-table" activeClassName="active-closed">
                        <Tooltip title="訂團表" placement="right">
                          <ListItem className={styles.listitem} button>
                            <StickyNote2Outlined />
                          </ListItem>
                        </Tooltip>
                      </CustomNavLink>
                      {(userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/accounting-table" activeClassName="active-closed">
                          <Tooltip title="對帳表" placement="right">
                            <ListItem className={styles.listitem} button>
                              <PriceCheck />
                            </ListItem>
                          </Tooltip>
                        </CustomNavLink>
                      )}
                      <CustomNavLink to="/Japan/contact-record" activeClassName="active-closed">
                        <Tooltip title="聯絡記錄" placement="right">
                          <ListItem className={styles.listitem} button>
                            <ManageSearch />
                          </ListItem>
                        </Tooltip>
                      </CustomNavLink>
                      {(userGroups.includes('日本線業務') || userGroups.includes('會計人員') || userGroups.includes('管理員')) && (
                        <CustomNavLink to="/Japan/other-related" activeClassName="active-closed">
                          <Tooltip title="其他相關" placement="right">
                            <ListItem className={styles.listitem} button>
                              <TurnedInNot />
                            </ListItem>
                          </Tooltip>
                        </CustomNavLink>
                      )}
                      {(userGroups.includes('管理員') || userGroups.includes('日本線管理')) && (
                        <CustomNavLink to="/Japan/manage" activeClassName="active-closed">
                          <Tooltip title="設定管理" placement="right">
                            <ListItem className={styles.listitem} button>
                              <Tune />
                            </ListItem>
                          </Tooltip>
                        </CustomNavLink>
                      )}
                    </List>
                  </Collapse>
              </List>
            </div>

            <Divider  />

            <List className={styles['list-closed']} style={{ marginBottom: '78px'}}>
              {userGroups.includes('管理員') && (
                <CustomNavLink to="/Global-Setting" activeClassName="active-closed">
                  <Tooltip title="站點管理" placement="right">
                    <ListItem className={styles.listitem} button>
                      <SettingsInputComponent />
                    </ListItem>
                  </Tooltip>
                </CustomNavLink>
              )}
              <CustomNavLink to="/Setting" activeClassName="active-closed">
                <Tooltip title="設定" placement="right">
                  <ListItem className={styles.listitem} button>
                    <Settings />
                  </ListItem>
                </Tooltip>
              </CustomNavLink>
              <Tooltip title="登出" placement="right">
                <ListItem className={styles.listitem} button onClick={handleLogout}>
                  <ExitToApp />
                </ListItem>
              </Tooltip>
            </List>
          </Drawer>
        </>
      )}
      
      <Container className={drawerOpen ? styles.container : styles['container-closed']} component="main" maxWidth={false}>
        <Box
          my={3}
          minWidth='968px'
          minHeight='calc(100vh - 64px - 40.2px - 24px - 24px)'
          sx={{ width: '100%', overflowX: 'auto' }}
        >
          {children}
        </Box>
      </Container>
      
      <footer className={ drawerOpen ? styles.footer : styles['footer-closed']}>
        <Typography variant="body2" color="textSecondary">
          © 2023 - {new Date().getFullYear()} 小滿科技有限公司 版權所有
        </Typography>
        <IconButton style={{ position: 'absolute', right: '10px', top: '8px', padding: '0', color: '#00000099'}} onClick={() => window.open('/ChangeLogs', '_blank')}>
          <InfoOutlined/>
        </IconButton>
      </footer>
    </div>
  );
}

export default Layout;