import React, { useState, useEffect } from 'react';
import api from '../../config/axiosConfig';
import UserGroupContext from '../../contexts/UserGroupContext';
import { Autocomplete, Box, TextField, Typography, Container, Grid, Paper, Card, CardContent, Chip, Avatar, Divider, Skeleton, CircularProgress, Button, Tooltip, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import styles from './css/ContactRecord.module.css';
import { Autorenew, Error, YoutubeSearchedFor } from '@mui/icons-material';

function ContactRecord() {
    const userGroups = React.useContext(UserGroupContext);
    const [orderGroupNumberList, setOrderGroupNumberList] = useState([]);
    const [searchType, setSearchType] = useState();
    const [selectedGroupNumber, setSelectedGroupNumber] = useState('');
    const [selectedUUID, setSelectedUUID] = useState('');
    const [externalRecords, setExternalRecords] = useState([]);
    const [internalRecords, setInternalRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [getingData, setGetingData] = useState(false);

    useEffect(() => {
        document.title = '日本線聯絡記錄查詢';
    }, []);

    const fetchOrderGroupNumberList = async (searchType) => {
        try {
            const response = await api.get(`/japan/api/order-group-number-list/?filter=${searchType}`);
            setOrderGroupNumberList(response.data.group_numbers);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setSelectedGroupNumber('');
        setInternalRecords([]);
        setExternalRecords([]);
        fetchOrderGroupNumberList(event.target.value);
    };

    const fetchContactRecord = async (uuid) => {
        try {
            setGetingData(true);
            const response = await api.get(`/japan/api/contact-records/?uuid=${uuid}`);
            setExternalRecords(response.data.external);
            setInternalRecords(response.data.internal);
            setGetingData(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleRecordSearch = (event, value) => {
        setExternalRecords([]);
        setInternalRecords([]);
        setSelectedGroupNumber('');
        if (value && orderGroupNumberList[value]) {
            const uuid = orderGroupNumberList[value];
            setSelectedUUID(uuid);
            setSelectedGroupNumber(value);
            fetchContactRecord(uuid);
        }
    };

    return (
        <Container component="main" maxWidth={false} sx={{ py: 3 }}>
            <Typography className={styles['text-h1']} variant="h5" gutterBottom>
                日 本 線 聯 絡 記 錄 查 詢
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <FormControl>
                    <InputLabel id="type-select-label">查詢類型</InputLabel>
                    <Select
                        value={searchType}
                        onChange={handleSearchTypeChange}
                        labelId='type-select-label'
                        id='type-select'
                        label='查詢類型'
                        style={{ width: '120px', marginRight: '10px' }}
                    >
                        <MenuItem value='all'>全部</MenuItem>
                        <MenuItem value='unreplied'>未回覆</MenuItem>
                    </Select>
                </FormControl>

                {searchType && (
                    <>
                        <Tooltip title="重新查詢" placement='top'>
                            <IconButton
                                color="primary"
                                size='small'
                                style={{ marginRight: '10px', border: '1px solid #1976d2' }}
                                onClick={() => {
                                    fetchOrderGroupNumberList(searchType)
                                    setSelectedGroupNumber('')
                                    setExternalRecords([])
                                    setInternalRecords([])
                                }}
                            >
                                <YoutubeSearchedFor />
                            </IconButton>
                        </Tooltip>

                        <Autocomplete
                            value={selectedGroupNumber}
                            options={loading ? Array.from(new Array(5)) : Object.keys(orderGroupNumberList)}
                            noOptionsText={"該條件下查無任何訂單團號"}
                            getOptionLabel={(option) => option}
                            style={{ width: 300 }}
                            onChange={handleRecordSearch}
                            renderInput={(params) => 
                                <TextField {...params}
                                    label="訂單團號"
                                    placeholder='請選擇或輸入欲查詢訂單團號'
                                />
                            }
                            renderOption={(props, option) => {
                                const { key, ...otherProps } = props;
                                return (
                                    <li key={key} {...otherProps}>
                                        {loading ? <Skeleton width="100%" /> : option}
                                    </li>
                                );
                            }}
                        />
                    </>
                )}

                {selectedGroupNumber && (
                    <Tooltip title="數據刷新" placement='top'>
                        <IconButton
                            color="primary"
                            size='small'
                            style={{ marginLeft: '10px', border: '1px solid #1976d2'}}
                            onClick={() => fetchContactRecord(orderGroupNumberList[selectedGroupNumber])}
                        >
                            <Autorenew />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <Grid container spacing={3} style={{ position: 'relative', marginTop: '20px' }}>
                    <Grid item xs={12} md={userGroups.includes('日方人員') ? 12 : 6} style={{ position: 'relative', paddingBottom: '10px' }}>
                        <Paper style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography className={styles["title-2"]}>
                                {!searchType && "外站聯絡記錄"}
                                {searchType === "all" && "外站聯絡記錄 - 全部"}
                                {searchType === "unreplied" && externalRecords.length <= 0 && "外站聯絡記錄 - 未回覆"}
                                {searchType === "unreplied" && externalRecords.length > 0 && `外站聯絡記錄 - ${externalRecords.filter(record => record.reply_user === null).length}筆未回覆`}
                            </Typography>
                            {getingData && (
                                <Box style={{ paddingTop: '30px' }}>
                                    <CircularProgress color='success'/>
                                </Box>
                            )}
                            {!getingData && externalRecords.map(record => (
                                <Container key={record.id}>
                                    <Card elevation={3} style={{ marginTop: '15px' }}>
                                        <CardContent>
                                            {record.reply_user === null && (
                                                <Box style={{ textAlign: 'center' }}>
                                                    <Chip
                                                        className={styles["question-unreplied"]}
                                                        icon={<Error />}
                                                        label="未回覆"
                                                        color='error'
                                                    />
                                                </Box>
                                            )}
                                            <Chip
                                                className={styles["question-user"]}
                                                avatar={<Avatar alt="提問人頭像" src={record.question_user_photo} />}
                                                label={record.question_user}
                                                variant="outlined"
                                            />
                                            <Divider variant="middle" textAlign="right" className={styles["question-time"]}>提問時間 {new Date(record.question_created_at).toLocaleString()}</Divider>
                                            <Typography className={styles.question}>{record.question}</Typography>
                                            {record.reply ? (
                                                <>
                                                    <Box style={{ paddingTop: '10px', textAlign: 'right' }}>
                                                        <Chip
                                                            className={styles["reply-user"]}
                                                            avatar={<Avatar alt="回覆人頭像" src={record.reply_user_photo} />}
                                                            label={record.reply_user}
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                    <Divider variant="middle" textAlign="left" className={styles["reply-time"]}>回覆時間 {new Date(record.reply_created_at).toLocaleString()}</Divider>
                                                    <Typography className={styles.reply}>{record.reply}</Typography>
                                                </>
                                            ) : (
                                                <Divider variant="middle" className={styles["no-reply"]}>尚未回覆</Divider>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Container>
                            ))}
                            {!getingData && selectedGroupNumber && externalRecords.length === 0 && (
                                <Typography className={styles["no-record"]}>查無外站聯絡記錄</Typography>
                            )}
                            {!searchType && (
                                <Typography className={styles["no-choose"]}>請先選擇欲查詢的類型</Typography>
                            )}
                            {selectedGroupNumber === '' && searchType && (
                                <Typography className={styles["no-choose"]}>請先選擇欲查詢的訂單團號</Typography>
                            )}
                            {!getingData && selectedGroupNumber && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginTop: '20px' }}
                                    href={`/Japan/order-information/${selectedUUID}`}
                                >
                                    查看訂單完整資訊
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                    {!userGroups.includes('日方人員') && (
                        <Grid item xs={12} md={6} style={{ position: 'relative', paddingBottom: '10px' }}>
                            <Paper style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography className={styles["title-1"]}>
                                    {!searchType && "外站聯絡記錄"}
                                    {searchType === "all" && "內站聯絡記錄 - 全部"}
                                    {searchType === "unreplied" && internalRecords.length <= 0 && "內站聯絡記錄 - 未回覆"}
                                    {searchType === "unreplied" && internalRecords.length > 0 && `內站聯絡記錄 - ${internalRecords.filter(record => record.reply_user === null).length}筆未回覆`}
                                </Typography>
                                {getingData && (
                                    <Box style={{ paddingTop: '30px' }}>
                                        <CircularProgress />
                                    </Box>
                                )}
                                {!getingData && internalRecords.map(record => (
                                    <Container key={record.id}>
                                        <Card elevation={3} style={{ marginTop: '15px' }}>
                                            <CardContent>
                                                {record.reply_user === null && (
                                                    <Box style={{ textAlign: 'center' }}>
                                                        <Chip
                                                            className={styles["question-unreplied"]}
                                                            icon={<Error />}
                                                            label="未回覆"
                                                            color='error'
                                                        />
                                                    </Box>
                                                )}
                                                <Chip
                                                    className={styles["question-user"]}
                                                    avatar={<Avatar alt="提問人頭像" src={record.question_user_photo} />}
                                                    label={record.question_user}
                                                    variant="outlined"
                                                />
                                                <Divider variant="middle" textAlign="right" className={styles["question-time"]}>提問時間 {new Date(record.question_created_at).toLocaleString()}</Divider>
                                                <Typography className={styles.question}>{record.question}</Typography>
                                                <Box className={styles['question-target']}>
                                                    {record.question_target.map(target => (
                                                        <Tooltip key={target.id} title={target.nickname} placement='top'>
                                                            <Avatar
                                                                alt={target.nickname}
                                                                src={target.photo}
                                                                sx={{ width: 24, height: 24, marginLeft: '3px' }}
                                                            />
                                                        </Tooltip>
                                                    ))}
                                                </Box>
                                                {record.reply ? (
                                                    <>
                                                        <div style={{ paddingTop: '20px', textAlign: 'right' }}>
                                                            <Chip
                                                                className={styles["reply-user"]}
                                                                avatar={<Avatar alt="回覆人頭像" src={record.reply_user_photo} />}
                                                                label={record.reply_user}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <Divider variant="middle" textAlign="left" className={styles["reply-time"]}>回覆時間 {new Date(record.reply_created_at).toLocaleString()}</Divider>
                                                        <Typography className={styles.reply}>{record.reply}</Typography>
                                                    </>
                                                ) : (
                                                    <Divider variant="middle" className={styles["no-reply"]}>尚未回覆</Divider>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Container>
                                ))}
                                {!getingData && selectedGroupNumber && internalRecords.length === 0 && (
                                    <Typography className={styles["no-record"]}>查無內站聯絡記錄</Typography>
                                )}
                                {!searchType && (
                                    <Typography className={styles["no-choose"]}>請先選擇欲查詢的類型</Typography>
                                )}
                                {selectedGroupNumber === '' && searchType && (
                                    <Typography className={styles["no-choose"]}>請先選擇欲查詢的訂單團號</Typography>
                                )}
                                {!getingData && selectedGroupNumber && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginTop: '20px' }}
                                        href={`/Japan/order-information/${selectedUUID}`}
                                    >
                                        查看訂單完整資訊
                                    </Button>
                                )}
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Box>

        </Container>
    );
};

export default ContactRecord;
