import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import { Container, Typography, Tabs, Tab, } from '@mui/material';
import styles from './css/GlobalSetting.module.css';
import UserTab from './GlobalManageTabs/UserTab';
import GroupTab from './GlobalManageTabs/GroupTab';
import EmployeeProfileTab from './GlobalManageTabs/EmployeeProfileTab';
import AnnouncementCategoryTab from './GlobalManageTabs/AnnouncementCategoryTab';

const GlobalSetting = () => {
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { hasUnsavedChanges } = useUnsavedChanges();
    const [lastHash, setLastHash] = useState(window.location.hash);
    const [ignoreNextHashChange, setIgnoreNextHashChange] = useState(false);

    useEffect(() => {
        const handleHashChange = () => {
            if (ignoreNextHashChange) {
                // 如果我們正在忽略這次的 hash 變更，則重設此標誌並返回
                setIgnoreNextHashChange(false);
                return;
            }

            if (hasUnsavedChanges) {
                const confirmLeave = window.confirm('似乎有編輯、修改未被保存，您確定要離開嗎？');
                if (!confirmLeave) {
                    // 如果用戶選擇不離開，將 hash 復原到之前的值
                    setIgnoreNextHashChange(true);
                    window.location.hash = lastHash;
                } else {
                    // 如果用戶選擇離開，更新 lastHash
                    setLastHash(window.location.hash);
                }
            } else {
                // 沒有未保存的更改，直接更新 lastHash
                setLastHash(window.location.hash);
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [hasUnsavedChanges, lastHash, ignoreNextHashChange]);

    useEffect(() => {
        const tabHashes = ['User', 'Group', 'EmployeeProfile', 'AnnouncementCategory'];
        const hash = location.hash;
      
        const index = hash ? tabHashes.indexOf(hash.replace('#', '')) : 0;
        
        if (index === -1 || !hash) {
          navigate(`#User`, { replace: true });
        } else {
          setTabValue(index);
        }
    
        const tabtitles = ['使用者帳號', '權限群組', '員工資料', '公告類別'];
        document.title = `站點管理頁面 - ${tabtitles[index]}`;
        
    }, [location, navigate]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderTabContent = () => {
        switch (tabValue) {
            case 0:
                return(<UserTab />);
            case 1:
                return(<GroupTab />);
            case 2:
                return(<EmployeeProfileTab />);
            case 3:
                return(<AnnouncementCategoryTab />);
            default:
                return null;
        }
    };

    return (
        <Container component="main" maxWidth={false} sx={{ py: 3 }}>
            <Typography className={styles['text-h1']} variant="h5" gutterBottom>
                站 點 管 理 頁 面
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tabs className={styles['main-tabs']} value={tabValue} variant='scrollable' scrollButtons='auto' onChange={handleTabChange}>
                    <Tab className={styles['sub-tab']} href='#User' label="使用者帳號" disableRipple disabled={hasUnsavedChanges} />
                    <Tab className={styles['sub-tab']} href='#Group' label="權限群組" disableRipple disabled={hasUnsavedChanges} />
                    <Tab className={styles['sub-tab']} href='#EmployeeProfile' label="員工資料" disableRipple disabled={hasUnsavedChanges} />
                    <Tab className={styles['sub-tab']} href='#AnnouncementCategory' label="公告類別" disableRipple disabled={hasUnsavedChanges} />
                </Tabs>
            </div>

            {renderTabContent()}

        </Container>
    );
}

export default GlobalSetting;