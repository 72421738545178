import React, { createContext, useState, useEffect, useCallback } from 'react';
import api from '../config/axiosConfig';
import { BACKEND_URL } from '../config/axiosConfig';
import NoticeSound from './sounds/notice.mp3'

export const NotificationContext = createContext();

export const NotificationProvider = ({ children, token }) => {
  const [websocketNotifications, setWebsocketNotifications] = useState([]);
  const [apiNotifications, setApiNotifications] = useState([]);

  const fetchApiNotifications = useCallback(() => {
    api.get('/core/api/Notification/')
      .then(response => {
        setApiNotifications(response.data);
      })
      .catch(error => {
        console.error('獲取API通知失敗', error);
      });
  }, []);

  useEffect(() => {
    if (token) {
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsUrl = `${protocol}//${new URL(BACKEND_URL).host}/ws/notifications/?token=${token}`;
      const socket = new WebSocket(wsUrl);

      socket.onopen = () => console.log('通訊系統已連接');
      socket.onmessage = (event) => {
        // console.log('通訊系統接收到訊息:', event.data);
        const notification = JSON.parse(event.data);
        setWebsocketNotifications(prev => [...prev, notification]);
        fetchApiNotifications();
        playNotificationSound();
      };
      socket.onerror = event => console.error('通訊系統錯誤:', event);
      socket.onclose = event => console.log('通訊系統已斷開:', event);

      return () => socket && socket.close();
    }
  }, [token, fetchApiNotifications]);

  const playNotificationSound = () => {
    const audio = new Audio(NoticeSound);
    audio.play().catch(error => {
      console.warn('音效播放失敗:', error);
    });
  }

  return (
    <NotificationContext.Provider value={{ websocketNotifications, apiNotifications, fetchApiNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
