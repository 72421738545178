import React, { useState, useEffect } from 'react';
import api from '../../config/axiosConfig';
import styles from './css/AdminTab.module.css';
import { ReactComponent as NoData} from '../../pages/img/NoData.svg';
import { Box, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';

const AdminTab = ({onOrderDataSend, year, month, filterSalesRep, filterCreatedDate}) => {
    const [data, setData] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [changedFields, setChangedFields] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const dataResponse = await api.get(`/japan/api/dashboard/?type=order_data_by_month_for_admin_detail&year=${year}&month=${month}`);
                setData(dataResponse.data);
                onOrderDataSend(dataResponse.data.current_month, dataResponse.data.last_month);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();

    // eslint-disable-next-line
    }, [year, month]);

    const handleDoubleClick = (index, field, value) => {
        setEditIndex(index);
        setEditValues({ ...editValues, [field]: value });
    };

    const handleChange = (field, event) => {
        setEditValues(prevValues => ({
            ...prevValues,
            [field]: event.target.value
        }));
        setChangedFields(prevFields => ({
            ...prevFields,
            [field]: true // 標記此字段為已更改
        }));
    };

    const handleBlur = () => {
        const updatedOrders = [...data.current_month];
        let updates = {};
        Object.keys(editValues).forEach(key => {
            if (changedFields[key] && editValues[key] !== updatedOrders[editIndex][key]) {
                updatedOrders[editIndex][key] = editValues[key];
                updates[key] = editValues[key];
            }
        });

        if (Object.keys(updates).length > 0) {

            if (Object.keys(updates).includes('orderfortable__fc_price')) {
                // console.log('偵測為外幣報價');
                try {
                    api.patch(`/japan/api/OrderForTable/${updatedOrders[editIndex].orderfortable__id}/`, {
                        fc_price: updates.orderfortable__fc_price
                    });
                    onOrderDataSend(updatedOrders, data.last_month);
                    console.log('updatedOrders:', updatedOrders);
                    // console.log('外幣報價已更新');
                } catch (error) {
                    console.error(error);
                }
            }

            if (Object.keys(updates).includes('orderfortable__fc_price_estimated')) {
                // console.log('偵測為預估外幣報價');
                try {
                    api.patch(`/japan/api/OrderForTable/${updatedOrders[editIndex].orderfortable__id}/`, {
                        fc_price_estimated: updates.orderfortable__fc_price_estimated
                    });
                    onOrderDataSend(updatedOrders, data.last_month);
                    // console.log('預估外幣報價已更新');
                } catch (error) {
                    console.error(error);
                }
            }

            if (Object.keys(updates).includes('charterbooking__booking_price')) {
                // console.log('偵測為外站報價');
                try {
                    api.patch(`/japan/api/CharterBooking/${updatedOrders[editIndex].charterbooking__id}/`, {
                        booking_price: updates.charterbooking__booking_price
                    });
                    onOrderDataSend(updatedOrders, data.last_month);
                    // console.log('外站報價已更新');
                } catch (error) {
                    console.error(error);
                }
            }

        }
        
        setData({ ...data, orders: updatedOrders });
        setEditIndex(null);
        setEditValues({});
        setChangedFields({});
    };

    const handleKeyDown = (event, field) => {
        if (event.key === 'Enter') {
            handleBlur();
        }
    };

    return (
        <Box className={styles['main-box']}>
            <Card className={styles['card']}>
                <CardContent className={styles['cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

                    <Box className={styles['cardcontent-title-box']}>
                        <Grid container spacing={1}>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    訂單團號
                                </Typography>
                            </Grid>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    外幣報價
                                </Typography>
                            </Grid>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    預估外幣報價
                                </Typography>
                            </Grid>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    外站報價
                                </Typography>
                            </Grid>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    代墊款
                                </Typography>
                            </Grid>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    獲利
                                </Typography>
                            </Grid>
                            <Grid item xs={1.7}>
                                <Typography className={styles['title-label']}>
                                    外站代收
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={styles['cardcontent-content-box']}>
                        <Grid container>
                            {data.current_month && data.current_month
                                .filter(order => 
                                    (filterSalesRep ? order.sales_rep === filterSalesRep : true) &&
                                    (filterCreatedDate ? 
                                        new Date(order.created_at).toISOString().split('T')[0] === filterCreatedDate 
                                        : true)
                                )
                                .map((order, index) => (
                                <Grid item xs={12} key={index} sx={{ ':hover': { background: '#ebf1fd' } }}>
                                    <Grid container spacing={1} className={styles['content-grid']}>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {order.group_number}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {editIndex === index && 'orderfortable__fc_price' in editValues ? (
                                                    <TextField
                                                        variant='standard'
                                                        value={editValues['orderfortable__fc_price'] || ''}
                                                        onChange={(e) => handleChange('orderfortable__fc_price', e)}
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => handleKeyDown(e, 'orderfortable__fc_price')}
                                                        autoFocus
                                                        type='number'
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'left',
                                                                padding: 0,
                                                            },
                                                            disableUnderline: true,
                                                        }}
                                                        sx={{
                                                            '& .MuiInput-root': {
                                                                width: '80px',
                                                                fontSize: '15px',
                                                            },
                                                            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type="number"]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <span
                                                        onDoubleClick={() => handleDoubleClick(index, 'orderfortable__fc_price', order.orderfortable__fc_price)}
                                                    >
                                                        {order.orderfortable__fc_price || '–'}
                                                    </span>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {editIndex === index && 'orderfortable__fc_price_estimated' in editValues ? (
                                                    <TextField
                                                        variant='standard'
                                                        value={editValues['orderfortable__fc_price_estimated'] || ''}
                                                        onChange={(e) => handleChange('orderfortable__fc_price_estimated', e)}
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => handleKeyDown(e, 'orderfortable__fc_price_estimated')}
                                                        autoFocus
                                                        type='number'
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'left',
                                                                padding: 0,
                                                            },
                                                            disableUnderline: true,
                                                        }}
                                                        sx={{
                                                            '& .MuiInput-root': {
                                                                width: '80px',
                                                                fontSize: '15px',
                                                            },
                                                            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type="number"]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <span
                                                        onDoubleClick={() => handleDoubleClick(index, 'orderfortable__fc_price_estimated', order.orderfortable__fc_price_estimated)}
                                                    >
                                                        {order.orderfortable__fc_price_estimated || '–'}
                                                    </span>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {editIndex === index && 'charterbooking__booking_price' in editValues ? (
                                                    <TextField
                                                        variant='standard'
                                                        value={editValues['charterbooking__booking_price'] || ''}
                                                        onChange={(e) => handleChange('charterbooking__booking_price', e)}
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => handleKeyDown(e, 'charterbooking__booking_price')}
                                                        autoFocus
                                                        type='number'
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'left',
                                                                padding: 0,
                                                            },
                                                            disableUnderline: true,
                                                        }}
                                                        sx={{
                                                            '& .MuiInput-root': {
                                                                width: '80px',
                                                                fontSize: '15px',
                                                            },
                                                            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                margin: 0
                                                            },
                                                            '& input[type="number"]': {
                                                                '-moz-appearance': 'textfield'
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <span
                                                        onDoubleClick={() => handleDoubleClick(index, 'charterbooking__booking_price', order.charterbooking__booking_price)}
                                                    >
                                                        {order.charterbooking__booking_price || '–'}
                                                    </span>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {order.total_advance_amount || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {order.orderfortable__fc_price - order.charterbooking__booking_price - order.total_advance_amount || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.7}>
                                            <Typography className={styles['content-info']}>
                                                {order.orderfortable__jp_recive_price || '–'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant='middle' />
                                </Grid>
                            ))}
                            {!loading && data.current_month.length === 0 &&
                                <Grid item xs={12}>
                                    <Box className={styles['no-data']}>
                                        <NoData style={{ width: '300px', height: '300px' }} />
                                        目前沒有任何訂單數據
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>

                </CardContent>
            </Card>
        </Box>
    );
}

export default AdminTab;