import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import api from '../../../config/axiosConfig';
import { Alert, Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { AddCircle, Delete, DeleteForever, DoDisturbOn, EditNote, SaveAs, Warning } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/LocalgroupTab.module.css';

const LocalgroupTab = ({ uuid, options }) => {
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {
                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleAddNewDayLocalGroupPaymentDetail = () => {
        setEditData((prevState) => ({
            ...prevState,
            localgrouppaymentdetails: {
                ...(prevState?.localgrouppaymentdetails || {}),
                newDayLocalGroupPaymentDetails: [
                    ...(prevState?.localgrouppaymentdetails?.newDayLocalGroupPaymentDetails || []),
                    { 
                        identifier: uuidv4(),
                        day: null,
                        hotel_name: '',
                        hotel_unit_price: '',
                        hotel_meal: null,
                        hotel_note: '',
                        lunch_unit_price: '',
                        lunch_note: '',
                        dinner_unit_price: '',
                        dinner_note: '',
                        busfare: ''
                    }
                ]
            }
        }));
    };

    const handleAddNewPlaceCostLocalGroupPaymentDetail = () => {
        setEditData((prevState) => ({
            ...prevState,
            localgrouppaymentdetails: {
                ...(prevState?.localgrouppaymentdetails || {}),
                newPlaceCostLocalGroupPaymentDetails: [
                    ...(prevState?.localgrouppaymentdetails?.newPlaceCostLocalGroupPaymentDetails || []),
                    { 
                        identifier: uuidv4(),
                        place: '',
                        place_unit_price: '',
                        place_note: ''
                    }
                ]
            }
        }));
    };

    const handleFieldChange = (parentKey, arrayKey, fieldName, fieldValue, idOrIdentifier, isNewItem = false) => {
        setEditData((prevState) => {
            // 确保 prevState 是一个对象
            const state = prevState || {};
    
            // 确保父级字典存在
            const parentDict = state[parentKey] ? { ...state[parentKey] } : {};
    
            // 获取目标数组
            let targetArray = parentDict[arrayKey] ? [...parentDict[arrayKey]] : [];
    
            // 根据 id 或 identifier 查找项目
            const itemIndex = targetArray.findIndex(
                item => item.id === idOrIdentifier || item.identifier === idOrIdentifier
            );
    
            if (itemIndex !== -1) {
                // 更新现有项目
                targetArray[itemIndex] = {
                    ...targetArray[itemIndex],
                    [fieldName]: fieldValue
                };
            } else {
                // 添加新项目
                const newItem = {
                    [fieldName]: fieldValue,
                    ...(isNewItem ? { identifier: idOrIdentifier } : { id: idOrIdentifier })
                };
                targetArray.push(newItem);
            }
    
            // 更新父级字典
            parentDict[arrayKey] = targetArray;
    
            // 返回新的状态
            return {
                ...state,
                [parentKey]: parentDict
            };
        });
    };    

    const handleEditClick = () => {
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditData(null);
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleDeleteClick = (deleteType, item) => {
        setDeleteItem({ deleteType, ...item });
        setDeleteCheckOpen(true);
    };

    const handleDeletCancelClick = (event, reason) => {
        // console.log(deleteItem);
        if (reason !== 'backdropClick') {
            setDeleteItem(null);
            setDeleteCheckOpen(false);
        }
    };

    const handleDeleteConfirmClick = async () => {
        switch (deleteItem.deleteType) {
            case 'DayLocalGroupPaymentDetails':
                try {
                    await api.delete(`/japan/api/DayLocalGroupPaymentDetails/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        localgrouppaymentdetails: {
                            ...prevState.localgrouppaymentdetails,
                            daylocalgrouppaymentdetails: prevState.localgrouppaymentdetails.daylocalgrouppaymentdetails.filter(day => day.id !== deleteItem.id)
                        }
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newDayLocalGroupPaymentDetails':
                setEditData((prevState) => ({
                    ...prevState,
                    localgrouppaymentdetails: {
                        ...prevState.localgrouppaymentdetails,
                        newDayLocalGroupPaymentDetails: prevState.localgrouppaymentdetails.newDayLocalGroupPaymentDetails.filter(day => day.identifier !== deleteItem.identifier)
                    }
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'PlaceCostLocalGroupPaymentDetails':
                try {
                    await api.delete(`/japan/api/PlaceCostLocalGroupPaymentDetails/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        localgrouppaymentdetails: {
                            ...prevState.localgrouppaymentdetails,
                            placecostlocalgrouppaymentdetails: prevState.localgrouppaymentdetails.placecostlocalgrouppaymentdetails.filter(place => place.id !== deleteItem.id)
                        }
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newPlaceCostLocalGroupPaymentDetails':
                setEditData((prevState) => ({
                    ...prevState,
                    localgrouppaymentdetails: {
                        ...prevState.localgrouppaymentdetails,
                        newPlaceCostLocalGroupPaymentDetails: prevState.localgrouppaymentdetails.newPlaceCostLocalGroupPaymentDetails.filter(place => place.identifier !== deleteItem.identifier)
                    }
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            default:
                console.log(`未知的刪除類型: ${deleteItem.deleteType}`);
                break;
        }
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);

            if (!editData) {
                setErrors(null);
                setEditMode(false);
                setEditData(null);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            const updateEditData = {
                ...editData,
                localgrouppaymentdetails: {
                    ...editData?.localgrouppaymentdetails,
                    daylocalgrouppaymentdetails: [
                        ...(editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails || []).map(day => ({ ...day })),
                        ...(editData?.localgrouppaymentdetails?.newDayLocalGroupPaymentDetails || []).map(day => ({ ...day }))
                    ],
                    placecostlocalgrouppaymentdetails: [
                        ...(editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails || []).map(place => ({ ...place })),
                        ...(editData?.localgrouppaymentdetails?.newPlaceCostLocalGroupPaymentDetails || []).map(place => ({ ...place }))
                    ]
                }
            };

            delete updateEditData.localgrouppaymentdetails.newDayLocalGroupPaymentDetails;
            if (updateEditData.localgrouppaymentdetails.daylocalgrouppaymentdetails.length === 0) {
                delete updateEditData.localgrouppaymentdetails.daylocalgrouppaymentdetails;
            }
            delete updateEditData.localgrouppaymentdetails.newPlaceCostLocalGroupPaymentDetails;
            if (updateEditData.localgrouppaymentdetails.placecostlocalgrouppaymentdetails.length === 0) {
                delete updateEditData.localgrouppaymentdetails.placecostlocalgrouppaymentdetails;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, updateEditData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box className={styles['content-box']}>

                {data && (
                    <Box className={styles['tab-main-box']}>

                        <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="導遊團費預支表" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.group_name}
                                    helperText={errors?.localgrouppaymentdetails?.group_name}
                                    label="團名"
                                    variant="standard"
                                    value={editData?.localgrouppaymentdetails?.group_name === '' ? '' : editData?.localgrouppaymentdetails?.group_name || data?.localgrouppaymentdetails?.group_name}
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, group_name: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.guild_name}
                                    helperText={errors?.localgrouppaymentdetails?.guild_name}
                                    label="導遊名稱"
                                    variant="standard"
                                    value={editData?.localgrouppaymentdetails?.guild_name === '' ? '' : editData?.localgrouppaymentdetails?.guild_name || data?.localgrouppaymentdetails?.guild_name}
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, guild_name: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.num_people}
                                    helperText={errors?.localgrouppaymentdetails?.num_people}
                                    label="旅遊人數"
                                    variant="standard"
                                    value={
                                        editData?.localgrouppaymentdetails?.hasOwnProperty('num_people')
                                            ? editData.localgrouppaymentdetails.num_people
                                            : data?.localgrouppaymentdetails?.num_people || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, num_people: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="飯店相關" />
                        </Divider>
                        {data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.map((day, index) => (
                            <div key={day.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('DayLocalGroupPaymentDetails', day)} />
                                )}

                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.day}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.day}
                                            label={"日期 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.day === null
                                                    ? null
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.day || day?.day
                                            }
                                            required
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "day", e.target.value || null, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_name}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_name}
                                            label={"飯店名稱 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_name === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_name || day?.hotel_name
                                            }
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "hotel_name", e.target.value, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_unit_price}
                                            label={"單價 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_unit_price === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_unit_price || parseFloat(day?.hotel_unit_price).toFixed(2).replace(/\.00$/, '')
                                            }
                                            required
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "hotel_unit_price", e.target.value, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label={"總計 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(
                                                (editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_unit_price || day?.hotel_unit_price) *
                                                (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)
                                            ).toFixed(2).replace(/\.00$/, '')}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={options?.local_group_payment_details?.hotel_meal}
                                            getOptionLabel={(option) => option.label}
                                            value={options?.local_group_payment_details?.hotel_meal.find(option => option.value === editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(i => i.id === day.id)?.hotel_meal) || options?.local_group_payment_details?.hotel_meal.find(option => option.value === day?.hotel_meal)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_meal}
                                                    helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_meal}
                                                    label={"餐食類型 #" + (index + 1)}
                                                    variant="standard"
                                                    required
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "hotel_meal", value?.value, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_note}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.hotel_note}
                                            label={"備註 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_note === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hotel_note || day?.hotel_note
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "hotel_note", e.target.value, day.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.localgrouppaymentdetails?.newDayLocalGroupPaymentDetails?.map((day, index) => (
                            <div key={day.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newDayLocalGroupPaymentDetails', day)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.day}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.day}
                                            label={"新增日期 #" + (index + 1)}
                                            variant="standard"
                                            value={day.day}
                                            required
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "day", e.target.value || null, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_name}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_name}
                                            label={"新增飯店名稱 #" + (index + 1)}
                                            variant="standard"
                                            value={day.hotel_name}
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "hotel_name", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_unit_price}
                                            label={"新增單價 #" + (index + 1)}
                                            variant="standard"
                                            value={day.hotel_unit_price}
                                            required
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "hotel_unit_price", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label={"新增總計 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(day.hotel_unit_price * (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)).toFixed(2).replace(/\.00$/, '')}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={options?.local_group_payment_details?.hotel_meal}
                                            getOptionLabel={(option) => option.label}
                                            value={options?.local_group_payment_details?.hotel_meal.find(option => option.value === day.hotel_meal)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_meal}
                                                    helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_meal}
                                                    label={"新增餐食類型 #" + (index + 1)}
                                                    variant="standard"
                                                    required
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "hotel_meal", value?.value, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_note}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.hotel_note}
                                            label={"新增備註 #" + (index + 1)}
                                            variant="standard"
                                            value={day.hotel_note}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "hotel_note", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="飯店預估金額"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.reduce((acc, day) => acc + Number(day.hotel_unit_price) * Number(data?.localgrouppaymentdetails?.num_people), 0)).toFixed(2).replace(/\.00$/, '')}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何飯店相關的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="餐食相關" />
                        </Divider>
                        {data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.map((day, index) => (
                            <div key={day.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('DayLocalGroupPaymentDetails', day)} />
                                )}
                                
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={1.5}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.lunch_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.lunch_unit_price}
                                            label={"午餐單價 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hasOwnProperty('lunch_unit_price')
                                                    ? editData.localgrouppaymentdetails.daylocalgrouppaymentdetails.find(it => it.id === day.id).lunch_unit_price || ''
                                                    : !isNaN(parseFloat(day?.lunch_unit_price))
                                                        ? parseFloat(day?.lunch_unit_price).toFixed(2).replace(/\.00$/, '')
                                                        : ''
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "lunch_unit_price", e.target.value, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <TextField
                                            label={"午餐預估金額 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(
                                                (editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.lunch_unit_price || day?.lunch_unit_price) *
                                                (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)
                                            ).toFixed(2).replace(/\.00$/, '')}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.lunch_note}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.lunch_note}
                                            label={"午餐備註 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.lunch_note === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.lunch_note || day?.lunch_note
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "lunch_note", e.target.value, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.dinner_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.dinner_unit_price}
                                            label={"晚餐單價 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hasOwnProperty('dinner_unit_price')
                                                    ? editData.localgrouppaymentdetails.daylocalgrouppaymentdetails.find(it => it.id === day.id).dinner_unit_price || ''
                                                    : !isNaN(parseFloat(day?.dinner_unit_price))
                                                        ? parseFloat(day?.dinner_unit_price).toFixed(2).replace(/\.00$/, '')
                                                        : ''
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "dinner_unit_price", e.target.value, day.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <TextField
                                            label={"晚餐預估金額 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(
                                                (editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.dinner_unit_price || day?.dinner_unit_price) *
                                                (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)
                                            ).toFixed(2).replace(/\.00$/, '')}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.dinner_note}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.dinner_note}
                                            label={"晚餐備註 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.dinner_note === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.dinner_note || day?.dinner_note
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "dinner_note", e.target.value, day.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.localgrouppaymentdetails?.newDayLocalGroupPaymentDetails?.map((day, index) => (
                            <div key={day.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newDayLocalGroupPaymentDetails', day)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.lunch_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.lunch_unit_price}
                                            label={"新增午餐單價 #" + (index + 1)}
                                            variant="standard"
                                            value={day.lunch_unit_price}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "lunch_unit_price", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.lunch_note}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.lunch_note}
                                            label={"新增午餐備註 #" + (index + 1)}
                                            variant="standard"
                                            value={day.lunch_note}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "lunch_note", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.dinner_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.dinner_unit_price}
                                            label={"新增晚餐單價 #" + (index + 1)}
                                            variant="standard"
                                            value={day.dinner_unit_price}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "dinner_unit_price", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.dinner_note}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.dinner_note}
                                            label={"新增晚餐備註 #" + (index + 1)}
                                            variant="standard"
                                            value={day.dinner_note}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "dinner_note", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="餐食預估金額"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.reduce((acc, day) => acc + Number(day.lunch_unit_price) * Number(data?.localgrouppaymentdetails?.num_people) + Number(day.dinner_unit_price) * Number(data?.localgrouppaymentdetails?.num_people), 0)).toFixed(2).replace(/\.00$/, '')}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何餐食相關的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        
                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="車費相關" />
                        </Divider>
                        {data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.map((day, index) => (
                            <div key={day.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('DayLocalGroupPaymentDetails', day)} />
                                )}

                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={"日期 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.day === null
                                                    ? null
                                                    : editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.day || day?.day
                                            }
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.busfare}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.id === String(day.id))?.busfare}
                                            label={"車輛費用 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(it => it.id === day.id)?.hasOwnProperty('busfare')
                                                    ? editData.localgrouppaymentdetails.daylocalgrouppaymentdetails.find(it => it.id === day.id).busfare || ''
                                                    : !isNaN(parseFloat(day?.busfare))
                                                        ? parseFloat(day?.busfare).toFixed(2).replace(/\.00$/, '')
                                                        : ''
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "daylocalgrouppaymentdetails", "busfare", e.target.value, day.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.localgrouppaymentdetails?.newDayLocalGroupPaymentDetails?.map((day, index) => (
                            <div key={day.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newDayLocalGroupPaymentDetails', day)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={"新增日期 #" + (index + 1)}
                                            variant="standard"
                                            value={day.day}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.busfare}
                                            helperText={errors?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.find(error => error.identifier === day.identifier)?.busfare}
                                            label={"新增車輛費用 #" + (index + 1)}
                                            variant="standard"
                                            value={day.busfare}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newDayLocalGroupPaymentDetails", "busfare", e.target.value, day.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="車費預估金額"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.reduce((acc, day) => acc + Number(day.busfare), 0)).toFixed(2).replace(/\.00$/, '')}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何車費相關的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {editMode && (
                            <Box className={styles['sub-add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewDayLocalGroupPaymentDetail}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增一天的飯店、餐食、車費資料
                                </Button>
                            </Box>
                        )}

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="景區相關" />
                        </Divider>
                        {data?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.map((place, index) => (
                            <div key={place.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('PlaceCostLocalGroupPaymentDetails', place)} />
                                )}
                                
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.id === String(place.id))?.place}
                                            helperText={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.id === String(place.id))?.place}
                                            label={"景點名稱 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(it => it.id === place.id)?.place === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(it => it.id === place.id)?.place || place?.place
                                            }
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "placecostlocalgrouppaymentdetails", "place", e.target.value, place.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.id === String(place.id))?.place_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.id === String(place.id))?.place_unit_price}
                                            label={"單價 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(it => it.id === place.id)?.hasOwnProperty('place_unit_price')
                                                    ? editData.localgrouppaymentdetails.placecostlocalgrouppaymentdetails.find(it => it.id === place.id).place_unit_price || ''
                                                    : !isNaN(parseFloat(place?.place_unit_price))
                                                        ? parseFloat(place?.place_unit_price).toFixed(2).replace(/\.00$/, '')
                                                        : ''
                                            }
                                            required
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "placecostlocalgrouppaymentdetails", "place_unit_price", e.target.value, place.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label={"總計 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(
                                                (editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(it => it.id === place.id)?.place_unit_price || place?.place_unit_price) *
                                                (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)
                                            ).toFixed(2).replace(/\.00$/, '')}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.id === String(place.id))?.place_note}
                                            helperText={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.id === String(place.id))?.place_note}
                                            label={"備註 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(it => it.id === place.id)?.place_note === ''
                                                    ? ''
                                                    : editData?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(it => it.id === place.id)?.place_note || place?.place_note
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "placecostlocalgrouppaymentdetails", "place_note", e.target.value, place.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.localgrouppaymentdetails?.newPlaceCostLocalGroupPaymentDetails?.map((place, index) => (
                            <div key={place.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newPlaceCostLocalGroupPaymentDetails', place)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.identifier === place.identifier)?.place}
                                            helperText={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.identifier === place.identifier)?.place}
                                            label={"新增景點名稱 #" + (index + 1)}
                                            variant="standard"
                                            value={place.place}
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newPlaceCostLocalGroupPaymentDetails", "place", e.target.value, place.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.identifier === place.identifier)?.place_unit_price}
                                            helperText={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.identifier === place.identifier)?.place_unit_price}
                                            label={"新增單價 #" + (index + 1)}
                                            variant="standard"
                                            value={place.place_unit_price}
                                            required
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newPlaceCostLocalGroupPaymentDetails", "place_unit_price", e.target.value, place.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label={"新增總計 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(place.place_unit_price * (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)).toFixed(2).replace(/\.00$/, '')}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.identifier === place.identifier)?.place_note}
                                            helperText={errors?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.find(error => error.identifier === place.identifier)?.place_note}
                                            label={"新增備註 #" + (index + 1)}
                                            variant="standard"
                                            value={place.place_note}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("localgrouppaymentdetails", "newPlaceCostLocalGroupPaymentDetails", "place_note", e.target.value, place.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="景區預估金額"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.reduce((acc, place) => acc + Number(place.place_unit_price) * Number(data?.localgrouppaymentdetails?.num_people), 0)).toFixed(2).replace(/\.00$/, '')}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何景區相關的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {editMode && (
                            <Box className={styles['sub-add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewPlaceCostLocalGroupPaymentDetail}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增一個景點資料
                                </Button>
                            </Box>
                        )}

                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={3}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.estimated_toll_amount}
                                    helperText={errors?.localgrouppaymentdetails?.estimated_toll_amount}
                                    label="過路費預估金額"
                                    variant="standard"
                                    value={
                                        editData?.localgrouppaymentdetails?.estimated_toll_amount === null
                                            ? null
                                            : editData?.localgrouppaymentdetails?.estimated_toll_amount === ''
                                                ? ''
                                                : editData?.localgrouppaymentdetails?.estimated_toll_amount || parseFloat(data?.localgrouppaymentdetails?.estimated_toll_amount).toFixed(2).replace(/\.00$/, '')
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, estimated_toll_amount: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.tour_guide_travel_expenses}
                                    helperText={errors?.localgrouppaymentdetails?.tour_guide_travel_expenses}
                                    label="導遊差旅費"
                                    variant="standard"
                                    value={
                                        editData?.localgrouppaymentdetails?.tour_guide_travel_expenses === null
                                            ? null
                                            : editData?.localgrouppaymentdetails?.tour_guide_travel_expenses === ''
                                                ? ''
                                                : editData?.localgrouppaymentdetails?.tour_guide_travel_expenses || parseFloat(data?.localgrouppaymentdetails?.tour_guide_travel_expenses).toFixed(2).replace(/\.00$/, '')
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, tour_guide_travel_expenses: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="預估合計金額"
                                    variant="standard"
                                    value={editMode ? '保存後計算' :
                                        parseFloat(
                                            data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.reduce((acc, cur) => {
                                                return acc + parseFloat(cur?.hotel_unit_price || 0) * (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)
                                            }, 0) +
                                            data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.reduce((acc, cur) => {
                                                return acc + parseFloat(cur?.lunch_unit_price || 0) + parseFloat(cur?.dinner_unit_price || 0)
                                            }, 0) +
                                            data?.localgrouppaymentdetails?.daylocalgrouppaymentdetails?.reduce((acc, cur) => {
                                                return acc + parseFloat(cur?.busfare || 0)
                                            }, 0) +
                                            data?.localgrouppaymentdetails?.placecostlocalgrouppaymentdetails?.reduce((acc, cur) => {
                                                return acc + parseFloat(cur?.place_unit_price || 0) * (editData?.localgrouppaymentdetails?.num_people || data?.localgrouppaymentdetails?.num_people)
                                            }, 0)
                                        ).toFixed(2).replace(/\.00$/, '')}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.actual_travel_expenses}
                                    helperText={errors?.localgrouppaymentdetails?.actual_travel_expenses}
                                    label="實際帶團費"
                                    variant="standard"
                                    value={
                                        editData?.localgrouppaymentdetails?.actual_travel_expenses === null
                                            ? null
                                            : editData?.localgrouppaymentdetails?.actual_travel_expenses === ''
                                                ? ''
                                                : editData?.localgrouppaymentdetails?.actual_travel_expenses || parseFloat(data?.localgrouppaymentdetails?.actual_travel_expenses).toFixed(2).replace(/\.00$/, '')
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                        startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, actual_travel_expenses: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.review}
                                    helperText={errors?.localgrouppaymentdetails?.review}
                                    label="審核人員"
                                    variant="standard"
                                    value={editData?.localgrouppaymentdetails?.review === '' ? '' : editData?.localgrouppaymentdetails?.review || data?.localgrouppaymentdetails?.review}
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, review: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={errors?.localgrouppaymentdetails?.creator}
                                    helperText={errors?.localgrouppaymentdetails?.creator}
                                    label="製表人"
                                    variant="standard"
                                    value={editData?.localgrouppaymentdetails?.creator === '' ? '' : editData?.localgrouppaymentdetails?.creator || data?.localgrouppaymentdetails?.creator}
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, localgrouppaymentdetails: { ...editData?.localgrouppaymentdetails, creator: e.target.value } })}
                                />
                            </Grid>
                        </Grid>

                        <Box />

                    </Box>
                )}

                {!data && (
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {loading ? (
                            <CircularProgress color="info" />
                        ) : (
                            <>
                                <NoDataImg style={{ width: '300px', height: '300px' }} />
                                無法取得訂單資料或發生錯誤: {errors?.detail}
                            </>
                        )}
                    </Box>
                )}
                
            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Box className={styles['action-box']}>
                {editMode ? (
                    <>
                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                            取消
                        </Button>
                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                            保存
                        </Button>
                    </>
                ) : (
                    <Button className={styles['edit-button']} onClick={handleEditClick}>
                        <EditNote sx={{ width: '22px', height: '22px' }} />
                        編輯
                    </Button>
                )}
            </Box>

            <Dialog disableEscapeKeyDown open={deleteCheckOpen} onClose={handleDeletCancelClick} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {deleteItem?.deleteType === 'DayLocalGroupPaymentDetails' && (
                            <div>
                                <div>日期：{deleteItem?.day || '未設置'}</div>
                                <div>飯店名稱：{deleteItem?.hotel_name || '未設置'}</div>
                                <div>飯店單價：{deleteItem?.hotel_unit_price ? parseFloat(deleteItem?.hotel_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                                <div>餐食類型：{deleteItem?.hotel_meal || '未設置'}</div>
                                <div>午餐單價：{deleteItem?.lunch_unit_price ? parseFloat(deleteItem?.lunch_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'} / 晚餐單價：{deleteItem?.dinner_unit_price ? parseFloat(deleteItem?.dinner_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                                <div>車費：{deleteItem?.busfare ? parseFloat(deleteItem?.busfare).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                                <span style={{ color: 'red' }}>將刪除以上資料(包含飯店、餐食、車費等)，確認要繼續嗎？</span>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newDayLocalGroupPaymentDetails' && (
                            <div>
                                <div>日期：{deleteItem?.day || '未設置'}</div>
                                <div>飯店名稱：{deleteItem?.hotel_name || '未設置'}</div>
                                <div>飯店單價：{deleteItem?.hotel_unit_price ? parseFloat(deleteItem?.hotel_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                                <div>餐食類型：{deleteItem?.hotel_meal || '未設置'}</div>
                                <div>午餐單價：{deleteItem?.lunch_unit_price ? parseFloat(deleteItem?.lunch_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'} / 晚餐單價：{deleteItem?.dinner_unit_price ? parseFloat(deleteItem?.dinner_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                                <div>車費：{deleteItem?.busfare ? parseFloat(deleteItem?.busfare).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                                <span style={{ color: 'red' }}>將刪除以上資料(包含飯店、餐食、車費等)，確認要繼續嗎？</span>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'PlaceCostLocalGroupPaymentDetails' && (
                            <div>
                                <div>景點名稱：{deleteItem?.place || '未設置'}</div>
                                <div>單價：{deleteItem?.place_unit_price ? parseFloat(deleteItem?.place_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newPlaceCostLocalGroupPaymentDetails' && (
                            <div>
                                <div>景點名稱：{deleteItem?.place || '未設置'}</div>
                                <div>單價：{deleteItem?.place_unit_price ? parseFloat(deleteItem?.place_unit_price).toFixed(2).replace(/\.00$/, '') : '未設置'}</div>
                            </div>
                        )}
                        <br />此操作無法復原，請謹慎處理。
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['delete-cancel-button']} onClick={handleDeletCancelClick}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleDeleteConfirmClick}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>
            
        </Box>
    );
}

export default LocalgroupTab;