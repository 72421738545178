import React, { useState, useEffect } from 'react';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import api from '../../config/axiosConfig';
import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography, IconButton,
    Dialog, DialogContent, Alert, Tooltip, Chip
} from '@mui/material';
import { Add, Delete, Edit, DoDisturbOn, Security, AddCircleOutlined, NotificationImportant } from '@mui/icons-material';
import styles from './css/GroupTab.module.css';

const GroupTab = () => {
    const [group, setGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [editingGroup, setEditingGroup] = useState(false);
    const [addingGroup, setAddingGroup] = useState(false);
    const [newGroup, setNewGroup] = useState({});
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [deleteGroup, setDeleteGroup] = useState({});
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState({});
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            try {
                const groupResponse = await api.get('/core/api/Group/');
                setGroup(groupResponse.data);
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [setHasUnsavedChanges]);

    useEffect(() => {
        let timer;
        if (status === 'NewUserSuccess' || status === 'EditUserSuccess' || status === 'DeleteUserSuccess') {
            timer = setTimeout(() => {
                if (status === 'NewUserSuccess' || status === 'EditUserSuccess' || status === 'DeleteUserSuccess') {
                    setStatus('initial');
                }
            }, 11500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleAddingGroup = () => {
        setAddingGroup(true);
        setHasUnsavedChanges(true);
    };

    const handleNewGroupSubmit = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const updateGroup = { ...newGroup, info: { ...newGroup.info, group_type: "全站使用" } };
            const response = await api.post('/core/api/Group/', updateGroup);
            setGroup([...group, response.data]);
            setHasUnsavedChanges(false);
            setAddingGroup(false);
            setStatus('NewGroupSuccess');
            setNewGroup({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('NewGroupError');
        }
    };

    const handleEditGroup = (group) => {
        setErrors({});
        setSelectedGroup(group);
        setEditingGroup(true);
        setHasUnsavedChanges(true);
    };

    const handleEditGroupSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            const response = await api.patch(`/core/api/Group/${selectedGroup.id}/`, selectedGroup);
            setGroup(group.map((group) => group.id === selectedGroup.id ? response.data : group));
            setHasUnsavedChanges(false);
            setEditingGroup(false);
            setStatus('EditGroupSuccess');
            setSelectedGroup({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('EditGroupError');
        }
    };

    const handleDeleteGroup = (group, id) => {
        setDeleteCheck(true);
        setDeleteGroup({ group: group, id: id });
    };

    const handleDeleteGroupConfirm = async (id) => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            await api.delete(`/core/api/Group/${id}/`);
            setGroup(group.filter((group) => group.id !== id));
            setDeleteCheck(false);
            setStatus('DeleteGroupSuccess');
            setDeleteGroup({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('DeleteGroupError');
        }
    };

    return (
        <Box className={styles['main-box']}>
            <Card className={styles['card']}>
                <CardContent className={styles['cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

                    {!addingGroup && !editingGroup && (
                        <>
                            <Box className={styles['view-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography className={styles['title-label']}>
                                            群組名稱
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={styles['title-label']}>
                                            權限分配說明
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography className={styles['title-label']}>
                                            操作
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['view-cardcontent-content-box']}>
                                {group.filter((groups) => groups.info.group_type === "全站使用").map((groups) => (
                                    <>
                                        <Grid container key={group.id}>

                                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']} sx={{ padding: '10px 0 10px 0' }}>
                                                    <Chip label={groups.name} icon={<Security sx={{ width: '20px', height: '20px'}} />} color="primary" />
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {groups.info?.introduction || <span style={{ color: 'GrayText'}}>沒有任何說明</span>}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="編輯" arrow placement="left">
                                                    <IconButton className={styles['edit-button']} disableRipple onClick={() => handleEditGroup(groups)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="刪除" arrow placement="right">
                                                    <IconButton className={styles['delete-button']} disableRipple onClick={() => handleDeleteGroup(groups.name, groups.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>

                                        </Grid>

                                        {group.filter((groups) => groups.info.group_type === "全站使用").indexOf(groups) < group.filter((groups) => groups.info.group_type === "全站使用").length - 1 && (
                                            <Divider />
                                        )}
                                    </>
                                ))}
                            </Box>

                            <Box className={styles['view-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddingGroup}>新增權限群組</Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <div style={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                                {status === 'NewGroupSuccess' && (
                                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                                        成功新增權限群組
                                    </Alert>
                                )}
                                {status === 'EditGroupSuccess' && (
                                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                                        成功保存群組資料
                                    </Alert>
                                )}
                                {status === 'DeleteGroupSuccess' && (
                                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                                        成功刪除權限群組
                                    </Alert>
                                )}
                            </div>
                        </>
                    )}

                    {addingGroup && (
                        <>
                            <Box className={styles['adding-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在新增權限群組. . .
                                            <span style={{ color: '#d32f2f' }}> (帶*為必填欄位)</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.name}
                                            helperText={errors?.name}
                                            required
                                            label="群組名稱"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(event) => setNewGroup({ ...newGroup, name: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            error={errors?.info?.introduction}
                                            helperText={errors?.info?.introduction}
                                            label="群組權限介紹"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(event) => setNewGroup({ ...newGroup, info: { ...newGroup.info, introduction: event.target.value } })}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button className={styles['cancel-button']} onClick={() => {
                                            setAddingGroup(false);
                                            setHasUnsavedChanges(false);
                                            setStatus('initial');
                                            setNewGroup({});
                                            setErrors({});
                                        }}>
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '14px' }}>
                                            {status === 'NewGroupError' && <span>提交失敗，請檢查所有欄位是否正確填寫</span>}
                                        </Typography>
                                        <Button className={styles['submit-button']} onClick={handleNewGroupSubmit}>
                                            提交
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                    {editingGroup && (
                        <>
                             <Box className={styles['editing-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在編輯權限群組 {selectedGroup.name} . . .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['editing-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.name}
                                            helperText={errors?.name}
                                            required
                                            label="群組名稱"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            value={selectedGroup.name}
                                            onChange={(event) => setSelectedGroup({ ...selectedGroup, name: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            error={errors?.info?.introduction}
                                            helperText={errors?.info?.introduction}
                                            label="群組權限介紹"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            value={selectedGroup.info?.introduction}
                                            onChange={(event) => setSelectedGroup({ ...selectedGroup, info: { ...selectedGroup.info, introduction: event.target.value } })}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['editing-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button className={styles['cancel-button']} onClick={() => {
                                            setEditingGroup(false);
                                            setHasUnsavedChanges(false);
                                            setStatus('initial');
                                            setSelectedGroup({});
                                            setErrors({});
                                        }}>
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '14px' }}>
                                            {status === 'EditGroupError' && <span>保存失敗，請仔細檢查所有數據資料</span>}
                                        </Typography>
                                        <Button className={styles['save-button']} onClick={handleEditGroupSave}>
                                            保存
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                </CardContent>
            </Card>

            <Dialog
                open={deleteCheck}
                onClose={() => setDeleteCheck(false)}
            >
                <DialogContent className={styles['delete-dialog-content']}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: '30px'}}>
                        <Typography className={styles['delete-dialog-title']}>
                            <NotificationImportant sx={{ fontSize: '40px' }} />
                            確定要刪除權限群組 {deleteGroup.group} 嗎？
                        </Typography>
                        <Typography className={styles['delete-dialog-subtitle']}>
                            註: 刪除後將無法復原，請謹慎操作
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {status === 'DeleteGroupError' && (
                            <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '15px' }}>
                                {errors?.error}
                            </Typography>
                        )}
                        <Box sx={{ position: 'absolute', bottom: '20px', display: 'flex', gap: '20px' }}>
                            <Button className={styles['delete-cancel-button']} disableRipple onClick={() => {
                                setDeleteCheck(false);
                                setStatus('initial');
                                setDeleteGroup({});
                                setErrors({});
                            }}>
                                <DoDisturbOn sx={{ width: '20px', height: '20px' }}/>
                                取消
                            </Button>
                            <Button className={styles['delete-confirm-button']} disableRipple onClick={() => handleDeleteGroupConfirm(deleteGroup.id)}>
                                刪除
                                <Delete sx={{ width: '20px', height: '20px' }}/>
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
                            
        </Box>
    );
}

export default GroupTab;