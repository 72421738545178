import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, TextField, Button, Typography, Alert, Box, InputAdornment, IconButton, Paper } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundImage from './img/login-background.png';

function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = "小滿訂單管理系統 - 登入";
    }, []);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log("Location before login:", location);
        try {
            const response = await api.post('/core/api/token/', {
                username: username,
                password: password,
            });

            // console.log('Login Submitted', { username, password });
            // console.log('Location State on Submit', location.state);

            if (response.data && response.data.access && response.data.refresh) {
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                setErrorMessage("");

                props.setToken(response.data.access);
                const from = location.state?.from || "/Announcement";
                // console.log("Navigating to:", from);
                if (from.pathname === "/") {
                    navigate("/Announcement", { replace: true });
                } else {
                    navigate(from, { replace: true });
                }

            } else {
                setErrorMessage("登入失敗，請再試一次.");
            }
        } catch (error) {
            if (error.response && error.response.data.non_field_errors) {
                setErrorMessage(error.response.data.non_field_errors[0]);
            } else {
                setErrorMessage("帳號或密碼錯誤，請再仔細檢查一遍.");
            }
        }
    };

    return (
        <div>
            <Grid container style={{ height: '100vh' }}>
                <Grid item xs={6} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                </Grid>

                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Paper elevation={5} style={{ padding: '30px 40px', borderRadius: '15px' }}>
                            <Typography component="h1" variant="h5" gutterBottom textAlign='center'>
                                小滿訂單管理系統登入
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} width="100%" mt={2}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="使用者帳號"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="使用者密碼"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {errorMessage && <Alert severity="error" style={{marginTop: 15}}>{errorMessage}</Alert>}
                                <Button 
                                    type="submit" 
                                    fullWidth 
                                    variant="contained" 
                                    color="primary" 
                                    style={{marginTop: 15, padding: '8px 0'}}
                                >
                                    點 擊 登 入
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

            <footer style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center', padding: '10px 0', backgroundColor: '#f1f1f1' }}>
                <Typography variant="caption" color="textSecondary">
                    {/* © 2021 小滿訂單管理系統 */}
                    © 2023 - {new Date().getFullYear()} 小滿科技有限公司 版權所有
                </Typography>
            </footer>

        </div>
    );
}

export default Login;