import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import UserGroupContext from '../../../contexts/UserGroupContext';
import api from '../../../config/axiosConfig';
import { Alert, Autocomplete, Avatar, AvatarGroup, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { AddCircle, Delete, DeleteForever, DoDisturbOn, EditNote, SaveAs, Warning } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/CommunicationsTab.module.css';

const CommunicationsTab = ({ uuid, options, currentUser, type }) => {
    const navigate = useNavigate();
    const userGroups = React.useContext(UserGroupContext);
    const [data, setData] = useState(null);
    const [tab, setTab] = useState('external');
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {
                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        if (type) {
          setTab(type);
        }
    }, [type]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleCommunicationTypeChange = (value) => {
        setTab(value);
        navigate(`#communications=${value}`);
    };

    const handleAddNewExternalCommunication = () => {
        setHasUnsavedChanges(true);
        setEditingId(-1);
        setEditData({
            ...editData,
            newExternalCommunications: [
                ...(editData?.newExternalCommunications || []),
                {
                    identifier: uuidv4(),
                    question: '',
                }
            ]
        });
    }

    const handleAddNewInternalCommunication = () => {
        setHasUnsavedChanges(true);
        setEditingId(-1);
        setEditData({
            ...editData,
            newInternalCommunications: [
                ...(editData?.newInternalCommunications || []),
                {
                    identifier: uuidv4(),
                    question: '',
                    question_target: [],
                }
            ]
        });
    }

    const handleFieldChange = (arrayName, fieldName, fieldValue, idOrIdentifier, isParentNew = false, nestedArrayName = null, parentId = null, isNestedNew = false) => {
        setEditData((prevState) => {
            let currentArray = prevState?.[arrayName] ? [...prevState[arrayName]] : [];
    
            if (parentId && nestedArrayName) {
                // 查找父級項目，無論父級是否是新的
                let parentIndex = currentArray.findIndex(item => item.id === parentId || item.identifier === parentId);
    
                // 如果父級不存在，創建父級數據
                if (parentIndex === -1) {
                    const newParentItem = {
                        [nestedArrayName]: [],
                        ...(isParentNew ? { identifier: parentId } : { id: parentId })
                    };
                    currentArray.push(newParentItem);
                    parentIndex = currentArray.length - 1;
                }
    
                // 獲取父級項目並找到其嵌套數組
                const parentItem = { ...currentArray[parentIndex] };
                let nestedArray = parentItem[nestedArrayName] ? [...parentItem[nestedArrayName]] : [];
    
                // 根據 isNestedNew 和 identifier/id 查找嵌套數據
                const nestedItemIndex = isNestedNew
                    ? nestedArray.findIndex(item => item.identifier === idOrIdentifier)
                    : nestedArray.findIndex(item => item.id === idOrIdentifier);
    
                if (nestedItemIndex !== -1) {
                    nestedArray[nestedItemIndex] = {
                        ...nestedArray[nestedItemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newNestedItem = {
                        [fieldName]: fieldValue
                    };
                    if (isNestedNew) {
                        newNestedItem.identifier = idOrIdentifier;
                    } else {
                        newNestedItem.id = idOrIdentifier;
                    }
                    nestedArray.push(newNestedItem);
                }
    
                parentItem[nestedArrayName] = nestedArray;
                currentArray[parentIndex] = parentItem;
    
            } else {
                // 操作父級數據（非嵌套）
                const itemIndex = currentArray.findIndex(item => item.id === idOrIdentifier || item.identifier === idOrIdentifier);
    
                if (itemIndex !== -1) {
                    currentArray[itemIndex] = {
                        ...currentArray[itemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newItem = {
                        [fieldName]: fieldValue
                    };
                    if (isParentNew) {
                        newItem.identifier = idOrIdentifier;
                    } else {
                        newItem.id = idOrIdentifier;
                    }
                    currentArray.push(newItem);
                }
            }
    
            return {
                ...prevState,
                [arrayName]: currentArray
            };
        });
    };

    const handleEditClick = (id) => {
        setEditingId(id);
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditingId(null);
        setEditData(null);
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleDeleteClick = (deleteType, item) => {
        setDeleteItem({ deleteType, ...item });
        setDeleteCheckOpen(true);
    };

    const handleDeletCancelClick = (event, reason) => {
        // console.log(deleteItem);
        if (reason !== 'backdropClick') {
            setDeleteItem(null);
            setDeleteCheckOpen(false);
        }
    };

    const handleDeleteConfirmClick = async () => {
        switch (deleteItem.deleteType) {
            case 'ExternalCommunication':
                try {
                    await api.delete(`/japan/api/ExternalCommunication/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        externalcommunications: prevState.externalcommunications.filter(ec => ec.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'InternalCommunication':
                try {
                    await api.delete(`/japan/api/InternalCommunication/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        internalcommunications: prevState.internalcommunications.filter(ic => ic.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            default:
                console.log(`未知的刪除類型: ${deleteItem.deleteType}`);
                break;
        }
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);

            if (!editData) {
                setErrors(null);
                setEditData(null);
                setEditingId(null);
                setEditMode(false);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            const updateEditData = {
                ...editData,
                externalcommunications: [
                    ...(editData?.externalcommunications || []).map(ec => ({ ...ec, order: uuid })),
                    ...(editData?.newExternalCommunications || []).map(ec => ({ ...ec, order: uuid }))
                ],
                internalcommunications: [
                    ...(editData?.internalcommunications || []).map(ic => ({ ...ic, order: uuid })),
                    ...(editData?.newInternalCommunications || []).map(ic => ({ ...ic, order: uuid }))
                ]
            };

            delete updateEditData.newExternalCommunications;
            if (updateEditData.externalcommunications.length === 0) {
                delete updateEditData.externalcommunications;
            }
            delete updateEditData.newInternalCommunications;
            if (updateEditData.internalcommunications.length === 0) {
                delete updateEditData.internalcommunications;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, updateEditData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditingId(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box sx={{ height: '38px', display: 'flex', justifyContent: 'center' }}>
                <Box className={styles['category-box']}>
                    {!userGroups.includes('日方人員') && (
                        <>
                            <Button className={tab === 'external' ? styles['category-button-selected'] : styles['category-button']} onClick={() => handleCommunicationTypeChange('external')} disabled={editingId}>
                                外站
                            </Button>
                            <Button className={tab === 'internal' ? styles['category-button-selected'] : styles['category-button']} onClick={() => handleCommunicationTypeChange('internal')} disabled={editingId}>
                                內站
                            </Button>
                        </>
                    )}
                </Box>
            </Box>

            <Box className={styles['content-box']}>

                {tab === 'external' && data && (
                    <Box className={styles['tab-main-box']}>

                        <Box />

                        {data?.externalcommunications.map((ec, index) => (
                            <div key={ec.id} className={styles['sub-box']}>
                                {userGroups.includes('管理員') && (!editMode || editingId !== ec.id) && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('ExternalCommunication', ec)} />
                                )}

                                {ec.question_user && editingId !== ec.id && (
                                    <>
                                        <Chip
                                            className={styles["question-user"]}
                                            avatar={<Avatar alt="提問人頭像" src={ec.question_user?.profile?.photo} />}
                                            label={ec?.question_user?.profile?.nickname || ec?.question_user?.username}
                                            variant="outlined"
                                        />
                                        <Divider variant="middle" textAlign="right" className={styles["question-time"]}>提問時間 {new Date(ec?.question_created_at).toLocaleString()}</Divider>
                                        <Typography className={styles.question}>{ec?.question}</Typography>
                                        {ec.question === '' && (
                                            <Divider variant="middle" className={styles["question-empty"]}>提問內容為空，請進一步確認是否為誤操作</Divider>
                                        )}
                                    </>
                                )}

                                {editMode && editingId === ec.id && (
                                    <>
                                        <Divider variant="middle" className={styles["edit-question"]}>編輯提問內容</Divider>
                                        <TextField
                                            error={errors?.externalcommunications?.find(error => error.id === String(ec.id))?.question}
                                            helperText={errors?.externalcommunications?.find(error => error.id === String(ec.id))?.question}
                                            label="提問"
                                            variant="standard"
                                            value={
                                                editData?.externalcommunications?.find(it => it.id === ec.id)?.question === ''
                                                    ? ''
                                                    : editData?.externalcommunications?.find(it => it.id === ec.id)?.question || ec.question
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode && ec.question_user?.id === currentUser?.id}
                                            InputProps={{
                                                readOnly: !editMode || ec.question_user?.id !== currentUser?.id,
                                            }}
                                            onChange={(e) => handleFieldChange("externalcommunications", "question", e.target.value, ec.id)}
                                        />
                                    </>
                                )}

                                {ec.reply_user && editingId !== ec.id && (
                                    <>
                                        <Box style={{ paddingTop: '10px', textAlign: 'right' }}>
                                            <Chip
                                                className={styles["reply-user"]}
                                                avatar={<Avatar alt="回覆人頭像" src={ec.reply_user?.profile?.photo} />}
                                                label={ec.reply_user?.profile?.nickname || ec.reply_user?.username}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Divider variant="middle" textAlign="left" className={styles["reply-time"]}>回覆時間 {new Date(ec.reply_created_at).toLocaleString()}</Divider>
                                        <Typography className={styles.reply}>{ec.reply}</Typography>
                                        {ec.reply === '' && (
                                            <Divider variant="middle" className={styles["reply-empty"]}>回覆內容為空，請進一步確認是否為誤操作</Divider>
                                        )}
                                    </>
                                )}

                                {!ec.reply_user && editingId !== ec.id && (
                                    <Divider variant="middle" className={styles["no-reply"]}>尚未回覆</Divider>
                                )}

                                {editMode && editingId === ec.id && (
                                    <>
                                        <Divider variant="middle" className={styles["edit-reply"]}>編輯回覆內容</Divider>
                                        <TextField
                                            error={errors?.externalcommunications?.find(error => error.id === String(ec.id))?.reply}
                                            helperText={errors?.externalcommunications?.find(error => error.id === String(ec.id))?.reply}
                                            label="回覆"
                                            variant="standard"
                                            value={
                                                editData?.externalcommunications?.find(it => it.id === ec.id)?.reply === ''
                                                    ? ''
                                                    : editData?.externalcommunications?.find(it => it.id === ec.id)?.reply || ec.reply
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode && ec.question_user?.id !== currentUser?.id}
                                            InputProps={{
                                                readOnly: !editMode || ec.question_user?.id === currentUser?.id,
                                            }}
                                            onChange={(e) => handleFieldChange("externalcommunications", "reply", e.target.value, ec.id)}
                                        />
                                    </>
                                )}

                                {editingId !== ec.id ? (
                                    <Box className={styles['action-box']}>
                                        <Button className={styles['edit-button']} onClick={() => handleEditClick(ec.id)} disabled={editingId ? editingId !== ec.id : false}>
                                            <EditNote sx={{ width: '22px', height: '22px' }} />
                                            {editingId ? (editingId !== ec.id ? '正在編輯其他筆資料' : '編輯') : '編輯'}
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box className={styles['action-box']}>
                                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                                            取消
                                        </Button>
                                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                                            保存
                                        </Button>
                                    </Box>
                                )}
                            </div>
                        ))}

                        {editData?.newExternalCommunications?.map((ec, index) => (
                            <div key={ec.identifier} className={styles['sub-box']} style={{ marginBottom: '10px'}}>
                                <TextField
                                    error={errors?.externalcommunications?.find(error => error.identifier === ec.identifier)?.question}
                                    helperText={errors?.externalcommunications?.find(error => error.identifier === ec.identifier)?.question}
                                    label={`新增提問 ${index + 1}`}
                                    variant="standard"
                                    value={ec.question}
                                    multiline
                                    size='small'
                                    fullWidth
                                    focused={true}
                                    onChange={(e) => handleFieldChange("newExternalCommunications", "question", e.target.value, ec.identifier, true)}
                                />
                                <Box className={styles['action-box']}>
                                    <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                                        <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleSaveClick}>
                                        <SaveAs sx={{ width: '22px', height: '22px' }} />
                                        保存
                                    </Button>
                                </Box>
                            </div>
                        ))}

                        {data?.externalcommunications.length === 0 && !editData?.newExternalCommunications && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <SearchingImg style={{ width: '300px', height: '300px' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何外站提問</Typography>
                                </Grid>
                            </Grid>
                        )}

                        {!editData?.newExternalCommunications && !editMode && (
                            <Box className={styles['add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewExternalCommunication}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增外站提問
                                </Button>
                            </Box>
                        )}

                    </Box>
                )}

                {tab === 'internal' && data && (
                    <Box className={styles['tab-main-box']}>

                        <Box />

                        {data?.internalcommunications.map((ic, index) => (
                            <div key={ic.id} className={styles['sub-box']}>
                                {userGroups.includes('管理員') && (!editMode || editingId !== ic.id) && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('InternalCommunication', ic)} />
                                )}
                                
                                {ic.question_user && editingId !== ic.id && (
                                    <>
                                        <Chip
                                            className={styles["question-user"]}
                                            avatar={<Avatar alt="提問人頭像" src={ic.question_user?.profile?.photo} />}
                                            label={ic?.question_user?.profile?.nickname || ic?.question_user?.username}
                                            variant="outlined"
                                        />
                                        <Divider variant="middle" textAlign="right" className={styles["question-time"]}>提問時間 {new Date(ic?.question_created_at).toLocaleString()}</Divider>
                                        <Typography className={styles.question}>{ic?.question}</Typography>
                                        {ic.question === '' && (
                                            <Divider variant="middle" className={styles["question-empty"]}>提問內容為空，請進一步確認是否為誤操作</Divider>
                                        )}
                                        <Box className={styles['question-target']}>
                                            <AvatarGroup max={4}>
                                                {ic.question_target_display.map(target => (
                                                    <Tooltip key={target.id} title={target?.profile?.nickname || target?.username} placement='top'>
                                                        <Avatar
                                                            alt={target?.profile?.nickname}
                                                            src={target?.profile?.photo}
                                                            sx={{ width: 24, height: 24, marginLeft: '3px' }}
                                                        />
                                                    </Tooltip>
                                                ))}
                                            </AvatarGroup>
                                        </Box>
                                    </>
                                )}

                                {editMode && editingId === ic.id && (
                                    <>
                                        <Divider variant="middle" className={styles["edit-question"]}>編輯提問內容</Divider>
                                        <TextField
                                            error={errors?.internalcommunications?.find(error => error.id === String(ic.id))?.question}
                                            helperText={errors?.internalcommunications?.find(error => error.id === String(ic.id))?.question}
                                            label="提問"
                                            variant="standard"
                                            value={
                                                editData?.internalcommunications?.find(it => it.id === ic.id)?.question === ''
                                                    ? ''
                                                    : editData?.internalcommunications?.find(it => it.id === ic.id)?.question || ic.question
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode && ic.question_user?.id === currentUser?.id}
                                            InputProps={{
                                                readOnly: !editMode || ic.question_user?.id !== currentUser?.id,
                                            }}
                                            onChange={(e) => handleFieldChange("internalcommunications", "question", e.target.value, ic.id)}
                                        />
                                    </>
                                )}

                                {ic.reply_user && editingId !== ic.id && (
                                    <>
                                        <Box style={{ paddingTop: '10px', textAlign: 'right' }}>
                                            <Chip
                                                className={styles["reply-user"]}
                                                avatar={<Avatar alt="回覆人頭像" src={ic.reply_user?.profile?.photo} />}
                                                label={ic.reply_user?.profile?.nickname || ic.reply_user?.username}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Divider variant="middle" textAlign="left" className={styles["reply-time"]}>回覆時間 {new Date(ic.reply_created_at).toLocaleString()}</Divider>
                                        <Typography className={styles.reply}>{ic.reply}</Typography>
                                        {ic.reply === '' && (
                                            <Divider variant="middle" className={styles["reply-empty"]}>回覆內容為空，請進一步確認是否為誤操作</Divider>
                                        )}
                                    </>
                                )}

                                {!ic.reply_user && editingId !== ic.id && (
                                    <Divider variant="middle" className={styles["no-reply"]}>尚未回覆</Divider>
                                )}

                                {editMode && editingId === ic.id && (
                                    <>
                                        <Divider variant="middle" className={styles["edit-reply"]}>編輯回覆內容</Divider>
                                        <TextField
                                            error={errors?.internalcommunications?.find(error => error.id === String(ic.id))?.reply}
                                            helperText={errors?.internalcommunications?.find(error => error.id === String(ic.id))?.reply}
                                            label="回覆"
                                            variant="standard"
                                            value={
                                                editData?.internalcommunications?.find(it => it.id === ic.id)?.reply === ''
                                                    ? ''
                                                    : editData?.internalcommunications?.find(it => it.id === ic.id)?.reply || ic.reply
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode && ic.question_user?.id !== currentUser?.id && ic.question_target_display.some(target => target.id === currentUser?.id)}
                                            InputProps={{
                                                readOnly: !editMode || ic.question_user?.id === currentUser?.id || !ic.question_target_display.some(target => target.id === currentUser?.id),
                                            }}
                                            onChange={(e) => handleFieldChange("internalcommunications", "reply", e.target.value, ic.id)}
                                        />
                                    </>
                                )}

                                {editingId !== ic.id ? (
                                    <Box className={styles['action-box']}>
                                        <Button className={styles['edit-button']} onClick={() => handleEditClick(ic.id)} disabled={editingId ? editingId !== ic.id : false}>
                                            <EditNote sx={{ width: '22px', height: '22px' }} />
                                            {editingId ? (editingId !== ic.id ? '正在編輯其他筆資料' : '編輯') : '編輯'}
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box className={styles['action-box']}>
                                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                                            取消
                                        </Button>
                                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                                            保存
                                        </Button>
                                    </Box>
                                )}
                            </div>
                        ))}

                        {editData?.newInternalCommunications?.map((ic, index) => (
                            <div key={ic.identifier} className={styles['sub-box']} style={{ marginBottom: '10px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.internalcommunications?.find(error => error.identifier === ic.identifier)?.question}
                                            helperText={errors?.internalcommunications?.find(error => error.identifier === ic.identifier)?.question}
                                            label={`新增提問 ${index + 1}`}
                                            variant="standard"
                                            value={ic.question}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={true}
                                            onChange={(e) => handleFieldChange("newInternalCommunications", "question", e.target.value, ic.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={options?.internal_communication?.question_target}
                                            getOptionLabel={(option) => option.label}
                                            value={ic.question_target.map(targetId => options?.internal_communication?.question_target.find(target => target.value === targetId))}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            multiple
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.internalcommunications?.find(error => error.identifier === ic.identifier)?.question_target}
                                                    helperText={errors?.internalcommunications?.find(error => error.identifier === ic.identifier)?.question_target}
                                                    label="提問對象"
                                                    placeholder="選擇提問對象(可多選)"
                                                    variant="standard"
                                                    required
                                                    focused={true}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(event, value) => {
                                                const targetIds = value.map(target => target.value);
                                                handleFieldChange("newInternalCommunications", "question_target", targetIds, ic.identifier, true);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box className={styles['action-box']}>
                                    <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                                        <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleSaveClick}>
                                        <SaveAs sx={{ width: '22px', height: '22px' }} />
                                        保存
                                    </Button>
                                </Box>
                            </div>
                        ))}
                        
                        {data?.internalcommunications.length === 0 && !editData?.newInternalCommunications && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <SearchingImg style={{ width: '300px', height: '300px' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何內站提問</Typography>
                                </Grid>
                            </Grid>
                        )}

                        {!editData?.newInternalCommunications && !editMode && (
                            <Box className={styles['add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewInternalCommunication}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增內站提問
                                </Button>
                            </Box>
                        )}

                    </Box>
                )}

                {!data && (
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {loading ? (
                            <CircularProgress color="info" />
                        ) : (
                            <>
                                <NoDataImg style={{ width: '300px', height: '300px' }} />
                                無法取得訂單資料或發生錯誤: {errors?.detail}
                            </>
                        )}
                    </Box>
                )}

            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Dialog disableEscapeKeyDown open={deleteCheckOpen} onClose={handleDeletCancelClick} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {deleteItem?.deleteType === 'ExternalCommunication' && (
                            <div>
                                <div>提問內容：{deleteItem?.question}</div>
                                <div>提問人：{deleteItem?.question_user?.profile?.nickname || deleteItem?.question_user?.username}</div>
                                <div>提問時間：{new Date(deleteItem?.question_created_at).toLocaleString()}</div>
                                <div>回覆內容：{deleteItem?.reply || '尚未回覆'}</div>
                                <div>回覆人：{deleteItem?.reply_user?.profile?.nickname || deleteItem?.reply_user?.username || '尚未回覆'}</div>
                                <div>回覆時間：{deleteItem?.reply_created_at ? new Date(deleteItem?.reply_created_at).toLocaleString() : '尚未回覆'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'InternalCommunication' && (
                            <div>
                                <div>提問內容：{deleteItem?.question}</div>
                                <div>提問人：{deleteItem?.question_user?.profile?.nickname || deleteItem?.question_user?.username}</div>
                                <div>提問時間：{new Date(deleteItem?.question_created_at).toLocaleString()}</div>
                                <div>提問對象：{deleteItem?.question_target_display.map(target => target?.profile?.nickname || target?.username).join('、')}</div>
                                <div>回覆內容：{deleteItem?.reply || '尚未回覆'}</div>
                                <div>回覆人：{deleteItem?.reply_user?.profile?.nickname || deleteItem?.reply_user?.username || '尚未回覆'}</div>
                                <div>回覆時間：{deleteItem?.reply_created_at ? new Date(deleteItem?.reply_created_at).toLocaleString() : '尚未回覆'}</div>
                            </div>
                        )}
                        <br />此操作無法復原，請謹慎處理。
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['delete-cancel-button']} onClick={handleDeletCancelClick}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleDeleteConfirmClick}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default CommunicationsTab;