import React, { useEffect, useState } from 'react';
import api from '../../config/axiosConfig';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import styles from './css/BasicInfoTab.module.css';
import { Autocomplete, Box, Button, Chip, Divider, Grid, TextField, Typography } from '@mui/material';
import { Badge, ContactMail, ContactPhone, RestartAlt, Save, School } from '@mui/icons-material';

const BasicInfo = ({ employee, onEmployeeChange }) => {
    const [originalEmployee, setOriginalEmployee] = useState({});
    const [currentEmployee, setCurrentEmployee] = useState({});
    const [changedFields, setChangedFields] = useState({});
    const [options, setOptions] = useState([]);
    const [error, setError] = useState({});
    const [status, setStatus] = useState('initial');
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);
        setOriginalEmployee(employee);
        setCurrentEmployee(employee);

        const fetchOptions = async () => {

            try {
                const OptionsResponse = await api.get('/core/api/employee-options/');
                setOptions(OptionsResponse.data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchOptions();
        // eslint-disable-next-line
    }, [employee]);
    
    const handleChange = (field, value) => {
        setCurrentEmployee((prevEmployee) => ({
          ...prevEmployee,
          [field]: value,
        }));
    
        setChangedFields((prevChangedFields) => ({
          ...prevChangedFields,
          [field]: value,
        }));

        setHasUnsavedChanges(true);
        setStatus('edited');
    };

    const handleReset = () => {
        setCurrentEmployee(originalEmployee);
        setChangedFields({});
        setError({});
        setHasUnsavedChanges(false);
        setStatus('reset');
    };

    const handleSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            console.log('Too fast');
            return;
        }
        setLastClickTime(now);
        
        try {
            if (Object.keys(changedFields).length === 0) {
                setStatus('noChange');
                return;
            }

            const response = await api.patch(`/core/api/EmployeeProfile/${currentEmployee.employee_number}/`, changedFields);
            onEmployeeChange(response.data);
            setError({});
            setStatus('success');
        } catch (error) {
            setError(error.response.data);
            setStatus('error');
            console.error(error);
        }
    };

    return (
        <Box className={styles.box}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider variant='middle' textAlign="left">
                        <Chip icon={<ContactMail />} label='基本資料' className={styles.chip} />
                    </Divider>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_number}
                        helperText={error?.employee_number}
                        fullWidth
                        name='employee_name'
                        label="姓名"
                        variant='standard'
                        required
                        value={currentEmployee.employee_name || ''}
                        onChange={(e) => handleChange('employee_name', e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        options={options?.employee_profile?.employee_gender || []}
                        getOptionLabel={(option) => option.label}
                        value={options?.employee_profile?.employee_gender.find(option => option.value === currentEmployee.employee_gender) || null}
                        renderInput={(params) => (
                            <TextField {...params} 
                                error={error?.employee_gender}
                                helperText={error?.employee_gender}
                                variant='standard'
                                required
                                label="性別"
                            />
                        )}
                        onChange={(e, newValue) => {
                            handleChange('employee_gender', newValue?.value || '');
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        error={error?.employee_birthday}
                        helperText={error?.employee_birthday}
                        fullWidth
                        name='employee_birthday'
                        label="生日"
                        variant='standard'
                        required
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        value={currentEmployee.employee_birthday || ''}
                        onChange={(e) => handleChange('employee_birthday', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_id}
                        helperText={error?.employee_id}
                        fullWidth
                        name='employee_id'
                        label="身份證字號"
                        variant='standard'
                        required
                        value={currentEmployee.employee_id || ''}
                        onChange={(e) => handleChange('employee_id', e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        options={options?.employee_profile?.employee_marriage || []}
                        getOptionLabel={(option) => option.label}
                        value={options?.employee_profile?.employee_marriage.find(option => option.value === currentEmployee.employee_marriage) || null}
                        renderInput={(params) => (
                            <TextField {...params} 
                                error={error?.employee_marriage}
                                helperText={error?.employee_marriage}
                                variant='standard'
                                required
                                label="婚姻狀況"
                            />
                        )}
                        onChange={(e, newValue) => {
                            handleChange('employee_marriage', newValue?.value || '');
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        error={error?.employee_children}
                        helperText={error?.employee_children}
                        fullWidth
                        name='employee_phone'
                        label="聯絡電話"
                        variant='standard'
                        required
                        value={currentEmployee.employee_phone || ''}
                        onChange={(e) => handleChange('employee_phone', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_email}
                        helperText={error?.employee_email}
                        fullWidth
                        name='employee_email'
                        label="電子信箱"
                        variant='standard'
                        value={currentEmployee.employee_email || ''}
                        onChange={(e) => handleChange('employee_email', e.target.value)}
                    />
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        error={error?.employee_address}
                        helperText={error?.employee_address}
                        fullWidth
                        name='employee_address'
                        label="通訊地址"
                        variant='standard'
                        required
                        value={currentEmployee.employee_address || ''}
                        onChange={(e) => handleChange('employee_address', e.target.value)}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider variant='middle' textAlign="left">
                        <Chip icon={<ContactPhone />} label='緊急聯絡人' className={styles.chip} />
                    </Divider>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.emergency_contact_name}
                        helperText={error?.emergency_contact_name}
                        fullWidth
                        name='emergency_contact_name'
                        label="緊急聯絡人姓名"
                        variant='standard'
                        required
                        value={currentEmployee.emergency_contact_name || ''}
                        onChange={(e) => handleChange('emergency_contact_name', e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        error={error?.emergency_contact_relationship}
                        helperText={error?.emergency_contact_relationship}
                        fullWidth
                        name='emergency_contact_relationship'
                        label="與緊急聯絡人關係"
                        variant='standard'
                        required
                        value={currentEmployee.emergency_contact_relationship || ''}
                        onChange={(e) => handleChange('emergency_contact_relationship', e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        error={error?.emergency_contact_phone}
                        helperText={error?.emergency_contact_phone}
                        fullWidth
                        name='emergency_contact_phone'
                        label="聯絡電話"
                        variant='standard'
                        required
                        value={currentEmployee.emergency_contact_phone || ''}
                        onChange={(e) => handleChange('emergency_contact_phone', e.target.value)}
                    />
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider variant='middle' textAlign="left">
                        <Chip icon={<School />} label='學歷資料' className={styles.chip} />
                    </Divider>
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        options={options?.employee_profile?.employee_education || []}
                        getOptionLabel={(option) => option.label}
                        value={options?.employee_profile?.employee_education.find(option => option.value === currentEmployee.employee_education) || null}
                        renderInput={(params) => (
                            <TextField {...params}
                                error={error?.employee_education}
                                helperText={error?.employee_education}
                                variant='standard'
                                required
                                label="最高學歷"
                            />
                        )}
                        onChange={(e, newValue) => {
                            handleChange('employee_education', newValue?.value || '');
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_school}
                        helperText={error?.employee_school}
                        fullWidth
                        name='employee_school'
                        label="畢業學校"
                        variant='standard'
                        required
                        value={currentEmployee.employee_school || ''}
                        onChange={(e) => handleChange('employee_school', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_department}
                        helperText={error?.employee_department}
                        fullWidth
                        name='employee_major'
                        label="就讀科系"
                        variant='standard'
                        required
                        value={currentEmployee.employee_major || ''}
                        onChange={(e) => handleChange('employee_major', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider variant='middle' textAlign="left">
                        <Chip icon={<Badge />} label='職務相關' className={styles.chip} />
                    </Divider>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        error={error?.employee_hire_date}
                        helperText={error?.employee_hire_date}
                        fullWidth
                        name='employee_hire_date'
                        label="到職日期"
                        variant='standard'
                        required
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        value={currentEmployee.employee_hire_date || null}
                        onChange={(e) => handleChange('employee_hire_date', e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        options={options?.employee_profile?.employee_status || []}
                        getOptionLabel={(option) => option.label}
                        value={options?.employee_profile?.employee_status.find(option => option.value === currentEmployee.employee_status) || null}
                        renderInput={(params) => (
                            <TextField {...params}
                                error={error?.employee_status}
                                helperText={error?.employee_status}
                                variant='standard'
                                label="工作狀態"
                            />
                        )}
                        onChange={(e, newValue) => {
                            handleChange('employee_status', newValue?.value || '');
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_position}
                        helperText={error?.employee_position}
                        fullWidth
                        name='employee_position'
                        label="職位"
                        variant='standard'
                        required
                        value={currentEmployee.employee_position || ''}
                        onChange={(e) => handleChange('employee_position', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={error?.employee_department}
                        helperText={error?.employee_department}
                        fullWidth
                        name='employee_department'
                        label="部門"
                        variant='standard'
                        required
                        value={currentEmployee.employee_department || ''}
                        onChange={(e) => handleChange('employee_department', e.target.value)}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={12}>
                    <TextField
                        error={error?.employee_status_reason}
                        helperText={error?.employee_status_reason}
                        fullWidth
                        name='employee_status_reason'
                        label="離職原因"
                        variant='standard'
                        value={currentEmployee.employee_status_reason || ''}
                        onChange={(e) => handleChange('employee_status_reason', e.target.value)}
                        multiline
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px', paddingTop: '5px', position: 'sticky', bottom: '0', zIndex: '1' }}>
                    <Button
                        className={styles['reset-button']}
                        color='secondary'
                        disabled={status === 'initial' || status === 'reset' || status === 'noChange' || status === 'success'}
                        onClick={() => handleReset()}
                    >
                        <RestartAlt />
                        還原
                    </Button>

                    <Typography className={styles['status-text']}>
                        {status === 'initial' && <span style={{ color: 'GrayText' }}>請在修改資料後依需求還原、保存</span>}
                        {status === 'reset' && <span style={{ color: '#2e85e7' }}>已還原至初始資料</span>}
                        {status === 'edited' && <span style={{ color: '#f57c00' }}>已偵測到有資料被編輯、修改，但尚未被保存</span>}
                        {status === 'success' && <span style={{ color: '#4caf50' }}>保存成功，資料已更新</span>}
                        {status === 'error' && <span style={{ color: '#d32f2f' }}>保存失敗，請檢查資料是否正確</span>}
                        {status === 'noChange' && <span style={{ color: '#2e85e7' }}>未進行任何編輯、修改</span>}
                    </Typography>

                    <Button
                        className={styles['save-button']}
                        color='primary'
                        onClick={handleSave}
                    >
                        保存
                        <Save />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BasicInfo;
