import React, { useState, useEffect } from 'react';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import api from '../../config/axiosConfig';
import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography, IconButton, Dialog,
    DialogContent, Tooltip, Alert
} from '@mui/material';
import { Add, Delete, Edit, DoDisturbOn, AddCircleOutlined, NotificationImportant } from '@mui/icons-material';
import styles from './css/AnnouncementCategoryTab.module.css';

const AnnouncementCategoryTab = () => {
    const [announcementCategory, setAnnouncementCategory] = useState([]);
    const [selectedAnnouncementCategory, setSelectedAnnouncementCategory] = useState({});
    const [editingAnnouncementCategory, setEditingAnnouncementCategory] = useState(false);
    const [addingAnnouncementCategory, setAddingAnnouncementCategory] = useState(false);
    const [newAnnouncementCategory, setNewAnnouncementCategory] = useState({});
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [deleteAnnouncementCategory, setDeleteAnnouncementCategory] = useState({});
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState({});
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            try {
                const announcementCategoryResponse = await api.get('/core/api/Category/');
                setAnnouncementCategory(announcementCategoryResponse.data);
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [setHasUnsavedChanges]);

    useEffect(() => {
        let timer;
        if (status === 'NewAnnouncementCategorySuccess' || status === 'EditAnnouncementCategorySuccess' || status === 'DeleteAnnouncementCategorySuccess') {
            timer = setTimeout(() => {
                if (status === 'NewAnnouncementCategorySuccess' || status === 'EditAnnouncementCategorySuccess' || status === 'DeleteAnnouncementCategorySuccess') {
                    setStatus('initial');
                }
            }, 11500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleAddAnnouncementCategory = () => {
        setAddingAnnouncementCategory(true);
        setHasUnsavedChanges(true);
    };

    const handleAddAnnouncementCategorySubmit = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const response = await api.post('/core/api/Category/', newAnnouncementCategory);
            setAnnouncementCategory([...announcementCategory, response.data]);
            setHasUnsavedChanges(false);
            setAddingAnnouncementCategory(false);
            setStatus('NewAnnouncementCategorySuccess');
            setNewAnnouncementCategory({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('NewAnnouncementCategoryError');
        }
    };

    const handleEditAnnouncementCategory = (category) => {
        setErrors({});
        setSelectedAnnouncementCategory(category);
        setEditingAnnouncementCategory(true);
        setHasUnsavedChanges(true);
    };

    const handleEditAnnouncementCategorySave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            const response = await api.patch(`/core/api/Category/${selectedAnnouncementCategory.id}/`, selectedAnnouncementCategory);
            setAnnouncementCategory(announcementCategory.map((category) => category.id === selectedAnnouncementCategory.id ? response.data : category));
            setHasUnsavedChanges(false);
            setEditingAnnouncementCategory(false);
            setStatus('EditAnnouncementCategorySuccess');
            setSelectedAnnouncementCategory({});
            setErrors({});
        }
        catch (error) {
            setErrors(error.response?.data);
            setStatus('EditAnnouncementCategoryError');
        }
    };

    const handleDeleteAnnouncementCategory = async (category, id) => {
        setDeleteCheck(true);
        setDeleteAnnouncementCategory({ category: category, id: id });
    };

    const handleDeleteAnnouncementCategoryConfirm = async (id) => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            await api.delete(`/core/api/Category/${id}/`);
            setAnnouncementCategory(announcementCategory.filter((category) => category.id !== id));
            setDeleteCheck(false);
            setStatus('DeleteAnnouncementCategorySuccess');
            setDeleteAnnouncementCategory({});
            setErrors({});
        } catch (error) {
            setErrors(error.response?.data);
            setStatus('DeleteAnnouncementCategoryError');
        }
    }

    return (
        <Box className={styles['main-box']}>
            <Card className={styles['card']}>
                <CardContent className={styles['cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

                    {!addingAnnouncementCategory && !editingAnnouncementCategory && (
                        <>
                            <Box className={styles['view-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography className={styles['title-label']}>
                                            類別名稱
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={styles['title-label']}>
                                            類別說明
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography className={styles['title-label']}>
                                            操作
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['view-cardcontent-content-box']}>
                                {announcementCategory.map((category) => (
                                    <>
                                        <Grid container key={category.id}>

                                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']} sx={{ lineHeight: '32px', padding: '10px 0 10px 0' }}>
                                                    {category.category_name}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography className={styles['content-label']}>
                                                    {category.category_description || <span style={{ color: 'GrayText'}}>沒有任何說明</span>}
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="編輯" arrow placement="left">
                                                    <IconButton className={styles['edit-button']} disableRipple onClick={() => handleEditAnnouncementCategory(category)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="刪除" arrow placement="right">
                                                    <IconButton className={styles['delete-button']} disableRipple onClick={() => handleDeleteAnnouncementCategory(category.category_name, category.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        {announcementCategory.indexOf(category) !== announcementCategory.length - 1 && (
                                            <Divider />
                                        )}
                                    </>
                                ))}
                            </Box>

                            <Box className={styles['view-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddAnnouncementCategory}>新增公告類別</Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <div style={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                                {status === 'NewAnnouncementCategorySuccess' && (
                                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                                        成功新增公告類別
                                    </Alert>
                                )}
                                {status === 'EditAnnouncementCategorySuccess' && (
                                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                                        成功編輯公告類別
                                    </Alert>
                                )}
                                {status === 'DeleteAnnouncementCategorySuccess' && (
                                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                                        成功刪除公告類別
                                    </Alert>
                                )}
                            </div>
                        </>
                    )}

                    {addingAnnouncementCategory && (
                        <>
                            <Box className={styles['adding-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在新增公告類別. . .
                                            <span style={{ color: '#d32f2f' }}> (帶*為必填欄位)</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                            <Box className={styles['adding-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.category_name}
                                            helperText={errors?.category_name}
                                            required
                                            label="類別名稱"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(event) => setNewAnnouncementCategory({ ...newAnnouncementCategory, category_name: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            error={errors?.category_description}
                                            helperText={errors?.category_description}
                                            label="類別說明"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            onChange={(event) => setNewAnnouncementCategory({ ...newAnnouncementCategory, category_description: event.target.value })}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['adding-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button className={styles['cancel-button']} onClick={() => {
                                            setAddingAnnouncementCategory(false);
                                            setHasUnsavedChanges(false);
                                            setStatus('initial');
                                            setNewAnnouncementCategory({});
                                            setErrors({});
                                        }}>
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '14px' }}>
                                            {status === 'NewAnnouncementCategoryError' && <span>提交失敗，請檢查所有欄位是否正確填寫</span>}
                                        </Typography>
                                        <Button className={styles['submit-button']} onClick={handleAddAnnouncementCategorySubmit}>
                                            提交
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                    {editingAnnouncementCategory && (
                        <>
                             <Box className={styles['editing-cardcontent-title-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', color: '#666666', padding: '10px' }}>
                                            正在編輯公告類別 {selectedAnnouncementCategory.category_name} . . .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['editing-cardcontent-content-box']}>
                                <Grid container spacing={2} sx={{ padding: '0 30px' }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.category_name}
                                            helperText={errors?.category_name}
                                            required
                                            label="類別名稱"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            value={selectedAnnouncementCategory.category_name}
                                            onChange={(event) => setSelectedAnnouncementCategory({ ...selectedAnnouncementCategory, category_name: event.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            error={errors?.category_description}
                                            helperText={errors?.category_description}
                                            label="類別說明"
                                            variant="standard"
                                            size='small'
                                            fullWidth
                                            value={selectedAnnouncementCategory.category_description}
                                            onChange={(event) => setSelectedAnnouncementCategory({ ...selectedAnnouncementCategory, category_description: event.target.value })}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={styles['editing-cardcontent-action-box']}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
                                        <Button className={styles['cancel-button']} onClick={() => {
                                            setEditingAnnouncementCategory(false);
                                            setHasUnsavedChanges(false);
                                            setStatus('initial');
                                            setSelectedAnnouncementCategory({});
                                            setErrors({});
                                        }}>
                                            <DoDisturbOn />
                                            取消
                                        </Button>
                                        <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '14px' }}>
                                            {status === 'EditAnnouncementCategoryError' && <span>保存失敗，請仔細檢查所有數據資料</span>}
                                        </Typography>
                                        <Button className={styles['submit-button']} onClick={handleEditAnnouncementCategorySave}>
                                            保存
                                            <AddCircleOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                </CardContent>
            </Card>

            <Dialog
                open={deleteCheck}
                onClose={() => setDeleteCheck(false)}
            >
                <DialogContent className={styles['delete-dialog-content']}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: '30px'}}>
                        <Typography className={styles['delete-dialog-title']}>
                            <NotificationImportant sx={{ fontSize: '40px' }} />
                            確定要刪除公告類別 {deleteAnnouncementCategory.category} 嗎？
                        </Typography>
                        <Typography className={styles['delete-dialog-subtitle']}>
                            註: 刪除後將無法復原，請謹慎操作
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {status === 'DeleteAnnouncementCategoryError' && (
                            <Typography sx={{ color: '#d32f2fff', textAlign: 'center', fontSize: '15px' }}>
                            {errors?.error}
                        </Typography>
                        )}
                        <Box sx={{ position: 'absolute', bottom: '20px', display: 'flex', gap: '20px' }}>
                            <Button className={styles['delete-cancel-button']} disableRipple onClick={() => {
                                setDeleteCheck(false);
                                setStatus('initial');
                                setDeleteAnnouncementCategory({});
                                setErrors({});
                            }}>
                                <DoDisturbOn sx={{ width: '20px', height: '20px' }}/>
                                取消
                            </Button>
                            <Button className={styles['delete-confirm-button']} disableRipple onClick={() => handleDeleteAnnouncementCategoryConfirm(deleteAnnouncementCategory.id)}>
                                <Delete sx={{ width: '20px', height: '20px' }}/>
                                確定
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

        </Box>
    );
}

export default AnnouncementCategoryTab;