import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import UserGroupContext from '../../contexts/UserGroupContext';
import api from '../../config/axiosConfig';
import styles from './css/OtherRelated.module.css';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, Card, CardContent, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Add, Delete, DoDisturbOn, Edit, ExpandMore, NoteAdd, Print, SaveAs } from '@mui/icons-material';

function OtherRelated() {
  const userGroups = React.useContext(UserGroupContext);
  const [companyAdvanceOptions, setCompanyAdvanceOptions] = useState([]);
  const [advance, setAdvance] = useState([]);
  const [newAdvance, setNewAdvance] = useState({});
  const [editAdvance, setEditAdvance] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [lastClickTime, setLastClickTime] = useState(0);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          event.returnValue = '';
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
      
  }, [hasUnsavedChanges]);

  useEffect(() => {
      setHasUnsavedChanges(false);
      document.title = '日本線其他相關';

      try {
        const fetchData = async () => {
          const advance = await api.get('/japan/api/CompanyAdvancePayment/');
          setAdvance(advance.data);
        };
        fetchData();
      } catch (error) {
        console.error(error);
      }

  }, [setHasUnsavedChanges]);

  useEffect(() => {
      const tabHashes = ['advance'];
      const hash = location.hash;
    
      const index = hash ? tabHashes.indexOf(hash.replace('#', '')) : 0;
      
      if (index === -1 || !hash) {
        navigate(`#advance`, { replace: true });
      } else {
        setTabValue(index);
      }
  
      const tabtitles = ['公司代墊款'];
      document.title = `日本線其他相關 - ${tabtitles[index]}`;
      
  }, [location, navigate]);

  const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
  };

  const fetchCompanyAdvanceOptions = async () => {
    try {
      const advanceOptions = await api.get('/japan/api/create-company-advance-payment/');
      setCompanyAdvanceOptions(advanceOptions.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExportRequest = (urlPath, fileName) => {
    const now = Date.now();
    if (now - lastClickTime < 3000) {
        return;
    }

    setLastClickTime(now);

    setIsLoading(true);

    const uniqueUrl = `${urlPath}?t=${now}`;
    
    api.get(uniqueUrl, { responseType: 'blob' })
    .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    })
    .catch(error => {
        console.error('下載發生錯誤:', error);
    })
    .finally(() => {
        setIsLoading(false);
    });
  }

  const handleAddNewAdvance = () => {
    fetchCompanyAdvanceOptions();
    setNewAdvance({
      application_date: null,
      advance_method: '',
      advance_item: '',
      advance_amount: '',
      payee: '',
      payee_account: '',
      advance_content: '',
      transfer_bank: '',
      payment_method: '',
    });
  };

  const handleSaveNewAdvance = async () => {
    const now = new Date().getTime();
    if (now - lastClickTime < 1000) {
        return;
    }
    setLastClickTime(now);

    try {
      const response = await api.post('/japan/api/CompanyAdvancePayment/', newAdvance);
      setAdvance([response.data, ...advance]);
      setNewAdvance({});
      setErrors({});
      setHasUnsavedChanges(false);
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  const handleDeleteAdvance = async (id) => {
    const now = new Date().getTime();
    if (now - lastClickTime < 1000) {
      return;
    }

    setLastClickTime(now);

    try {
      await api.delete(`/japan/api/CompanyAdvancePayment/${id}/`);
      setAdvance(advance.filter((item) => item.id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveEditedAdvance = async () => {
    const now = new Date().getTime();
    if (now - lastClickTime < 1000) {
        return;
    }
    setLastClickTime(now);

    try {
      delete editAdvance.accounting_staff;
      delete editAdvance.manager_staff;
      delete editAdvance.accounting_audit_date;
      delete editAdvance.manager_audit_date;
      delete editAdvance.payment_date;
      const response = await api.patch(`/japan/api/CompanyAdvancePayment/${editAdvance.id}/`, editAdvance);
      setAdvance(advance.map((item) => (item.id === response.data.id ? response.data : item)));
      setEditAdvance({});
      setErrors({});
      setHasUnsavedChanges(false);
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  return (
    <Container component="main" sx={{ py: 3 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography className={styles['text-h1']} variant="h5" gutterBottom>
          其 他 相 關
      </Typography>

      <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs value={tabValue} variant='scrollable' scrollButtons='auto' onChange={handleTabChange}>
              <Tab href='#advance' label="公司代墊款" />
          </Tabs>
      </Box>

      {tabValue === 0 && (
        <Box>
          <Grid container spacing={2}>

            {Object.keys(newAdvance).length === 0 && Object.keys(editAdvance).length === 0 && (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddNewAdvance}>新增公司代墊款</Button>
                  </Box>
                </Grid>
            )}

            {Object.keys(newAdvance).length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography textAlign='center'>
                      正在新增公司代墊款. . .
                    </Typography>
                    <Card elevation={3} sx={{ margin: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <CardContent style={{ width: '100%' }}>
                        <Grid container spacing={1}>

                          <Grid item xs={3}>
                            <TextField
                              error={errors.application_date}
                              helperText={errors.application_date}
                              fullWidth
                              label="申請日期"
                              type="date"
                              value={newAdvance.application_date}
                              onChange={(event) => setNewAdvance({ ...newAdvance, application_date: event.target.value })}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <FormControl fullWidth error={errors.advance_method}>
                              <InputLabel>代墊方式</InputLabel>
                              <Select
                                value={newAdvance.advance_method}
                                onChange={(event) => setNewAdvance({ ...newAdvance, advance_method: event.target.value })}
                              >
                                {(companyAdvanceOptions.advance_method || [] ).map((option) => (
                                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.advance_method && <FormHelperText>{errors.advance_method}</FormHelperText>}
                          </Grid>

                          <Grid item xs={3}>
                            <TextField
                              error={errors.advance_item}
                              helperText={errors.advance_item}
                              fullWidth
                              label="代墊項目"
                              value={newAdvance.advance_item}
                              onChange={(event) => setNewAdvance({ ...newAdvance, advance_item: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <TextField
                              error={errors.advance_amount}
                              helperText={errors.advance_amount}
                              fullWidth
                              label="代墊金額"
                              value={newAdvance.advance_amount}
                              onChange={(event) => setNewAdvance({ ...newAdvance, advance_amount: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              error={errors.payee}
                              helperText={errors.payee}
                              fullWidth
                              label="受款人"
                              value={newAdvance.payee}
                              onChange={(event) => setNewAdvance({ ...newAdvance, payee: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              error={errors.payee_account}
                              helperText={errors.payee_account}
                              fullWidth
                              label="受款人帳號"
                              value={newAdvance.payee_account}
                              onChange={(event) => setNewAdvance({ ...newAdvance, payee_account: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              error={errors.advance_content}
                              helperText={errors.advance_content}
                              fullWidth
                              label="代墊內容"
                              value={newAdvance.advance_content}
                              onChange={(event) => setNewAdvance({ ...newAdvance, advance_content: event.target.value })}
                              multiline
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              error={errors.transfer_bank}
                              helperText={errors.transfer_bank}
                              fullWidth
                              label="轉帳銀行"
                              value={newAdvance.transfer_bank}
                              onChange={(event) => setNewAdvance({ ...newAdvance, transfer_bank: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth error={errors.payment_method}>
                              <InputLabel>付款方式</InputLabel>
                              <Select
                                value={newAdvance.payment_method}
                                onChange={(event) => setNewAdvance({ ...newAdvance, payment_method: event.target.value })}
                              >
                                {(companyAdvanceOptions.payment_method || [] ).map((option) => (
                                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.payment_method && <FormHelperText>{errors.payment_method}</FormHelperText>}
                          </Grid>
                          
                        </Grid>
                      </CardContent>
                    </Card>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                      <Button className={styles['cancel-button']} onClick={() => {
                        setNewAdvance({});
                        setErrors({});
                        setHasUnsavedChanges(false);
                      }}>
                        <DoDisturbOn />
                        取消
                      </Button>
                      <Button className={styles['save-button']} onClick={handleSaveNewAdvance}>
                        <NoteAdd />
                        新增
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {Object.keys(newAdvance).length === 0 && Object.keys(editAdvance).length === 0 && (
              <Grid item xs={12}>
                {advance.map((item) => (
                  <Accordion key={item.id}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography sx={{ width: '25%' }}>{item.advance_method}</Typography>
                      <Typography sx={{ width: '50%', color: 'text.secondary', fontSize: '15px' }}>申請人: {item.applicant.profile.nickname || item.applicant.username}</Typography>
                      <Typography sx={{ width: '25%', color: 'text.secondary', fontSize: '13px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>創建時間: {new Date(item.created_at).toLocaleString()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: -1 }}>
                      <Card elevation={3} sx={{ margin: 1, display: 'grid', flexDirection: 'row', alignItems: 'center' }}>
                        <CardContent>
                          <Typography textAlign="center" marginBottom={1}>
                            詳細資料
                          </Typography>
                          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="申請日期"
                                value={item.application_date}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="代墊項目"
                                value={item.advance_item}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="代墊金額"
                                value={item.advance_amount}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="受款人"
                                value={item.payee}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="受款人帳號"
                                value={item.payee_account}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="代墊內容"
                                value={item.advance_content}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="轉帳銀行"
                                value={item.transfer_bank}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="付款方式"
                                value={item.payment_method}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="支付日期"
                                value={item.payment_date}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={6} textAlign="center">
                              <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    checked={item.accounting_audit}
                                  />
                                }
                                label="會計審核"
                              />
                            </Grid>

                            <Grid item xs={6} textAlign="center">
                              <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    checked={item.manager_audit}
                                  />
                                }
                                label="主管審核"
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                fullWidth
                                label="會計審核時間"
                                value={item.accounting_audit_date ? new Date(item.accounting_audit_date).toLocaleString() : ''}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                fullWidth
                                label="審核會計人員"
                                value={item.accounting_staff ? item.accounting_staff.profile.nickname || item.accounting_staff.username : ''}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                fullWidth
                                label="主管審核時間"
                                value={item.manager_audit_date ? new Date(item.manager_audit_date).toLocaleString() : ''}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                fullWidth
                                label="審核主管人員"
                                value={item.manager_staff ? item.manager_staff.profile.nickname || item.manager_staff.username : ''}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button 
                          className={styles['edit-button']}
                          onClick={() => {
                            setEditAdvance(item)
                            fetchCompanyAdvanceOptions();
                          }}
                        >
                          <Edit />編輯
                        </Button>
                        <Button disabled={isLoading} className={styles['print-button']} onClick={() => handleExportRequest(`/japan/export_company_advance_payment/${item.id}/`, `${item.applicant.profile.nickname || item.applicant.username} - ${new Date(item.created_at).toLocaleDateString()} - 代墊款申請單.pdf`)}><Print />列印</Button>
                        <Button className={styles['delete-button']} onClick={() => handleDeleteAdvance(item.id)}><Delete />刪除</Button>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
                {advance.length === 0 && (
                  <Card>
                    <CardContent>
                      <Typography className={styles['no-data']}>
                        目前沒有公司代墊款
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            )}

            {Object.keys(editAdvance).length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography textAlign='center'>
                      正在編輯公司代墊款. . .
                    </Typography>
                    <Card elevation={3} sx={{ margin: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <CardContent style={{ width: '100%' }}>
                        <Grid container spacing={1}>

                          <Grid item xs={3}>
                            <TextField
                              error={errors.application_date}
                              helperText={errors.application_date}
                              fullWidth
                              label="申請日期"
                              type="date"
                              value={editAdvance.application_date}
                              onChange={(event) => setEditAdvance({ ...editAdvance, application_date: event.target.value })}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <FormControl fullWidth error={errors.advance_method}>
                              <InputLabel>代墊方式</InputLabel>
                              <Select
                                value={editAdvance.advance_method}
                                onChange={(event) => setEditAdvance({ ...editAdvance, advance_method: event.target.value })}
                              >
                                {(companyAdvanceOptions.advance_method || [] ).map((option) => (
                                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.advance_method && <FormHelperText>{errors.advance_method}</FormHelperText>}
                          </Grid>

                          <Grid item xs={3}>
                            <TextField
                              error={errors.advance_item}
                              helperText={errors.advance_item}
                              fullWidth
                              label="代墊項目"
                              value={editAdvance.advance_item}
                              onChange={(event) => setEditAdvance({ ...editAdvance, advance_item: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <TextField
                              error={errors.advance_amount}
                              helperText={errors.advance_amount}
                              fullWidth
                              label="代墊金額"
                              value={editAdvance.advance_amount}
                              onChange={(event) => setEditAdvance({ ...editAdvance, advance_amount: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              error={errors.payee}
                              helperText={errors.payee}
                              fullWidth
                              label="受款人"
                              value={editAdvance.payee}
                              onChange={(event) => setEditAdvance({ ...editAdvance, payee: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              error={errors.payee_account}
                              helperText={errors.payee_account}
                              fullWidth
                              label="受款人帳號"
                              value={editAdvance.payee_account}
                              onChange={(event) => setEditAdvance({ ...editAdvance, payee_account: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              error={errors.advance_content}
                              helperText={errors.advance_content}
                              fullWidth
                              label="代墊內容"
                              value={editAdvance.advance_content}
                              onChange={(event) => setEditAdvance({ ...editAdvance, advance_content: event.target.value })}
                              multiline
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              error={errors.transfer_bank}
                              helperText={errors.transfer_bank}
                              fullWidth
                              label="轉帳銀行"
                              value={editAdvance.transfer_bank}
                              onChange={(event) => setEditAdvance({ ...editAdvance, transfer_bank: event.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth error={errors.payment_method}>
                              <InputLabel>付款方式</InputLabel>
                              <Select
                                value={editAdvance.payment_method}
                                onChange={(event) => setEditAdvance({ ...editAdvance, payment_method: event.target.value })}
                              >
                                {(companyAdvanceOptions.payment_method || [] ).map((option) => (
                                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.payment_method && <FormHelperText>{errors.payment_method}</FormHelperText>}
                          </Grid>

                          {(userGroups.includes('管理員') || userGroups.includes('會計人員') ) && (
                            <React.Fragment>
                              <Grid item xs={6} textAlign="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      error={errors.accounting_audit}
                                      helperText={errors.accounting_audit}
                                      checked={editAdvance.accounting_audit}
                                      onChange={(event) => setEditAdvance({ ...editAdvance, accounting_audit: event.target.checked })}
                                    />
                                  }
                                  label="會計審核"
                                />
                              </Grid>
                              {userGroups.includes('管理員') ? (
                                <Grid item xs={6} textAlign="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        error={errors.manager_audit}
                                        helperText={errors.manager_audit}
                                        checked={editAdvance.manager_audit}
                                        onChange={(event) => setEditAdvance({ ...editAdvance, manager_audit: event.target.checked })}
                                      />
                                    }
                                    label="主管審核"
                                  />
                                </Grid>
                              ) : (
                                <Grid item xs={6} textAlign="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={editAdvance.manager_audit}
                                        disabled
                                      />
                                    }
                                    label="主管審核"
                                  />
                                </Grid>
                              )}
                            </React.Fragment>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                      <Button className={styles['cancel-button']} onClick={() => setEditAdvance({})}>
                        <DoDisturbOn />
                        取消
                      </Button>
                      <Button className={styles['save-button']} onClick={handleSaveEditedAdvance}><SaveAs />保存</Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

          </Grid>
        </Box>
      )}

    </Container>
  );
}
export default OtherRelated;