import React, { useState, useEffect } from 'react';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import { v4 as uuidv4 } from 'uuid';
import api from '../../config/axiosConfig';
import { Box, Button, Container, Tab, Tabs, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Typography, TextField, CircularProgress, RadioGroup, FormControlLabel, Radio, Autocomplete} from '@mui/material';
import { AddCircleOutlineOutlined, Article, Assignment, CreateNewFolder, Delete, HighlightOff } from '@mui/icons-material';
import styles from './css/OrderCreate.module.css';
import { ReactComponent as WarningImg } from '../../pages/img/Warning.svg'

function OrderCreate(props, { initialOrder = {}, onSubmit }) {
  const [isLoading, setIsLoading] = useState(true);
  const [createMethod, setCreateMethod] = useState('default');
  const [peersCompanies, setPeersCompanies] = useState([]);
  const [selectedPeersCompany, setSelectedPeersCompany] = useState({});
  const [peersSales, setPeersSales] = useState([]);
  const [selectedPeersSales, setSelectedPeersSales] = useState({});
  const [value, setValue] = useState(0); 
  const [order, setOrder] = useState(initialOrder);
  const [itineraries, setItineraries] = useState(initialOrder.itineraries || []);
  const [charterCompanyOptions, setCharterCompanyOptions] = useState([]);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState([]);
  const [warningTypesOptions, setWarningTypesOptions] = useState([]);
  const [policyTypesOptions, setPolicyTypesOptions] = useState([]);
  const [remittanceInfoOptions, setRemittanceInfoOptions] = useState([]);
  const [salesRepOptions, setSalesRepOptions] = useState([]);
  const [orderInfoOptions, setOrderInfoOptions] = useState({
    communication_app: [],
    service_type: [],
    operation_fee_currency: [],
    full_amount_currency: [],
    total_amount_currency: []
  });
  const [itineraryRoomReservationOptions, setItineraryRoomReservationOptions] = useState({
    room_reservation: [],
    service_info: []
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteType, setDeleteType] = useState("");
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, [hasUnsavedChanges]);

  useEffect(() => {
    setHasUnsavedChanges(false);

    const fetchOrderOptions = async () => {
      try {
        document.title = '日本線訂單創建';

        const PeersCompaniesResponse = await api.get('/japan/api/Peers_Company/');
        setPeersCompanies(PeersCompaniesResponse.data);
        
        const PeersSalesResponse = await api.get('/japan/api/Peers_Sales/');
        setPeersSales(PeersSalesResponse.data);
        
        const CharterCompanyResponse = await api.get('/japan/api/Charter_Company/');
        setCharterCompanyOptions(CharterCompanyResponse.data);

        const WarningTypesResponse = await api.get('/japan/api/Order_Warning_Type/');
        setWarningTypesOptions(WarningTypesResponse.data);
        const PolicyTypesResponse = await api.get('/japan/api/Order_Policy_Type/');
        setPolicyTypesOptions(PolicyTypesResponse.data);
        const RemittanceInfoResponse = await api.get('/japan/api/Remittance_Info/');
        setRemittanceInfoOptions(RemittanceInfoResponse.data);

        const SalesRepResponse = await api.get('/core/api/user_basic_info/');
        setSalesRepOptions(SalesRepResponse.data.filter(option => option.groups.some(group => group.name === "日本線業務" || group.name === "管理員")));

        const CreateOrderOptionsResponse = await api.get('/japan/api/create-order-options/');
        setOrderInfoOptions({
          communication_app: CreateOrderOptionsResponse.data.order_options.communication_app,
          service_type: CreateOrderOptionsResponse.data.order_options.service_type,
          operation_fee_currency: CreateOrderOptionsResponse.data.order_options.operation_fee_currency,
          full_amount_currency: CreateOrderOptionsResponse.data.order_options.full_amount_currency,
          total_amount_currency: CreateOrderOptionsResponse.data.order_options.total_amount_currency,
        });
        setItineraryRoomReservationOptions({
          room_reservation: CreateOrderOptionsResponse.data.itinerary_options.room_reservation,
          service_info: CreateOrderOptionsResponse.data.itinerary_options.service_info,
        });

      } catch (error) {
        console.error('Error fetching options', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrderOptions();
  }, [setHasUnsavedChanges]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setSubmitStatus(null);
  };

  const handleCreateMethodChange = (event) => {
    setCreateMethod(event.target.value);
    setSelectedPeersCompany({});
    setSelectedPeersSales({});
    setHasUnsavedChanges(false);
    setErrors({});
  };

  const handleOrderChange = (field, value) => {
    setOrder({ ...order, [field]: value });
    setHasUnsavedChanges(true);
  };

  const handleAddItinerary = () => {
    setItineraries([...itineraries, { 
      identifier: uuidv4(),
      itinerary_date: '',
      reception_hours: '',
      activity: '',
      itinerary_hotel: '',
      room_reservation: '',
      service_info: '',
      itinerary_notes: ''
    }]);
  };

  const handleRemoveItinerary = (index) => {
    setDeleteIndex(index);
    setDeleteType("itinerary");
    setPopupOpen(true);
  };

  const handleItineraryChange = (index, field, value) => {
    const newItineraries = [...itineraries];
    newItineraries[index][field] = value;
    setItineraries(newItineraries);
  };

  const handleConfirmDelete = () => {
    if (deleteType === "itinerary") {
      setItineraries(prevItineraries => prevItineraries.filter((_, i) => i !== deleteIndex));
    }
    setPopupOpen(false);
    setDeleteIndex(null);
    setDeleteType("");
  };

  const handleResetSubmitStatus = () => {
    setSubmitStatus(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitStatus(null);
    const newErrors = {};
    
    try {
      const { charter_company, vehicle_type, warning_type, policy_type, remittance_info, peers_sales, sales_rep, ...otherOrderData } = order;
      const charter_company_id = charter_company?.id;
      const vehicle_type_id = vehicle_type?.id;
      const warning_type_id = warning_type?.id;
      const policy_type_id = policy_type?.id;
      const remittance_info_id = remittance_info?.id;
      const peers_sales_id = peers_sales?.id;
      const sales_rep_id = sales_rep?.id;

      const updatedOrderData = {
          ...otherOrderData,
          ...(charter_company_id && { charter_company_id }),
          ...(vehicle_type_id && { vehicle_type_id }),
          ...(warning_type_id && { warning_type_id }),
          ...(policy_type_id && { policy_type_id }),
          ...(remittance_info_id && { remittance_info_id }),
          ...(peers_sales_id && { peers_sales_id }),
          ...(sales_rep_id && { sales_rep_id })
      };

      const filledItineraries = itineraries.filter(itinerary => 
        Object.values(itinerary).some(value => value && value.trim().length > 0)
      );

      const itinerariesData = filledItineraries.map(itinerary => ({...itinerary, itinerary_date: itinerary.itinerary_date === "" ? null : itinerary.itinerary_date , reception_hours: itinerary.reception_hours === "" ? null : itinerary.reception_hours,}));

      const bulkData = {
        ...updatedOrderData,
        itineraries: itinerariesData,
      };

      if (createMethod === 'default') {
        if (!bulkData.customer_name) {
          newErrors.customer_name = '請輸入客戶名稱';
        }
        if (!bulkData.customer_phone) {
          newErrors.customer_phone = '請輸入客戶電話';
        }
        if (!bulkData.communication_app) {
          newErrors.communication_app = '請選擇客戶通訊軟體';
        }
        if (!bulkData.communication_id) {
          newErrors.communication_id = '請輸入客戶通訊軟體ID';
        }
      } else if (createMethod === 'peersSales') {
        if (!bulkData.peers_sales_id) {
          newErrors.peers_sales_id = '請選擇同行業務';
        }
      }

      if (bulkData.operation_fee && (!bulkData.operation_fee_currency || !bulkData.operation_fee_due_date)) {
        if (!bulkData.operation_fee_currency) {
          newErrors.operation_fee_currency = '請選擇幣別';
        }
        if (!bulkData.operation_fee_due_date) {
          newErrors.operation_fee_due_date = '請選擇付款截止日期';
        }
        newErrors.tabValue = 0;
      }

      if (bulkData.full_amount && (!bulkData.full_amount_currency || !bulkData.full_amount_due_date)) {
        if (!bulkData.full_amount_currency) {
          newErrors.full_amount_currency = '請選擇幣別';
        }
        if (!bulkData.full_amount_due_date) {
          newErrors.full_amount_due_date = '請選擇付款截止日期';
        }
        newErrors.tabValue = 0;
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setSubmitStatus('error');
        setValue(newErrors.tabValue || 0);
        return;
      }

      const orderResponse = await api.post('/japan/api/Order/', bulkData);

      setSubmitStatus('success');
      setHasUnsavedChanges(false);
      setOrder({});
      setSelectedPeersSales({});
      setItineraries(initialOrder.itineraries || [{ 
          itinerary_date: '',
          reception_hours: '',
          activity: '',
          itinerary_hotel: '',
          room_reservation: '',
          service_info: '',
          itinerary_notes: '' 
      }]);
      setErrors({});
      
      onSubmit && onSubmit(orderResponse.data);
    } catch (error) {
      if (error.response && error.response.data) {
        const errors = error.response.data;
        setSubmitStatus('error');
        setErrors(errors);

        if (Object.keys(errors).length > 0) {
          const firstErrorField = Object.keys(errors)[0];

          if(firstErrorField === 'itineraries') {
            setValue(1);
          } else {
            setValue(0);
          }
        }
      } 
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress color="info" />
      </div>
    );
  }

  if (salesRepOptions.length === 0 || charterCompanyOptions.length === 0 || !charterCompanyOptions.some(company => company.vehicles.length > 0) || warningTypesOptions.length === 0 || policyTypesOptions.length === 0 || remittanceInfoOptions.length === 0 || !props.CurrentUser.profile.line_uuid) {
    return (
      <Container component="main" maxWidth="lg" sx={{ py: 3 }}>

        <Typography className={styles['text-h1']} variant="h5" gutterBottom>
            日 本 線 訂 單 創 建
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <WarningImg style={{ width: '500px'}} />
        </Box>
        
        <Typography className={styles['essential-text']}>
          請先完成以下資料的設定才能創建訂單：<br />
          {salesRepOptions.length === 0 ? <>業務<br /></> : ''}
          {charterCompanyOptions.length === 0 ? <>包車公司<br /></> : ''}
          {charterCompanyOptions.some(company => company.vehicles.length < 0) ? <>車輛<br /></> : ''}
          {warningTypesOptions.length === 0 ? <>注意事項<br /></> : ''}
          {policyTypesOptions.length === 0 ? <>取消政策<br /></> : ''}
          {remittanceInfoOptions.length === 0 ? <>匯款資訊<br /></> : ''}
          {!props.CurrentUser.profile.line_uuid ? <>綁定Line帳號<br /></> : ''}
        </Typography>

      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="lg" sx={{ py: 3 }}>
        <Typography className={styles['text-h1']} variant="h5" gutterBottom>
            日 本 線 訂 單 創 建
        </Typography>

        <Tabs value={value} onChange={handleTabChange} centered>
            <Tab
              icon={<Article />}
              label="訂單資料"
              className={value === 0 ? styles['active-tab'] : styles.tab}
            />
            <Tab
              icon={<Assignment />}
              label="訂單行程資訊"
              className={value === 1 ? styles['active-tab'] : styles.tab}
            />
        </Tabs>
        
        <form noValidate onSubmit={handleSubmit}>
            {value === 0 && (
                <Card elevation={3} sx={{ mt: 2 }}>
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                          <FormControl component="fieldset">
                              <RadioGroup
                                row 
                                aria-label="position" 
                                name="position" 
                                defaultValue="default" 
                                onChange={handleCreateMethodChange}
                              >
                                <FormControlLabel value="default" control={<Radio color="primary" />} label="預設" />
                                <FormControlLabel value="peersSales" control={<Radio color="primary" />} label="同行業務" />
                                <FormControlLabel disabled value="member" control={<Radio color="primary" />} label="個人會員" />
                              </RadioGroup>
                          </FormControl>
                        </Box>

                        {createMethod === 'default' && (
                          <Card elevation={3} sx={{  margin: 2, display: 'grid', flexDirection: 'row', alignItems: 'center' }}>
                              <CardContent>
                                  <Typography>
                                    客戶信息
                                  </Typography>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <TextField
                                          error={Boolean(errors.customer_name)}
                                          helperText={errors.customer_name || ''}
                                          required
                                          label='客戶名稱'
                                          value={order.customer_name || ''}
                                          onChange={(e) => handleOrderChange('customer_name', e.target.value)}
                                          fullWidth
                                          className={styles.griditem}
                                          size="small"
                                      />
                                    </Grid>

                                    <Grid item xs={5}>
                                      <TextField
                                          error={Boolean(errors.customer_phone)}
                                          helperText={errors.customer_phone || ''}
                                          required
                                          label='客戶電話'
                                          value={order.customer_phone || ''}
                                          onChange={(e) => handleOrderChange('customer_phone', e.target.value)}
                                          fullWidth
                                          className={styles.griditem}
                                          size="small"
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.communication_app)}>
                                        <InputLabel id="communication-app-label">
                                            客戶通訊軟體
                                        </InputLabel>
                                        <Select
                                            labelId="communication-app-label"
                                            value={order.communication_app || ''}
                                            onChange={(e) => handleOrderChange('communication_app', e.target.value)}
                                          >
                                            {(orderInfoOptions.communication_app || []).map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.communication_app}</FormHelperText>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={9}>
                                      <TextField
                                          error={Boolean(errors.customer_email)}
                                          helperText={errors.customer_email || ''}
                                          label='客戶Email'
                                          value={order.customer_email || ''}
                                          onChange={(e) => handleOrderChange('customer_email', e.target.value)}
                                          fullWidth
                                          className={styles.griditem}
                                          size="small"
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                          error={Boolean(errors.communication_id)}
                                          helperText={errors.communication_id || ''}
                                          required
                                          label='客戶通訊軟體ID'
                                          value={order.communication_id || ''}
                                          onChange={(e) => handleOrderChange('communication_id', e.target.value)}
                                          fullWidth
                                          className={styles.griditem}
                                          size="small"
                                      />
                                    </Grid>

                                  </Grid>
                              </CardContent>
                          </Card>
                        )}

                        {createMethod === 'peersSales' && (
                          <Card elevation={3} sx={{  margin: 2, display: 'grid', flexDirection: 'row', alignItems: 'center'}}>
                              <CardContent>
                                  <Typography>
                                    客戶信息
                                  </Typography>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <Autocomplete
                                        value={selectedPeersCompany?.peers_company_name || '' }
                                        options={peersCompanies.map((option) => option.peers_company_name)}
                                        noOptionsText="目前沒有任何同行公司資料"
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) => {
                                          const selectedPeersCompanies = peersCompanies.find(company => company.peers_company_name === value);
                                          setSelectedPeersCompany(selectedPeersCompanies || {});
                                          setSelectedPeersSales({});
                                          setHasUnsavedChanges(true);
                                        }}
                                        renderInput={(params) =>
                                          <TextField {...params}
                                            label="同行公司"
                                            placeholder='請選擇同行公司'
                                            fullWidth
                                            size='small'
                                            className={styles.griditem}
                                          />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Autocomplete
                                        value={selectedPeersSales?.peers_sales_name || ''}
                                        options={peersSales.filter(sales => sales.peers_company.id === selectedPeersCompany?.id).map((option) => option.peers_sales_name)}
                                        // noOptionsText="目前沒有任何同行業務資料"
                                        // 如果沒有選擇同行公司，則提示選擇同行公司；如果選擇了同行公司，但沒有同行業務資料，則提示目前沒有任何同行業務資料
                                        noOptionsText={Object.keys(selectedPeersCompany).length === 0 ? "請選擇一個同行公司" : "該同行公司目前尚無任何業務資料"}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) => {
                                          const selectedPeersSales = peersSales.find(sales => sales.peers_sales_name === value);
                                          handleOrderChange('peers_sales_id', selectedPeersSales?.id);
                                          setSelectedPeersSales(selectedPeersSales);
                                        }}
                                        renderInput={(params) => 
                                          <TextField {...params} 
                                            error={Boolean(errors.peers_sales_id)}
                                            helperText={errors.peers_sales_id || ''}
                                            label="同行業務"
                                            placeholder='請選擇一個同行業務'
                                            fullWidth
                                            size='small'
                                            className={styles.griditem}
                                          />
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                              </CardContent>
                          </Card>
                        )}

                        <Card elevation={3} sx={{ margin: 2, display: 'grid', flexDirection: 'row', alignItems: 'center' }}>
                            <CardContent>
                                <Typography>
                                  業務信息
                                </Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.sales_rep_id)}>
                                      <InputLabel id="vehicle-type-label">
                                          業務名稱
                                      </InputLabel>
                                      <Select
                                          labelId="sales-rep-label"
                                          value={order.sales_rep ? order.sales_rep.id : ''}
                                          onChange={(e) => { 
                                            const selectedSalesRep = salesRepOptions.find(rep => rep.id === e.target.value);
                                            handleOrderChange('sales_rep', selectedSalesRep);
                                          }}
                                        >
                                          {salesRepOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.profile.nickname || option.username}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.sales_rep_id}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card elevation={3} sx={{ margin: 2, display: 'grid', flexDirection: 'row', alignItems: 'center' }}>
                            <CardContent>
                                <Typography>
                                  訂單資訊
                                </Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.group_number)}
                                        helperText={errors.group_number || ''}
                                        required
                                        label='團號'
                                        value={order.group_number || ''}
                                        onChange={(e) => handleOrderChange('group_number', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.service_type)}>
                                      <InputLabel id="service-type-label">
                                          包車內容
                                      </InputLabel>
                                      <Select
                                          labelId="service-type-label"
                                          value={order.service_type || ''}
                                          onChange={(e) => handleOrderChange('service_type', e.target.value)}
                                        >
                                          {(orderInfoOptions.service_type || []).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.service_type}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.charter_company_id)}>
                                      <InputLabel id="charter-company-label">
                                          包車公司
                                      </InputLabel>
                                      <Select
                                          labelId="charter-company-label"
                                          value={order.charter_company ? order.charter_company.id : ''}
                                          onChange={(e) => {
                                            const selectedCharterCompany = charterCompanyOptions.find(company => company.id === e.target.value);
                                            handleOrderChange('charter_company', selectedCharterCompany);
                                            setVehicleTypesOptions(selectedCharterCompany.vehicles);
                                          }}
                                        >
                                          {charterCompanyOptions.map((company) => (
                                            <MenuItem key={company.id} value={company.id}>
                                                {company.company_name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.charter_company}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.travel_start_date)}
                                        helperText={errors.travel_start_date || ''}
                                        required
                                        label='出發日期'
                                        value={order.travel_start_date || ''}
                                        onChange={(e) => handleOrderChange('travel_start_date', e.target.value)}
                                        type='date'
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.travel_end_date)}
                                        helperText={errors.travel_end_date || ''}
                                        required
                                        label='返回日期'
                                        value={order.travel_end_date || ''}
                                        onChange={(e) => handleOrderChange('travel_end_date', e.target.value)}
                                        type='date'
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                        error={Boolean(errors.num_travelers)}
                                        helperText={errors.num_travelers || ''}
                                        required
                                        label='旅遊人數'
                                        value={order.num_travelers || ''}
                                        onChange={(e) => handleOrderChange('num_travelers', e.target.value)}
                                        type='number'
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                        inputProps={{ min: 1 }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                        error={Boolean(errors.num_vehicles)}
                                        helperText={errors.num_vehicles || ''}
                                        required
                                        label='車輛數'
                                        value={order.num_vehicles || ''}
                                        onChange={(e) => handleOrderChange('num_vehicles', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                        inputProps={{ min: 1 }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.vehicle_type_id)}>
                                      <InputLabel id="vehicle-type-label">
                                          車型
                                      </InputLabel>
                                      <Select
                                          labelId="vehicle-type-label"
                                          value={order.vehicle_type ? order.vehicle_type.id : ''}
                                          onChange={(e) => {
                                            const selectedVehicleType = vehicleTypesOptions.find(vtype => vtype.id === e.target.value);
                                            handleOrderChange('vehicle_type', selectedVehicleType)
                                          }}
                                        >
                                          {vehicleTypesOptions.length > 0 ? (
                                            vehicleTypesOptions.map((type) => (
                                              <MenuItem key={type.id} value={type.id}>
                                                {type.vehicle_name}
                                              </MenuItem>
                                            ))
                                          ) : (
                                            <MenuItem disabled>
                                              請先選擇包車公司或包車公司無車輛
                                            </MenuItem>
                                          )}
                                      </Select>
                                      <FormHelperText>{errors.vehicle_type_id}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      error={Boolean(errors.area)}
                                      helperText={errors.area || ''}
                                      required
                                      label='地區'
                                      value={order.area || ''}
                                      onChange={(e) => handleOrderChange('area', e.target.value)}
                                      fullWidth
                                      className={styles.griditem}
                                      size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <TextField
                                        error={Boolean(errors.include_driver_accommodation)}
                                        helperText={errors.include_driver_accommodation || ''}
                                        required
                                        label='司機住宿天數'
                                        value={order.include_driver_accommodation || ''}
                                        onChange={(e) => handleOrderChange('include_driver_accommodation', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                        inputProps={{ min: 0 }}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <TextField
                                        error={Boolean(errors.include_babyseat)}
                                        helperText={errors.include_babyseat || ''}
                                        required
                                        label='兒童座椅數量'
                                        value={order.include_babyseat || ''}
                                        onChange={(e) => handleOrderChange('include_babyseat', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                        inputProps={{ min: 0 }}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <TextField
                                        error={Boolean(errors.booster_seat)}
                                        helperText={errors.booster_seat || ''}
                                        required
                                        label='增高墊數量'
                                        value={order.booster_seat || ''}
                                        onChange={(e) => handleOrderChange('booster_seat', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                        inputProps={{ min: 0 }}
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <Autocomplete
                                      multiple
                                      value={order.baby_age ? order.baby_age.split('、').map(age => parseInt(age)) : []}
                                      options={[1, 2, 3, 4, 5]}
                                      getOptionLabel={(option) => `${option}歲`}
                                      onChange={(e, value) => {
                                        const ageCount = value.length;
                                        handleOrderChange('baby_age', ageCount > 0 ? value.join('、') : '');
                                      }}
                                      renderInput={(params) =>
                                        <TextField {...params}
                                          error={Boolean(errors.baby_age)}
                                          helperText={errors.baby_age || ''}
                                          label="兒童年齡"
                                          placeholder='請選擇兒童年齡'
                                          fullWidth
                                          size='small'
                                          className={styles.griditem}
                                        />
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={1}>
                                    <FormControl fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.operation_fee_currency)}>
                                      <InputLabel>幣別</InputLabel>
                                      <Select
                                          value={order.operation_fee_currency || ''}
                                          onChange={(e) => handleOrderChange('operation_fee_currency', e.target.value)}
                                        >
                                          {(orderInfoOptions.operation_fee_currency || []).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.operation_fee_currency}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <TextField
                                        error={Boolean(errors.operation_fee)}
                                        helperText={errors.operation_fee || ''}
                                        label='作業金金額'
                                        value={order.operation_fee || ''}
                                        onChange={(e) => handleOrderChange('operation_fee', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.operation_fee_due_date)}
                                        helperText={errors.operation_fee_due_date || ''}
                                        label='作業金付款截止日'
                                        value={order.operation_fee_due_date || ''}
                                        onChange={(e) => handleOrderChange('operation_fee_due_date', e.target.value)}
                                        type='date'
                                        InputLabelProps={{ shrink: true}}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={1}>
                                    <FormControl fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.full_amount_currency)}>
                                      <InputLabel>幣別</InputLabel>
                                      <Select
                                          value={order.full_amount_currency || ''}
                                          onChange={(e) => handleOrderChange('full_amount_currency', e.target.value)}
                                        >
                                          {(orderInfoOptions.full_amount_currency || []).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.full_amount_currency}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <TextField
                                        error={Boolean(errors.full_amount)}
                                        helperText={errors.full_amount || ''}
                                        label='全額'
                                        value={order.full_amount || ''}
                                        onChange={(e) => handleOrderChange('full_amount', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.full_amount_due_date)}
                                        helperText={errors.full_amount_due_date || ''}
                                        label='全額付款截止日'
                                        value={order.full_amount_due_date || ''}
                                        onChange={(e) => handleOrderChange('full_amount_due_date', e.target.value)}
                                        type='date'
                                        InputLabelProps={{ shrink: true}}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={1}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.total_amount_currency)}>
                                      <InputLabel>幣別</InputLabel>
                                      <Select
                                          value={order.total_amount_currency || ''}
                                          onChange={(e) => handleOrderChange('total_amount_currency', e.target.value)}
                                        >
                                          {(orderInfoOptions.total_amount_currency || []).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.total_amount_currency}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={11}>
                                    <TextField
                                        error={Boolean(errors.total_amount)}
                                        helperText={errors.total_amount || ''}
                                        required
                                        label='總計'
                                        value={order.total_amount || ''}
                                        onChange={(e) => handleOrderChange('total_amount', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(errors.order_notes1)}
                                        helperText={errors.order_notes1 || ''}
                                        label='其他備註'
                                        value={order.order_notes1 || ''}
                                        onChange={(e) => handleOrderChange('order_notes1', e.target.value)}
                                        fullWidth
                                        multiline
                                        inputProps={{ wrap: 'off' }}
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(errors.order_booking_notes)}
                                        helperText={errors.order_booking_notes || ''}
                                        label='預約書備註'
                                        value={order.order_booking_notes || ''}
                                        onChange={(e) => handleOrderChange('order_booking_notes', e.target.value)}
                                        fullWidth
                                        multiline
                                        inputProps={{ wrap: 'off' }}
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.remittance_info_id)}>
                                      <InputLabel id="remittance-info-label">
                                          匯款資訊
                                      </InputLabel>
                                      <Select
                                          labelId="remittance-info-label"
                                          value={order.remittance_info ? order.remittance_info.id : ''}
                                          onChange={(e) => {
                                            const selectedRemittanceInfo = remittanceInfoOptions.find(rinfo => rinfo.id === e.target.value);
                                            handleOrderChange('remittance_info', selectedRemittanceInfo);
                                          }}
                                        >
                                          {remittanceInfoOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.remittance_name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.remittance_info_id}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                </Grid>
                            </CardContent>
                        </Card>

                        <Card elevation={3} sx={{ margin: 2, display: 'grid', flexDirection: 'row', alignItems: 'center' }}>
                            <CardContent>
                                <Typography>
                                  航班資訊
                                </Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.arrival_flight_number)}
                                        helperText={errors.arrival_flight_number || ''}
                                        label='抵達航班編號'
                                        value={order.arrival_flight_number || ''}
                                        onChange={(e) => handleOrderChange('arrival_flight_number', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField
                                        error={Boolean(errors.departure_flight_number)}
                                        helperText={errors.departure_flight_number || ''}
                                        label='離開航班編號'
                                        value={order.departure_flight_number || ''}
                                        onChange={(e) => handleOrderChange('departure_flight_number', e.target.value)}
                                        fullWidth
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card elevation={3} sx={{ margin: 2, display: 'grid', flexDirection: 'row', alignItems: 'center' }}>
                            <CardContent>
                                <Typography>
                                  注意事項及取消政策備註
                                </Typography>
                                <Grid container spacing={1}>

                                  <Grid item xs={2}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.warning_type_id)}>
                                      <InputLabel id="warning-type-label">
                                          注意事項
                                      </InputLabel>
                                      <Select
                                          labelId="warning-type-label"
                                          value={order.warning_type ? order.warning_type.id : ''}
                                          onChange={(e) => {
                                            const selectedWarningType = warningTypesOptions.find(wtype => wtype.id === e.target.value);
                                            handleOrderChange('warning_type', selectedWarningType);
                                          }}
                                        >
                                          {warningTypesOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.warning_type}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.warning_type_id}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={10}>
                                    <TextField
                                        error={Boolean(errors.order_notes2)}
                                        helperText={errors.order_notes2 || ''}
                                        label='注意事項備註'
                                        value={order.order_notes2 || ''}
                                        onChange={(e) => handleOrderChange('order_notes2', e.target.value)}
                                        fullWidth
                                        multiline
                                        inputProps={{ wrap: 'off' }}
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <FormControl required fullWidth variant="outlined" size="small" className={styles.griditem} error={Boolean(errors.policy_type_id)}>
                                      <InputLabel id="policy-type-label">
                                          取消政策
                                      </InputLabel>
                                      <Select
                                          labelId="policy-type-label"
                                          value={order.policy_type ? order.policy_type.id : ''}
                                          onChange={(e) => {
                                            const selectedPolicyType = policyTypesOptions.find(ptype => ptype.id === e.target.value);
                                            handleOrderChange('policy_type', selectedPolicyType);
                                          }}
                                        >
                                          {policyTypesOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.policy_type}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                      <FormHelperText>{errors.policy_type_id}</FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={10}>
                                    <TextField
                                        error={Boolean(errors.order_notes3)}
                                        helperText={errors.order_notes3 || ''}
                                        label='取消政策備註'
                                        value={order.order_notes3 || ''}
                                        onChange={(e) => handleOrderChange('order_notes3', e.target.value)}
                                        fullWidth
                                        multiline
                                        inputProps={{ wrap: 'off' }}
                                        className={styles.griditem}
                                        size="small"
                                    />
                                  </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
                
            )}

            {value === 1 && (
                <Card elevation={3} sx={{ mt: 2 }}>
                    <CardContent>
                        {itineraries.map((itinerary, index) => (
                            <Card elevation={3} sx={{ margin: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <CardContent style={{ width: '100%' }}>
                                <Grid container spacing={1}>
                                  <Grid item xs={3}>
                                    <TextField
                                      error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.itinerary_date)}
                                      helperText={errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.itinerary_date?.[0] || ''}
                                      label='旅遊日期'
                                      value={itinerary.itinerary_date}
                                      onChange={(e) => handleItineraryChange(index, 'itinerary_date', e.target.value)}
                                      type="date"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      className={styles.griditem}
                                      size="small"
                                      key={index}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.reception_hours)}
                                      helperText={errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.reception_hours?.[0] || ''}
                                      label='接待時間'
                                      value={itinerary.reception_hours}
                                      onChange={(e) => handleItineraryChange(index, 'reception_hours', e.target.value)}
                                      type="time"
                                      defaultValue="none"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      className={styles.griditem}
                                      size="small"
                                      key={index}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" size="small" className={styles.griditem} error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.room_reservation)}>
                                        <InputLabel id="room-reservation-label">
                                          訂房資訊
                                        </InputLabel>
                                        <Select
                                          helperText={errors.room_reservation || ''}
                                          labelId="room-reservation-label"
                                          value={itinerary.room_reservation}
                                          onChange={(e) => handleItineraryChange(index, 'room_reservation', e.target.value)}
                                        >
                                          {(itineraryRoomReservationOptions.room_reservation || []).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.room_reservation && <FormHelperText>{errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.room_reservation?.[0] || ''}</FormHelperText>}
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" size="small" className={styles.griditem} error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.service_info)}>
                                        <InputLabel id="service-info-label">
                                          服務資訊
                                        </InputLabel>
                                        <Select
                                          error={Boolean(errors.service_info)}
                                          helperText={errors.service_info || ''}
                                          labelId="service-info-label"
                                          value={itinerary.service_info}
                                          onChange={(e) => handleItineraryChange(index, 'service_info', e.target.value)}
                                        >
                                          {(itineraryRoomReservationOptions.service_info || []).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.service_info && <FormHelperText>{errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.service_info?.[0] || ''}</FormHelperText>}
                                    </FormControl>
                                  </Grid>

                                  <Grid item sm='12'>
                                    <TextField
                                      error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.activity)}
                                      helperText={errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.activity?.[0] || ''}
                                      label='行程內容'
                                      value={itinerary.activity}
                                      onChange={(e) => handleItineraryChange(index, 'activity', e.target.value)}
                                      multiline
                                      fullWidth
                                      inputProps={{ wrap: 'off', style: { textAlign: 'center' } }}
                                      className={styles.griditem}
                                      size="small"
                                      key={index}
                                    />
                                  </Grid>

                                  <Grid item sm='12'>
                                    <TextField
                                      error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.itinerary_hotel)}
                                      helperText={errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.itinerary_hotel?.[0] || ''}
                                      label='飯店名稱'
                                      value={itinerary.itinerary_hotel}
                                      onChange={(e) => handleItineraryChange(index, 'itinerary_hotel', e.target.value)}
                                      fullWidth
                                      multiline
                                      inputProps={{ wrap: 'off', style: { textAlign: 'center' } }}
                                      className={styles.griditem}
                                      size="small"
                                      key={index}
                                    />
                                  </Grid>

                                  <Grid item sm='12'>
                                    <TextField
                                      error={errors?.itineraries?.find(error => error.identifier === itinerary.identifier && error?.itinerary_notes)}
                                      helperText={errors?.itineraries?.find(error => error.identifier === itinerary.identifier)?.itinerary_notes?.[0] || ''}
                                      label='備註'
                                      value={itinerary.itinerary_notes}
                                      onChange={(e) => handleItineraryChange(index, 'itinerary_notes', e.target.value)}
                                      fullWidth
                                      multiline
                                      inputProps={{ wrap: 'off' }}
                                      className={styles.griditem}
                                      size="small"
                                      key={index}
                                    />
                                  </Grid>

                                </Grid>
                              </CardContent>
                              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <IconButton className={styles['delete-button']} onClick={() => handleRemoveItinerary(index)}>
                                    <Delete />
                                </IconButton>
                              </Box>
                            </Card>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                          <Button className={styles['add-button']} onClick={handleAddItinerary}>
                              <AddCircleOutlineOutlined fontSize="medium"/>
                              新增行程
                          </Button>
                        </Box>
                    </CardContent>
                </Card>
            )}

            <Container>
              {submitStatus === 'success' && (
                <Card elevation={3} sx={{ margin: 2, position: 'relative'}}>
                  <CardContent>
                    <Typography className={styles['success-text']} variant="h6" color="success">
                      創建成功，此表單已重置。
                    </Typography>
                  </CardContent>
                  <Box sx={{ position: 'absolute', right: 0, top: 0, p: 1 }}>
                    <IconButton onClick={handleResetSubmitStatus}  color="success">
                        <HighlightOff/>
                    </IconButton>
                  </Box>
                </Card>
              )}
              {submitStatus === 'error' && (
                <Card elevation={3} sx={{ margin: 2, position: 'relative'}}>
                  <CardContent>
                    <Typography className={styles['error-text']} variant="h6" color="error">
                      創建失敗，請詳細檢查所有輸入資訊。
                    </Typography>
                  </CardContent>
                  <Box sx={{ position: 'absolute', right: 0, top: 0, p: 1 }}>
                    <IconButton onClick={handleResetSubmitStatus}  color="error">
                        <HighlightOff/>
                    </IconButton>
                  </Box>
                </Card>
              )}
            </Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button className={styles['submit-button']} type="submit" variant="contained" sx={{ mt: 2 }} onClick={handleSubmit}>
                  <CreateNewFolder fontSize='medium' /> 創建訂單
              </Button>
            </Box>
        </form>
        <Dialog
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
          PaperProps={{
            style: {
              borderRadius: 10,
              padding: '20px 30px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DialogTitle style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <Delete color="error" style={{ marginRight: '10px', fontSize: '50px' }} />
            刪除確認
          </DialogTitle>
          <DialogContent dividers={false} style={{ marginBottom: '5px' }}>
            <DialogContentText>
              {deleteType === "itinerary" ? "是否真的要刪除此行程資訊 ? 此操作無法復原" : "是否真的要刪除該旅客信息 ? 此操作無法復原"}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button onClick={() => setPopupOpen(false)} variant="outlined" color="primary">
              取消
            </Button>
            <Button onClick={handleConfirmDelete} variant="contained" color="primary">
              確認
            </Button>
          </DialogActions>
        </Dialog>
    </Container>
  );
}

export default OrderCreate;
