import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import UserGroupContext from '../../../contexts/UserGroupContext';
import api from '../../../config/axiosConfig';
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { AddCircle, Delete, DeleteForever, DoDisturbOn, Download, EditNote, SaveAs, Warning } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/AdvanceTab.module.css';

const AdvanceTab = ({ uuid, options }) => {
    const userGroups = React.useContext(UserGroupContext);
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {
                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExportRequest = (urlPath, fileName) => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            return;
        }
    
        setLastClickTime(now);
    
        handleMenuClose();
        // setIsLoading(true);
    
        const uniqueUrl = `${urlPath}?t=${now}`;
        
        api.get(uniqueUrl, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(error => {
            console.error('下載發生錯誤:', error);
        })
        .finally(() => {
            // setIsLoading(false);
        });
    };

    const handleAddNewAdvancePayment = () => {
        setEditData({
            ...editData,
            newAdvancePayments: [
                ...(editData?.newAdvancePayments || []),
                {
                    identifier: uuidv4(),
                    advance_method: null,
                    advance_item: '',
                    advance_currency: null,
                    advance_amount: '',
                    application_date: null,
                    payee: '',
                    payee_account: '',
                    advance_content: '',
                    transfer_bank: '',
                    payment_method: '',
                }
            ]
        });
    }

    const handleFieldChange = (arrayName, fieldName, fieldValue, idOrIdentifier, isParentNew = false, nestedArrayName = null, parentId = null, isNestedNew = false) => {
        setEditData((prevState) => {
            let currentArray = prevState?.[arrayName] ? [...prevState[arrayName]] : [];
    
            if (parentId && nestedArrayName) {
                // 查找父級項目，無論父級是否是新的
                let parentIndex = currentArray.findIndex(item => item.id === parentId || item.identifier === parentId);
    
                // 如果父級不存在，創建父級數據
                if (parentIndex === -1) {
                    const newParentItem = {
                        [nestedArrayName]: [],
                        ...(isParentNew ? { identifier: parentId } : { id: parentId })
                    };
                    currentArray.push(newParentItem);
                    parentIndex = currentArray.length - 1;
                }
    
                // 獲取父級項目並找到其嵌套數組
                const parentItem = { ...currentArray[parentIndex] };
                let nestedArray = parentItem[nestedArrayName] ? [...parentItem[nestedArrayName]] : [];
    
                // 根據 isNestedNew 和 identifier/id 查找嵌套數據
                const nestedItemIndex = isNestedNew
                    ? nestedArray.findIndex(item => item.identifier === idOrIdentifier)
                    : nestedArray.findIndex(item => item.id === idOrIdentifier);
    
                if (nestedItemIndex !== -1) {
                    nestedArray[nestedItemIndex] = {
                        ...nestedArray[nestedItemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newNestedItem = {
                        [fieldName]: fieldValue
                    };
                    if (isNestedNew) {
                        newNestedItem.identifier = idOrIdentifier;
                    } else {
                        newNestedItem.id = idOrIdentifier;
                    }
                    nestedArray.push(newNestedItem);
                }
    
                parentItem[nestedArrayName] = nestedArray;
                currentArray[parentIndex] = parentItem;
    
            } else {
                // 操作父級數據（非嵌套）
                const itemIndex = currentArray.findIndex(item => item.id === idOrIdentifier || item.identifier === idOrIdentifier);
    
                if (itemIndex !== -1) {
                    currentArray[itemIndex] = {
                        ...currentArray[itemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newItem = {
                        [fieldName]: fieldValue
                    };
                    if (isParentNew) {
                        newItem.identifier = idOrIdentifier;
                    } else {
                        newItem.id = idOrIdentifier;
                    }
                    currentArray.push(newItem);
                }
            }
    
            return {
                ...prevState,
                [arrayName]: currentArray
            };
        });
    };

    const handleEditClick = () => {
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditData(null);
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleDeleteClick = (deleteType, item) => {
        setDeleteItem({ deleteType, ...item });
        setDeleteCheckOpen(true);
    };

    const handleDeletCancelClick = (event, reason) => {
        // console.log(deleteItem);
        if (reason !== 'backdropClick') {
            setDeleteItem(null);
            setDeleteCheckOpen(false);
        }
    };

    const handleDeleteConfirmClick = async () => {
        switch (deleteItem.deleteType) {
            case 'AdvancePayment':
                try {
                    await api.delete(`/japan/api/AdvancePayment/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        advancepayments: prevState.advancepayments.filter(advancepayment => advancepayment.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newAdvancePayment':
                setEditData((prevState) => ({
                    ...prevState,
                    newAdvancePayments: prevState.newAdvancePayments.filter(advancepayment => advancepayment.identifier !== deleteItem.identifier)
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            default:
                console.log(`未知的刪除類型: ${deleteItem.deleteType}`);
                break;
        }
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);

            if (!editData) {
                setErrors(null);
                setEditMode(false);
                setEditData(null);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            const updateEditData = {
                ...editData,
                advancepayments: [
                    ...(editData?.advancepayments || []).map(advancepayment => ({ ...advancepayment, order: uuid })),
                    ...(editData?.newAdvancePayments || []).map(advancepayment => ({ ...advancepayment, order: uuid }))
                ],
            };

            delete updateEditData.newAdvancePayments;
            if (updateEditData.advancepayments.length === 0) {
                delete updateEditData.advancepayments;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, updateEditData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box sx={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Box className={styles['print-box']}>
                    <Button
                        className={styles['print-button']}
                        sx={{
                            '& .MuiButton-startIcon': {
                                margin: 0,
                                '&>*:nth-of-type(1)': {
                                    fontSize: '18px',
                                }
                            },
                        }}
                        disabled={editMode}
                        startIcon={<Download />}
                        onClick={handleMenuClick}
                    >
                        下載導出
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        {data?.advancepayments.length > 0 &&
                            <MenuItem onClick={() => handleExportRequest(`/japan/export_order_advance_payment/${uuid}/`, `${data?.group_number} - 代墊(退)款申請單.pdf`)}>代墊(退)款申請單</MenuItem>
                        }
                    </Menu>
                </Box>
            </Box>

            <Box className={styles['content-box']}>
                <Box className={styles['tab-main-box']}>

                    {data?.advancepayments.map((advancepayment, index) => (
                        <div key={advancepayment.id}>
                            <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                <Chip
                                    className={styles['chip-text']}
                                    label={advancepayment?.advance_method + " / " + (!editMode ? advancepayment?.application_date : '編輯中. . .')}
                                    onDelete={editMode ? null : () => handleDeleteClick('AdvancePayment', advancepayment)}
                                    deleteIcon={editMode ? null : <DeleteForever className={styles['delete-icon']}/>}
                                />
                            </Divider>
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.advance_payment?.advance_method}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            options?.advance_payment?.advance_method.find(option => option.value === editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_method) 
                                                || options?.advance_payment?.advance_method.find(option => option.value === advancepayment?.advance_method)
                                        }
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_method}
                                                helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_method}
                                                label="款項類型"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("advancepayments", "advance_method", value?.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_item}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_item}
                                        label={(editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_method || advancepayment?.advance_method) + "項目"}
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_item === null
                                                ? '' 
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_item || advancepayment?.advance_item
                                        }
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "advance_item", e.target.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.advance_payment?.advance_currency}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            options?.advance_payment?.advance_currency.find(option => option.value === editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_currency) 
                                                || options?.advance_payment?.advance_currency.find(option => option.value === advancepayment?.advance_currency)
                                        }
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_currency}
                                                helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_currency}
                                                label="幣別"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("advancepayments", "advance_currency", value?.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_amount}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_amount}
                                        label="金額"
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_amount === ''
                                                ? '' 
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_amount || parseFloat(advancepayment?.advance_amount).toFixed(2).replace(/\.00$/, '')
                                        }
                                        type='number'
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                '-webkit-appearance': 'none',
                                                margin: 0
                                            },
                                            '& input[type="number"]': {
                                                '-moz-appearance': 'textfield'
                                            }
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "advance_amount", e.target.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.application_date}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.application_date}
                                        label="申請日期"
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.application_date === null
                                                ? '' 
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.application_date || advancepayment?.application_date
                                        }
                                        required
                                        type='date'
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "application_date", e.target.value || null, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="申請人"
                                        variant="standard"
                                        value={advancepayment?.applicant?.profile?.nickname || advancepayment?.applicant?.username}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.payee}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.payee}
                                        label="受款人"
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.payee === null
                                                ? ''
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.payee || advancepayment?.payee
                                        }
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "payee", e.target.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.payee_account}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.payee_account}
                                        label="受款帳號"
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.payee_account === null
                                                ? ''
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.payee_account || advancepayment?.payee_account
                                        }
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "payee_account", e.target.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_content}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.advance_content}
                                        label={(editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_method || advancepayment?.advance_method) + "內容"}
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_content === null
                                                ? ''
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.advance_content || advancepayment?.advance_content
                                        }
                                        multiline
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "advance_content", e.target.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.transfer_bank}
                                        helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.transfer_bank}
                                        label="轉出銀行"
                                        variant="standard"
                                        value={
                                            editData?.advancepayments?.find(it => it.id === advancepayment.id)?.transfer_bank === null
                                                ? ''
                                                : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.transfer_bank || advancepayment?.transfer_bank
                                        }
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("advancepayments", "transfer_bank", e.target.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        options={options?.advance_payment?.payment_method}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            options?.advance_payment?.payment_method.find(option => option.value === editData?.advancepayments?.find(it => it.id === advancepayment.id)?.payment_method) 
                                                || options?.advance_payment?.payment_method.find(option => option.value === advancepayment?.payment_method)
                                        }
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.payment_method}
                                                helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.payment_method}
                                                label="支付方式"
                                                variant="standard"
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("advancepayments", "payment_method", value?.value, advancepayment.id)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="支付日期"
                                        variant="standard"
                                        value={advancepayment?.payment_date}
                                        type='date'
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} justifyContent="center" sx={{ position: 'relative' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.accounting_audit}
                                                helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.accounting_audit}
                                                checked={
                                                    editData?.advancepayments?.find(it => it.id === advancepayment.id)?.accounting_audit === false
                                                        ? false
                                                        : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.accounting_audit || advancepayment?.accounting_audit
                                                }
                                                onChange={(e) => handleFieldChange("advancepayments", "accounting_audit", e.target.checked, advancepayment.id)}
                                                color="primary"
                                                disabled={!editMode || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))}
                                                sx={{
                                                    top: '9px',
                                                    padding: 0,
                                                    margin: '10px',
                                                    '&.Mui-disabled': {
                                                      color: advancepayment.accounting_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)',
                                                      opacity: 0.6,
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                      color: !editMode || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))
                                                        ? (advancepayment.accounting_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)') 
                                                        : 'inherit',
                                                    },
                                                }}
                                            />
                                        }
                                        label="會計審核"
                                        labelPlacement='top'
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                              fontSize: '12px',
                                              fontWeight: '400',
                                              color: editMode ? 'primary.main' : 'rgba(0, 0, 0, 0.6)',
                                              position: 'absolute',
                                              top: '10px',
                                            },
                                            '& .MuiFormControlLabel-label.Mui-disabled': {
                                              color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} justifyContent="center" sx={{ position: 'relative' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                error={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.manager_audit}
                                                helperText={errors?.advancepayments?.find(error => error.id === String(advancepayment.id))?.manager_audit}
                                                checked={
                                                    editData?.advancepayments?.find(it => it.id === advancepayment.id)?.manager_audit === false
                                                        ? false
                                                        : editData?.advancepayments?.find(it => it.id === advancepayment.id)?.manager_audit || advancepayment?.manager_audit
                                                }
                                                onChange={(e) => handleFieldChange("advancepayments", "manager_audit", e.target.checked, advancepayment.id)}
                                                color="primary"
                                                disabled={!editMode || !userGroups.includes('管理員')}
                                                sx={{
                                                    top: '9px',
                                                    padding: 0,
                                                    margin: '10px',
                                                    '&.Mui-disabled': {
                                                      color: advancepayment.manager_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)',
                                                      opacity: 0.6,
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                      color: !editMode || !userGroups.includes('管理員')
                                                        ? (advancepayment.manager_audit ? 'primary.main' : 'rgba(0, 0, 0, 0.26)') 
                                                        : 'inherit',
                                                    },
                                                }}
                                            />
                                        }
                                        label="主管審核"
                                        labelPlacement='top'
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                              fontSize: '12px',
                                              fontWeight: '400',
                                              color: editMode ? 'primary.main' : 'rgba(0, 0, 0, 0.6)',
                                              position: 'absolute',
                                              top: '10px',
                                            },
                                            '& .MuiFormControlLabel-label.Mui-disabled': {
                                              color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="會計審核日期"
                                        variant="standard"
                                        value={advancepayment?.accounting_audit_date ? new Date(advancepayment.accounting_audit_date).toLocaleDateString() : ''}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="會計審核人員"
                                        variant="standard"
                                        value={advancepayment?.accounting_staff?.profile?.nickname || advancepayment?.accounting_staff?.username}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="主管審核日期"
                                        variant="standard"
                                        value={advancepayment?.manager_audit_date ? new Date(advancepayment.manager_audit_date).toLocaleDateString() : ''}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="主管審核人員"
                                        variant="standard"
                                        value={advancepayment?.manager_staff?.profile?.nickname || advancepayment?.manager_staff?.username}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}

                    {!editMode && (
                        <Box />
                    )}

                    {editData?.newAdvancePayments?.map((advancepayment, index) => (
                        <div key={advancepayment.identifier}>
                            <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                <Chip className={styles['chip-text']} label={"新增#" + (index + 1) + " / " + (advancepayment?.advance_method ? advancepayment?.advance_method : '. . .')} onDelete={() => handleDeleteClick('newAdvancePayment', advancepayment)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                            </Divider>
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={options?.advance_payment?.advance_method}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            options?.advance_payment?.advance_method.find(option => option.value === advancepayment?.advance_method)
                                        }
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_method}
                                                helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_method}
                                                label="款項類型"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("newAdvancePayments", "advance_method", value?.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_item}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_item}
                                        label={(advancepayment?.advance_method ? advancepayment?.advance_method : '') + "項目"}
                                        variant="standard"
                                        value={advancepayment?.advance_item}
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "advance_item", e.target.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        options={options?.advance_payment?.advance_currency}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            options?.advance_payment?.advance_currency.find(option => option.value === advancepayment?.advance_currency)
                                        }
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_currency}
                                                helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_currency}
                                                label="幣別"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("newAdvancePayments", "advance_currency", value?.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_amount}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_amount}
                                        label="金額"
                                        variant="standard"
                                        value={advancepayment?.advance_amount}
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "advance_amount", e.target.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.application_date}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.application_date}
                                        label="申請日期"
                                        variant="standard"
                                        value={advancepayment?.application_date}
                                        required
                                        type='date'
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "application_date", e.target.value || null, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.payee}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.payee}
                                        label="受款人"
                                        variant="standard"
                                        value={advancepayment?.payee}
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "payee", e.target.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.payee_account}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.payee_account}
                                        label="受款帳號"
                                        variant="standard"
                                        value={advancepayment?.payee_account}
                                        required
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "payee_account", e.target.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_content}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.advance_content}
                                        label={(advancepayment?.advance_method ? advancepayment?.advance_method : '')  + "內容"}
                                        variant="standard"
                                        value={advancepayment?.advance_content}
                                        multiline
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "advance_content", e.target.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.transfer_bank}
                                        helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.transfer_bank}
                                        label="轉出銀行"
                                        variant="standard"
                                        value={advancepayment?.transfer_bank}
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFieldChange("newAdvancePayments", "transfer_bank", e.target.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={options?.advance_payment?.payment_method}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            options?.advance_payment?.payment_method.find(option => option.value === advancepayment?.payment_method)
                                        }
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.payment_method}
                                                helperText={errors?.advancepayments?.find(error => error.identifier === advancepayment.identifier)?.payment_method}
                                                label="支付方式"
                                                variant="standard"
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => handleFieldChange("newAdvancePayments", "payment_method", value?.value, advancepayment.identifier, true)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}

                    {editMode && (
                        <Box className={styles['add-item-box']}>
                            <Button className={styles['add-item-button']} onClick={handleAddNewAdvancePayment}>
                                <AddCircle sx={{ width: '20px', height: '20px' }} />
                                新增代墊/退款單
                            </Button>
                        </Box>
                    )}

                    {!editMode && data?.advancepayments.length === 0 && !editData?.newAdvancePayments && (
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <SearchingImg style={{ width: '300px', height: '300px' }} />
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何代墊/退款資訊</Typography>
                            </Grid>
                        </Grid>
                    )}

                    {!data && (
                        <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            {loading ? (
                                <CircularProgress color="info" />
                            ) : (
                                <>
                                    <NoDataImg style={{ width: '300px', height: '300px' }} />
                                    無法取得訂單資料或發生錯誤: {errors?.detail}
                                </>
                            )}
                        </Box>
                    )}

                </Box>
            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Box className={styles['action-box']}>
                {editMode ? (
                    <>
                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                            取消
                        </Button>
                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                            保存
                        </Button>
                    </>
                ) : (
                    <Button className={styles['edit-button']} onClick={handleEditClick}>
                        <EditNote sx={{ width: '22px', height: '22px' }} />
                        編輯
                    </Button>
                )}
            </Box>

            <Dialog disableEscapeKeyDown open={deleteCheckOpen} onClose={handleDeletCancelClick} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {deleteItem?.deleteType === 'AdvancePayment' && (
                            <div>
                                <div>款項類型：{deleteItem?.advance_method}</div>
                                <div>{deleteItem?.advance_method}金額：{deleteItem?.advance_amount ? parseFloat(deleteItem?.advance_amount).toFixed(2).replace(/\.00$/, '') : '未知'}</div>
                                <div>申請日期：{deleteItem?.application_date} / 申請人：{deleteItem?.applicant?.profile?.nickname || deleteItem?.applicant?.username}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newAdvancePayment' && (
                            <div>
                                <div>款項類型：{deleteItem?.advance_method || '未知'}</div>
                                <div>{deleteItem?.advance_method}金額：{deleteItem?.advance_amount ? parseFloat(deleteItem?.advance_amount).toFixed(2).replace(/\.00$/, '') : '未知'}</div>
                                <div>申請日期：{deleteItem?.application_date || '未知'}</div>
                            </div>
                        )}
                        <br />此操作無法復原，請謹慎處理。
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['delete-cancel-button']} onClick={handleDeletCancelClick}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleDeleteConfirmClick}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

export default AdvanceTab;