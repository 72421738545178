import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import api from '../../../config/axiosConfig';
import { Alert, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material';
import { AddCircle, Delete, DeleteForever, DoDisturbOn, EditNote, SaveAs, Warning } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/CostcalculationTab.module.css';

const CostcalculationTab = ({ uuid }) => {
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {
                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleAddNewHotelCostCalculation = () => {
        setEditData((prevState) => ({
            ...prevState,
            costcalculation: {
                ...(prevState?.costcalculation || {}),
                newHotelCostCalculations: [
                    ...(prevState?.costcalculation?.newHotelCostCalculations || []),
                    { 
                        identifier: uuidv4(),
                        area: '',
                        hotel_info: '',
                        price: '',
                        days: null,
                    }
                ]
            }
        }));
    };

    const handleAddNewMealCostCalculation = () => {
        setEditData((prevState) => ({
            ...prevState,
            costcalculation: {
                ...(prevState?.costcalculation || {}),
                newMealCostCalculations: [
                    ...(prevState?.costcalculation?.newMealCostCalculations || []),
                    { 
                        identifier: uuidv4(),
                        lunch_price: '',
                        dinner_price: '',
                    }
                ]
            }
        }));
    };

    const handleAddNewAttractionsCostCalculation = () => {
        setEditData((prevState) => ({
            ...prevState,
            costcalculation: {
                ...(prevState?.costcalculation || {}),
                newAttractionsCostCalculations: [
                    ...(prevState?.costcalculation?.newAttractionsCostCalculations || []),
                    { 
                        identifier: uuidv4(),
                        attraction: '',
                        price: '',
                    }
                ]
            }
        }));
    };

    const handleFieldChange = (parentKey, arrayKey, fieldName, fieldValue, idOrIdentifier, isNewItem = false) => {
        setEditData((prevState) => {
            // 确保 prevState 是一个对象
            const state = prevState || {};
    
            // 确保父级字典存在
            const parentDict = state[parentKey] ? { ...state[parentKey] } : {};
    
            // 获取目标数组
            let targetArray = parentDict[arrayKey] ? [...parentDict[arrayKey]] : [];
    
            // 根据 id 或 identifier 查找项目
            const itemIndex = targetArray.findIndex(
                item => item.id === idOrIdentifier || item.identifier === idOrIdentifier
            );
    
            if (itemIndex !== -1) {
                // 更新现有项目
                targetArray[itemIndex] = {
                    ...targetArray[itemIndex],
                    [fieldName]: fieldValue
                };
            } else {
                // 添加新项目
                const newItem = {
                    [fieldName]: fieldValue,
                    ...(isNewItem ? { identifier: idOrIdentifier } : { id: idOrIdentifier })
                };
                targetArray.push(newItem);
            }
    
            // 更新父级字典
            parentDict[arrayKey] = targetArray;
    
            // 返回新的状态
            return {
                ...state,
                [parentKey]: parentDict
            };
        });
    };    

    const handleEditClick = () => {
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditData(null);
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleDeleteClick = (deleteType, item) => {
        setDeleteItem({ deleteType, ...item });
        setDeleteCheckOpen(true);
    };

    const handleDeletCancelClick = (event, reason) => {
        // console.log(deleteItem);
        if (reason !== 'backdropClick') {
            setDeleteItem(null);
            setDeleteCheckOpen(false);
        }
    };

    const handleDeleteConfirmClick = async () => {
        switch (deleteItem.deleteType) {
            case 'HotelCostCalculation':
                try {
                    await api.delete(`/japan/api/HotelCostCalculation/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        costcalculation: {
                            ...prevState.costcalculation,
                            hotelcostcalculations: prevState.costcalculation.hotelcostcalculations.filter(hotel => hotel.id !== deleteItem.id)
                        }
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newHotelCostCalculation':
                setEditData((prevState) => ({
                    ...prevState,
                    costcalculation: {
                        ...prevState.costcalculation,
                        newHotelCostCalculations: prevState.costcalculation.newHotelCostCalculations.filter(hotel => hotel.identifier !== deleteItem.identifier)
                    }
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'MealCostCalculation':
                try {
                    await api.delete(`/japan/api/MealCostCalculation/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        costcalculation: {
                            ...prevState.costcalculation,
                            mealcostcalculations: prevState.costcalculation.mealcostcalculations.filter(meal => meal.id !== deleteItem.id)
                        }
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newMealCostCalculation':
                setEditData((prevState) => ({
                    ...prevState,
                    costcalculation: {
                        ...prevState.costcalculation,
                        newMealCostCalculations: prevState.costcalculation.newMealCostCalculations.filter(meal => meal.identifier !== deleteItem.identifier)
                    }
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'AttractionsCostCalculation':
                try {
                    await api.delete(`/japan/api/AttractionsCostCalculation/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        costcalculation: {
                            ...prevState.costcalculation,
                            attractionscostcalculations: prevState.costcalculation.attractionscostcalculations.filter(attraction => attraction.id !== deleteItem.id)
                        }
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newAttractionsCostCalculation':
                setEditData((prevState) => ({
                    ...prevState,
                    costcalculation: {
                        ...prevState.costcalculation,
                        newAttractionsCostCalculations: prevState.costcalculation.newAttractionsCostCalculations.filter(attraction => attraction.identifier !== deleteItem.identifier)
                    }
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            default:
                console.log(`未知的刪除類型: ${deleteItem.deleteType}`);
                break;
        }
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);

            if (!editData) {
                setErrors(null);
                setEditMode(false);
                setEditData(null);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            const updateEditData = {
                ...editData,
                costcalculation: {
                    ...editData?.costcalculation,
                    hotelcostcalculations: [
                        ...(editData?.costcalculation?.hotelcostcalculations || []).map(hotel => ({ ...hotel })),
                        ...(editData?.costcalculation?.newHotelCostCalculations || []).map(hotel => ({ ...hotel }))
                    ],
                    mealcostcalculations: [
                        ...(editData?.costcalculation?.mealcostcalculations || []).map(meal => ({ ...meal })),
                        ...(editData?.costcalculation?.newMealCostCalculations || []).map(meal => ({ ...meal }))
                    ],
                    attractionscostcalculations: [
                        ...(editData?.costcalculation?.attractionscostcalculations || []).map(attraction => ({ ...attraction })),
                        ...(editData?.costcalculation?.newAttractionsCostCalculations || []).map(attraction => ({ ...attraction }))
                    ]
                }
            };

            delete updateEditData.costcalculation.newHotelCostCalculations;
            if (updateEditData.costcalculation.hotelcostcalculations.length === 0) {
                delete updateEditData.costcalculation.hotelcostcalculations;
            }
            delete updateEditData.costcalculation.newMealCostCalculations;
            if (updateEditData.costcalculation.mealcostcalculations.length === 0) {
                delete updateEditData.costcalculation.mealcostcalculations;
            }
            delete updateEditData.costcalculation.newAttractionsCostCalculations;
            if (updateEditData.costcalculation.attractionscostcalculations.length === 0) {
                delete updateEditData.costcalculation.attractionscostcalculations;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, updateEditData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box className={styles['tips-box']}>
                ※ 請注意：此報價為成本價，另小費費用請另加上。(小孩不佔床扣NT5000)
            </Box>

            <Box className={styles['content-box']}>

                {data && (
                    <Box className={styles['tab-main-box']}>

                        <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="成本計算表" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={10} />
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.costcalculation?.jpy_exchange_rate}
                                    helperText={errors?.costcalculation?.jpy_exchange_rate}
                                    label="日幣匯率"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('jpy_exchange_rate')
                                            ? editData.costcalculation.jpy_exchange_rate
                                            : parseFloat(data?.costcalculation?.jpy_exchange_rate).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, jpy_exchange_rate: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="飯店成本" />
                        </Divider>
                        {data?.costcalculation?.hotelcostcalculations?.map((hotel, index) => (
                            <div key={hotel.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('HotelCostCalculation', hotel)} />
                                )}

                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.area}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.area}
                                            label={"地區 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.area === ''
                                                    ? ''
                                                    : editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.area || hotel?.area
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "hotelcostcalculations", "area", e.target.value, hotel.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.hotel_info}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.hotel_info}
                                            label={"飯店資訊 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.hotel_info === ''
                                                    ? ''
                                                    : editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.hotel_info || hotel?.hotel_info
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "hotelcostcalculations", "hotel_info", e.target.value, hotel.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.price}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.price}
                                            label={"價格 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.price === ''
                                                    ? ''
                                                    : editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.price || parseFloat(hotel?.price).toString()
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "hotelcostcalculations", "price", e.target.value, hotel.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.days}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.id === String(hotel.id))?.days}
                                            label={"天數 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.days === ''
                                                    ? ''
                                                    : editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.days || parseFloat(hotel?.days).toString()
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "hotelcostcalculations", "days", e.target.value, hotel.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label={"合計 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat((editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.price || hotel?.price) * (editData?.costcalculation?.hotelcostcalculations?.find(it => it.id === hotel.id)?.days || hotel?.days)).toString()}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.costcalculation?.newHotelCostCalculations?.map((hotel, index) => (
                            <div key={hotel.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newHotelCostCalculation', hotel)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.area}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.area}
                                            label={"新增地區 #" + (index + 1)}
                                            variant="standard"
                                            value={hotel.area}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newHotelCostCalculations", "area", e.target.value, hotel.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.hotel_info}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.hotel_info}
                                            label={"新增飯店資訊 #" + (index + 1)}
                                            variant="standard"
                                            value={hotel.hotel_info}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newHotelCostCalculations", "hotel_info", e.target.value, hotel.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.price}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.price}
                                            label={"新增價格 #" + (index + 1)}
                                            variant="standard"
                                            value={hotel.price}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newHotelCostCalculations", "price", e.target.value, hotel.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            error={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.days}
                                            helperText={errors?.costcalculation?.hotelcostcalculations?.find(error => error.identifier === hotel.identifier)?.days}
                                            label={"新增天數 #" + (index + 1)}
                                            variant="standard"
                                            value={hotel.days}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newHotelCostCalculations", "days", e.target.value === '' ? null : e.target.value, hotel.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label={"新增合計 #" + (index + 1)}
                                            variant="standard"
                                            value={parseFloat(hotel.price * (editData?.costcalculation?.hotelcostcalculations?.find(it => it.identifier === hotel.identifier)?.days || hotel.days)).toString()}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.costcalculation?.hotelcostcalculations?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="飯店成本總計"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * hotel.days, 0)).toString()}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.costcalculation?.hotelcostcalculations?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何飯店成本的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {editMode && (
                            <Box className={styles['sub-add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewHotelCostCalculation}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增飯店成本資料
                                </Button>
                            </Box>
                        )}

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="餐飲成本" />
                        </Divider>
                        {data?.costcalculation?.mealcostcalculations?.map((meal, index) => (
                            <div key={meal.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('MealCostCalculation', meal)} />
                                )}

                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.costcalculation?.mealcostcalculations?.find(error => error.id === String(meal.id))?.lunch_price}
                                            helperText={errors?.costcalculation?.mealcostcalculations?.find(error => error.id === String(meal.id))?.lunch_price}
                                            label={"午餐費用 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.mealcostcalculations?.find(it => it.id === meal.id)?.lunch_price === ''
                                                    ? ''
                                                    : editData?.costcalculation?.mealcostcalculations?.find(it => it.id === meal.id)?.lunch_price || parseFloat(meal?.lunch_price).toString()
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "mealcostcalculations", "lunch_price", e.target.value, meal.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.costcalculation?.mealcostcalculations?.find(error => error.id === String(meal.id))?.dinner_price}
                                            helperText={errors?.costcalculation?.mealcostcalculations?.find(error => error.id === String(meal.id))?.dinner_price}
                                            label={"晚餐費用 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.mealcostcalculations?.find(it => it.id === meal.id)?.dinner_price === ''
                                                    ? ''
                                                    : editData?.costcalculation?.mealcostcalculations?.find(it => it.id === meal.id)?.dinner_price || parseFloat(meal?.dinner_price).toString()
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "mealcostcalculations", "dinner_price", e.target.value, meal.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.costcalculation?.newMealCostCalculations?.map((meal, index) => (
                            <div key={meal.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newMealCostCalculation', meal)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.costcalculation?.mealcostcalculations?.find(error => error.identifier === meal.identifier)?.lunch_price}
                                            helperText={errors?.costcalculation?.mealcostcalculations?.find(error => error.identifier === meal.identifier)?.lunch_price}
                                            label={"新增午餐費用 #" + (index + 1)}
                                            variant="standard"
                                            value={meal.lunch_price}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newMealCostCalculations", "lunch_price", e.target.value, meal.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.costcalculation?.mealcostcalculations?.find(error => error.identifier === meal.identifier)?.dinner_price}
                                            helperText={errors?.costcalculation?.mealcostcalculations?.find(error => error.identifier === meal.identifier)?.dinner_price}
                                            label={"新增晚餐費用 #" + (index + 1)}
                                            variant="standard"
                                            value={meal.dinner_price}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newMealCostCalculations", "dinner_price", e.target.value, meal.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.costcalculation?.mealcostcalculations?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="餐飲成本總計"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0)).toString()}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.costcalculation?.mealcostcalculations?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何餐飲成本的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {editMode && (
                            <Box className={styles['sub-add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewMealCostCalculation}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增餐飲成本資料
                                </Button>
                            </Box>
                        )}
                        
                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="景點成本" />
                        </Divider>
                        {data?.costcalculation?.attractionscostcalculations?.map((attraction, index) => (
                            <div key={attraction.id} className={styles['sub-box']}>
                                {!editMode && (
                                    <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('AttractionsCostCalculation', attraction)} />
                                )}
                                
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={10}>
                                        <TextField
                                            error={errors?.costcalculation?.attractionscostcalculations?.find(error => error.id === String(attraction.id))?.attraction}
                                            helperText={errors?.costcalculation?.attractionscostcalculations?.find(error => error.id === String(attraction.id))?.attraction}
                                            label={"景點名稱 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.attractionscostcalculations?.find(it => it.id === attraction.id)?.attraction === ''
                                                    ? ''
                                                    : editData?.costcalculation?.attractionscostcalculations?.find(it => it.id === attraction.id)?.attraction || attraction?.attraction
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "attractionscostcalculations", "attraction", e.target.value, attraction.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.costcalculation?.attractionscostcalculations?.find(error => error.id === String(attraction.id))?.price}
                                            helperText={errors?.costcalculation?.attractionscostcalculations?.find(error => error.id === String(attraction.id))?.price}
                                            label={"價格 #" + (index + 1)}
                                            variant="standard"
                                            value={
                                                editData?.costcalculation?.attractionscostcalculations?.find(it => it.id === attraction.id)?.price === ''
                                                    ? ''
                                                    : editData?.costcalculation?.attractionscostcalculations?.find(it => it.id === attraction.id)?.price || parseFloat(attraction?.price).toString()
                                            }
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "attractionscostcalculations", "price", e.target.value, attraction.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editData?.costcalculation?.newAttractionsCostCalculations?.map((attraction, index) => (
                            <div key={attraction.identifier} className={styles['sub-box']}>
                                <DeleteForever className={styles['delete-icon']} onClick={() => handleDeleteClick('newAttractionsCostCalculation', attraction)} />
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 0 8px  ' }}>
                                    <Grid item xs={10}>
                                        <TextField
                                            error={errors?.costcalculation?.attractionscostcalculations?.find(error => error.identifier === attraction.identifier)?.attraction}
                                            helperText={errors?.costcalculation?.attractionscostcalculations?.find(error => error.identifier === attraction.identifier)?.attraction}
                                            label={"新增景點名稱 #" + (index + 1)}
                                            variant="standard"
                                            value={attraction.attraction}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newAttractionsCostCalculations", "attraction", e.target.value, attraction.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.costcalculation?.attractionscostcalculations?.find(error => error.identifier === attraction.identifier)?.price}
                                            helperText={errors?.costcalculation?.attractionscostcalculations?.find(error => error.identifier === attraction.identifier)?.price}
                                            label={"新增價格 #" + (index + 1)}
                                            variant="standard"
                                            value={attraction.price}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => handleFieldChange("costcalculation", "newAttractionsCostCalculations", "price", e.target.value, attraction.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.costcalculation?.attractionscostcalculations?.length > 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={10} />
                                <Grid item xs={2}>
                                    <TextField
                                        label="景點成本總計"
                                        variant="standard"
                                        value={editMode ? '保存後計算' : parseFloat(data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0)).toString()}
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {!editMode && data?.costcalculation?.attractionscostcalculations?.length === 0 && (
                            <Box className={styles['sub-box']}>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '150px', height: '150px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>目前沒有任何景點成本的資料</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {editMode && (
                            <Box className={styles['sub-add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewAttractionsCostCalculation}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增景點成本資料
                                </Button>
                            </Box>
                        )}

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="團體共同成本" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.fare}
                                    helperText={errors?.costcalculation?.fare}
                                    label="車費"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('fare')
                                            ? editData.costcalculation.fare
                                            : parseFloat(data?.costcalculation?.fare).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, fare: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.fare_days}
                                    helperText={errors?.costcalculation?.fare_days}
                                    label="車費天數"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('fare_days')
                                            ? editData.costcalculation.fare_days
                                            : parseFloat(data?.costcalculation?.fare_days).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, fare_days: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="車費總額"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.fare && editData?.costcalculation?.fare_days
                                            ? (Number(editData.costcalculation.fare) * Number(editData.costcalculation.fare_days)).toString()
                                            : data?.costcalculation?.fare && data?.costcalculation?.fare_days
                                            ? (Number(data.costcalculation.fare) * Number(data.costcalculation.fare_days)).toString()
                                            : ""
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.highway_fee}
                                    helperText={errors?.costcalculation?.highway_fee}
                                    label="高速過路費"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('highway_fee')
                                            ? editData.costcalculation.highway_fee
                                            : parseFloat(data?.costcalculation?.highway_fee).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, highway_fee: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.highway_fee_days}
                                    helperText={errors?.costcalculation?.highway_fee_days}
                                    label="高速過路費天數"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('highway_fee_days')
                                            ? editData.costcalculation.highway_fee_days
                                            : parseFloat(data?.costcalculation?.highway_fee_days).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, highway_fee_days: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="高速過路費總額"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.highway_fee && editData?.costcalculation?.highway_fee_days
                                            ? (Number(editData.costcalculation.highway_fee) * Number(editData.costcalculation.highway_fee_days)).toString()
                                            : data?.costcalculation?.highway_fee && data?.costcalculation?.highway_fee_days
                                            ? (Number(data.costcalculation.highway_fee) * Number(data.costcalculation.highway_fee_days)).toString()
                                            : ""
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.tour_guild_accommodation}
                                    helperText={errors?.costcalculation?.tour_guild_accommodation}
                                    label="導遊住宿費"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('tour_guild_accommodation')
                                            ? editData.costcalculation.tour_guild_accommodation
                                            : parseFloat(data?.costcalculation?.tour_guild_accommodation).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, tour_guild_accommodation: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.tour_guild_accommodation_days}
                                    helperText={errors?.costcalculation?.tour_guild_accommodation_days}
                                    label="導遊住宿費天數"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('tour_guild_accommodation_days')
                                            ? editData.costcalculation.tour_guild_accommodation_days
                                            : parseFloat(data?.costcalculation?.tour_guild_accommodation_days).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, tour_guild_accommodation_days: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="導遊住宿費總額"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.tour_guild_accommodation && editData?.costcalculation?.tour_guild_accommodation_days
                                            ? (Number(editData.costcalculation.tour_guild_accommodation) * Number(editData.costcalculation.tour_guild_accommodation_days)).toString()
                                            : data?.costcalculation?.tour_guild_accommodation && data?.costcalculation?.tour_guild_accommodation_days
                                            ? (Number(data.costcalculation.tour_guild_accommodation) * Number(data.costcalculation.tour_guild_accommodation_days)).toString()
                                            : ""
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.tour_guild_meal}
                                    helperText={errors?.costcalculation?.tour_guild_meal}
                                    label="導遊餐飲費"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('tour_guild_meal')
                                            ? editData.costcalculation.tour_guild_meal
                                            : parseFloat(data?.costcalculation?.tour_guild_meal).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, tour_guild_meal: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.tour_guild_meal_days}
                                    helperText={errors?.costcalculation?.tour_guild_meal_days}
                                    label="導遊餐飲費天數"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('tour_guild_meal_days')
                                            ? editData.costcalculation.tour_guild_meal_days
                                            : parseFloat(data?.costcalculation?.tour_guild_meal_days).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, tour_guild_meal_days: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="導遊餐飲費總額"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.tour_guild_meal && editData?.costcalculation?.tour_guild_meal_days
                                            ? (Number(editData.costcalculation.tour_guild_meal) * Number(editData.costcalculation.tour_guild_meal_days)).toString()
                                            : data?.costcalculation?.tour_guild_meal && data?.costcalculation?.tour_guild_meal_days
                                            ? (Number(data.costcalculation.tour_guild_meal) * Number(data.costcalculation.tour_guild_meal_days)).toString()
                                            : ""
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.leader_meal}
                                    helperText={errors?.costcalculation?.leader_meal}
                                    label="領隊餐飲費"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('leader_meal')
                                            ? editData.costcalculation.leader_meal
                                            : parseFloat(data?.costcalculation?.leader_meal).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, leader_meal: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.leader_meal_days}
                                    helperText={errors?.costcalculation?.leader_meal_days}
                                    label="領隊餐飲費天數"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('leader_meal_days')
                                            ? editData.costcalculation.leader_meal_days
                                            : parseFloat(data?.costcalculation?.leader_meal_days).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, leader_meal_days: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="領隊餐飲費總額"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.leader_meal && editData?.costcalculation?.leader_meal_days
                                            ? (Number(editData.costcalculation.leader_meal) * Number(editData.costcalculation.leader_meal_days)).toString()
                                            : data?.costcalculation?.leader_meal && data?.costcalculation?.leader_meal_days
                                            ? (Number(data.costcalculation.leader_meal) * Number(data.costcalculation.leader_meal_days)).toString()
                                            : ""
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.handling_fee}
                                    helperText={errors?.costcalculation?.handling_fee}
                                    label="手續費"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('handling_fee')
                                            ? editData.costcalculation.handling_fee
                                            : parseFloat(data?.costcalculation?.handling_fee).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, handling_fee: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.costcalculation?.handling_fee_days}
                                    helperText={errors?.costcalculation?.handling_fee_days}
                                    label="手續費天數"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('handling_fee_days')
                                            ? editData.costcalculation.handling_fee_days
                                            : parseFloat(data?.costcalculation?.handling_fee_days).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, handling_fee_days: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="手續費總額"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.handling_fee && editData?.costcalculation?.handling_fee_days
                                            ? (Number(editData.costcalculation.handling_fee) * Number(editData.costcalculation.handling_fee_days)).toString()
                                            : data?.costcalculation?.handling_fee && data?.costcalculation?.handling_fee_days
                                            ? (Number(data.costcalculation.handling_fee) * Number(data.costcalculation.handling_fee_days)).toString()
                                            : ""
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="共同費用總合計"
                                    variant="standard"
                                    value={
                                        (
                                            Number(editData?.costcalculation?.fare || data?.costcalculation?.fare || 0) * Number(editData?.costcalculation?.fare_days || data?.costcalculation?.fare_days || 0) +
                                            Number(editData?.costcalculation?.highway_fee || data?.costcalculation?.highway_fee || 0) * Number(editData?.costcalculation?.highway_fee_days || data?.costcalculation?.highway_fee_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_accommodation || data?.costcalculation?.tour_guild_accommodation || 0) * Number(editData?.costcalculation?.tour_guild_accommodation_days || data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_meal || data?.costcalculation?.tour_guild_meal || 0) * Number(editData?.costcalculation?.tour_guild_meal_days || data?.costcalculation?.tour_guild_meal_days || 0) +
                                            Number(editData?.costcalculation?.leader_meal || data?.costcalculation?.leader_meal || 0) * Number(editData?.costcalculation?.leader_meal_days || data?.costcalculation?.leader_meal_days || 0) +
                                            Number(editData?.costcalculation?.handling_fee || data?.costcalculation?.handling_fee || 0) * Number(editData?.costcalculation?.handling_fee_days || data?.costcalculation?.handling_fee_days || 0)
                                        ).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.costcalculation?.num_people1}
                                    helperText={errors?.costcalculation?.num_people1}
                                    label="人數(一)"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('num_people1')
                                            ? editData.costcalculation.num_people1
                                            : data?.costcalculation?.num_people1 || 1
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, num_people1: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="人數(一)分攤費用"
                                    variant="standard"
                                    value={
                                        ((
                                            Number(editData?.costcalculation?.fare || data?.costcalculation?.fare || 0) * Number(editData?.costcalculation?.fare_days || data?.costcalculation?.fare_days || 0) +
                                            Number(editData?.costcalculation?.highway_fee || data?.costcalculation?.highway_fee || 0) * Number(editData?.costcalculation?.highway_fee_days || data?.costcalculation?.highway_fee_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_accommodation || data?.costcalculation?.tour_guild_accommodation || 0) * Number(editData?.costcalculation?.tour_guild_accommodation_days || data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_meal || data?.costcalculation?.tour_guild_meal || 0) * Number(editData?.costcalculation?.tour_guild_meal_days || data?.costcalculation?.tour_guild_meal_days || 0) +
                                            Number(editData?.costcalculation?.leader_meal || data?.costcalculation?.leader_meal || 0) * Number(editData?.costcalculation?.leader_meal_days || data?.costcalculation?.leader_meal_days || 0) +
                                            Number(editData?.costcalculation?.handling_fee || data?.costcalculation?.handling_fee || 0) * Number(editData?.costcalculation?.handling_fee_days || data?.costcalculation?.handling_fee_days || 0)
                                        ) / (editData?.costcalculation?.num_people1 || data?.costcalculation?.num_people1 || 1)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.costcalculation?.num_people2}
                                    helperText={errors?.costcalculation?.num_people2}
                                    label="人數(二)"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('num_people2')
                                            ? editData.costcalculation.num_people2
                                            : data?.costcalculation?.num_people2 || 1
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, num_people2: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="人數(二)分攤費用"
                                    variant="standard"
                                    value={
                                        ((
                                            Number(editData?.costcalculation?.fare || data?.costcalculation?.fare || 0) * Number(editData?.costcalculation?.fare_days || data?.costcalculation?.fare_days || 0) +
                                            Number(editData?.costcalculation?.highway_fee || data?.costcalculation?.highway_fee || 0) * Number(editData?.costcalculation?.highway_fee_days || data?.costcalculation?.highway_fee_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_accommodation || data?.costcalculation?.tour_guild_accommodation || 0) * Number(editData?.costcalculation?.tour_guild_accommodation_days || data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_meal || data?.costcalculation?.tour_guild_meal || 0) * Number(editData?.costcalculation?.tour_guild_meal_days || data?.costcalculation?.tour_guild_meal_days || 0) +
                                            Number(editData?.costcalculation?.leader_meal || data?.costcalculation?.leader_meal || 0) * Number(editData?.costcalculation?.leader_meal_days || data?.costcalculation?.leader_meal_days || 0) +
                                            Number(editData?.costcalculation?.handling_fee || data?.costcalculation?.handling_fee || 0) * Number(editData?.costcalculation?.handling_fee_days || data?.costcalculation?.handling_fee_days || 0) 
                                        ) / (editData?.costcalculation?.num_people2 || data?.costcalculation?.num_people2 || 1)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.costcalculation?.num_people3}
                                    helperText={errors?.costcalculation?.num_people3}
                                    label="人數(三)"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('num_people3')
                                            ? editData.costcalculation.num_people3
                                            : data?.costcalculation?.num_people3 || 1
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, num_people3: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="人數(三)分攤費用"
                                    variant="standard"
                                    value={
                                        ((
                                            Number(editData?.costcalculation?.fare || data?.costcalculation?.fare || 0) * Number(editData?.costcalculation?.fare_days || data?.costcalculation?.fare_days || 0) +
                                            Number(editData?.costcalculation?.highway_fee || data?.costcalculation?.highway_fee || 0) * Number(editData?.costcalculation?.highway_fee_days || data?.costcalculation?.highway_fee_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_accommodation || data?.costcalculation?.tour_guild_accommodation || 0) * Number(editData?.costcalculation?.tour_guild_accommodation_days || data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                            Number(editData?.costcalculation?.tour_guild_meal || data?.costcalculation?.tour_guild_meal || 0) * Number(editData?.costcalculation?.tour_guild_meal_days || data?.costcalculation?.tour_guild_meal_days || 0) +
                                            Number(editData?.costcalculation?.leader_meal || data?.costcalculation?.leader_meal || 0) * Number(editData?.costcalculation?.leader_meal_days || data?.costcalculation?.leader_meal_days || 0) +
                                            Number(editData?.costcalculation?.handling_fee || data?.costcalculation?.handling_fee || 0) * Number(editData?.costcalculation?.handling_fee_days || data?.costcalculation?.handling_fee_days || 0)
                                        ) / (editData?.costcalculation?.num_people3 || data?.costcalculation?.num_people3 || 1)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="日本地區團費" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(一)團體費用"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            (
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people1 || 1)
                                            ).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(二)團體費用"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            (
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people2 || 1)
                                            ).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(三)團體費用"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            (
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people3 || 1)
                                            ).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="台幣其他成本" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={2.4}>
                                <TextField
                                    error={errors?.costcalculation?.flight_ticket}
                                    helperText={errors?.costcalculation?.flight_ticket}
                                    label="機票費用"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('flight_ticket')
                                            ? editData.costcalculation.flight_ticket
                                            : parseFloat(data?.costcalculation?.flight_ticket).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, flight_ticket: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <TextField
                                    error={errors?.costcalculation?.tax}
                                    helperText={errors?.costcalculation?.tax}
                                    label="稅金"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('tax')
                                            ? editData.costcalculation.tax
                                            : parseFloat(data?.costcalculation?.tax).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, tax: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <TextField
                                    error={errors?.costcalculation?.insurance}
                                    helperText={errors?.costcalculation?.insurance}
                                    label="保險"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('insurance')
                                            ? editData.costcalculation.insurance
                                            : parseFloat(data?.costcalculation?.insurance).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, insurance: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <TextField
                                    error={errors?.costcalculation?.gross_profit}
                                    helperText={errors?.costcalculation?.gross_profit}
                                    label="毛利"
                                    variant="standard"
                                    value={
                                        editData?.costcalculation?.hasOwnProperty('gross_profit')
                                            ? editData.costcalculation.gross_profit
                                            : parseFloat(data?.costcalculation?.gross_profit).toString() || ''
                                    }
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, costcalculation: { ...editData?.costcalculation, gross_profit: e.target.value === '' ? null : e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <TextField
                                    label="台幣其他成本小計"
                                    variant="standard"
                                    value={
                                        (
                                            Number(editData?.costcalculation?.flight_ticket || data?.costcalculation?.flight_ticket || 0) +
                                            Number(editData?.costcalculation?.tax || data?.costcalculation?.tax || 0) +
                                            Number(editData?.costcalculation?.insurance || data?.costcalculation?.insurance || 0) +
                                            Number(editData?.costcalculation?.gross_profit || data?.costcalculation?.gross_profit || 0)
                                        ).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="台幣總團費(未含、機票、稅金、保險、毛利)" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(一)台幣成本金額"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            ((
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people1 || 1)
                                            ) * Number(data?.costcalculation?.jpy_exchange_rate)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(二)台幣成本金額"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            ((
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people2 || 1)
                                            ) * Number(data?.costcalculation?.jpy_exchange_rate)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(三)台幣成本金額"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            ((
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people3 || 1)
                                            ) * Number(data?.costcalculation?.jpy_exchange_rate)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="台幣團費全含" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(一)台幣售價金額"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            ((
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people1 || 1)
                                            ) * Number(data?.costcalculation?.jpy_exchange_rate) +
                                            Number(data?.costcalculation?.flight_ticket || 0) +
                                            Number(data?.costcalculation?.tax || 0) +
                                            Number(data?.costcalculation?.insurance || 0) +
                                            Number(data?.costcalculation?.gross_profit || 0)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(二)台幣售價金額"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            ((
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people2 || 1)
                                            ) * Number(data?.costcalculation?.jpy_exchange_rate) +
                                            Number(data?.costcalculation?.flight_ticket || 0) +
                                            Number(data?.costcalculation?.tax || 0) +
                                            Number(data?.costcalculation?.insurance || 0) +
                                            Number(data?.costcalculation?.gross_profit || 0)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="人數(三)台幣售價金額"
                                    variant="standard"
                                    value={
                                        editMode ? '保存後計算' :
                                            ((
                                                data?.costcalculation?.hotelcostcalculations?.reduce((acc, hotel) => acc + Number(hotel.price) * Number(hotel.days), 0) +
                                                data?.costcalculation?.mealcostcalculations?.reduce((acc, meal) => acc + Number(meal.lunch_price) + Number(meal.dinner_price), 0) +
                                                data?.costcalculation?.attractionscostcalculations?.reduce((acc, attraction) => acc + Number(attraction.price), 0) +
                                                (
                                                    Number(data?.costcalculation?.fare || 0) * Number(data?.costcalculation?.fare_days || 0) +
                                                    Number(data?.costcalculation?.highway_fee || 0) * Number(data?.costcalculation?.highway_fee_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_accommodation || 0) * Number(data?.costcalculation?.tour_guild_accommodation_days || 0) +
                                                    Number(data?.costcalculation?.tour_guild_meal || 0) * Number(data?.costcalculation?.tour_guild_meal_days || 0) +
                                                    Number(data?.costcalculation?.leader_meal || 0) * Number(data?.costcalculation?.leader_meal_days || 0) +
                                                    Number(data?.costcalculation?.handling_fee || 0) * Number(data?.costcalculation?.handling_fee_days || 0)
                                                ) / (data?.costcalculation?.num_people3 || 1)
                                            ) * Number(data?.costcalculation?.jpy_exchange_rate) +
                                            Number(data?.costcalculation?.flight_ticket || 0) +
                                            Number(data?.costcalculation?.tax || 0) +
                                            Number(data?.costcalculation?.insurance || 0) +
                                            Number(data?.costcalculation?.gross_profit || 0)).toString()
                                    }
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box />

                    </Box>
                )}

                {!data && (
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {loading ? (
                            <CircularProgress color="info" />
                        ) : (
                            <>
                                <NoDataImg style={{ width: '300px', height: '300px' }} />
                                無法取得訂單資料或發生錯誤: {errors?.detail}
                            </>
                        )}
                    </Box>
                )}
                
            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Box className={styles['action-box']}>
                {editMode ? (
                    <>
                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                            取消
                        </Button>
                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                            保存
                        </Button>
                    </>
                ) : (
                    <Button className={styles['edit-button']} onClick={handleEditClick}>
                        <EditNote sx={{ width: '22px', height: '22px' }} />
                        編輯
                    </Button>
                )}
            </Box>

            <Dialog disableEscapeKeyDown open={deleteCheckOpen} onClose={handleDeletCancelClick} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {deleteItem?.deleteType === 'HotelCostCalculation' && (
                            <div>
                                <div>地區：{deleteItem?.area || '未設置'}</div>
                                <div>飯店資訊：{deleteItem?.hotel_info || '未設置'}</div>
                                <div>價格：{deleteItem?.price ? parseFloat(deleteItem?.price).toString() : '未設置'}</div>
                                <div>天數：{deleteItem?.days || '未設置'}</div>
                                <span style={{ color: 'red' }}>將刪除以上資料，確認要繼續嗎？</span>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newHotelCostCalculation' && (
                            <div>
                                <div>地區：{deleteItem?.area || '未設置'}</div>
                                <div>飯店資訊：{deleteItem?.hotel_info || '未設置'}</div>
                                <div>價格：{deleteItem?.price ? parseFloat(deleteItem?.price).toString() : '未設置'}</div>
                                <div>天數：{deleteItem?.days || '未設置'}</div>
                                <span style={{ color: 'red' }}>將刪除以上資料，確認要繼續嗎？</span>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'MealCostCalculation' && (
                            <div>
                                <div>午餐價格：{deleteItem?.lunch_price ? parseFloat(deleteItem?.lunch_price).toString() : '未設置'}</div>
                                <div>晚餐價格：{deleteItem?.dinner_price ? parseFloat(deleteItem?.dinner_price).toString() : '未設置'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newMealCostCalculation' && (
                            <div>
                                <div>午餐價格：{deleteItem?.lunch_price ? parseFloat(deleteItem?.lunch_price).toString() : '未設置'}</div>
                                <div>晚餐價格：{deleteItem?.dinner_price ? parseFloat(deleteItem?.dinner_price).toString() : '未設置'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'AttractionsCostCalculation' && (
                            <div>
                                <div>景點名稱：{deleteItem?.name || '未設置'}</div>
                                <div>價格：{deleteItem?.price ? parseFloat(deleteItem?.price).toString() : '未設置'}</div>
                            </div>
                        )}
                        {deleteItem?.deleteType === 'newAttractionsCostCalculation' && (
                            <div>
                                <div>景點名稱：{deleteItem?.name || '未設置'}</div>
                                <div>價格：{deleteItem?.price ? parseFloat(deleteItem?.price).toString() : '未設置'}</div>
                            </div>
                        )}
                        <br />此操作無法復原，請謹慎處理。
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['delete-cancel-button']} onClick={handleDeletCancelClick}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleDeleteConfirmClick}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>
            
        </Box>
    );
}

export default CostcalculationTab;