import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import styles from './CustomNavLink.module.css';

const CustomNavLink = ({ to, children, activeClassName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasUnsavedChanges } = useUnsavedChanges();

  const handleNavigationAttempt = (e) => {
    if (hasUnsavedChanges && !window.confirm('偵測到有未保存的更改，確定要離開此頁面嗎？')) {
      e.preventDefault();
      return;
    }
    navigate(to);
  };

  const isActive = location.pathname === to;

  return (
    <div
      href={to} 
      onClick={handleNavigationAttempt} 
      className={isActive ? `${styles[activeClassName]}` : ''}
    >
      {children}
    </div>
  );
};

export default CustomNavLink;
