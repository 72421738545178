import React, { useEffect, useState } from 'react';
import api from '../../config/axiosConfig';
import styles from './css/OrdersTab.module.css';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';

const Orders = ({ employee, OrderInfo }) => {
    const [orders, setOrders] = useState({});

    useEffect(() => {
        fetchOrders();
        // eslint-disable-next-line
    }, []);
    
    const fetchOrders = async () => {
        const ordersResponse = await api.get(`/japan/api/sales-order/?sales=${employee?.employee_account?.id}`);
        setOrders(ordersResponse.data);
        handleInfoCardStates(ordersResponse.data);
    }
    
    const handleInfoCardStates = (order) => {
        const orderCount = order.orders.length;
        const completed = order.orders.filter(order => order.order_status === '尾款已付' || order.order_status === '全額付款').length;
        const canceled = order.orders.filter(order => order.order_status === '取消').length;
        const orderCompletionRate = (completed / (completed + canceled) * 100).toFixed(2);
        
        OrderInfo(orderCount, orderCompletionRate);
    }

    return (
        <Box className={styles['main-box']}>
            <Box className={styles['head-box']}>
                <Grid container spacing={1}>
                    <Grid item xs={2.4}>
                        <Typography className={styles['head-title']}>團號</Typography>
                    </Grid>
                    <Grid item xs={2.4}>
                        <Typography className={styles['head-title']}>訂單狀態</Typography>
                    </Grid>
                    <Grid item xs={2.4}>
                        <Typography className={styles['head-title']}>客戶名稱</Typography>
                    </Grid>
                    <Grid item xs={2.4}>
                        <Typography className={styles['head-title']}>出發日期</Typography>
                    </Grid>
                    <Grid item xs={2.4}>
                        <Typography className={styles['head-title']}>出發日期</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className={styles['orders-box']}>
                <Grid container>
                    {orders?.orders?.map(order => (
                        <Grid item xs={12} key={order.uuid}>
                            <Grid container spacing={1} className={styles['orders-grid']}>
                                <Grid item xs={2.4}>
                                    <Typography className={styles['orders-info']}>
                                        <Link className={styles['order-link']} href={`/Japan/order-information/${order.uuid}`} target="_blank" rel="noopener" underline='none'>
                                            {order.group_number}
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                    <Typography className={styles['orders-info']}>{order.order_status}</Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                    <Typography className={styles['orders-info']}>{order.customer_name || order?.peers_sales__peers_company__peers_company_name + ' - ' + order?.peers_sales__peers_sales_name}</Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                    <Typography className={styles['orders-info']}>{order.travel_start_date}</Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                    <Typography className={styles['orders-info']}>{order.travel_end_date}</Typography>
                                </Grid>
                            </Grid>
                            <Divider variant='middle' />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Orders;