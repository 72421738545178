import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import api from '../../config/axiosConfig';
import { Autocomplete, Button, Container, Typography, TextField, Tabs, Tab, Box, Divider, Grid,
    Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Card,
    CardContent, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Collapse, FormControl, RadioGroup, FormControlLabel, Radio, Chip, Avatar, Badge
} from '@mui/material';
import { AccountCircle, Add, NoteAdd, Edit, Delete, DoDisturbOn, Groups, Warning, Policy, DirectionsCar,
    Business, ExpandLess, ExpandMore, AccountBalance
} from '@mui/icons-material';
import styles from './css/Manage.module.css';

function Manage() {
    const [user, setUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editingUser, setEditingUser] = useState(false);
    const [addingUser, setAddingUser] = useState(false);
    const [newUser, setNewUser] = useState({});
    const [group, setGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [editingGroup, setEditingGroup] = useState(false);
    const [addingGroup, setAddingGroup] = useState(false);
    const [newGroup, setNewGroup] = useState({});
    const [warningTypes, setWarningTypes] = useState([]);
    const [selectedWarningType, setSelectedWarningType] = useState(null);
    const [editingWarningType, setEditingWarningType] = useState(false);
    const [addingWarningType, setAddingWarningType] = useState(false);
    const [newWarningType, setNewWarningType] = useState({});
    const [newWarning, setNewWarning] = useState([]);
    const [policyTypes, setPolicyTypes] = useState([]);
    const [selectedPolicyType, setSelectedPolicyType] = useState(null);
    const [editingPolicyType, setEditingPolicyType] = useState(false);
    const [addingPolicyType, setAddingPolicyType] = useState(false);
    const [newPolicyType, setNewPolicyType] = useState({});
    const [newPolicy, setNewPolicy] = useState([]);
    const [remittanceInfo, setRemittanceInfo] = useState([]);
    const [selectedRemittanceInfo, setSelectedRemittanceInfo] = useState(null);
    const [editingRemittanceInfo, setEditingRemittanceInfo] = useState(false);
    const [addingRemittanceInfo, setAddingRemittanceInfo] = useState(false);
    const [newRemittanceInfoType, setNewRemittanceInfoType] = useState('domestic');
    const [newRemittanceInfo, setNewRemittanceInfo] = useState({});
    const [peersSales, setPeersSales] = useState([]);
    const [selectedPeersSale, setSelectedPeersSale] = useState(null);
    const [editingPeersSale, setEditingPeersSale] = useState(false);
    const [addingPeersSale, setAddingPeersSale] = useState(false);
    const [newPeersSale, setNewPeersSale] = useState({});
    const [peersCompanies, setPeersCompanies] = useState([]);
    const [selectedPeersCompany, setSelectedPeersCompany] = useState(null);
    const [editingPeersCompany, setEditingPeersCompany] = useState(false);
    const [addingPeersCompany, setAddingPeersCompany] = useState(false);
    const [newPeersCompany, setNewPeersCompany] = useState({});
    const [vehicle, setVehicle] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [editingVehicle, setEditingVehicle] = useState(false);
    const [addingVehicle, setAddingVehicle] = useState(false);
    const [newVehicle, setNewVehicle] = useState({});
    const [charterCompanies, setCharterCompanies] = useState([]);
    const [selectedCharterCompany, setSelectedCharterCompany] = useState(null);
    const [editingCharterCompany, setEditingCharterCompany] = useState(false);
    const [addingCharterCompany, setAddingCharterCompany] = useState(false);
    const [newCharterCompany, setNewCharterCompany] = useState({});
    const [manageOptions, setManageOptions] = useState({
        peers_sales_communication_app: []
    });
    const [baseDataPicker, setBaseDataPicker] = useState('peersSale');
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [deleteCheckInfo, setDeleteCheckInfo] = useState({ type: '', data: null, index: null});
    const [expandedRow, setExpandedRow] = useState(null);
    const [status, setStatus] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            try {
                document.title = '日本線管理頁面';

                const userResponse = await api.get('/core/api/User/');
                setUser(userResponse.data);

                const groupResponse = await api.get('/core/api/Group/');
                setGroup(groupResponse.data);

                const peersSalesResponse = await api.get('/japan/api/Peers_Sales/');
                setPeersSales(peersSalesResponse.data);

                const peersCompanyResponse = await api.get('/japan/api/Peers_Company/');
                setPeersCompanies(peersCompanyResponse.data);

                const vehicleResponse = await api.get('/japan/api/Vehicle_types/');
                setVehicle(vehicleResponse.data);
                
                const charterCompanyResponse = await api.get('/japan/api/Charter_Company/');
                setCharterCompanies(charterCompanyResponse.data);

                const warningTypeResponse = await api.get('/japan/api/Order_Warning_Type/');
                setWarningTypes(warningTypeResponse.data);

                const policyTypeResponse = await api.get('/japan/api/Order_Policy_Type/');
                setPolicyTypes(policyTypeResponse.data);

                const remittanceInfoResponse = await api.get('/japan/api/Remittance_Info/');
                setRemittanceInfo(remittanceInfoResponse.data);

                const manageOptionsResponse = await api.get('/japan/api/manage-options/');
                setManageOptions({
                    peers_sales_communication_app: manageOptionsResponse.data.peersSales.peers_sales_communication_app
                });

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [setHasUnsavedChanges]);

    useEffect(() => {
        const tabHashes = ['User', 'Group', 'Remittance', 'Warning', 'Policy', 'BaseData', 'PeersCompany', 'Vehicle', 'CharterCompany'];
        const hash = location.hash;
      
        const index = hash ? tabHashes.indexOf(hash.replace('#', '')) : 0;
        
        if (index === -1 || !hash) {
          navigate(`#User`, { replace: true });
        } else {
          setTabValue(index);
        }
    
        const tabtitles = ['使用者帳號', '權限群組', '匯款資訊', '注意事項', '取消政策', '基本資料', '同行公司', '車輛信息', '包車公司'];
        document.title = `日本線管理頁面 - ${tabtitles[index]}`;
        
    }, [location, navigate]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAddUser = () => {
        setAddingUser(true);
        setHasUnsavedChanges(true);
    };

    const handleAddGroup = () => {
        setAddingGroup(true);
        setHasUnsavedChanges(true);
    };

    const handleAddWarningType = () => {
        setAddingWarningType(true);
        setHasUnsavedChanges(true);
    };

    const handleAddNewWarning = () => {
        setNewWarning([...newWarning, {
            warning_note: '',
        }]);
        setHasUnsavedChanges(true);
    };

    const handleAddPolicyType = () => {
        setAddingPolicyType(true);
        setHasUnsavedChanges(true);
    };

    const handleAddNewPolicy = () => {
        setNewPolicy([...newPolicy, {
            policy_note: '',
        }]);
        setHasUnsavedChanges(true);
    };

    const handleAddRemittanceInfo = () => {
        setAddingRemittanceInfo(true);
        setHasUnsavedChanges(true);
    };

    const handleAddPeersSale = () => {
        setAddingPeersSale(true);
        setHasUnsavedChanges(true);
    };

    const handleAddPeersCompany = () => {
        setAddingPeersCompany(true);
        setHasUnsavedChanges(true);
    };

    const handleAddVehicle = () => {
        setAddingVehicle(true);
        setHasUnsavedChanges(true);
    };

    const handleAddCharterCompany = () => {
        setAddingCharterCompany(true);
        setHasUnsavedChanges(true);
    };

    const handleAddUserConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const updateNewUser = { 
                ...newUser,
                password: 'defaultpassword',
                confirm_password: 'defaultpassword',
                profile: { 
                    ...newUser.profile,
                    account_type: "日本線"
                }
            };
            const response = await api.post('/core/api/User/', updateNewUser);
            setAddingUser(false);
            setStatus('add-newUser-success');
            setUser([...user, response.data]);
            setNewUser({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newUser-error');
        }
    };

    const handleAddGroupConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            newGroup.info = { ...newGroup.info, group_type: "日本線" };
            const response = await api.post('/core/api/Group/', newGroup);
            setAddingGroup(false);
            setStatus('add-newGroup-success');
            setGroup([...group, response.data]);
            setNewGroup({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newGroup-error');
        }
    };

    const handleAddWarningTypeConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const response = await api.post('/japan/api/Order_Warning_Type/', newWarningType);
            setAddingWarningType(false);
            setStatus('add-newWarningType-success');
            setWarningTypes([...warningTypes, response.data]);
            setNewWarningType({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newWarningType-error');
        }
    };

    const handleAddPolicyTypeConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const response = await api.post('/japan/api/Order_Policy_Type/', newPolicyType);
            setAddingPolicyType(false);
            setStatus('add-newPolicyType-success');
            setPolicyTypes([...policyTypes, response.data]);
            setNewPolicyType({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newPolicyType-error');
        }
    };

    const handleAddRemittanceInfoConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        if (newRemittanceInfoType === 'domestic') {
            if (!newRemittanceInfo.remittance_name) {
                setErrors({ remittance_name: '請輸入匯款戶名' });
                return;
            }
            if (!newRemittanceInfo.remittance_account) {
                setErrors({ remittance_account: '請輸入匯款帳號' });
                return;
            }
            if (!newRemittanceInfo.bank_code) {
                setErrors({ remittance_bank: '請輸入銀行代碼' });
                return;
            }
        } else if (newRemittanceInfoType === 'overseas') {
            if (!newRemittanceInfo.remittance_name) {
                setErrors({ remittance_name: '請輸入識別名稱' });
                return;
            }
            if (!newRemittanceInfo.special_remit_info) {
                setErrors({ special_remit_info: '請輸入完整的匯款資訊' });
                return;
            }
        }

        try {
            const response = await api.post('/japan/api/Remittance_Info/', newRemittanceInfo);
            setAddingRemittanceInfo(false);
            setStatus('add-newRemittanceInfo-success');
            setRemittanceInfo([...remittanceInfo, response.data]);
            setNewRemittanceInfo({});
            setNewRemittanceInfoType('domestic');
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newRemittanceInfo-error');
        }
    };

    const handleAddPeersSaleConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const response = await api.post('/japan/api/Peers_Sales/', newPeersSale);
            setAddingPeersSale(false);
            setStatus('add-newPeersSale-success');
            setPeersSales([...peersSales, response.data]);
            setNewPeersSale({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newPeersSale-error');
        }
    };

    const handleAddPeersCompanyConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const newGroup = { name: newPeersCompany.peers_company_name, info: { introduction: `${newPeersCompany.peers_company_name} - 專用權限群組`, group_type: "日本線", tags: "同行公司" } };
            const response = await api.post('/japan/api/Peers_Company/', newPeersCompany);
            const groupResponse = await api.post('/core/api/Group/', newGroup);
            setAddingPeersCompany(false);
            setStatus('add-newPeersCompany-success');
            setPeersCompanies([...peersCompanies, response.data]);
            setGroup([...group, groupResponse.data]);
            setNewPeersCompany({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newPeersCompany-error');
        }
    };

    const handleAddVehicleConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const response = await api.post('/japan/api/Vehicle_types/', newVehicle);
            setAddingVehicle(false);
            setStatus('add-newVehicle-success');
            setVehicle([...vehicle, response.data]);
            setNewVehicle({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newVehicle-error');
        }
    };

    const handleAddCharterCompanyConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            const newGroup = { name: newCharterCompany.company_name, info: { introduction: `${newCharterCompany.company_name} - 專用權限群組`, group_type: "日本線", tags: "包車公司" } };
            const response = await api.post('/japan/api/Charter_Company/', newCharterCompany);
            const groupResponse = await api.post('/core/api/Group/', newGroup);
            setAddingCharterCompany(false);
            setStatus('add-newCharterCompany-success');
            setCharterCompanies([...charterCompanies, response.data]);
            setGroup([...group, groupResponse.data]);
            setNewCharterCompany({});
            setErrors({});
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('add-newCharterCompany-error');
        }
    };

    const handleEditUser = (user) => {
        setErrors({});
        setSelectedUser(user);
        setEditingUser(true);
        setHasUnsavedChanges(true);
    };

    const handleEditGroup = (group) => {
        setErrors({});
        setSelectedGroup(group);
        setEditingGroup(true);
        setHasUnsavedChanges(true);
    };

    const handleEditWarningType = (warningType) => {
        setErrors({});
        setSelectedWarningType(warningType);
        setEditingWarningType(true);
        setHasUnsavedChanges(true);
    };

    const handleEditPolicyType = (policyType) => {
        setErrors({});
        setSelectedPolicyType(policyType);
        setEditingPolicyType(true);
        setHasUnsavedChanges(true);
    };

    const handleEditRemittanceInfo = (remittanceInfo) => {
        setErrors({});
        setSelectedRemittanceInfo(remittanceInfo);
        setEditingRemittanceInfo(true);
        setHasUnsavedChanges(true);
    };

    const handleEditPeersSale = (peersSale) => {
        setErrors({});
        setSelectedPeersSale(peersSale);
        setEditingPeersSale(true);
        setHasUnsavedChanges(true);
    };

    const handleEditPeersCompany = (peersCompany) => {
        setErrors({});
        setSelectedPeersCompany(peersCompany);
        setEditingPeersCompany(true);
        setHasUnsavedChanges(true);
    };

    const handleEditVehicle = (vehicle) => {
        setErrors({});
        setSelectedVehicle(vehicle);
        setEditingVehicle(true);
        setHasUnsavedChanges(true);
    };

    const handleEditCharterCompany = (charterCompany) => {
        setErrors({});
        setSelectedCharterCompany(charterCompany);
        setEditingCharterCompany(true);
        setHasUnsavedChanges(true);
    };

    const handleDelete = (type, item=null, index=null) => {
        setDeleteCheckInfo({ type, data: item, index });
        setDeleteCheck(true);
    };

    const handleDeleteConfirm = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        try {
            switch (deleteCheckInfo.type) {
                case 'user':
                    await api.delete(`/core/api/User/${deleteCheckInfo.data.id}/`);
                    setUser(user.filter((u) => u.id !== deleteCheckInfo.data.id));
                    break;
                case 'group':
                    await api.delete(`/core/api/Group/${deleteCheckInfo.data.id}/`);
                    setGroup(group.filter((g) => g.id !== deleteCheckInfo.data.id));
                    break;
                case 'warningType':
                    await api.delete(`/japan/api/Order_Warning_Type/${deleteCheckInfo.data.id}/`);
                    setWarningTypes(warningTypes.filter((w) => w.id !== deleteCheckInfo.data.id));
                    break;
                case 'existWarning':
                    await api.delete(`/japan/api/Order_Warning/${deleteCheckInfo.data.id}/`);
                    setWarningTypes(warningTypes.map((warningType) => warningType.id === selectedWarningType.id ? { ...warningType, warnings: warningType.warnings.filter((w) => w.id !== deleteCheckInfo.data.id) } : warningType));
                    setSelectedWarningType({ ...selectedWarningType, warnings: selectedWarningType.warnings.filter((w) => w.id !== deleteCheckInfo.data.id) });
                    break;
                case 'newWarning':
                    setNewWarning(newWarning.filter((w, i) => i !== deleteCheckInfo.index));
                    break;
                case 'policyType':
                    await api.delete(`/japan/api/Order_Policy_Type/${deleteCheckInfo.data.id}/`);
                    setPolicyTypes(policyTypes.filter((p) => p.id !== deleteCheckInfo.data.id));
                    break;
                case 'existPolicy':
                    await api.delete(`/japan/api/Order_Policy/${deleteCheckInfo.data.id}/`);
                    setPolicyTypes(policyTypes.map((policyType) => policyType.id === selectedPolicyType.id ? { ...policyType, policies: policyType.policies.filter((p) => p.id !== deleteCheckInfo.data.id) } : policyType));
                    setSelectedPolicyType({ ...selectedPolicyType, policies: selectedPolicyType.policies.filter((p) => p.id !== deleteCheckInfo.data.id) });
                    break;
                case 'newPolicy':
                    setNewPolicy(newPolicy.filter((p, i) => i !== deleteCheckInfo.index));
                    break;
                case 'remittanceInfo':
                    await api.delete(`/japan/api/Remittance_Info/${deleteCheckInfo.data.id}/`);
                    setRemittanceInfo(remittanceInfo.filter((r) => r.id !== deleteCheckInfo.data.id));
                    break;
                case 'peersSale':
                    await api.delete(`/japan/api/Peers_Sales/${deleteCheckInfo.data.id}/`);
                    setPeersSales(peersSales.filter((p) => p.id !== deleteCheckInfo.data.id));
                    break;
                case 'peersCompany':
                    await api.delete(`/japan/api/Peers_Company/${deleteCheckInfo.data.id}/`);
                    setPeersCompanies(peersCompanies.filter((p) => p.id !== deleteCheckInfo.data.id));
                    break;
                case 'vehicle':
                    await api.delete(`/japan/api/Vehicle_types/${deleteCheckInfo.data.id}/`);
                    setVehicle(vehicle.filter((v) => v.id !== deleteCheckInfo.data.id));
                    break;
                case 'charterCompany':
                    await api.delete(`/japan/api/Charter_Company/${deleteCheckInfo.data.id}/`);
                    setCharterCompanies(charterCompanies.filter((c) => c.id !== deleteCheckInfo.data.id));
                    break;
                default:
                    break;
            }
        } catch (error) {
            setStatus('delete-error');
            setErrors(error.response?.data);
        }

        setDeleteCheck(false);
        setDeleteCheckInfo({ type: '', data: null, index: null });
    };

    const handleEditUserSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            delete selectedUser.groups;
            delete selectedUser.date_joined;
            delete selectedUser.last_login;
            delete selectedUser.profile.photo;
            delete selectedUser.profile.account_type;
            delete selectedUser.profile.tags;
            delete selectedUser.profile.is_online;
            const response = await api.patch(`/core/api/User/${selectedUser.id}/`, selectedUser);
            setEditingUser(false);
            setStatus('edit-user-success');
            setUser(user.map((user) => user.id === selectedUser.id ? response.data : user));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-user-error');
        }
    };

    const handleEditGroupSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            const response = await api.patch(`/core/api/Group/${selectedGroup.id}/`, selectedGroup);
            setEditingGroup(false);
            setStatus('edit-group-success');
            setGroup(group.map((group) => group.id === selectedGroup.id ? response.data : group));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-group-error');
        }
    };

    const handleEditWarningTypeSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            const existWarnings = selectedWarningType.warnings;
            const bulkWarnings = [
                ...existWarnings,
                ...newWarning
            ]
            const updateWarningType = { 
                ...selectedWarningType,
                warnings: bulkWarnings.map(warning => ({
                    ...warning,
                    warning_type: selectedWarningType.id
                }))
            };
            const response = await api.patch(`/japan/api/Order_Warning_Type/${selectedWarningType.id}/`, updateWarningType);
            setNewWarning([]);
            setEditingWarningType(false);
            setStatus('edit-warningType-success');
            setWarningTypes(warningTypes.map((warningType) => warningType.id === selectedWarningType.id ? response.data : warningType));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-warningType-error');
        }
    };

    const handleEditPolicyTypeSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);
        
        setErrors({});

        try {
            const existPolicies = selectedPolicyType.policies;
            const bulkPolicies = [
                ...existPolicies,
                ...newPolicy
            ]
            const updatePolicyType = {
                ...selectedPolicyType,
                policies: bulkPolicies.map(policy => ({
                    ...policy,
                    policy_type: selectedPolicyType.id
                }))
            };
            const response = await api.patch(`/japan/api/Order_Policy_Type/${selectedPolicyType.id}/`, updatePolicyType);
            setNewPolicy([]);
            setEditingPolicyType(false);
            setStatus('edit-policyType-success');
            setPolicyTypes(policyTypes.map((policyType) => policyType.id === selectedPolicyType.id ? response.data : policyType));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-policyType-error');
        }
    };

    const handleEditRemittanceInfoSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        if (!selectedRemittanceInfo.remittance_account && !selectedRemittanceInfo.bank_code) {
            if (!selectedRemittanceInfo.special_remit_info) {
                setErrors({ special_remit_info: '請輸入完整的匯款資訊' });
                return;
            }
        } else if (!selectedRemittanceInfo.remittance_account) {
            setErrors({ remittance_account: '請輸入匯款帳號' });
            return;
        } else if (!selectedRemittanceInfo.bank_code) {
            setErrors({ remittance_bank: '請輸入銀行代碼' });
            return;
        }

        try {
            const response = await api.patch(`/japan/api/Remittance_Info/${selectedRemittanceInfo.id}/`, selectedRemittanceInfo);
            setEditingRemittanceInfo(false);
            setStatus('edit-remittanceInfo-success');
            setRemittanceInfo(remittanceInfo.map((remittanceInfo) => remittanceInfo.id === selectedRemittanceInfo.id ? response.data : remittanceInfo));
            setHasUnsavedChanges(false);
            setErrors({});
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-remittanceInfo-error');
        }
    };

    const handleEditPeersSaleSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            delete selectedPeersSale.peers_company;
            const response = await api.patch(`/japan/api/Peers_Sales/${selectedPeersSale.id}/`, selectedPeersSale);
            setEditingPeersSale(false);
            setStatus('edit-peersSale-success');
            setPeersSales(peersSales.map((peersSale) => peersSale.id === selectedPeersSale.id ? response.data : peersSale));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-peersSale-error');
        }
    };

    const handleEditPeersCompanySave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            delete selectedPeersCompany.peers_sales;
            const response = await api.patch(`/japan/api/Peers_Company/${selectedPeersCompany.id}/`, selectedPeersCompany);
            setEditingPeersCompany(false);
            setStatus('edit-peersCompany-success');
            setPeersCompanies(peersCompanies.map((peersCompany) => peersCompany.id === selectedPeersCompany.id ? response.data : peersCompany));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-peersCompany-error');
        }
    };

    const handleEditVehicleSave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);

        setErrors({});

        try {
            const response = await api.patch(`/japan/api/Vehicle_types/${selectedVehicle.id}/`, selectedVehicle);
            setEditingVehicle(false);
            setStatus('edit-vehicle-success');
            setVehicle(vehicle.map((vehicle) => vehicle.id === selectedVehicle.id ? response.data : vehicle));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-vehicle-error');
        }
    };

    const handleEditCharterCompanySave = async () => {
        const now = new Date().getTime();
        if (now - lastClickTime < 1000) {
            return;
        }
        setLastClickTime(now);
        
        setErrors({});

        try {
            delete selectedCharterCompany.vehicles;
            const response = await api.patch(`/japan/api/Charter_Company/${selectedCharterCompany.id}/`, selectedCharterCompany);
            setEditingCharterCompany(false);
            setStatus('edit-charterCompany-success');
            setCharterCompanies(charterCompanies.map((charterCompany) => charterCompany.id === selectedCharterCompany.id ? response.data : charterCompany));
            setHasUnsavedChanges(false);
        } catch (error) {
            setErrors(error.response.data);
            setStatus('edit-charterCompany-error');
        }
    };

    return (
        <Container component="main" maxWidth={false} sx={{ py: 3 }}>
            <Typography className={styles['text-h1']} variant="h5" gutterBottom>
                日 本 線 管 理 頁 面
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tabs value={tabValue} variant='scrollable' scrollButtons='auto' onChange={handleTabChange}>
                    <Tab href='#User' label="使用者帳號" />
                    <Tab href='#Group' label="權限群組" />
                    <Tab href='#Remittance' label="匯款資訊" />
                    <Tab href='#Warning' label="注意事項" />
                    <Tab href='#Policy' label="取消政策" />
                    <Tab href='#BaseData' label="基本資料" />
                    <Tab href='#PeersCompany' label="同行公司" />
                    <Tab href='#Vehicle' label="車輛信息" />
                    <Tab href='#CharterCompany' label="車輛公司" />
                </Tabs>
            </div>

            {tabValue === 0 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingUser && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <AccountCircle sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        創建使用者帳號
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="使用者帳號"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.username}
                                                    helperText={errors.username}
                                                    onChange={(event) => setNewUser({ ...newUser, username: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="暱稱/別名"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.profile?.nickname}
                                                    helperText={errors.profile?.nickname}
                                                    onChange={(event) => setNewUser({ ...newUser, profile: { ...newUser.profile, nickname: event.target.value } })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.profile?.phone}
                                                    helperText={errors.profile?.phone}
                                                    onChange={(event) => setNewUser({ ...newUser, profile: { ...newUser.profile, phone: event.target.value } })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.email}
                                                    helperText={errors.email}
                                                    onChange={(event) => setNewUser({ ...newUser, email: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    multiple
                                                    options={group.filter(option => option.info.group_type === "日本線")}
                                                    getOptionLabel={(option) => option.name}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="權限群組"
                                                            placeholder="選擇權限群組(可複選)"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        const groupIds = value.map(group => group.id);
                                                        setNewUser({ ...newUser, group_ids: groupIds });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{ color: 'gray', textAlign: 'center', fontSize: '14px' }}>
                                                    註: 密碼將自動設定為 "defaultpassword"，請使用者登入後自行修改密碼
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingUser(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddUserConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingUser && !editingUser && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddUser}>創建使用者帳號</Button>
                                </Box>
                            </Grid>
                        )}

                        {!editingUser && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={styles.label}>使用者帳號</TableCell>
                                                <TableCell className={styles.label}>別名/暱稱</TableCell>
                                                <TableCell className={styles.label}>權限群組</TableCell>
                                                <TableCell className={styles.label}>創建時間</TableCell>
                                                <TableCell className={styles.label}>最後登入</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {user.filter((user) => user.profile.account_type === "日本線").map((user) => (
                                            <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className={styles.field} component="th" scope="row">
                                                    <Badge
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        color={user.profile.is_online ? 'success' : 'error'}
                                                        variant="dot"
                                                    >
                                                        <Chip
                                                            label={user.username}
                                                            color='info'
                                                            avatar={
                                                                <Avatar
                                                                    src={user.profile.photo}
                                                                    alt={user.username}
                                                                />
                                                            }
                                                        />
                                                    </Badge>
                                                </TableCell>
                                                <TableCell align='center'>{user.profile.nickname ? user.profile.nickname : '–'}</TableCell>
                                                <TableCell align='center'>
                                                    {user.groups.map((group) => (
                                                        <div key={group.id} className={styles['groups-area']}>
                                                            {group.name}
                                                        </div>
                                                    ))}
                                                </TableCell>
                                                <TableCell align="center">{new Date(user.date_joined).toLocaleDateString()}</TableCell>
                                                <TableCell align="center">{user.last_login ? new Date(user.last_login).toLocaleString() : '無記錄'}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton className={styles['edit-button']} onClick={() => handleEditUser(user)}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton className={styles['delete-button']} onClick={() => handleDelete('user', user)}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {user.filter((user) => user.profile.account_type === "日本線").length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何日本線使用者帳號
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingUser && (
                            <Grid item xs={12}>

                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯使用者 {selectedUser.username} 的帳號
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="使用者帳號"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.username}
                                                    helperText={errors.username}
                                                    value={selectedUser.username}
                                                    onChange={(event) => setSelectedUser({ ...selectedUser, username: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="暱稱/別名"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.profile?.nickname}
                                                    helperText={errors.profile?.nickname}
                                                    value={selectedUser.profile?.nickname}
                                                    onChange={(event) => setSelectedUser({ ...selectedUser, profile: { ...selectedUser.profile, nickname: event.target.value } })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.profile?.phone}
                                                    helperText={errors.profile?.phone}
                                                    value={selectedUser.profile?.phone}
                                                    onChange={(event) => setSelectedUser({ ...selectedUser, profile: { ...selectedUser.profile, phone: event.target.value } })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.email}
                                                    helperText={errors.email}
                                                    value={selectedUser.email}
                                                    onChange={(event) => setSelectedUser({ ...selectedUser, email: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    multiple
                                                    options={group.filter(option => option.info.group_type === "日本線")}
                                                    getOptionLabel={(option) => option.name}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    defaultValue={selectedUser.groups}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="權限群組"
                                                            placeholder="編輯權限群組(可複選)"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        const groupIds = value.map(group => group.id);
                                                        setSelectedUser({ ...selectedUser, group_ids: groupIds });
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingUser(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditUserSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 1 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingGroup && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <Groups sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        創建權限群組
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <TextField
                                                    label="群組名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.name}
                                                    helperText={errors.name}
                                                    onChange={(event) => setNewGroup({ ...newGroup, name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={10} >
                                                <TextField
                                                    label="群組權限介紹"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.info?.introduction}
                                                    helperText={errors.info?.introduction}
                                                    onChange={(event) => setNewGroup({ ...newGroup, info: { ...newGroup.info, introduction: event.target.value } })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingGroup(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddGroupConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingGroup && !editingGroup && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddGroup}>創建群組</Button>
                                </Box>
                            </Grid>
                        )}
                        
                        {!editingGroup && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={styles.label}>群組名稱</TableCell>
                                                <TableCell className={styles.label}>權限分配說明</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {group.filter((group) => group.info.group_type === "日本線").map((group) => (
                                            <TableRow key={group.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className={styles.field} component="th" scope="row">
                                                {group.name}
                                            </TableCell>
                                            <TableCell className={styles.field}>{group.info?.introduction}</TableCell>
                                            <TableCell align="center">
                                                <IconButton className={styles['edit-button']} onClick={() => handleEditGroup(group)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton className={styles['delete-button']} onClick={() => handleDelete('group', group)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        {group.filter((group) => group.info.group_type === "日本線").length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何日本線權限群組，請先創建必要權限群組，如日本線業務、日方人員，再進行其他操作，謝謝！
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingGroup && (
                            <Grid item xs={12}>

                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯群組 {selectedGroup.name} 權限群組
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <TextField
                                                    label="群組名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.name}
                                                    helperText={errors.name}
                                                    value={selectedGroup.name}
                                                    onChange={(event) => setSelectedGroup({ ...selectedGroup, name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={10} >
                                                <TextField
                                                    label="群組權限介紹"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.info?.introduction}
                                                    helperText={errors.info?.introduction}
                                                    value={selectedGroup.info?.introduction}
                                                    onChange={(event) => setSelectedGroup({ ...selectedGroup, info: { ...selectedGroup.info, introduction: event.target.value } })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingGroup(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditGroupSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 2 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingRemittanceInfo && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <AccountBalance sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新建匯款資訊
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup 
                                                            row 
                                                            aria-label="position" 
                                                            name="position" 
                                                            defaultValue="domestic" 
                                                            onChange={(event) => {
                                                                setNewRemittanceInfoType(event.target.value);
                                                                setErrors({});
                                                            }}
                                                        >
                                                            <FormControlLabel value="domestic" control={<Radio color="primary" />} label="國內" />
                                                            <FormControlLabel value="overseas" control={<Radio color="primary" />} label="國外" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            {newRemittanceInfoType === 'domestic' ? (
                                                <>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            label="匯款戶名"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                            error={errors.remittance_name}
                                                            helperText={errors.remittance_name}
                                                            onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, remittance_name: event.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <TextField
                                                            label="匯款帳號"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                            error={errors.remittance_account}
                                                            helperText={errors.remittance_account}
                                                            onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, remittance_account: event.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <TextField
                                                            label="銀行代碼"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                            error={errors.bank_code}
                                                            helperText={errors.bank_code}
                                                            onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, bank_code: event.target.value })}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            label="識別名稱"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                            error={errors.remittance_name}
                                                            helperText={errors.remittance_name}
                                                            onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, remittance_name: event.target.value })}
                                                            InputProps={{ placeholder: '用於在訂單上選擇匯款資訊時方便識別' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            label="匯款戶名以及帳號"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                            error={errors.special_remit_info}
                                                            helperText={errors.special_remit_info}
                                                            onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, special_remit_info: event.target.value })}
                                                            multiline
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡人"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.contact_person}
                                                    helperText={errors.contact_person}
                                                    onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, contact_person: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.contact_phone}
                                                    helperText={errors.contact_phone}
                                                    onChange={(event) => setNewRemittanceInfo({ ...newRemittanceInfo, contact_phone: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingRemittanceInfo(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddRemittanceInfoConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingRemittanceInfo && !editingRemittanceInfo && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddRemittanceInfo}>新增匯款資訊</Button>
                                </Box>
                            </Grid>
                        )}

                        {!editingRemittanceInfo && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell className={styles.label}>匯款戶名</TableCell>
                                                <TableCell className={styles.label}>聯絡人</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {remittanceInfo.map((remittanceInfo) => (
                                            <React.Fragment>
                                                <TableRow key={remittanceInfo.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left" width={1}>
                                                        <IconButton onClick={() => setExpandedRow(expandedRow === remittanceInfo.id ? null : remittanceInfo.id)}>
                                                            {expandedRow === remittanceInfo.id ? <ExpandLess /> : <ExpandMore />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell className={styles.field} component="th" scope="row">
                                                        {remittanceInfo.remittance_name}
                                                    </TableCell>
                                                    <TableCell className={styles.field}>{remittanceInfo.contact_person}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton className={styles['edit-button']} onClick={() => handleEditRemittanceInfo(remittanceInfo)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('remittanceInfo', remittanceInfo)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={expandedRow === remittanceInfo.id} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    {remittanceInfo.remittance_name} - 匯款資訊
                                                                </Typography>
                                                            </Box>
                                                            <Card elevation={3} sx={{ ml: 2, mr: 2, mb: 2 }}>
                                                                <CardContent>
                                                                    {!remittanceInfo.special_remit_info ? (
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    label="匯款帳號"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={remittanceInfo.remittance_account}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    label="銀行代碼"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={remittanceInfo.bank_code}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    label="聯絡電話"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={remittanceInfo.contact_phone}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    ) : (
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={9}>
                                                                                <TextField
                                                                                    label="匯款戶名以及帳號"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={remittanceInfo.special_remit_info}
                                                                                    fullWidth
                                                                                    multiline
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <TextField
                                                                                    label="聯絡電話"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={remittanceInfo.contact_phone}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </CardContent>
                                                            </Card>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                        {remittanceInfo.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何日本線匯款資訊
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingRemittanceInfo && (
                            <Grid item xs={12}>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯匯款資訊 {selectedRemittanceInfo.remittance_name}
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <>
                                            {!remittanceInfo.find((remittanceInfo) => remittanceInfo.id === selectedRemittanceInfo.id).special_remit_info ? (
                                                <>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        label="匯款戶名"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        required
                                                        error={errors.remittance_name}
                                                        helperText={errors.remittance_name}
                                                        value={selectedRemittanceInfo.remittance_name}
                                                        onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, remittance_name: event.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <TextField
                                                        label="匯款帳號"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        required
                                                        error={errors.remittance_account}
                                                        helperText={errors.remittance_account}
                                                        value={selectedRemittanceInfo.remittance_account}
                                                        onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, remittance_account: event.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <TextField
                                                        label="銀行代碼"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        required
                                                        error={errors.bank_code}
                                                        helperText={errors.bank_code}
                                                        value={selectedRemittanceInfo.bank_code}
                                                        onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, bank_code: event.target.value })}
                                                    />
                                                </Grid>
                                                </>
                                            ) : (
                                                <>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        label="識別名稱"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        required
                                                        error={errors.remittance_name}
                                                        helperText={errors.remittance_name}
                                                        value={selectedRemittanceInfo.remittance_name}
                                                        onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, remittance_name: event.target.value })}
                                                        InputProps={{ placeholder: '用於在訂單上選擇匯款資訊時方便識別' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        label="匯款戶名以及帳號"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        required
                                                        error={errors.special_remit_info}
                                                        helperText={errors.special_remit_info}
                                                        value={selectedRemittanceInfo.special_remit_info}
                                                        onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, special_remit_info: event.target.value })}
                                                        multiline
                                                    />
                                                </Grid>
                                                </>
                                            )}
                                            </>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡人"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.contact_person}
                                                    helperText={errors.contact_person}
                                                    value={selectedRemittanceInfo.contact_person}
                                                    onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, contact_person: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.contact_phone}
                                                    helperText={errors.contact_phone}
                                                    value={selectedRemittanceInfo.contact_phone}
                                                    onChange={(event) => setSelectedRemittanceInfo({ ...selectedRemittanceInfo, contact_phone: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingRemittanceInfo(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditRemittanceInfoSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 3 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingWarningType && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <Warning sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新增注意事項類型
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="注意事項類別名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.warning_type}
                                                    helperText={errors.warning_type}
                                                    onChange={(event) => setNewWarningType({ ...newWarningType, warning_type: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="注意事項類別描述"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.warning_description}
                                                    helperText={errors.warning_description}
                                                    onChange={(event) => setNewWarningType({ ...newWarningType, warning_description: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingWarningType(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddWarningTypeConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingWarningType && !editingWarningType && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddWarningType}>新增注意事項類型</Button>
                                </Box>
                            </Grid>
                        )}

                        {!editingWarningType && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell className={styles.label}>注意事項類別名稱</TableCell>
                                                <TableCell className={styles.label}>注意事項類別描述</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {warningTypes.map((warningType) => (
                                            <React.Fragment>
                                                <TableRow key={warningType.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align='left' width={1}>
                                                        <IconButton onClick={() => setExpandedRow(expandedRow === warningType.id ? null : warningType.id)}>
                                                            {expandedRow === warningType.id ? <ExpandLess /> : <ExpandMore />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell className={styles.field} component="th" scope="row">
                                                        {warningType.warning_type}
                                                    </TableCell>
                                                    <TableCell className={styles.field}>{warningType.warning_description}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton className={styles['edit-button']} onClick={() => handleEditWarningType(warningType)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('warningType', warningType)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={expandedRow === warningType.id} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    {warningType.warning_type} - 所有注意事項
                                                                </Typography>
                                                            </Box>
                                                            <Card elevation={3} sx={{ ml: 2, mr: 2, mb: 2 }}>
                                                                <CardContent>
                                                                    <Grid container spacing={2}>
                                                                        {warningType.warnings.map((warning) => (
                                                                        <React.Fragment>
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    label="注意事項內容"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={warning.warning_note}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                        ))}
                                                                        {warningType.warnings.length < 1 && (
                                                                            <Grid item xs={12}>
                                                                                <Typography className={styles['no-data']}>
                                                                                    目前沒有任何注意事項
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                        {warningTypes.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何注意事項類型
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingWarningType && (
                            <Grid item xs={12}>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯注意事項 {selectedWarningType.warning_type} 類別
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="注意事項類別名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.warning_type}
                                                    helperText={errors.warning_type}
                                                    value={selectedWarningType.warning_type}
                                                    onChange={(event) => setSelectedWarningType({ ...selectedWarningType, warning_type: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="注意事項類別描述"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.warning_description}
                                                    helperText={errors.warning_description}
                                                    value={selectedWarningType.warning_description}
                                                    onChange={(event) => setSelectedWarningType({ ...selectedWarningType, warning_description: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        注意事項類別 {selectedWarningType.warning_type} - 所有注意事項
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            {selectedWarningType.warnings.map((warning, index) => (
                                                <React.Fragment key={warning.id}>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            label="注意事項內容"
                                                            variant="outlined"
                                                            size="small"
                                                            value={warning.warning_note || ''}
                                                            fullWidth
                                                            error={errors?.warnings?.[index]?.warning_note}
                                                            helperText={errors?.warnings?.[index]?.warning_note}
                                                            onChange={(event) => setSelectedWarningType({ ...selectedWarningType, warnings: selectedWarningType.warnings.map(w => w.id === warning.id ? { ...w, warning_note: event.target.value } : w) })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('existWarning', warning)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                            {newWarning.map((warning, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            label="當前新增注意事項內容"
                                                            variant="outlined"
                                                            size="small"
                                                            value={warning.warning_note || ''}
                                                            fullWidth
                                                            error={errors?.warnings?.[index + selectedWarningType.warnings.length]?.warning_note}
                                                            helperText={errors?.warnings?.[index + selectedWarningType.warnings.length]?.warning_note}
                                                            onChange={(event) => setNewWarning(newWarning.map((w, i) => i === index ? { ...w, warning_note: event.target.value } : w))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('newWarning', null, index)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>

                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddNewWarning}>新增注意事項</Button>
                                        </Box>

                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingWarningType(false);
                                        setNewWarning([]);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditWarningTypeSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}

            {tabValue === 4 && (
                <Box>
                    <Grid container spacing={2}>
                        
                        {addingPolicyType && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <Policy sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新增取消政策類別
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="取消政策類別名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.policy_type}
                                                    helperText={errors.policy_type}
                                                    onChange={(event) => setNewPolicyType({ ...newPolicyType, policy_type: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="取消政策類別描述"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.policy_description}
                                                    helperText={errors.policy_description}
                                                    onChange={(event) => setNewPolicyType({ ...newPolicyType, policy_description: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingPolicyType(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddPolicyTypeConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingPolicyType && !editingPolicyType && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddPolicyType}>新增取消政策類別</Button>
                                </Box>
                            </Grid>
                        )}

                        {!editingPolicyType && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell className={styles.label}>取消政策類別名稱</TableCell>
                                                <TableCell className={styles.label}>取消政策類別描述</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {policyTypes.map((policyType) => (
                                            <React.Fragment>
                                                <TableRow key={policyType.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align='left' width={1}>
                                                        <IconButton onClick={() => setExpandedRow(expandedRow === policyType.id ? null : policyType.id)}>
                                                            {expandedRow === policyType.id ? <ExpandLess /> : <ExpandMore />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell className={styles.field} component="th" scope="row">
                                                        {policyType.policy_type}
                                                    </TableCell>
                                                    <TableCell className={styles.field}>{policyType.policy_description}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton className={styles['edit-button']} onClick={() => handleEditPolicyType(policyType)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('policyType', policyType)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={expandedRow === policyType.id} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    {policyType.policy_type} - 所有取消政策
                                                                </Typography>
                                                            </Box>
                                                            <Card elevation={3} sx={{ ml: 2, mr: 2, mb: 2 }}>
                                                                <CardContent>
                                                                    <Grid container spacing={2}>
                                                                        {policyType.policies.map((policy, index) => (
                                                                        <React.Fragment key={policy.id}>
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    label="取消政策內容"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={policy.policy_note}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                        ))}
                                                                        {policyType.policies.length < 1 && (
                                                                            <Grid item xs={12}>
                                                                                <Typography className={styles['no-data']}>
                                                                                    目前沒有任何取消政策
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                        {policyTypes.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何取消政策類型
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingPolicyType && (
                            <Grid item xs={12}>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯取消政策 {selectedPolicyType.policy_type} 類別
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="取消政策類別名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.policy_type}
                                                    helperText={errors.policy_type}
                                                    value={selectedPolicyType.policy_type}
                                                    onChange={(event) => setSelectedPolicyType({ ...selectedPolicyType, policy_type: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="取消政策類別描述"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.policy_description}
                                                    helperText={errors.policy_description}
                                                    value={selectedPolicyType.policy_description}
                                                    onChange={(event) => setSelectedPolicyType({ ...selectedPolicyType, policy_description: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        取消政策類別 {selectedPolicyType.policy_type} - 所有取消政策
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            {selectedPolicyType.policies.map((policy, index) => (
                                                <React.Fragment key={policy.id}>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            label="取消政策內容"
                                                            variant="outlined"
                                                            size="small"
                                                            value={policy.policy_note || ''}
                                                            fullWidth
                                                            error={errors?.policies?.[index]?.policy_note}
                                                            helperText={errors?.policies?.[index]?.policy_note}
                                                            onChange={(event) => setSelectedPolicyType({ ...selectedPolicyType, policies: selectedPolicyType.policies.map(p => p.id === policy.id ? { ...p, policy_note: event.target.value } : p) })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('existPolicy', policy)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                            {newPolicy.map((policy, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            label="當前新增取消政策內容"
                                                            variant="outlined"
                                                            size="small"
                                                            value={policy.policy_note || ''}
                                                            fullWidth
                                                            error={errors?.policies?.[index + selectedPolicyType.policies.length]?.policy_note}
                                                            helperText={errors?.policies?.[index + selectedPolicyType.policies.length]?.policy_note}
                                                            onChange={(event) => setNewPolicy(newPolicy.map((p, i) => i === index ? { ...p, policy_note: event.target.value } : p))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('newPolicy', null, index)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>

                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddNewPolicy}>新增取消政策</Button>
                                        </Box>

                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingPolicyType(false);
                                        setNewPolicy([]);
                                        setErrors({});
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditPolicyTypeSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 5 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingPeersSale && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <Business sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新增同行業務
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={peersCompanies}
                                                    getOptionLabel={(option) => option.peers_company_name}
                                                    noOptionsText="請先創建同行業務公司"
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.peers_company_id}
                                                            helperText={errors.peers_company_id}
                                                            label="所屬公司"
                                                            placeholder="選擇此同行業務的所屬公司"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        setNewPeersSale({ ...newPeersSale, peers_company_id: value?.id });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={user.filter((user) => user.profile.account_type === "日本線" && user.profile.tags === '同行業務')}
                                                    getOptionLabel={(option) => option.profile.nickname || option.username}
                                                    noOptionsText="目前沒有可以關聯的使用者帳號"
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.user}
                                                            helperText={errors.user}
                                                            label="關聯使用者帳號"
                                                            placeholder="選擇此同行業務的關聯使用者帳號"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        setNewPeersSale({ ...newPeersSale, user: value?.id });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="業務名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_sales_name}
                                                    helperText={errors.peers_sales_name}
                                                    onChange={(event) => setNewPeersSale({ ...newPeersSale, peers_sales_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_sales_phone}
                                                    helperText={errors.peers_sales_phone}
                                                    onChange={(event) => setNewPeersSale({ ...newPeersSale, peers_sales_phone: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={manageOptions.peers_sales_communication_app}
                                                    getOptionLabel={(option) => option.label}
                                                    noOptionsText="目前沒有任何通訊軟體選項"
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.peers_sales_communication_app}
                                                            helperText={errors.peers_sales_communication_app}
                                                            label="通訊軟體"
                                                            placeholder="選擇此同行業務的通訊軟體"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        setNewPeersSale({ ...newPeersSale, peers_sales_communication_app: value?.value});
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="通訊軟體ID"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_sales_communication_id}
                                                    helperText={errors.peers_sales_communication_id}
                                                    onChange={(event) => setNewPeersSale({ ...newPeersSale, peers_sales_communication_id: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_sales_email}
                                                    helperText={errors.peers_sales_email}
                                                    onChange={(event) => setNewPeersSale({ ...newPeersSale, peers_sales_email: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingPeersSale(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddPeersSaleConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingPeersSale && !editingPeersSale && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%'}}>
                                    <Box sx={{ flex: 1 }}></Box>
                            
                                    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1, mt: '25px' }}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="position"
                                                name="position"
                                                defaultValue="peersSale"
                                                onChange={(event) => setBaseDataPicker(event.target.value)}
                                            >
                                                <FormControlLabel value="peersSale" control={<Radio color="primary" />} label="同行業務" />
                                                <FormControlLabel disabled value="member" control={<Radio color="primary" />} label="個人會員" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    
                                    {baseDataPicker === 'peersSale' && (
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                                            <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddPeersSale}>新增同行業務</Button>
                                        </Box>
                                    )}
                                    {baseDataPicker === 'member' && (
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                                            <Button className={styles['add-button']} startIcon={<Add />} variant="contained">新增個人會員</Button>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        )}

                        {!editingPeersSale && (
                            <Grid item xs={12}>
                                {baseDataPicker === 'peersSale' && (
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell className={styles.label}>業務名稱</TableCell>
                                                    <TableCell className={styles.label}>連絡電話</TableCell>
                                                    <TableCell className={styles.label}>所屬公司</TableCell>
                                                    <TableCell className={styles.label}>關聯帳號</TableCell>
                                                    <TableCell className={styles.label}>操作</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {peersSales.map((peersSale) => (
                                                <React.Fragment>
                                                    <TableRow key={peersSale.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell align='left' width={1}>
                                                            <IconButton onClick={() => setExpandedRow(expandedRow === peersSale.id ? null : peersSale.id)}>
                                                                {expandedRow === peersSale.id ? <ExpandLess /> : <ExpandMore />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell className={styles.field} component="th" scope="row">
                                                            {peersSale.peers_sales_name}
                                                        </TableCell>
                                                        <TableCell className={styles.field}>{peersSale.peers_sales_phone}</TableCell>
                                                        <TableCell className={styles.field}>
                                                            {typeof peersSale.peers_company === 'number'
                                                                ? peersCompanies.find((company) => company.id === peersSale.peers_company)?.peers_company_name
                                                                : peersSale.peers_company.peers_company_name
                                                            }
                                                        </TableCell>
                                                        <TableCell className={styles.field}>
                                                            {user.find((u) => u.id === peersSale.user)?.profile.nickname || user.find((u) => u.id === peersSale.user)?.username || '–'}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton className={styles['edit-button']} onClick={() => handleEditPeersSale(peersSale)}>
                                                                <Edit />
                                                            </IconButton>
                                                            <IconButton className={styles['delete-button']} onClick={() => handleDelete('peersSale', peersSale)}>
                                                                <Delete />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={expandedRow === peersSale.id} timeout="auto" unmountOnExit>
                                                                <Box sx={{ margin: 1 }}>
                                                                    <Typography variant="h6" gutterBottom component="div">
                                                                        {peersSale.peers_sales_name} - 聯絡方式
                                                                    </Typography>
                                                                </Box>
                                                                    
                                                                <Card elevation={3} sx={{ ml: 2, mr: 2, mb: 2 }}>
                                                                    <CardContent>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    label="電子郵件"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={peersSale.peers_sales_email}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    label="通訊軟體"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={peersSale.peers_sales_communication_app}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    label="通訊軟體ID"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={peersSale.peers_sales_communication_id}
                                                                                    fullWidth
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </CardContent>
                                                                </Card>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                            {peersSales.length < 1 && (
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Typography className={styles['no-data']}>
                                                            目前尚無任何同行業務資料
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {baseDataPicker === 'member' && (
                                    // 設計中
                                    <Typography sx={{ textAlign: 'center', color: 'GrayText'}}>個人會員資料結構設計中. . .</Typography>
                                )}
                            </Grid>
                        )}

                        {editingPeersSale && (
                            <Grid item xs={12}>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯同行業務 {selectedPeersSale.peers_sales_name} 資料
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={peersCompanies}
                                                    getOptionLabel={(option) => option.peers_company_name}
                                                    noOptionsText="請先創建同行業務公司"
                                                    defaultValue={selectedPeersSale.peers_company}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.peers_company_id}
                                                            helperText={errors.peers_company_id}
                                                            label="所屬公司"
                                                            placeholder="選擇此同行業務的所屬公司"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                        />
                                                    )}
                                                    value={peersCompanies.find((company) => company.id === selectedPeersSale.peers_company)}
                                                    onChange={(event, value) => {
                                                        setSelectedPeersSale({ ...selectedPeersSale, peers_company_id: value?.id || null});
                                                        console.log(selectedPeersSale);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={user.filter((user) =>  user.profile.account_type === "日本線" && user.profile.tags === '同行業務')}
                                                    getOptionLabel={(option) => option.profile.nickname || option.username}
                                                    noOptionsText="目前沒有可以關聯的使用者帳號"
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.user}
                                                            helperText={errors.user}
                                                            label="關聯使用者帳號"
                                                            placeholder="選擇此同行業務的關聯使用者帳號"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    )}
                                                    value={user.find((u) => u.id === selectedPeersSale.user)}
                                                    onChange={(event, value) => {
                                                        setSelectedPeersSale({ ...selectedPeersSale, user: value?.id });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="業務名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_sales_name}
                                                    helperText={errors.peers_sales_name}
                                                    value={selectedPeersSale.peers_sales_name}
                                                    onChange={(event) => setSelectedPeersSale({ ...selectedPeersSale, peers_sales_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_sales_phone}
                                                    helperText={errors.peers_sales_phone}
                                                    value={selectedPeersSale.peers_sales_phone}
                                                    onChange={(event) => setSelectedPeersSale({ ...selectedPeersSale, peers_sales_phone: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={manageOptions.peers_sales_communication_app}
                                                    getOptionLabel={(option) => option.label}
                                                    noOptionsText="目前沒有任何通訊軟體選項"
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.peers_sales_communication_app}
                                                            helperText={errors.peers_sales_communication_app}
                                                            label="通訊軟體"
                                                            placeholder="選擇此同行業務的通訊軟體"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    )}
                                                    value={manageOptions.peers_sales_communication_app.find((app) => app.value === selectedPeersSale.peers_sales_communication_app)}
                                                    onChange={(event, value) => {
                                                        setSelectedPeersSale({ ...selectedPeersSale, peers_sales_communication_app: value?.value});
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="通訊軟體ID"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_sales_communication_id}
                                                    helperText={errors.peers_sales_communication_id}
                                                    value={selectedPeersSale.peers_sales_communication_id}
                                                    onChange={(event) => setSelectedPeersSale({ ...selectedPeersSale, peers_sales_communication_id: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_sales_email}
                                                    helperText={errors.peers_sales_email}
                                                    value={selectedPeersSale.peers_sales_email}
                                                    onChange={(event) => setSelectedPeersSale({ ...selectedPeersSale, peers_sales_email: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingPeersSale(false);
                                        setErrors({});
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditPeersSaleSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 6 && (
                <Box>
                    <Grid container spacing={2}>
                        
                        {addingPeersCompany && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <Business sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新增同行公司
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="公司名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_name}
                                                    helperText={errors.peers_company_name}
                                                    onChange={(event) => setNewPeersCompany({ ...newPeersCompany, peers_company_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_phone}
                                                    helperText={errors.peers_company_phone}
                                                    onChange={(event) => setNewPeersCompany({ ...newPeersCompany, peers_company_phone: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_company_email}
                                                    helperText={errors.peers_company_email}
                                                    onChange={(event) => setNewPeersCompany({ ...newPeersCompany, peers_company_email: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="負責人"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_contact_person}
                                                    helperText={errors.peers_company_contact_person}
                                                    onChange={(event) => setNewPeersCompany({ ...newPeersCompany, peers_company_contact_person: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司地址"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_address}
                                                    helperText={errors.peers_company_address}
                                                    onChange={(event) => setNewPeersCompany({ ...newPeersCompany, peers_company_address: event.target.value })}
                                                    multiline
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司描述"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_company_description}
                                                    helperText={errors.peers_company_description}
                                                    onChange={(event) => setNewPeersCompany({ ...newPeersCompany, peers_company_description: event.target.value })}
                                                    multiline
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingPeersCompany(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddPeersCompanyConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingPeersCompany && !editingPeersCompany && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddPeersCompany}>創建同行公司資料</Button>
                                </Box>
                            </Grid>
                        )}

                        {!editingPeersCompany && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={styles.label}>公司名稱</TableCell>
                                                <TableCell className={styles.label}>負責人</TableCell>
                                                <TableCell className={styles.label}>聯絡電話</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {peersCompanies.map((peersCompany) => (
                                            <TableRow key={peersCompany.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className={styles.field} component="th" scope="row">{peersCompany.peers_company_name}</TableCell>
                                                <TableCell className={styles.field}>{peersCompany.peers_company_contact_person}</TableCell>
                                                <TableCell className={styles.field}>{peersCompany.peers_company_phone}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton className={styles['edit-button']} onClick={() => handleEditPeersCompany(peersCompany)}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton className={styles['delete-button']} onClick={() => handleDelete('peersCompany', peersCompany)}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {peersCompanies.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何同行公司資料
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingPeersCompany && (
                            <Grid item xs={12}>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯同行公司 {selectedPeersCompany.peers_company_name} 資料
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="公司名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_name}
                                                    helperText={errors.peers_company_name}
                                                    value={selectedPeersCompany.peers_company_name}
                                                    onChange={(event) => setSelectedPeersCompany({ ...selectedPeersCompany, peers_company_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_phone}
                                                    helperText={errors.peers_company_phone}
                                                    value={selectedPeersCompany.peers_company_phone}
                                                    onChange={(event) => setSelectedPeersCompany({ ...selectedPeersCompany, peers_company_phone: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_company_email}
                                                    helperText={errors.peers_company_email}
                                                    value={selectedPeersCompany.peers_company_email}
                                                    onChange={(event) => setSelectedPeersCompany({ ...selectedPeersCompany, peers_company_email: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="負責人"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_contact_person}
                                                    helperText={errors.peers_company_contact_person}
                                                    value={selectedPeersCompany.peers_company_contact_person}
                                                    onChange={(event) => setSelectedPeersCompany({ ...selectedPeersCompany, peers_company_contact_person: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司地址"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.peers_company_address}
                                                    helperText={errors.peers_company_address}
                                                    value={selectedPeersCompany.peers_company_address}
                                                    onChange={(event) => setSelectedPeersCompany({ ...selectedPeersCompany, peers_company_address: event.target.value })}
                                                    multiline
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司描述"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.peers_company_description}
                                                    helperText={errors.peers_company_description}
                                                    value={selectedPeersCompany.peers_company_description}
                                                    onChange={(event) => setSelectedPeersCompany({ ...selectedPeersCompany, peers_company_description: event.target.value })}
                                                    multiline
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingPeersCompany(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditPeersCompanySave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>

                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 7 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingVehicle && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <DirectionsCar sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新建車輛信息
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="車輛名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.vehicle_name}
                                                    helperText={errors.vehicle_name}
                                                    onChange={(event) => setNewVehicle({ ...newVehicle, vehicle_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={charterCompanies}
                                                    getOptionLabel={(option) => option.company_name}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.charter_company}
                                                            helperText={errors.charter_company}
                                                            label="車輛所屬公司"
                                                            placeholder="選擇車輛所屬公司"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                        />
                                                    )}
                                                    onChange={(event, value) => {
                                                        setNewVehicle({ ...newVehicle, charter_company: value?.id });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="車輛說明"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.vehicle_description}
                                                    helperText={errors.vehicle_description}
                                                    onChange={(event) => setNewVehicle({ ...newVehicle, vehicle_description: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="車輛超時說明"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.vehicle_overtime_description}
                                                    helperText={errors.vehicle_overtime_description}
                                                    onChange={(event) => setNewVehicle({ ...newVehicle, vehicle_overtime_description: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingVehicle(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddVehicleConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {charterCompanies.length > 0 && !addingVehicle && !editingVehicle && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddVehicle}>創建車輛信息</Button>
                                </Box>
                            </Grid>
                        )}
                            
                        {!editingVehicle && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell className={styles.label}>車輛名稱</TableCell>
                                                <TableCell className={styles.label}>車輛說明</TableCell>
                                                <TableCell className={styles.label}>所屬公司</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {vehicle.map((vehicle) => (
                                            <React.Fragment>
                                                <TableRow key={vehicle.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left" width={1}>
                                                        <IconButton onClick={() => setExpandedRow(expandedRow === vehicle.id ? null : vehicle.id)}>
                                                            {expandedRow === vehicle.id ? <ExpandLess /> : <ExpandMore />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell className={styles.field} component="th" scope="row">{vehicle.vehicle_name}</TableCell>
                                                    <TableCell className={styles.field}>{vehicle.vehicle_description}</TableCell>
                                                    <TableCell className={styles.field}>
                                                        {typeof vehicle.charter_company === 'number'
                                                            ? (charterCompanies.find(company => company.id === vehicle.charter_company) || {}).company_name
                                                            : vehicle.charter_company.company_name
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton className={styles['edit-button']} onClick={() => handleEditVehicle(vehicle)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton className={styles['delete-button']} onClick={() => handleDelete('vehicle', vehicle)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={expandedRow === vehicle.id} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    {vehicle.vehicle_name} - 車輛超時說明
                                                                </Typography>
                                                            </Box>
                                                            <Card elevation={3} sx={{ ml: 2, mr: 2, mb: 2 }}>
                                                                <CardContent>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                label="車輛超時說明"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={vehicle.vehicle_overtime_description}
                                                                                fullWidth
                                                                                InputProps={{ readOnly: true }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                        {charterCompanies.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何車輛公司信息，請先創建車輛公司，再創建車輛信息。
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {charterCompanies.length > 0 && vehicle.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何車輛信息
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingVehicle && (
                            <Grid item xs={12}>

                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯車輛 {selectedVehicle.vehicle_name} 車輛信息
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="車輛名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.vehicle_name}
                                                    helperText={errors.vehicle_name}
                                                    value={selectedVehicle.vehicle_name}
                                                    onChange={(event) => setSelectedVehicle({ ...selectedVehicle, vehicle_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    options={charterCompanies}
                                                    getOptionLabel={(option) => option.company_name}
                                                    filterSelectedOptions
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={errors.charter_company}
                                                            helperText={errors.charter_company}
                                                            label="所屬車輛公司"
                                                            placeholder="選擇車輛所屬公司"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            required
                                                        />
                                                    )}
                                                    defaultValue={charterCompanies.find(company => company.id === selectedVehicle.charter_company)}
                                                    onChange={(event, value) => {
                                                        setSelectedVehicle({ ...selectedVehicle, charter_company: value? value.id : null});
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="車輛說明"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.vehicle_description}
                                                    helperText={errors.vehicle_description}
                                                    value={selectedVehicle.vehicle_description}
                                                    onChange={(event) => setSelectedVehicle({ ...selectedVehicle, vehicle_description: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="車輛超時說明"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.vehicle_overtime_description}
                                                    helperText={errors.vehicle_overtime_description}
                                                    value={selectedVehicle.vehicle_overtime_description}
                                                    onChange={(event) => setSelectedVehicle({ ...selectedVehicle, vehicle_overtime_description: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingVehicle(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditVehicleSave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            {tabValue === 8 && (
                <Box>
                    <Grid container spacing={2}>

                        {addingCharterCompany && (
                            <Container>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px'}}>
                                            <Business sx={{ fontSize: 80, color: 'Highlight' }} />
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        新建車輛公司
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="公司名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.company_name}
                                                    helperText={errors.company_name}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_email}
                                                    helperText={errors.company_email}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_email: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡人"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.company_contact_person}
                                                    helperText={errors.company_contact_person}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_contact_person: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_phone}
                                                    helperText={errors.company_phone}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_phone: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司地址"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_address}
                                                    helperText={errors.company_address}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_address: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司介紹"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    multiline
                                                    error={errors.company_description}
                                                    helperText={errors.company_description}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_description: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="Line群組ID"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_line_uuid}
                                                    helperText={errors.company_line_uuid}
                                                    onChange={(event) => setNewCharterCompany({ ...newCharterCompany, company_line_uuid: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setAddingCharterCompany(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleAddCharterCompanyConfirm}>
                                        <NoteAdd />
                                        創建
                                    </Button>
                                </Box>
                            </Container>
                        )}

                        {!addingCharterCompany && !editingCharterCompany && (
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={styles['add-button']} startIcon={<Add />} variant="contained" onClick={handleAddCharterCompany}>創建車輛公司資料</Button>
                                </Box>
                            </Grid>
                        )}

                        {!editingCharterCompany && (
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={styles.label}>公司名稱</TableCell>
                                                <TableCell className={styles.label}>聯絡人</TableCell>
                                                <TableCell className={styles.label}>聯絡電話</TableCell>
                                                <TableCell className={styles.label}>操作</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {charterCompanies.map((charterCompany) => (
                                            <TableRow key={charterCompany.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className={styles.field} component="th" scope="row">{charterCompany.company_name}</TableCell>
                                                <TableCell className={styles.field}>{charterCompany.company_contact_person}</TableCell>
                                                <TableCell className={styles.field}>{charterCompany.company_phone}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton className={styles['edit-button']} onClick={() => handleEditCharterCompany(charterCompany)}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton className={styles['delete-button']} onClick={() => handleDelete('charterCompany', charterCompany)}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {charterCompanies.length < 1 && (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography className={styles['no-data']}>
                                                        目前尚無任何車輛公司資料
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}

                        {editingCharterCompany && (
                            <Grid item xs={12}>
                                <Card elevation={3} sx={{ mt: 3, ml: 20, mr: 20, mb: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CardContent style={{ width: "100%" }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Typography variant="h6" gutterBottom>
                                                        正在編輯車輛公司 {selectedCharterCompany.company_name} 資料
                                                    </Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="公司名稱"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.company_name}
                                                    helperText={errors.company_name}
                                                    value={selectedCharterCompany.company_name}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_name: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="電子郵件"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_email}
                                                    helperText={errors.company_email}
                                                    value={selectedCharterCompany.company_email}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_email: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡人"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                    error={errors.company_contact_person}
                                                    helperText={errors.company_contact_person}
                                                    value={selectedCharterCompany.company_contact_person}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_contact_person: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <TextField
                                                    label="聯絡電話"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_phone}
                                                    helperText={errors.company_phone}
                                                    value={selectedCharterCompany.company_phone}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_phone: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司地址"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_address}
                                                    helperText={errors.company_address}
                                                    value={selectedCharterCompany.company_address}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_address: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="公司介紹"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    multiline
                                                    error={errors.company_description}
                                                    helperText={errors.company_description}
                                                    value={selectedCharterCompany.company_description}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_description: event.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    label="Line群組ID"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.company_line_uuid}
                                                    helperText={errors.company_line_uuid}
                                                    value={selectedCharterCompany.company_line_uuid}
                                                    onChange={(event) => setSelectedCharterCompany({ ...selectedCharterCompany, company_line_uuid: event.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className={styles['cancel-button']} onClick={() => {
                                        setEditingCharterCompany(false);
                                        setErrors({});
                                        setHasUnsavedChanges(false);
                                    }}>
                                        <DoDisturbOn />
                                        取消
                                    </Button>
                                    <Button className={styles['save-button']} onClick={handleEditCharterCompanySave}>
                                        <NoteAdd />
                                        保存
                                    </Button>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            )}

            <Dialog
                open={deleteCheck}
                onClose={() => setDeleteCheck(false)}
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            >
                <DialogTitle className={styles['dialog-title']}>確定要刪除嗎？</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {deleteCheckInfo?.type === 'user' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.username}</b> 此帳號？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'group' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.name}</b> 此權限群組？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'peersSale' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.peers_sales_name}</b> 此同行業務資料？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'peersCompany' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.peers_company_name}</b> 此同行公司資料？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'vehicle' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.vehicle_name}</b> 此車輛信息？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'charterCompany' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.company_name}</b> 此車輛公司資料？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'warningType' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.warning_type}</b> 此注意事項類別？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'existWarning' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.warning_note}</b> 此注意事項內容？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'newWarning' && (
                            <div>
                                刪除後無法復原，請確認是否刪除該筆當前新增的注意事項內容？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'policyType' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.policy_type}</b> 此取消政策類別？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'existPolicy' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.policy_note}</b> 此取消政策內容？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'newPolicy' && (
                            <div>
                                刪除後無法復原，請確認是否刪除該筆當前新增的取消政策內容？
                            </div>
                        )}
                        {deleteCheckInfo?.type === 'remittanceInfo' && (
                            <div>
                                刪除後無法復原，請確認是否刪除 <b>{deleteCheckInfo?.data?.remittance_name}</b> 此匯款資訊？
                            </div>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteCheck(false)}>取消</Button>
                    <Button onClick={handleDeleteConfirm}>確定</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={status === 'delete-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => {
                    setStatus(null);
                    setErrors({});
                }} severity="error" sx={{ width: '100%' }}>
                    {errors?.error}
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newUser-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    使用者創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newUser-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    使用者創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-user-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新使用者資訊！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-user-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    使用者資訊更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newGroup-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    權限群組創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newGroup-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    權限群組創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-group-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新權限群組資訊！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-group-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    權限群組資訊更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newRemittanceInfo-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    匯款資訊創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newRemittanceInfo-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    匯款資訊創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-remittanceInfo-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新匯款資訊！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-remittanceInfo-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    匯款資訊更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newWarningType-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    注意事項類別創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newWarningType-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    注意事項類別創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-warningType-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新注意事項資料！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-warningType-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    注意事項資料更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>
            
            <Snackbar
                open={status === 'add-newPolicyType-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    取消政策類別創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newPolicyType-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    取消政策類別創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-policyType-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新取消政策類別資料！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-policyType-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    取消政策類別資料更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>
            
            <Snackbar
                open={status === 'add-newPeersSale-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    同行業務資料創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newPeersSale-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    同行業務資料創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-peersSale-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新同行業務資料！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-peersSale-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    同行業務資料更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newPeersCompany-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    同行公司資料創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newPeersCompany-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    同行公司資料創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-peersCompany-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新同行公司資料！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-peersCompany-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    同行公司資料更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newVehicle-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    車輛信息創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newVehicle-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    車輛信息創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-vehicle-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新車輛信息！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-vehicle-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    車輛信息更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newCharterCompany-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    車輛公司資料創建成功！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'add-newCharterCompany-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    車輛公司資料創建失敗，請仔細檢查！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-charterCompany-success'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="success" sx={{ width: '100%' }}>
                    成功更新車輛公司資料！
                </Alert>
            </Snackbar>

            <Snackbar
                open={status === 'edit-charterCompany-error'}
                autoHideDuration={6000}
                onClose={() => setStatus(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setStatus(null)} severity="error" sx={{ width: '100%' }}>
                    車輛公司資料更新失敗，請仔細檢查！
                </Alert>
            </Snackbar>

        </Container>
    );
}

export default Manage;