import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import UserGroupContext from '../contexts/UserGroupContext';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Container, Typography, Box, Button, TextField, Dialog, DialogTitle, DialogActions, DialogContent, Divider,
    FormControl, DialogContentText,  Autocomplete, FormHelperText, Tooltip, Collapse, Menu, MenuItem
  } from '@mui/material';
import { Add, Edit, Delete, ExpandMore, SaveAs, DoDisturbOn, PushPin, Timer, Warning } from '@mui/icons-material';
import styles from './css/Announcement.module.css';
import { ReactComponent as TogetherImg } from './img/Together.svg';

function deepCompare(oldObj, newObj, result = {}) {
    Object.keys({ ...oldObj, ...newObj }).forEach(key => {
        const oldVal = oldObj[key];
        const newVal = newObj[key];
  
        if (Array.isArray(oldVal) && Array.isArray(newVal)) {
            const arrayChanges = [];
            const maxLength = Math.max(oldVal.length, newVal.length);
            for (let i = 0; i < maxLength; i++) {
                if (i >= oldVal.length) {
                    arrayChanges.push(newVal[i]);
                } else if (i >= newVal.length) {
                } else {
                    const itemChanges = {};
                    deepCompare(oldVal[i], newVal[i], itemChanges);
                    if (Object.keys(itemChanges).length > 0) {
                        if (newVal[i].id !== undefined) {
                            itemChanges['id'] = newVal[i].id;
                        }
                        arrayChanges.push(itemChanges);
                    }
                }
            }
            if (arrayChanges.length > 0) {
                result[key] = arrayChanges;
            }
        } else if (oldVal && typeof oldVal === 'object' && newVal && typeof newVal === 'object') {
            const nestedChanges = {};
            deepCompare(oldVal, newVal, nestedChanges);
            if (Object.keys(nestedChanges).length > 0) {
                result[key] = nestedChanges;
            }
        } else if (oldVal !== newVal) {
            result[key] = newVal;
        }
    });
  
    return result;
  }

function Announcement({ CurrentUser }) {
  const userGroups = React.useContext(UserGroupContext);
  const [announcements, setAnnouncements] = useState([]);
  const [filterAnnouncements, setFilterAnnouncements] = useState([]);
  const [groups, setGroups] = useState([]);
  const [category, setCategory] = useState([]);
  const [create, setCreate] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [newAnnouncement, setNewAnnouncement] = useState({ category_id: 1, pinned: false });
  const [editingAnnouncement, setEditingAnnouncement] = useState({});
  const [deletingAnnouncement, setDeletingAnnouncement] = useState({});
  const [searchingCategory, setSearchingCategory] = useState('全部');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
  const editModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['clean']
    ],
  };
  const viewModules = {
    toolbar: false,
  };

  useEffect(() => {

    document.title = '最新資訊';
    
    const fetchData = async () => {
        try {
            const response = await api.get('/core/api/Announcement/');
            setAnnouncements(response.data);
            setFilterAnnouncements(response.data);

            const groupResponse = await api.get('/core/api/Group/');
            setGroups(groupResponse.data);

            const categoryResponse = await api.get('/core/api/Category/');
            setCategory(categoryResponse.data);
        } catch (error) {
            console.error('獲取資料時發生錯誤:', error);
        }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, [hasUnsavedChanges]);

  const handleFilterClose = (option) => {
    if (option) {
        if (option === 'all') {
            setSearchingCategory('全部');
            // 將所有公告顯示出來
            setFilterAnnouncements(announcements);
        } else {
            setSearchingCategory(option.category_name);
            // 將符合類別的公告顯示出來
            setFilterAnnouncements(announcements.filter(vt => vt.category.id === option.id));
        }
    }
    setAnchorEl(null);
  };

  const handleAnnouncementOpen = (id) => {
    setOpen(open === id ? false : id);
  };

  const handleCreateAnnouncement = () => {
    setNewAnnouncement({ category_id: 1, pinned: false });
    setCreate(true);
    setHasUnsavedChanges(true);
  };

  const handlelCreateCancel = (event, reason) => {
    if (reason !== 'backdropClick') {
        setCreate(false);
        setNewAnnouncement({ category_id: 1, pinned: false });
        setErrors({});
        setHasUnsavedChanges(false);
    }
  };

  const handleEditAnnouncement = (announcement) => {
    setEditingAnnouncement(announcement);
    setEditing(true);
    setHasUnsavedChanges(true);
  };

  const promptDeleteAnnouncement = (announcement) => {
    setDeletingAnnouncement(announcement);
    setDeleting(true);
    setHasUnsavedChanges(true);
  };

  const handleDeletCancel = (event, reason) => {
    if (reason !== 'backdropClick') {
        setDeletingAnnouncement({});
        setDeleting(false);
        setHasUnsavedChanges(false);
    }
  };

  const handleConfirmDeleteAnnouncement = async () => {
    if (deletingAnnouncement.id) {
        try {
            await api.delete(`/core/api/Announcement/${deletingAnnouncement.id}/`);
            setAnnouncements(announcements.filter(vt => vt.id !== deletingAnnouncement.id));
            setFilterAnnouncements(filterAnnouncements.filter(vt => vt.id !== deletingAnnouncement.id));
        } catch (error) {
            console.error('刪除公告時發生錯誤:', error);
        }
        setDeletingAnnouncement({});
        setDeleting(false);
        setHasUnsavedChanges(false);
    }
  };

  const handleEditCancel = (event, reason) => {
    if (reason !== 'backdropClick') {
        setEditing(false);
        setEditingAnnouncement(null);
        setHasUnsavedChanges(false);
    }
  };

  const handleEditSave = async () => {
    try {
        const changes = {}
        deepCompare(announcements.find(vt => vt.id === editingAnnouncement.id), editingAnnouncement, changes);
        const changedAnnouncementData = { 
            ...changes,
            id: editingAnnouncement.id,
            visible_groups: editingAnnouncement.visible_groups
        };
        
        if (Object.keys(changedAnnouncementData).length === 1) {
            setEditing(false);
            setEditingAnnouncement(null);
            setHasUnsavedChanges(false);
            return;
        }
        
        const response = await api.patch(`/core/api/Announcement/${editingAnnouncement.id}/`, changedAnnouncementData);

        setAnnouncements(announcements.map(vt => vt.id === response.data.id ? response.data : vt));
        setFilterAnnouncements(filterAnnouncements.map(vt => vt.id === response.data.id ? response.data : vt));
        setEditing(false);
        setEditingAnnouncement(null);
        setHasUnsavedChanges(false);
    } catch (error) {
        console.error('更新公告時發生錯誤:', error);
    }
  };

  const handleCeateSubmit = async () => {
    try {
        let updatedAnnouncement = { ...newAnnouncement };

        const stripHtml = (html) => {
            const div = document.createElement('div');
            div.innerHTML = html;
            return div.textContent || div.innerText || '';
        };

        if (stripHtml(updatedAnnouncement.content).trim() === '') {
            updatedAnnouncement.content = '';
        }

        if (userGroups.includes("日方人員")) {
            const visibleGroups = groups.filter(vt => vt.info.group_type === "日本線" && vt.info.tags === "台灣業務").map(vt => vt.id);
            const otherGroupId = userGroups.find(group => group !== "日方人員");
    
            // 將獲取的群組 ID 添加到 visibleGroups
            if (otherGroupId) {
                const otherGroup = groups.find(group => group.name === otherGroupId);
                if (otherGroup) {
                    visibleGroups.push(otherGroup.id);
                }
            }
            updatedAnnouncement = { ...updatedAnnouncement, visible_groups: visibleGroups };
        }

        const response = await api.post('/core/api/Announcement/', updatedAnnouncement);
        setAnnouncements([...announcements, response.data]);
        setFilterAnnouncements([...announcements, response.data]);
        setNewAnnouncement({ category_id: 1, pinned: false });
        setErrors({});
        setCreate(false);
        setHasUnsavedChanges(false);
    } catch (error) {
        console.error("新增公告時發生錯誤:", error);
        if (error.response && error.response.data) {
            setErrors(error.response.data);
        }
    }
  };
  
  return (
    <Container maxWidth={'lg'} className={styles['main-container']}>

        <Typography className={styles['page-title']}>最新資訊</Typography>

        <Box className={styles['function-box']}>

            <Button
                size="small"
                className={styles['filter-button']}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {searchingCategory}
                <ExpandMore sx={{ width: '20px', height: '20px', transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleFilterClose(null)}
                sx={{
                    '& .MuiList-root': {
                        backgroundColor: '#a6bfea',
                        padding: 0,
                    },
                }}
            >
                <MenuItem
                    onClick={() => handleFilterClose('all')}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#4963c7',
                            color: '#FFF',
                        },
                    }}
                >
                    全部
                </MenuItem>
                {category.map((item) => (
                    <MenuItem
                        key={item.id}
                        onClick={() => handleFilterClose(item)}
                        sx={{
                            '&:hover': {
                                backgroundColor: '#4963c7',
                                color: '#FFF',
                            },
                        }}
                    >
                        {item.category_name}
                    </MenuItem>
                ))}
            </Menu>

            <Button className={styles['create-button']} onClick={handleCreateAnnouncement}>
                <Add sx={{ width: '20px', height: '20px' }} />
                創建公告
            </Button>

        </Box>

        <Box className={styles['announcement-box']}>
            <Box className={styles['announcement-list']}>

                {filterAnnouncements.map(announcement => (
                    <Box key={announcement.id} className={styles['announcement-card']}>
                        {announcement.pinned && (
                            <Tooltip title="置頂公告" placement="left" >
                                <PushPin className={styles['pinned-icon']}/>
                            </Tooltip>
                        )}

                        {announcement.end_at && (
                            <Tooltip 
                                title={`時效: ${new Date(announcement.end_at).toLocaleString('zh-TW', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                })}`} 
                                placement="right"
                            >
                                <Timer className={styles['timer-icon']} />
                            </Tooltip>
                        )}

                        <Box className={styles['title-box']}>
                            <Typography className={styles['announcement-title']}>{announcement.title}</Typography>
                            <Typography
                                className={styles['announcement-type']}
                                sx={{ 
                                    backgroundColor: announcement.category.category_name &&
                                        announcement.category.category_name === "一般" ? "#374151" :
                                        announcement.category.category_name === "重要" ? "#bb5502" :
                                        announcement.category.category_name === "緊急" ? "#b7192c" :
                                        'none',
                                    border: announcement.category.category_name &&
                                        announcement.category.category_name === "一般" ? "1px solid #030712" :
                                        announcement.category.category_name === "重要" ? "1px solid #481a00" :
                                        announcement.category.category_name === "緊急" ? "1px solid #490812" :
                                        'none',
                                }}
                            >
                                {announcement.category.category_name}
                            </Typography>
                        </Box>

                        <Divider variant='middle' sx={{ display: open !== announcement.id ? 'none' : 'block' }} />
                        
                        <Collapse in={open === announcement.id} timeout="auto" unmountOnExit sx={{ overflow: 'hidden' }}>
                            <Box className={styles['announcement-content']}>

                                <ReactQuill
                                    theme="bubble"
                                    value={announcement.content}
                                    readOnly
                                    modules={viewModules}
                                />

                                <Box className={styles['announcement-info']}>
                                    <Typography variant="caption" color="textSecondary">
                                        {`發布者: ${announcement.author.username}`}
                                        <br />
                                        {`發布時間: ${new Date(announcement.date_posted).toLocaleString()}`}
                                        {announcement.date_edited && (
                                            <>
                                                <br />
                                                {`編輯時間: ${new Date(announcement.date_edited).toLocaleString()}`}
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>

                            {(CurrentUser.id === announcement.author?.id || userGroups.includes("管理員")) && (
                                <Box className={styles['action-box']}>
                                    <Box className={styles['action-buttons']}>
                                        <Tooltip title="編輯" placement="left">
                                            <Button className={styles['edit-button']} disabled={editing} onClick={() => handleEditAnnouncement(announcement)} >
                                                <Edit />
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title="刪除" placement="right">
                                            <Button className={styles['delete-button']} disabled={editing} onClick={() => promptDeleteAnnouncement(announcement)} >
                                                <Delete />
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        </Collapse>

                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={() => handleAnnouncementOpen(announcement.id)}>
                            <ExpandMore className={styles['expand-icon']} sx={{ transform: open === announcement.id ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                        </Box>
                    </Box>
                ))}

                {announcements.length === 0 && (
                    <Box className={styles['no-data']}>
                        <TogetherImg style={{ width: '500px', height: '500px' }} />
                        目前沒有任何公告
                    </Box>
                )}

                {announcements.length > 0 && filterAnnouncements.length === 0 && (
                    <Box className={styles['no-data']}>
                        <TogetherImg style={{ width: '500px', height: '500px' }} />
                        目前沒有 {searchingCategory}類型 的公告
                    </Box>
                )}
                
            </Box>
        </Box>

        <Dialog disableEscapeKeyDown open={create} onClose={handlelCreateCancel} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw', minHeight: '350px'} }}>
            <DialogTitle sx={{ color: '#394494', width: '800px', fontSize: '25px', fontWeight: '600', textAlign: 'center', padding: '15px'}}>創建新公告</DialogTitle>
            <DialogContent sx={{ width: '800px', minHeight: '250px', padding: '15px', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                <Box className={styles['create-content-box']}>
                    <Box className={styles['create-content-row']}>
                        <Autocomplete
                            options={category}
                            getOptionLabel={(option) => option.category_name}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='類別'
                                    placeholder='請選擇公告類別'
                                    variant='outlined'
                                    size='small'
                                    error={!!errors.category_id}
                                    helperText={errors.category_id}
                                />
                            )}
                            defaultValue={category.find(vt => vt.category_name === "一般")}
                            sx={{ flexGrow: 1 }}
                            onChange={(event, value) => setNewAnnouncement({ ...newAnnouncement, category_id: value?.id })}
                        />

                        <Autocomplete
                            options={[{ id: false, pinned: '否' }, { id: true, pinned: '是' }]}
                            getOptionLabel={(option) => option.pinned}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='置頂'
                                    placeholder='請選擇是否置頂'
                                    variant='outlined'
                                    size='small'
                                    error={!!errors.pinned}
                                    helperText={errors.pinned}
                                />
                            )}
                            defaultValue={{ id: false, pinned: '否' }}
                            sx={{ flexGrow: 1 }}
                            onChange={(event, value) => setNewAnnouncement({ ...newAnnouncement, pinned: value?.id })}
                        />

                        <TextField
                            size='small'
                            label='公告時效到期時間'
                            type='datetime-local'
                            value={newAnnouncement.end_at || ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setNewAnnouncement({ ...newAnnouncement, end_at: e.target.value })}
                            sx={{ flexGrow: 1 }}
                        />
                    </Box>

                    {!userGroups.includes("日方人員") && (
                        <Autocomplete
                            multiple
                            options={groups}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            noOptionsText='目前沒有任何群組可以選擇'
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='公告可見群組'
                                    placeholder='請選擇該公告的可見群組'
                                    variant='outlined'
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.visible_groups}
                                    helperText={errors.visible_groups}
                                />
                            )}
                            sx={{ flexGrow: 1 }}
                            onChange={(event, value) => {
                                const groupIds = value.map(vt => vt.id);
                                setNewAnnouncement({ ...newAnnouncement, visible_groups: groupIds });
                            }}
                        />
                    )}

                    <TextField
                        fullWidth
                        size='small'
                        label='公告標題'
                        placeholder='請輸入公告標題'
                        value={newAnnouncement.title || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setNewAnnouncement({ ...newAnnouncement, title: e.target.value })}
                        error={!!errors.title}
                        helperText={errors.title}
                    />

                    <FormControl fullWidth error={errors.content}>
                        <ReactQuill
                            theme="snow"
                            value={newAnnouncement.content || ''}
                            onChange={(value) => setNewAnnouncement({ ...newAnnouncement, content: value })}
                            modules={editModules}
                            placeholder="請輸入公告內容"
                        />
                        <FormHelperText>{errors.content}</FormHelperText>
                    </FormControl>

                </Box>
            </DialogContent>
            <DialogActions sx={{ width: '800px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                <Button className={styles['cancel-button']} onClick={handlelCreateCancel}>
                    <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                    取消
                </Button>
                <Button className={styles['submit-button']} onClick={handleCeateSubmit}>
                    提交
                    <Add sx={{ width: '20px', height: '20px' }}/>
                </Button>
            </DialogActions>
        </Dialog>

        {editing && (
            <Dialog disableEscapeKeyDown open={editing} onClose={handleEditCancel} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#394494', width: '800px', fontSize: '25px', fontWeight: '600', textAlign: 'center', padding: '15px'}}>
                    正在編輯公告 {editingAnnouncement?.title}
                </DialogTitle>
                <DialogContent sx={{ width: '800px', minHeight: '250px', padding: '15px', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <Box className={styles['create-content-box']}>
                        <Box className={styles['create-content-row']}>
                            <Autocomplete
                                options={category}
                                getOptionLabel={(option) => option.category_name}
                                filterSelectedOptions
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='類別'
                                        placeholder='請選擇公告類別'
                                        variant='outlined'
                                        size='small'
                                        error={!!errors.category_id}
                                        helperText={errors.category_id}
                                    />
                                )}
                                defaultValue={category.find(vt => vt.id === editingAnnouncement.category.id)}
                                sx={{ flexGrow: 1 }}
                                onChange={(event, value) => setEditingAnnouncement({ ...editingAnnouncement, category: value })}
                            />

                            <Autocomplete
                                options={[{ id: false, pinned: '否' }, { id: true, pinned: '是' }]}
                                getOptionLabel={(option) => option.pinned}
                                filterSelectedOptions
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='置頂'
                                        placeholder='請選擇是否置頂'
                                        variant='outlined'
                                        size='small'
                                        error={!!errors.pinned}
                                        helperText={errors.pinned}
                                    />
                                )}
                                defaultValue={{ id: editingAnnouncement.pinned, pinned: editingAnnouncement.pinned ? '是' : '否' }}
                                sx={{ flexGrow: 1 }}
                                onChange={(event, value) => setEditingAnnouncement({ ...editingAnnouncement, pinned: value?.id })}
                            />

                            <TextField
                                size='small'
                                label='公告時效到期時間'
                                type='datetime-local'
                                value={editingAnnouncement.end_at || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setEditingAnnouncement({ ...editingAnnouncement, end_at: e.target.value })}
                                sx={{ flexGrow: 1 }}
                            />
                        </Box>

                        {!userGroups.includes("日方人員") && (
                            <Autocomplete
                                multiple
                                options={groups}
                                getOptionLabel={(option) => option.name}
                                filterSelectedOptions
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText='目前沒有任何群組可以選擇'
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='公告可見群組'
                                        placeholder='請選擇該公告的可見群組'
                                        variant='outlined'
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                        error={!!errors.visible_groups}
                                        helperText={errors.visible_groups}
                                    />
                                )}
                                defaultValue={groups.filter(vt => editingAnnouncement.visible_groups.includes(vt.id))}
                                sx={{ flexGrow: 1 }}
                                onChange={(event, value) => {
                                    const groupIds = value.map(vt => vt.id);
                                    setEditingAnnouncement({ ...editingAnnouncement, visible_groups: groupIds });
                                }}
                            />
                        )}

                        <TextField
                            fullWidth
                            size='small'
                            label='公告標題'
                            placeholder='請輸入公告標題'
                            value={editingAnnouncement.title || ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setEditingAnnouncement({ ...editingAnnouncement, title: e.target.value })}
                            error={!!errors.title}
                            helperText={errors.title}
                        />

                        <FormControl fullWidth error={errors.content}>
                            <ReactQuill
                                theme="snow"
                                value={editingAnnouncement.content || ''}
                                onChange={(value) => setEditingAnnouncement({ ...editingAnnouncement, content: value })}
                                modules={editModules}
                                placeholder="請輸入公告內容"
                            />
                            <FormHelperText>{errors.content}</FormHelperText>
                        </FormControl>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ width: '800px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['cancel-button']} onClick={handleEditCancel}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['submit-button']} onClick={handleEditSave}>
                        儲存
                        <SaveAs sx={{ width: '20px', height: '20px' }}/>
                    </Button>
                </DialogActions>
            </Dialog>
        )}

        {deleting && (
            <Dialog disableEscapeKeyDown open={deleting} onClose={handleDeletCancel} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        確定要刪除 {deletingAnnouncement?.title} 這則公告嗎？
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['cancel-button']} onClick={handleDeletCancel}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleConfirmDeleteAnnouncement}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>
        )}

    </Container>
  );
}

export default Announcement;
