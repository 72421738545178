import React, { useState, useEffect } from 'react';
import api from '../../config/axiosConfig';
import styles from './css/AccountingTab.module.css';
import { ReactComponent as NoData} from '../../pages/img/NoData.svg';
import { Box, Card, CardContent, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

const AccountingTab = ({year, month}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataResponse = await api.get(`/japan/api/dashboard/?type=order_data_by_month_for_accounting_detail&year=${year}&month=${month}`);
                setData(dataResponse.data.orders);
                setLoading(false);
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [year, month]);

    return (
        <Box className={styles['main-box']}>
            <Card className={styles['card']}>
                <CardContent className={styles['cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

                    <Box className={styles['cardcontent-title-box']}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Typography className={styles['title-label']}>
                                    訂單團號
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography className={styles['title-label']}>
                                    狀態
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={styles['title-label']}>
                                    客戶名稱
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography className={styles['title-label']}>
                                    作業金
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography className={styles['title-label']}>
                                    尾款
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography className={styles['title-label']}>
                                    全額
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography className={styles['title-label']}>
                                    代墊款
                                </Typography>
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography className={styles['title-label']}>
                                    備註
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={styles['cardcontent-content-box']}>
                        <Grid container>
                            {data.map((item, index) => (
                                <Grid item xs={12} key={index} sx={{ position: 'relative', ':hover': { background: '#ebf1fd' } }}>
                                    <Box className={styles['status-color-box']} sx={{ height: '100%', position: 'absolute', borderRadius: '0 5px 5px 0', borderLeft: '5px solid', borderLeftColor: item.order_status === '未付款' ? '#b8b5b5' : item.order_status === '作業金已付' ? '#94cf73' : item.order_status === '尾款已付' ? '#e8ab65' : item.order_status === '全額已付' ? '#5e91f0' : '#e38785' }} />
                                    <Grid container spacing={1} className={styles['content-grid']}>
                                        <Grid item xs={2}>
                                            <Typography className={styles['content-info']}>
                                                {item.group_number}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography className={styles['content-info']} sx={{ color: item.order_status === '未付款' ? '#b8b5b5' : item.order_status === '作業金已付' ? '#94cf73' : item.order_status === '尾款已付' ? '#e8ab65' : item.order_status === '全額已付' ? '#5e91f0' : '#e38785' }}>
                                                {item.order_status}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={styles['content-info']}>
                                                {item.customer_name || (item.peers_sales__peers_company__peers_company_name + ' - ' + item.peers_sales__peers_sales_name) || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography className={styles['content-info']}>
                                                {item.operation_fee ? (item.operation_fee_currency === 'JPY' ? '￥' : item.operation_fee_currency === 'TWD' ? 'NT$' : '') : ''}{item.operation_fee || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography className={styles['content-info']}>
                                                {item.remaining_fee ? (item.remaining_fee_currency === 'JPY' ? '￥' : item.remaining_fee_currency === 'TWD' ? 'NT$' : '') : ''}{item.remaining_fee || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography className={styles['content-info']}>
                                                {item.full_amount ? (item.full_amount_currency === 'JPY' ? '￥' : item.full_amount_currency === 'TWD' ? 'NT$' : '') : ''}{item.full_amount || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography className={styles['content-info']}>
                                                {item.advance_amount_total || '–'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0.5}>
                                            <Typography className={styles['content-info']}>
                                                <Tooltip
                                                    placement='top'
                                                    title={
                                                        item.orderfortable__orderfortable_notes &&
                                                        <Typography sx={{ fontSize: '15px' }}>
                                                            {item.orderfortable__orderfortable_notes || '沒有任何備註'}
                                                        </Typography>
                                                    }
                                                    sx={{ m: 0 }}
                                                >
                                                    <Info color={item.orderfortable__orderfortable_notes ? 'info' : 'disabled'} sx={{ width: '15px', height: '15px' }}/>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant='middle' sx={{ marginLeft: '13px' }} />
                                </Grid>
                            ))}
                            {!loading && data.length === 0 && (
                                <Grid item xs={12}>
                                    <Box className={styles['no-data']}>
                                        <NoData style={{ width: '300px', height: '300px' }} />
                                        目前沒有任何訂單數據
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                </CardContent>
            </Card>
        </Box>
    );
}

export default AccountingTab;