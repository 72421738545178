import React, { useState, useEffect } from 'react';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import { Alert, Box, Button, Container, TextField, Grid, Paper, Typography, Snackbar, Avatar, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { Edit, InfoOutlined, Lock, LockOpen, SaveAs } from '@mui/icons-material';
import api from '../config/axiosConfig';
import styles from './css/Setting.module.css';
import UserGroupContext from '../contexts/UserGroupContext';
import {BACKEND_URL} from '../config/axiosConfig';
// import LineQRCode from './img/LineQRCode-260ehspv.svg';
import { ReactComponent as LineQRCodeImg } from './img/LineQRCode-989zaqtr.svg';
import styled from '@emotion/styled';

function UserProfileSettings({ CurrentUser, onUserChange }) {
  const userGroups = React.useContext(UserGroupContext);
  const [userData, setUserData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [passwordData, setPasswordData] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  });
  const [showLineQRCode, setShowLineQRCode] = useState(false);
  const [showLineUUID, setShowLineUUID] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, [hasUnsavedChanges]);

  useEffect(() => {
    document.title = '個人資料設定';
    setHasUnsavedChanges(false);
    setUserData(CurrentUser);

    if (!CurrentUser?.profile?.line_uuid) {
      setShowLineUUID(true);
    }

  }, [setHasUnsavedChanges, CurrentUser]);

  const handleShowLineUUID = () => {
    setShowLineUUID(!showLineUUID);
  };

  const LineQRCodeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(`
      color: black;
      text-align: center;
      background-color: white;
      border: 1.5px solid #ccc;
      border-radius: 15px;
  `);
  

  const handleSave = (event) => {
    event.preventDefault();
    const formData = new FormData();

    if (selectedFile) {
      formData.append('profile.photo', selectedFile);
    }

    formData.append('profile.nickname', userData.profile.nickname);
    formData.append('email', userData.email);
    formData.append('profile.phone', userData.profile.phone);
    formData.append('profile.line_uuid', userData.profile.line_uuid);

    if (passwordData.old_password) {
      if (passwordData.new_password) {
        if (passwordData.confirm_new_password) {
          if (passwordData.new_password === passwordData.confirm_new_password) {
            formData.append('old_password', passwordData.old_password);
            formData.append('new_password', passwordData.new_password);
            formData.append('confirm_new_password', passwordData.confirm_new_password);
          } else {
            setErrors({confirm_new_password: '新密碼不一致'});
            setSubmitStatus('error');
            return;
          }
        } else {
          setErrors({confirm_new_password: '請重複輸入新密碼'});
          setSubmitStatus('error');
          return;
        }
      } else {
        setErrors({new_password: '請輸入新密碼'});
        setSubmitStatus('error');
        return;
      }
    }

    api.patch('/core/api/update_user_info/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        setSubmitStatus('success');
        setErrors({});
        setHasUnsavedChanges(false);
        onUserChange(response.data);
        setSelectedFile(null);
        setPasswordData({old_password: '', new_password: '', confirm_new_password: ''});
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setErrors(error.response.data);
        } else {
          console.error("Error updating user data: ", error);
        }
        setSubmitStatus('error');
      });
  };

  return (
    <Container component="main" maxWidth={false} sx={{ py: 3 }}>
      
      <Typography className={styles['text-h1']} variant="h5" gutterBottom>
        個 人 資 料 設 定
      </Typography>

      <form onSubmit={handleSave}>

        <Grid container direction="column" alignItems="center" spacing={3} sx={{ py: 2}}>

          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h6">基本資料</Typography>
              <Box
                textAlign="center"
                position="relative"
                display="inline-block"
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                    setHasUnsavedChanges(true);
                  }}
                  style={{ display: 'none' }}
                />
                <Avatar
                  src={selectedFile ? URL.createObjectURL(selectedFile) : `${BACKEND_URL + CurrentUser.profile.photo}?${new Date().getTime()}`}
                  sx={{ width: 100, height: 100, margin: 'auto' }}
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    width: 101,
                    height: 101,
                    top: '50%',
                    right: '50%',
                    transform: 'translate(50%, -50%)',
                    opacity: 0,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(22, 28, 36)',
                      opacity: 0.8,
                    },
                  }}
                  disableRipple
                  onClick={() => document.querySelector('input[type="file"]').click()}
                >
                  <Edit />
                </IconButton>
              </Box>
              <TextField
                label="暱稱/別名"
                fullWidth
                margin="normal"
                value={userData?.profile?.nickname || ''}
                onChange={e => {
                  setUserData({ ...userData, profile: { ...userData.profile, nickname: e.target.value } });
                  setHasUnsavedChanges(true);
                }}
                error={Boolean(errors?.profile?.nickname)}
                helperText={errors?.profile?.nickname}
              />
              <TextField
                label="電子郵件Email"
                fullWidth
                margin="normal"
                value={userData?.email || ''}
                onChange={e => {
                  setUserData({ ...userData, email: e.target.value });
                  setHasUnsavedChanges(true);
                }}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
              <TextField
                label="聯絡電話"
                fullWidth
                margin="normal"
                value={userData?.profile?.phone || ''}
                onChange={e => {
                  setUserData({ ...userData, profile: { ...userData.profile, phone: e.target.value } });
                  setHasUnsavedChanges(true);
                }}
                error={Boolean(errors?.profile?.phone)}
                helperText={errors?.profile?.phone}
              />
              <TextField
                label="Line使用者ID"
                fullWidth
                margin="normal"
                value={userData?.profile?.line_uuid || ''}
                onChange={e => {
                  setUserData({ ...userData, profile: { ...userData.profile, line_uuid: e.target.value } });
                  setHasUnsavedChanges(true);
                }}
                disabled={!showLineUUID}
                InputProps={CurrentUser?.profile?.line_uuid ? { 
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="點擊解鎖 / 鎖定" placement="top">
                        <IconButton
                          onClick={() => {
                            handleShowLineUUID();
                          }}
                        >
                          {showLineUUID ? <LockOpen /> : <Lock />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                } : {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LineQRCodeTooltip
                        title={
                          <>
                            <Typography sx={{ marginTop: "10px", fontWeight: "bold"}}>掃描下方QR Code添加系統帳號</Typography>
                            <Typography variant='subtitle2' color='GrayText' >若已加入過，請封鎖再解除封鎖即可</Typography>
                            <LineQRCodeImg style={{ maxWidth: "240px", maxHeight: "240px"}} />
                          </>
                        }
                        placement="left"
                        open={showLineQRCode}
                        onOpen={() => setShowLineQRCode(true)}
                        onClose={() => setShowLineQRCode(false)}
                      >
                        <IconButton disableRipple onClick={() => setShowLineQRCode(!showLineQRCode)}>
                          <InfoOutlined />
                        </IconButton>
                      </LineQRCodeTooltip>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errors?.profile?.line_uuid)}
                helperText={errors?.profile?.line_uuid}
              />
              <TextField
                fullWidth
                label="身分組"
                value={userGroups || ''}
                margin="normal"
                inputProps={{ readOnly: true }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h6">密碼變更</Typography>
              <TextField
                type="password"
                label="舊密碼"
                fullWidth
                margin="normal"
                autocomplete="current-password"
                onChange={e => {
                  setPasswordData({ ...passwordData, old_password: e.target.value });
                  setHasUnsavedChanges(true);
                }}
                error={Boolean(errors.old_password)}
                helperText={errors.old_password}
              />
              <TextField
                type="password"
                label="新密碼"
                fullWidth
                margin="normal"
                autoComplete='new-password'
                onChange={e => {
                  setPasswordData({ ...passwordData, new_password: e.target.value });
                  setHasUnsavedChanges(true);
                }}
                error={Boolean(errors.new_password)}
                helperText={errors.new_password}
              />
              <TextField
                type="password"
                label="確認新密碼"
                fullWidth
                margin="normal"
                autoComplete='new-password'
                onChange={e => {
                  setPasswordData({ ...passwordData, confirm_new_password: e.target.value });
                  setHasUnsavedChanges(true);
                }}
                error={Boolean(errors.confirm_new_password)}
                helperText={errors.confirm_new_password}
              />
            </Paper>
          </Grid>

        </Grid>

      </form>

      <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          <SaveAs /> 保存變更
        </Button>
      </Box>

      <Snackbar
        open={submitStatus === 'success'}
        autoHideDuration={6000}
        onClose={() => setSubmitStatus(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSubmitStatus(null)} severity="success" sx={{ width: '100%' }}>
            變更保存成功！
        </Alert>
      </Snackbar>

      <Snackbar
        open={submitStatus === 'error'}
        autoHideDuration={6000}
        onClose={() => setSubmitStatus(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSubmitStatus(null)} severity="error" sx={{ width: '100%' }}>
            變更保存失敗！
        </Alert>
      </Snackbar>

    </Container>
  );
}

export default UserProfileSettings;