import { useState, useEffect } from 'react';
import api from '../config/axiosConfig';

const useAuthToken = (initialToken) => {
    const [token, setToken] = useState(initialToken);

    useEffect(() => {
        const interval = setInterval(async () => {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    const response = await api.post('/core/api/token/refresh/', {
                        refresh: refreshToken,
                    });
                    const newAccessToken = response.data.access;
                    setToken(newAccessToken);
                    localStorage.setItem('accessToken', newAccessToken);
                } catch (error) {
                    console.error("Token刷新失敗: ", error);
                    setToken(null);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                }
            }
        }, 10 * 30 * 60 * 1000);

        return () => clearInterval(interval);
    }, [token]);

    return [token, setToken];
}

export default useAuthToken;