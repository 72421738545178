import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export function exportToExcel(order, jsonData) {
    // 排序數據
    jsonData.sort((a, b) => {
        if (a.room_allocation === b.room_allocation) {
            return new Date(a.birth_date) - new Date(b.birth_date);
        }
        return a.room_allocation - b.room_allocation;
    });

    // 創建工作簿
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('名單總表');

    // 添加第一行，合併A到H，內容是order變量
    worksheet.mergeCells('A1:H1');
    const orderCell = worksheet.getCell('A1');
    orderCell.value = order + "名單總表";
    orderCell.font = {
        name: '微軟正黑體',
        size: 15,
        bold: true
    };
    orderCell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
    };
    orderCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    // 添加第二行的標題
    const headers = ["分房代號", "姓名", "性別", "英文名稱", "出生日期", "護照號碼", "發照日期", "有效日期"];
    const headerRow = worksheet.addRow(headers);
    headerRow.font = { 
        name: '微軟正黑體',
        size: 13,
    };
    headerRow.eachCell(cell => {
        cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
        };
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    });

    // 添加數據
    jsonData.forEach(passenger => {
        const row = worksheet.addRow([
            passenger.room_allocation || '',
            passenger.chinese_name || '',
            passenger.gender || '',
            passenger.english_name || '',
            passenger.birth_date || '',
            passenger.passport_number || '',
            passenger.passport_issue_date || '',
            passenger.passport_expiry_date || ''
        ]);
        row.eachCell((cell) => {
            cell.font = {
                name: '微軟正黑體',
                size: 12
            };
            cell.alignment = { 
                vertical: 'middle',
                horizontal: 'center'
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
    });

    // // 單獨設置每一列的寬度
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;

    // 合併相同的 "分房代號" 欄位
    let mergeStart = 3; // 起始行為第三行（索引為2）
    for (let i = 1; i < jsonData.length; i++) {
        if (jsonData[i].room_allocation !== jsonData[i - 1].room_allocation) {
            if (mergeStart < i + 2) {
                worksheet.mergeCells(`A${mergeStart}:A${i + 2}`);
            }
            mergeStart = i + 3;
        }
    }
    if (mergeStart < jsonData.length + 3) {
        worksheet.mergeCells(`A${mergeStart}:A${jsonData.length + 2}`);
    }

    // 設置第一行的行高
    worksheet.getRow(1).height = 25;

    // 設置其他行的行高
    for (let i = 2; i <= jsonData.length + 2; i++) {
        worksheet.getRow(i).height = 20;
    }

    // 生成 Excel 文件並保存
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, order + '名單總表.xlsx');
    });
}

export function exportDashBoardDataToExcel(data, dataSources) {
    // 第一行的標題: dataSources.year年dataSources.month月數據，如果有dataSources.salesRep則加上dataSources.salesRep
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('數據總表');
    worksheet.mergeCells('A1:G1');
    const title = worksheet.getCell('A1');
    title.value = `${dataSources.year}年${dataSources.month}月數據`;
    if (dataSources.salesRep) {
        title.value += `(${dataSources.salesRep})`;
    }
    title.font = {
        name: '微軟正黑體',
        size: 15,
        bold: true
    };
    title.alignment = {
        vertical: 'middle',
        horizontal: 'center'
    };
    title.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    // 第二行的標題: 訂單團號、外幣報價、預估外幣報價、外站報價、代墊款、獲利、外站代收
    const headers = ['訂單團號', '外幣報價', '預估外幣報價', '外站報價', '代墊款', '獲利', '外站代收'];
    const headerRow = worksheet.addRow(headers);
    headerRow.font = {
        name: '微軟正黑體',
        size: 12,
        bold: true
    };
    headerRow.eachCell(cell => {
        cell.alignment = {
            vertical: 'middle',
            horizontal: 'left'
        };
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    });

    // 添加數據: data.group_number、data.orderfortable__fc_price、data.orderfortable__fc_price_estimated、data.charterbooking__booking_price、data.total_advance_amount、獲利=外幣報價-(外站報價+代墊款)、data.orderfortable__jp_recive_price
    data.forEach(d => {
        const row = worksheet.addRow([
            d.group_number,
            Number(d.orderfortable__fc_price) || 0,
            Number(d.orderfortable__fc_price_estimated) || 0,
            Number(d.charterbooking__booking_price) || 0,
            Number(d.total_advance_amount) || 0,
            { formula: `B${worksheet.rowCount + 1} - (D${worksheet.rowCount + 1} + E${worksheet.rowCount + 1})`, result: Number(d.orderfortable__fc_price) - (Number(d.charterbooking__booking_price) + Number(d.total_advance_amount)) || 0 },
            Number(d.orderfortable__jp_recive_price) || 0
        ]);
        row.eachCell(cell => {
            cell.font = {
                name: '微軟正黑體',
                size: 10
            };
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'left'
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
    });

    // 增加總計行
    const totalRow = worksheet.addRow([
        '總計',
        { formula: `SUM(B3:B${worksheet.rowCount})`, result: data.reduce((acc, cur) => acc + Number(cur.orderfortable__fc_price), 0) },
        { formula: `SUM(C3:C${worksheet.rowCount})`, result: data.reduce((acc, cur) => acc + Number(cur.orderfortable__fc_price_estimated), 0) },
        { formula: `SUM(D3:D${worksheet.rowCount})`, result: data.reduce((acc, cur) => acc + Number(cur.charterbooking__booking_price), 0) },
        { formula: `SUM(E3:E${worksheet.rowCount})`, result: data.reduce((acc, cur) => acc + Number(cur.total_advance_amount), 0) },
        { formula: `SUM(F3:F${worksheet.rowCount})`, result: data.reduce((acc, cur) => acc + (Number(cur.orderfortable__fc_price) - (Number(cur.charterbooking__booking_price) + Number(cur.total_advance_amount))), 0) },
        { formula: `SUM(G3:G${worksheet.rowCount})`, result: data.reduce((acc, cur) => acc + Number(cur.orderfortable__jp_recive_price), 0) }
    ]);
    totalRow.eachCell(cell => {
        cell.font = {
            name: '微軟正黑體',
            size: 11
        };
        cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
        };
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    });

    // 設置每一列的寬度
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    
    // 設置第一行的行高
    worksheet.getRow(1).height = 25;

    // 設置其他行的行高
    for (let i = 2; i <= data.length + 2; i++) {
        worksheet.getRow(i).height = 20;
    }

    // 設置總計行的行高
    worksheet.getRow(data.length + 3).height = 20;

    // 凍結第二行
    worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 2 }
    ];

    // 生成 Excel 文件並保存
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, dataSources.salesRep ? `${dataSources.year}年${dataSources.month}月數據 - ${dataSources.salesRep}.xlsx` : `${dataSources.year}年${dataSources.month}月數據.xlsx`);
    });
}