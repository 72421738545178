import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import UserGroupContext from './contexts/UserGroupContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { UnsavedChangesProvider } from './contexts/UnsavedChangesContext';
import api from './config/axiosConfig';
import useAuthToken from './hooks/useAuthToken';
import { CircularProgress } from '@mui/material';
import Layout from './Layout/Layout';
import Login from './pages/Login';
import Announcement from './pages/Announcement';
import DashBoard from './pages/DashBoard';
import JapanOrderCreate from './Japan/pages/OrderCreate';
import JapanOrderTable from './Japan/pages/OrderTable';
import JapanCharterBooking from './Japan/pages/CharterBooking';
import JapanAccountingTable from './Japan/pages/AccountingTable';
import JapanManage from './Japan/pages/Manage';
import JapanOrderInformation from './Japan/pages/OrderInformation';
import JapanContactRecord from './Japan/pages/ContactRecord';
import JapanOtherRelated from './Japan/pages/OtherRelated';
import GlobalSetting from './pages/GlobalSetting';
import Setting from './pages/Setting';
import ChangeLog from './pages/ChangeLog';
import EmployeeInfo from './pages/EmployeeInfo';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  const [token, setToken] = useAuthToken(localStorage.getItem('accessToken'));
  const [user, setUser] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [isUserGroupsLoaded, setIsUserGroupsLoaded] = useState(false);

  useEffect(() => {
      if (!token) {
          return;
      }
      api.get('/core/api/get_user_info/')
          .then(response => {
            // console.log('User Info Loaded', response.data);
            setUser(response.data);
            setUserGroups(response.data.groups.map(group => group.name));
            setIsUserGroupsLoaded(true);
          });
  }, [token]);

  const handleUserChange = (updatedUser) => {
    setUser(updatedUser);
  };

  const RequireAuth = ({ children }) => {
    let location = useLocation();
    // console.log('RequireAuth Check', { token, isUserGroupsLoaded, location });
    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  };

  const RedirectIfLoggedIn = ({ children }) => {
    if (token) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  if (token && !isUserGroupsLoaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress color="info" />
      </div>    
    );
  }

  return (
    <UnsavedChangesProvider>
      <UserGroupContext.Provider value={userGroups}>
        <Router>
          <NotificationProvider token={token}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={5000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Routes>
                <Route 
                  path="/login" 
                  element={
                    <RedirectIfLoggedIn>
                      <Login setToken={setToken} />
                    </RedirectIfLoggedIn>
                  } 
                />
                <Route 
                  path="*" 
                  element={
                    <RequireAuth>
                      <Layout setToken={setToken} CurrentUser={user}>
                        <Routes>
                          <Route path="/Announcement" element={<Announcement CurrentUser={user} />} />
                          <Route path="/Dashboard" element={userGroups.includes("管理員") || userGroups.includes("會計人員") || userGroups.includes("日本線管理") ? <DashBoard /> : <Navigate to="/" />} />
                          <Route path="/Japan/order-create" element={userGroups.includes("日方人員") ? <Navigate to="/" /> : <JapanOrderCreate CurrentUser={user} />} />
                          <Route path="/Japan/order-table" element={userGroups.includes("日方人員") ? <Navigate to="/" /> : <JapanOrderTable CurrentUser={user} />} />
                          <Route path="/Japan/charterbooking-table" element={<JapanCharterBooking CurrentUser={user} />} />
                          <Route path="/Japan/accounting-table" element={userGroups.includes("管理員") || userGroups.includes("會計人員") ?  <JapanAccountingTable /> : <Navigate to="/" />} />
                          <Route path="/Japan/contact-record" element={<JapanContactRecord />} />
                          <Route path="/Japan/other-related" element={userGroups.includes("日方人員") ? <Navigate to="/" /> : <JapanOtherRelated />} />
                          <Route path="/Japan/manage" element={userGroups.includes("管理員") || userGroups.includes("日本線管理") ? <JapanManage /> : <Navigate to="/" />} />
                          <Route path="/Japan/order-information/:uuid" element={<JapanOrderInformation CurrentUser={user} />} />
                          <Route path="/Global-Setting" element={userGroups.includes("管理員") ? <GlobalSetting /> : <Navigate to="/" />} />
                          <Route path="/Setting" element={<Setting CurrentUser={user} onUserChange={handleUserChange} />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </Layout>
                    </RequireAuth>
                  } 
                />
                <Route
                  path='/EmployeeInfo/:id'
                  element={
                    <RequireAuth>
                      {userGroups.includes("管理員") || userGroups.includes("會計人員") ? <EmployeeInfo /> : <Navigate to="/" />}
                    </RequireAuth>
                  }
                />
                <Route
                  path="/ChangeLogs"
                  element={
                    <RequireAuth>
                      <ChangeLog />
                    </RequireAuth>
                  }
                />
              </Routes>
            </SnackbarProvider>
          </NotificationProvider>
        </Router>
      </UserGroupContext.Provider>
    </UnsavedChangesProvider>
  );
}

export default App;