import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import api from '../../../config/axiosConfig';
import { exportToExcel } from '../../../utils/exportUtils';
import { Alert, Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, Menu, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { AddCircle, Delete, DeleteForever, DoDisturbOn, Download, EditNote, SaveAs, Warning } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/RequestTab.module.css';

const RequestTab = ({ uuid, options }) => {
    const [data, setData] = useState(null);
    const [customerType, setCustomerType] = useState(null);
    const [tab, setTab] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {
                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);
                setCustomerType(dataResponse.data?.peers_sales === null ? 'default' : 'peersSales');

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExportRequest = (urlPath, fileName) => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            return;
        }
    
        setLastClickTime(now);
    
        handleMenuClose();
        // setIsLoading(true);
    
        const uniqueUrl = `${urlPath}?t=${now}`;
        
        api.get(uniqueUrl, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(error => {
            console.error('下載發生錯誤:', error);
        })
        .finally(() => {
            // setIsLoading(false);
        });
    }

    const handleCustomerTypeChange = (event) => {
        setCustomerType(event.target.value);
        if (event.target.value === 'default') {
            setEditData({
                ...editData,
                customer_name: editData?.customer_name || data?.customer_name || '',
                customer_phone: editData?.customer_phone || data?.customer_phone || '',
                communication_app: editData?.communication_app || data?.communication_app || '',
                communication_id: editData?.communication_id || data?.communication_id || '',
                customer_email: editData?.customer_email || data?.customer_email || ''
            });
        } else if (event.target.value === 'peersSales') {
            setEditData({
                ...editData,
                peers_company_id: editData?.peers_company_id || data?.peers_sales?.peers_company?.id || null,
                peers_sales_id: editData?.peers_sales_id || data?.peers_sales?.id || null
            });
        }
    };

    const handleAddNewItinerary = () => {
        setEditData({
            ...editData,
            newItineraries: [
                ...(editData?.newItineraries || []),
                {
                    identifier: uuidv4(),
                    itinerary_date: null,
                    reception_hours: null,
                    activity: '',
                    itinerary_hotel: '',
                    room_reservation: '',
                    service_info: null,
                    itinerary_notes: ''
                }
            ]
        });
    }

    const handleAddNewPassenger = () => {
        setEditData({
            ...editData,
            newPassengers: [
                ...(editData?.newPassengers || []),
                {
                    identifier: uuidv4(),
                    chinese_name: '',
                    english_name: '',
                    gender: '',
                    id_number: '',
                    birth_date: null,
                    passport_number: '',
                    passport_issue_date: null,
                    passport_expiry_date: null,
                    room_allocation: '',
                    passenger_notes: '',
                }
            ]
        });
    };

    const handleAddNewAdditionalItem = () => {
        const itineraryCopy = data?.itineraries.map(itinerary => {
            const { id, order, ...rest } = itinerary;
            return {
                ...rest,
                identifier: uuidv4()
            };
        });

        setEditData({
            ...editData,
            newAdditionalItems: [
                ...(editData?.newAdditionalItems || []),
                {
                    identifier: uuidv4(),
                    item_name: '',
                    item_currency: null,
                    item_price: '',
                    item_due_date: null,
                    item_description: '',
                    additionalitemitineraries: itineraryCopy
                }
            ]
        });
    };

    const handleAddNewAdditionalItemItinerary = (idOrIdentifier, isNew = false) => {
        setEditData(prevEditData => {
            const additionalItem = 
                prevEditData?.additionalitems?.find(item => item.id === idOrIdentifier || item.identifier === idOrIdentifier) ||
                prevEditData?.newAdditionalItems?.find(item => item.identifier === idOrIdentifier);
            
            // console.log(additionalItem || '找不到對應的追加項目');
    
            if (additionalItem && !isNew) {
                return {
                    ...prevEditData,
                    additionalitems: prevEditData?.additionalitems.map(item => {
                        if (item.id === additionalItem.id) {
                            return {
                                ...item,
                                newAdditionalItemItineraries: [
                                    ...(item.newAdditionalItemItineraries || []), // 確保初始化 newAdditionalItemItineraries
                                    {
                                        identifier: uuidv4(),
                                        itinerary_date: null,
                                        reception_hours: null,
                                        activity: '',
                                        itinerary_hotel: '',
                                        room_reservation: '',
                                        service_info: null,
                                        itinerary_notes: ''
                                    }
                                ]
                            };
                        }
                        return item;
                    })
                };
            } else if (!additionalItem && !isNew) {
                return {
                    ...prevEditData,
                    additionalitems: [
                        ...(prevEditData?.additionalitems || []),
                        {
                            id: idOrIdentifier, // 使用 idOrIdentifier 作為 id
                            newAdditionalItemItineraries: [
                                {
                                    identifier: uuidv4(),
                                    itinerary_date: null,
                                    reception_hours: null,
                                    activity: '',
                                    itinerary_hotel: '',
                                    room_reservation: '',
                                    service_info: null,
                                    itinerary_notes: ''
                                }
                            ]
                        }
                    ]
                };
            } else if (isNew) {
                return {
                    ...prevEditData,
                    newAdditionalItems: prevEditData?.newAdditionalItems.map(item => {
                        if (item.identifier === idOrIdentifier) {
                            return {
                                ...item,
                                newAdditionalItemItineraries: [
                                    ...(item.newAdditionalItemItineraries || []), // 確保初始化 newAdditionalItemItineraries
                                    {
                                        identifier: uuidv4(),
                                        itinerary_date: null,
                                        reception_hours: null,
                                        activity: '',
                                        itinerary_hotel: '',
                                        room_reservation: '',
                                        service_info: null,
                                        itinerary_notes: ''
                                    }
                                ]
                            };
                        }
                        return item;
                    })
                };
            }
    
            return prevEditData;
        });
    };    

    const handleFieldChange = (arrayName, fieldName, fieldValue, idOrIdentifier, isParentNew = false, nestedArrayName = null, parentId = null, isNestedNew = false) => {
        setEditData((prevState) => {
            let currentArray = prevState?.[arrayName] ? [...prevState[arrayName]] : [];
    
            if (parentId && nestedArrayName) {
                // 查找父級項目，無論父級是否是新的
                let parentIndex = currentArray.findIndex(item => item.id === parentId || item.identifier === parentId);
    
                // 如果父級不存在，創建父級數據
                if (parentIndex === -1) {
                    const newParentItem = {
                        [nestedArrayName]: [],
                        ...(isParentNew ? { identifier: parentId } : { id: parentId })
                    };
                    currentArray.push(newParentItem);
                    parentIndex = currentArray.length - 1;
                }
    
                // 獲取父級項目並找到其嵌套數組
                const parentItem = { ...currentArray[parentIndex] };
                let nestedArray = parentItem[nestedArrayName] ? [...parentItem[nestedArrayName]] : [];
    
                // 根據 isNestedNew 和 identifier/id 查找嵌套數據
                const nestedItemIndex = isNestedNew
                    ? nestedArray.findIndex(item => item.identifier === idOrIdentifier)
                    : nestedArray.findIndex(item => item.id === idOrIdentifier);
    
                if (nestedItemIndex !== -1) {
                    nestedArray[nestedItemIndex] = {
                        ...nestedArray[nestedItemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newNestedItem = {
                        [fieldName]: fieldValue
                    };
                    if (isNestedNew) {
                        newNestedItem.identifier = idOrIdentifier;
                    } else {
                        newNestedItem.id = idOrIdentifier;
                    }
                    nestedArray.push(newNestedItem);
                }
    
                parentItem[nestedArrayName] = nestedArray;
                currentArray[parentIndex] = parentItem;
    
            } else {
                // 操作父級數據（非嵌套）
                const itemIndex = currentArray.findIndex(item => item.id === idOrIdentifier || item.identifier === idOrIdentifier);
    
                if (itemIndex !== -1) {
                    currentArray[itemIndex] = {
                        ...currentArray[itemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newItem = {
                        [fieldName]: fieldValue
                    };
                    if (isParentNew) {
                        newItem.identifier = idOrIdentifier;
                    } else {
                        newItem.id = idOrIdentifier;
                    }
                    currentArray.push(newItem);
                }
            }
    
            return {
                ...prevState,
                [arrayName]: currentArray
            };
        });
    };

    const handleEditClick = () => {
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditData(null);
        if (data?.peers_sales === null) {
            setCustomerType('default');
        } else {
            setCustomerType('peersSales');
        }
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleDeleteClick = (deleteType, item) => {
        setDeleteItem({ deleteType, ...item });
        setDeleteCheckOpen(true);
    };

    const handleDeletCancelClick = (event, reason) => {
        // console.log(deleteItem);
        if (reason !== 'backdropClick') {
            setDeleteItem(null);
            setDeleteCheckOpen(false);
        }
    };

    const handleDeleteConfirmClick = async () => {
        switch (deleteItem.deleteType) {
            case 'Itinerary':
                try {
                    await api.delete(`/japan/api/Itinerary/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        itineraries: prevState.itineraries.filter(itinerary => itinerary.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newItinerary':
                setEditData((prevState) => ({
                    ...prevState,
                    newItineraries: prevState.newItineraries.filter(itinerary => itinerary.identifier !== deleteItem.identifier)
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'Passenger':
                try {
                    await api.delete(`/japan/api/Passenger/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        passengers: prevState.passengers.filter(passenger => passenger.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newPassenger':
                setEditData((prevState) => ({
                    ...prevState,
                    newPassengers: prevState.newPassengers.filter(passenger => passenger.identifier !== deleteItem.identifier)
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'AdditionalItem':
                try {
                    await api.delete(`/japan/api/AdditionalItem/${deleteItem.id}/`);
                    setData((prevState) => ({
                        ...prevState,
                        additionalitems: prevState.additionalitems.filter(item => item.id !== deleteItem.id)
                    }));
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'newAdditionalItem':
                setEditData((prevState) => ({
                    ...prevState,
                    newAdditionalItems: prevState.newAdditionalItems.filter(item => item.identifier !== deleteItem.identifier)
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'AdditionalItemItinerary':
                try {
                    await api.delete(`/japan/api/AdditionalItemItinerary/${deleteItem.id}/`);
                    setData((prevState) => {
                        const additionalitems = prevState.additionalitems.map(item => {
                            if (item.id === deleteItem.additional_item) {
                                return {
                                    ...item,
                                    additionalitemitineraries: item.additionalitemitineraries.filter(item => item.id !== deleteItem.id)
                                };
                            }
                            return item;
                        });
                        return {
                            ...prevState,
                            additionalitems
                        };
                    });
                    setDeleteItem(null);
                    setDeleteCheckOpen(false);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    setErrors(error.response.data);
                }
                break;
            case 'o-newAdditionalItemItinerary':
                setEditData((prevState) => ({
                    ...prevState,
                    additionalitems: prevState.additionalitems.map(item => {
                        const newAdditionalItemItineraries = item.newAdditionalItemItineraries.filter(item => item.identifier !== deleteItem.identifier);
                        return {
                            ...item,
                            newAdditionalItemItineraries
                        };
                    })
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            case 'n-newAdditionalItemItinerary':
                setEditData((prevState) => ({
                    ...prevState,
                    newAdditionalItems: prevState.newAdditionalItems.map(item => {
                        const newAdditionalItemItineraries = item.newAdditionalItemItineraries.filter(item => item.identifier !== deleteItem.identifier);
                        return {
                            ...item,
                            newAdditionalItemItineraries
                        };
                    })
                }));
                setDeleteItem(null);
                setDeleteCheckOpen(false);
                break;
            default:
                console.log(`未知的刪除類型: ${deleteItem.deleteType}`);
                break;
        }
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);
            
            const custom_errors = {};
            if (customerType === 'default') {
                if (editData?.customer_name === '') {
                    custom_errors.customer_name = ['客戶名稱不能為空'];
                }
                if (editData?.customer_phone === '') {
                    custom_errors.customer_phone = ['客戶電話不能為空'];
                }
                if (editData?.communication_app === '') {
                    custom_errors.communication_app = ['通訊軟體不能為空'];
                }
                if (editData?.communication_id === '') {
                    custom_errors.communication_id = ['通訊軟體ID不能為空'];
                }
            } else if (customerType === 'peersSales') {
                if (editData?.hasOwnProperty('peers_company_id') && editData?.peers_company_id === null) {
                    custom_errors.peers_company_id = ['同行業務公司不能為空'];
                }
                if (editData?.hasOwnProperty('peers_sales_id') && editData?.peers_sales_id === null) {
                    custom_errors.peers_sales_id = ['同行業務不能為空'];
                }
            }
            if (editData?.operation_fee_currency || editData?.operation_fee || editData?.operation_fee_due_date) {
                if (!editData?.operation_fee_currency) {
                    custom_errors.operation_fee_currency = ['作業金幣別不能為空'];
                }
                if (!editData?.operation_fee) {
                    custom_errors.operation_fee = ['作業金金額不能為空'];
                }
                if (!editData?.operation_fee_due_date) {
                    custom_errors.operation_fee_due_date = ['作業金付款截止日不能為空'];
                }
            }
            if (editData?.remaining_fee_currency || editData?.remaining_fee || editData?.remaining_fee_due_date) {
                if (!editData?.remaining_fee_currency) {
                    custom_errors.remaining_fee_currency = ['尾款幣別不能為空'];
                }
                if (!editData?.remaining_fee) {
                    custom_errors.remaining_fee = ['尾款金額不能為空'];
                }
                if (!editData?.remaining_fee_due_date) {
                    custom_errors.remaining_fee_due_date = ['尾款付款截止日不能為空'];
                }
            }
            if (editData?.full_amount_currency || editData?.full_amount || editData?.full_amount_due_date) {
                if (!editData?.full_amount_currency) {
                    custom_errors.full_amount_currency = ['全額幣別不能為空'];
                }
                if (!editData?.full_amount) {
                    custom_errors.full_amount = ['全額金額不能為空'];
                }
                if (!editData?.full_amount_due_date) {
                    custom_errors.full_amount_due_date = ['全額付款截止日不能為空'];
                }
            }

            if (Object.keys(custom_errors).length > 0) {
                setErrors(custom_errors);
                setStatus('save-error');
                return;
            }

            if (!editData) {
                setErrors(null);
                setEditMode(false);
                setEditData(null);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            const updateEditData = {
                ...editData,
                itineraries: [
                    ...(editData?.itineraries || []).map(itinerary => ({ ...itinerary, order: uuid })),
                    ...(editData?.newItineraries || []).map(itinerary => ({ ...itinerary, order: uuid }))
                ],
                passengers: [
                    ...(editData?.passengers || []).map(passenger => ({ ...passenger, order: uuid })),
                    ...(editData?.newPassengers || []).map(passenger => ({ ...passenger, order: uuid }))
                ],
                additionalitems: [
                    ...(editData?.additionalitems || []).map(item => ({
                        ...item,
                        order: uuid,
                        additionalitemitineraries: [
                            ...(item.additionalitemitineraries || []),
                            ...(item.newAdditionalItemItineraries || [])
                        ]
                    })),
                    ...(editData?.newAdditionalItems || []).map(item => ({
                        ...item,
                        order: uuid,
                        additionalitemitineraries: [
                            ...(item.additionalitemitineraries || []),
                            ...(item.newAdditionalItemItineraries || [])
                        ]
                    }))
                ]
            };

            delete updateEditData.peers_company_id;
            if (customerType === 'default') {
                delete updateEditData.peers_sales_id;
            }
            if (customerType === 'peersSales' && !updateEditData?.hasOwnProperty('peers_sales_id')) {
                updateEditData.peers_sales_id = data?.peers_sales?.id;
            }
            if (customerType === 'peersSales') {
                updateEditData.customer_name = '';
                updateEditData.customer_phone = '';
                updateEditData.communication_app = '';
                updateEditData.communication_id = '';
                updateEditData.customer_email = '';
            }
            delete updateEditData.newItineraries;
            if (updateEditData.itineraries.length === 0) {
                delete updateEditData.itineraries;
            }
            delete updateEditData.newPassengers;
            if (updateEditData.passengers.length === 0) {
                delete updateEditData.passengers;
            }
            delete updateEditData.newAdditionalItems;
            updateEditData.additionalitems.forEach(item => {
                delete item.newAdditionalItemItineraries;
            });
            if (updateEditData.additionalitems.length === 0) {
                delete updateEditData.additionalitems;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, updateEditData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Box className={styles['print-box']}>
                    <Button
                        className={styles['print-button']}
                        sx={{ 
                            '& .MuiButton-startIcon': {
                                margin: 0,
                                '&>*:nth-of-type(1)': {
                                    fontSize: '18px',
                                }
                            },
                        }}
                        disabled={editMode}
                        startIcon={<Download />}
                        onClick={handleMenuClick}
                    >
                        下載導出
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        {!data?.full_amount && !data?.operation_fee && !data?.remaining_fee && !data?.advancepayments.length &&
                            <MenuItem disabled>無可導出的檔案</MenuItem>
                        }

                        {data?.full_amount &&
                            <MenuItem onClick={() => handleExportRequest(`/japan/export_order_fullamount_request/${uuid}/`, `${data?.group_number} - 全額請求書.pdf`)}>全額請求書</MenuItem>
                        }

                        {data?.operation_fee &&
                            <MenuItem onClick={() => handleExportRequest(`/japan/export_order_operation_request/${uuid}/`, `${data?.group_number} - 作業金請求書.pdf`)}>作業金請求書</MenuItem>
                        }

                        {data?.remaining_fee &&
                            <MenuItem onClick={() => handleExportRequest(`/japan/export_order_remaining_request/${uuid}/`, `${data?.group_number} - 尾款請求書.pdf`)}>尾款請求書</MenuItem>
                        }

                        {data?.passengers.length > 0 &&
                            <MenuItem onClick={() => exportToExcel(data?.group_number, data?.passengers)}>旅客資訊</MenuItem>
                        }

                        {data?.additionalitems.length > 0 &&
                            data?.additionalitems?.map((additionalitem, index) => (
                                <MenuItem key={index} onClick={() => handleExportRequest(`/japan/export_order_additional_item_request/${uuid}/${additionalitem.id}/`, `${data?.group_number} - 追加費用請求書.pdf`)}>{additionalitem.item_name}追加費用請求書</MenuItem>
                            ))
                        }
                    </Menu>
                </Box>

                <Box className={styles['category-box']}>
                    <Button className={tab === 0 ? styles['category-button-selected'] : styles['category-button']} onClick={() => setTab(0)}>
                        訂單資料
                    </Button>
                    <Button className={tab === 1 ? styles['category-button-selected'] : styles['category-button']} onClick={() => setTab(1)}>
                        行程資訊
                    </Button>
                    <Button className={tab === 2 ? styles['category-button-selected'] : styles['category-button']} onClick={() => setTab(2)}>
                        旅客名單
                    </Button>
                    <Button className={tab === 3 ? styles['category-button-selected'] : styles['category-button']} onClick={() => setTab(3)}>
                        追加項目
                    </Button>
                </Box>
            </Box>

            <Box className={styles['content-box']}>

                {tab === 0 && data && (
                    <Box className={styles['tab-main-box']}>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label={"基本信息 / " + (customerType === 'default' ? '一般' : customerType === 'peersSales' ? '同行業務' : '未知')} />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        value={customerType}
                                        onChange={handleCustomerTypeChange}
                                    >
                                        <FormControlLabel value="default" disabled={!editMode} control={<Radio color="primary" />} label="一般" />
                                        <FormControlLabel value="peersSales" disabled={!editMode} control={<Radio color="primary" />} label="同行業務" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {customerType === 'default' && (
                                <>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.customer_name}
                                            helperText={errors?.customer_name}
                                            label="客戶名稱"
                                            variant="standard"
                                            value={editData?.customer_name === '' ? '' : editData?.customer_name || data?.customer_name}
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, customer_name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.customer_phone}
                                            helperText={errors?.customer_phone}
                                            label="客戶電話"
                                            variant="standard"
                                            value={editData?.customer_phone === '' ? '' : editData?.customer_phone || data?.customer_phone}
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, customer_phone: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={options?.order_options?.communication_app}
                                            getOptionLabel={(option) => option.label}
                                            value={options?.order_options?.communication_app.find(option => option.value === editData?.communication_app) || options?.order_options?.communication_app.find(option => option.value === data?.communication_app)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.communication_app}
                                                    helperText={errors?.communication_app}
                                                    required
                                                    label="通訊軟體"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => setEditData({ ...editData, communication_app: value?.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.communication_id}
                                            helperText={errors?.communication_id}
                                            label="通訊軟體ID"
                                            variant="standard"
                                            value={editData?.communication_id === '' ? '' : editData?.communication_id || data?.communication_id}
                                            required
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, communication_id: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="電子郵件/Email"
                                            variant="standard"
                                            value={editData?.customer_email === '' ? '' : editData?.customer_email || data?.customer_email}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, customer_email: e.target.value })}
                                        />
                                    </Grid>
                                </>
                            )}
                            {customerType === 'peersSales' && (
                                <>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            options={options?.order_options?.peers_sales.filter((v, i, a) => a.findIndex(t => (t.company_id === v.company_id)) === i)}
                                            getOptionLabel={(option) => option.company}
                                            value={options?.order_options?.peers_sales.find(option => option.value === editData?.peers_company_id) || options?.order_options?.peers_sales.find(option => option.company_id === data?.peers_sales?.peers_company?.id)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.peers_company_id}
                                                    helperText={errors?.peers_company_id}
                                                    required
                                                    label="同行業務"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => setEditData({ ...editData, peers_company_id: value?.company_id })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            options={options?.order_options?.peers_sales.filter(option => option.company_id === editData?.peers_company_id || option.company_id === data?.peers_sales?.peers_company?.id)}
                                            getOptionLabel={(option) => option.label}
                                            noOptionsText={editData?.peers_company_id ? '此同行公司旗下未配置任何業務' : '請先選擇同行公司'}
                                            value={options?.order_options?.peers_sales.find(option => option.value === editData?.peers_sales_id) || options?.order_options?.peers_sales.find(option => option.value === data?.peers_sales?.id)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.peers_sales_id}
                                                    helperText={errors?.peers_sales_id}
                                                    required
                                                    label="同行業務"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => setEditData({ ...editData, peers_sales_id: value?.value })}
                                        />
                                    </Grid>
                                    {!editMode && (
                                        <>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="同行業務電話"
                                                    variant="standard"
                                                    value={data?.peers_sales?.peers_sales_phone}
                                                    size='small'
                                                    fullWidth
                                                    focused={false}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="同行業務通訊軟體"
                                                    variant="standard"
                                                    value={data?.peers_sales?.peers_sales_communication_app}
                                                    size='small'
                                                    fullWidth
                                                    focused={false}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="同行業務通訊軟體ID"
                                                    variant="standard"
                                                    value={data?.peers_sales?.peers_sales_communication_id}
                                                    size='small'
                                                    fullWidth
                                                    focused={false}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="同行業務電子郵件/Email"
                                                    variant="standard"
                                                    value={data?.peers_sales?.peers_sales_email}
                                                    size='small'
                                                    fullWidth
                                                    focused={false}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body2' color='textSecondary' sx={{ textAlign: 'center' }}>
                                                    * 請注意：同行業務信息不可在此頁面修改，如需修改請至管理頁面進行操作
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="訂單資訊" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={6}>
                                <TextField
                                    error={errors?.group_number}
                                    helperText={errors?.group_number}
                                    label="團號"
                                    variant="standard"
                                    value={editData?.group_number === '' ? '' : editData?.group_number || data?.group_number}
                                    required
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, group_number: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={options?.order_options?.sales_rep}
                                    getOptionLabel={(option) => option.label}
                                    value={options?.order_options?.sales_rep.find(option => option.value === editData?.sales_rep_id) || options?.order_options?.sales_rep.find(option => option.value === data?.sales_rep?.id)}
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    readOnly={!editMode}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            error={errors?.sales_rep}
                                            helperText={errors?.sales_rep}
                                            label="承辦業務"
                                            variant="standard"
                                            required
                                            focused={editMode}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                    onChange={(e, value) => setEditData({ ...editData, sales_rep_id: value?.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.num_travelers}
                                    helperText={errors?.num_travelers}
                                    label="旅遊人數"
                                    variant="standard"
                                    value={editData?.num_travelers === '' ? '' : editData?.num_travelers || data?.num_travelers}
                                    required
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, num_travelers: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.travel_start_date}
                                    helperText={errors?.travel_start_date}
                                    label="出發日期"
                                    variant="standard"
                                    value={editData?.travel_start_date === null ? '' : editData?.travel_start_date || data?.travel_start_date}
                                    required
                                    type='date'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, travel_start_date: e.target.value || null })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.travel_end_date}
                                    helperText={errors?.travel_end_date}
                                    label="返回日期"
                                    variant="standard"
                                    value={editData?.travel_end_date === null ? '' : editData?.travel_end_date || data?.travel_end_date}
                                    required
                                    type='date'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, travel_end_date: e.target.value || null })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    options={options?.order_options?.service_type}
                                    getOptionLabel={(option) => option.label}
                                    value={options?.order_options?.service_type.find(option => option.value === editData?.service_type) || options?.order_options?.service_type.find(option => option.value === data?.service_type)}
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    readOnly={!editMode}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            error={errors?.service_type}
                                            helperText={errors?.service_type}
                                            label="包車內容"
                                            variant="standard"
                                            required
                                            focused={editMode}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                    onChange={(e, value) => setEditData({ ...editData, service_type: value?.value })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    options={options?.order_options?.charter_company}
                                    getOptionLabel={(option) => option.label}
                                    value={options?.order_options?.charter_company.find(option => option.value === editData?.charter_company_id) || options?.order_options?.charter_company.find(option => option.value === data?.charter_company?.id)}
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    readOnly={!editMode}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            error={errors?.charter_company_id}
                                            helperText={errors?.charter_company_id}
                                            label="包車公司"
                                            variant="standard"
                                            required
                                            focused={editMode}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                    onChange={(e, value) => setEditData({ ...editData, charter_company_id: value?.value, vehicle_type_id: null })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    options={options?.order_options?.vehicle_type.filter(option => option.charter_company === (editData?.charter_company_id || data?.charter_company?.id))}
                                    getOptionLabel={(option) => option.label}
                                    value={options?.order_options?.vehicle_type.find(option => option.value === (editData?.charter_company_id ? editData?.vehicle_type_id : data?.vehicle_type?.id))}
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    readOnly={!editMode}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            error={errors?.vehicle_type_id}
                                            helperText={errors?.vehicle_type_id}
                                            label="使用車型"
                                            variant="standard"
                                            required
                                            focused={editMode}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                    onChange={(e, value) => setEditData({ ...editData, vehicle_type_id: value?.value })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <TextField
                                    error={errors?.num_vehicles}
                                    helperText={errors?.num_vehicles}
                                    label="車輛數量"
                                    variant="standard"
                                    value={editData?.num_vehicles === '' ? '' : editData?.num_vehicles || data?.num_vehicles}
                                    required
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, num_vehicles: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <TextField
                                    error={errors?.area}
                                    helperText={errors?.area}
                                    label="地區"
                                    variant="standard"
                                    value={editData?.area === '' ? '' : editData?.area || data?.area}
                                    required
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, area: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.include_driver_accommodation}
                                    helperText={errors?.include_driver_accommodation}
                                    label="司機住宿天數"
                                    variant="standard"
                                    value={editData?.include_driver_accommodation === '' ? '' : editData?.include_driver_accommodation || data?.include_driver_accommodation}
                                    required
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, include_driver_accommodation: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.include_babyseat}
                                    helperText={errors?.include_babyseat}
                                    label="兒童座椅數量"
                                    variant="standard"
                                    value={editData?.hasOwnProperty('include_babyseat') ? editData.include_babyseat : data?.include_babyseat}
                                    required
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, include_babyseat: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    error={errors?.booster_seat}
                                    helperText={errors?.booster_seat}
                                    label="兒童增高墊數量"
                                    variant="standard"
                                    value={editData?.hasOwnProperty('booster_seat') ? editData.booster_seat : data?.booster_seat}
                                    required
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, booster_seat: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={Array.from({ length: 5 }, (_, i) => (i + 1).toString())}
                                    getOptionLabel={(option) => `${option} 歲`}
                                    filterSelectedOptions
                                    value={
                                        editData?.baby_age !== undefined
                                            ? (editData.baby_age !== '' ? editData.baby_age.split('、').map(age => age.trim()) : [])
                                            : (data?.baby_age !== undefined && data.baby_age !== '' 
                                                ? data.baby_age.split('、').map(age => age.trim()) 
                                                : [])
                                    }
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    multiple
                                    limitTags={4}
                                    readOnly={!editMode}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            error={errors?.baby_age}
                                            helperText={errors?.baby_age}
                                            label="兒童年齡"
                                            variant="standard"
                                            focused={editMode}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                    onChange={(e, value) => {
                                        const ageCount = value.length;
                                    
                                        setEditData({
                                            ...editData,
                                            baby_age: ageCount > 0 ? value.join('、') : '',
                                        });
                                    }}
                                />
                            </Grid>
                            {(editMode || data?.operation_fee) && (
                                <>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={options?.order_options?.operation_fee_currency}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                editData?.operation_fee_currency !== undefined 
                                                    ? options?.order_options?.operation_fee_currency.find(option => option.value === editData.operation_fee_currency) || null
                                                    : options?.order_options?.operation_fee_currency.find(option => option.value === data?.operation_fee_currency) || null
                                            }
                                            size='small'
                                            fullWidth
                                            readOnly={!editMode}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.operation_fee_currency}
                                                    helperText={errors?.operation_fee_currency}
                                                    label="作業金幣別"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => setEditData({ ...editData, operation_fee_currency: value?.value || '' })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.operation_fee}
                                            helperText={errors?.operation_fee}
                                            label="作業金金額"
                                            variant="standard"
                                            value={editData?.operation_fee === '' ? '' : editData?.operation_fee || parseFloat(data?.operation_fee).toFixed(2).replace(/\.00$/, '')}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => setEditData({ ...editData, operation_fee: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.operation_fee_due_date}
                                            helperText={errors?.operation_fee_due_date}
                                            label="作業金付款截止日"
                                            variant="standard"
                                            value={editData?.operation_fee_due_date === null ? '' : editData?.operation_fee_due_date || data?.operation_fee_due_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, operation_fee_due_date: e.target.value || null })}
                                        />
                                    </Grid>
                                </>
                            )}
                            {(editMode || data?.remaining_fee) && (
                                <>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={options?.order_options?.remaining_fee_currency}
                                            getOptionLabel={(option) => option.label}
                                            value={editData?.remaining_fee_currency !== undefined 
                                                ? options?.order_options?.remaining_fee_currency.find(option => option.value === editData.remaining_fee_currency) || null
                                                : options?.order_options?.remaining_fee_currency.find(option => option.value === data?.remaining_fee_currency) || null
                                            }
                                            size='small'
                                            fullWidth
                                            readOnly={!editMode}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.remaining_fee_currency}
                                                    helperText={errors?.remaining_fee_currency}
                                                    label="尾款幣別"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => setEditData({ ...editData, remaining_fee_currency: value?.value || '' })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.remaining_fee}
                                            helperText={errors?.remaining_fee}
                                            label="尾款金額"
                                            variant="standard"
                                            value={editData?.remaining_fee === '' ? '' : editData?.remaining_fee || parseFloat(data?.remaining_fee).toFixed(2).replace(/\.00$/, '')}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => setEditData({ ...editData, remaining_fee: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.remaining_fee_due_date}
                                            helperText={errors?.remaining_fee_due_date}
                                            label="尾款付款截止日"
                                            variant="standard"
                                            value={editData?.remaining_fee_due_date === null ? '' : editData?.remaining_fee_due_date || data?.remaining_fee_due_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, remaining_fee_due_date: e.target.value || null })}
                                        />
                                    </Grid>
                                </>
                            )}
                            {(editMode || data?.full_amount) && (
                                <>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            options={options?.order_options?.full_amount_currency}
                                            getOptionLabel={(option) => option.label}
                                            value={editData?.full_amount_currency !== undefined
                                                ? options?.order_options?.full_amount_currency.find(option => option.value === editData.full_amount_currency) || null
                                                : options?.order_options?.full_amount_currency.find(option => option.value === data?.full_amount_currency) || null
                                            }
                                            size='small'
                                            fullWidth
                                            readOnly={!editMode}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    error={errors?.full_amount_currency}
                                                    helperText={errors?.full_amount_currency}
                                                    label="全額幣別"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => setEditData({ ...editData, full_amount_currency: value?.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={errors?.full_amount}
                                            helperText={errors?.full_amount}
                                            label="全額金額"
                                            variant="standard"
                                            value={editData?.full_amount === '' ? '' : editData?.full_amount || parseFloat(data?.full_amount).toFixed(2).replace(/\.00$/, '')}
                                            type='number'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                },
                                                '& input[type="number"]': {
                                                    '-moz-appearance': 'textfield'
                                                }
                                            }}
                                            onChange={(e) => setEditData({ ...editData, full_amount: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={errors?.full_amount_due_date}
                                            helperText={errors?.full_amount_due_date}
                                            label="全額付款截止日"
                                            variant="standard"
                                            value={editData?.full_amount_due_date === null ? '' : editData?.full_amount_due_date || data?.full_amount_due_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setEditData({ ...editData, full_amount_due_date: e.target.value || null })}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={2}>
                                <Autocomplete
                                    options={options?.order_options?.total_amount_currency}
                                    getOptionLabel={(option) => option.label}
                                    value={options?.order_options?.total_amount_currency.find(option => option.value === editData?.total_amount_currency) || options?.order_options?.total_amount_currency.find(option => option.value === data?.total_amount_currency)}
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    readOnly={!editMode}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            error={errors?.total_amount_currency}
                                            helperText={errors?.total_amount_currency}
                                            label="總計幣別"
                                            variant="standard"
                                            required
                                            focused={editMode}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    }
                                    onChange={(e, value) => setEditData({ ...editData, total_amount_currency: value?.value })}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    error={errors?.total_amount}
                                    helperText={errors?.total_amount}
                                    label="總計金額"
                                    variant="standard"
                                    value={editData?.total_amount === '' ? '' : editData?.total_amount || parseFloat(data?.total_amount).toFixed(2).replace(/\.00$/, '')}
                                    required
                                    type='number'
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type="number"]': {
                                            '-moz-appearance': 'textfield'
                                        }
                                    }}
                                    onChange={(e) => setEditData({ ...editData, total_amount: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={errors?.order_notes1}
                                    helperText={errors?.order_notes1}
                                    label="其他備註"
                                    variant="standard"
                                    value={editData?.order_notes1 === '' ? '' : editData?.order_notes1 || data?.order_notes1}
                                    multiline
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, order_notes1: e.target.value })}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="額外資訊" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={6}>
                                <TextField
                                    error={errors?.arrival_flight_number}
                                    helperText={errors?.arrival_flight_number}
                                    label="抵達航班編號"
                                    variant="standard"
                                    value={editData?.arrival_flight_number === '' ? '' : editData?.arrival_flight_number || data?.arrival_flight_number}
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, arrival_flight_number: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={errors?.departure_flight_number}
                                    helperText={errors?.departure_flight_number}
                                    label="離開航班編號"
                                    variant="standard"
                                    value={editData?.departure_flight_number === '' ? '' : editData?.departure_flight_number || data?.departure_flight_number}
                                    size='small'
                                    fullWidth
                                    focused={editMode}
                                    InputProps={{
                                        readOnly: !editMode,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, departure_flight_number: e.target.value })}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label={"匯款資訊 / " + (editData?.remittance_info_id ? options?.order_options?.remittance_info.find(option => option.value === editData?.remittance_info_id)?.label : options?.order_options?.remittance_info.find(option => option.value === data?.remittance_info?.id)?.label)} />
                        </Divider>
                        {!editMode ? (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                {data?.remittance_info?.special_remit_info ? (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="特殊匯款資訊"
                                                variant="standard"
                                                value={data?.remittance_info?.special_remit_info}
                                                multiline
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="銀行代碼"
                                                variant="standard"
                                                value={data?.remittance_info?.bank_code}
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="匯款帳號"
                                                variant="standard"
                                                value={data?.remittance_info?.remittance_account}
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <TextField
                                        label="聯絡人"
                                        variant="standard"
                                        value={data?.remittance_info?.contact_person}
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="聯絡電話"
                                        variant="standard"
                                        value={data?.remittance_info?.contact_phone}
                                        size='small'
                                        fullWidth
                                        focused={editMode}
                                        InputProps={{
                                            readOnly: !editMode,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={options?.order_options?.remittance_info}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.order_options?.remittance_info.find(option => option.value === editData?.remittance_info_id) || options?.order_options?.remittance_info.find(option => option.value === data?.remittance_info?.id)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.remittance_info_id}
                                                helperText={errors?.remittance_info_id}
                                                label="匯款資訊"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => setEditData({ ...editData, remittance_info_id: value?.value })}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label={"注意事項 / " + (editData?.warning_type_id ? options?.order_options?.warning_type.find(option => option.value === editData?.warning_type_id)?.label : options?.order_options?.warning_type.find(option => option.value === data?.warning_type?.id)?.label)} />
                        </Divider>
                        {!editMode ? (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                {data?.warning_type?.warnings.map((warning, index) => (
                                    <Grid item xs={12} key={index}>
                                        <TextField
                                            label={"事項" + (index + 1)}
                                            variant="standard"
                                            value={warning.warning_note}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                ))}
                                {data?.warning_type?.warnings.length === 0 && (
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', textAlign: 'center' }}>注意事項 "{data?.warning_type?.warning_type}" 類別中，未包含任何注意事項內容</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={options?.order_options?.warning_type}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.order_options?.warning_type.find(option => option.value === editData?.warning_type_id) || options?.order_options?.warning_type.find(option => option.value === data?.warning_type?.id)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.warning_type_id}
                                                helperText={errors?.warning_type_id}
                                                label="注意事項"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => setEditData({ ...editData, warning_type_id: value?.value })}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label={"取消政策 / " + (editData?.policy_type_id ? options?.order_options?.policy_type.find(option => option.value === editData?.policy_type_id)?.label : options?.order_options?.policy_type.find(option => option.value === data?.policy_type?.id)?.label)} />
                        </Divider>
                        {!editMode ? (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 10px 16px' }}>
                                {data?.policy_type?.policies.map((policy, index) => (
                                    <Grid item xs={12} key={index}>
                                        <TextField
                                            label={"政策" + (index + 1)}
                                            variant="standard"
                                            value={policy.policy_note}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                ))}
                                {data?.policy_type?.policies.length === 0 && (
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', textAlign: 'center' }}>取消政策 "{data?.policy_type?.policy_type}" 類別中，未包含任何政策內容</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 10px 16px' }}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={options?.order_options?.policy_type}
                                        getOptionLabel={(option) => option.label}
                                        value={options?.order_options?.policy_type.find(option => option.value === editData?.policy_type_id) || options?.order_options?.policy_type.find(option => option.value === data?.policy_type?.id)}
                                        size='small'
                                        fullWidth
                                        disableClearable
                                        readOnly={!editMode}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={errors?.policy_type_id}
                                                helperText={errors?.policy_type_id}
                                                label="取消政策"
                                                variant="standard"
                                                required
                                                focused={editMode}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        onChange={(e, value) => setEditData({ ...editData, policy_type_id: value?.value })}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Box />

                    </Box>
                )}

                {tab === 1 && data && (
                    <Box className={styles['tab-main-box']}>

                        {data?.itineraries.map((itinerary, index) => (
                            <div key={itinerary.id}>
                                <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                    <Chip
                                        className={styles['chip-text']}
                                        label={"行程安排 #" + (index + 1)}
                                        onDelete={editMode ? null : () => handleDeleteClick('Itinerary', itinerary)}
                                        deleteIcon={editMode ? null : <DeleteForever className={styles['delete-icon']}/>}
                                    />
                                </Divider>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.itinerary_date}
                                            helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.itinerary_date}
                                            label="日期"
                                            variant="standard"
                                            value={
                                                editData?.itineraries?.find(it => it.id === itinerary.id)?.itinerary_date === null
                                                    ? '' 
                                                    : editData?.itineraries?.find(it => it.id === itinerary.id)?.itinerary_date || itinerary?.itinerary_date
                                            }
                                            required
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("itineraries", "itinerary_date", e.target.value || null, itinerary.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.reception_hours}
                                            helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.reception_hours}
                                            label="接待時間"
                                            variant="standard"
                                            value={
                                                editData?.itineraries?.find(it => it.id === itinerary.id)?.reception_hours === null
                                                    ? ''
                                                    : editData?.itineraries?.find(it => it.id === itinerary.id)?.reception_hours || itinerary?.reception_hours
                                            }
                                            type='time'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("itineraries", "reception_hours", e.target.value || null, itinerary.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={options?.itinerary_options?.room_reservation}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                options?.itinerary_options?.room_reservation.find(option => option.value === editData?.itineraries?.find(it => it.id === itinerary.id)?.room_reservation) 
                                                    || options?.itinerary_options?.room_reservation.find(option => option.value === itinerary?.room_reservation)
                                            }
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.room_reservation}
                                                    helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.room_reservation}
                                                    label="訂房資訊"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("itineraries", "room_reservation", value?.value, itinerary.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={options?.itinerary_options?.service_info}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                options?.itinerary_options?.service_info.find(option => option.value === editData?.itineraries?.find(it => it.id === itinerary.id)?.service_info) 
                                                    || options?.itinerary_options?.service_info.find(option => option.value === itinerary?.service_info)
                                            }
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.service_info}
                                                    helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.service_info}
                                                    label="服務內容"
                                                    variant="standard"
                                                    required
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("itineraries", "service_info", value?.value, itinerary.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.activity}
                                            helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.activity}
                                            label="行程內容"
                                            variant="standard"
                                            value={
                                                editData?.itineraries?.find(it => it.id === itinerary.id)?.activity === ''
                                                    ? ''
                                                    : editData?.itineraries?.find(it => it.id === itinerary.id)?.activity || itinerary?.activity
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                                inputProps: { style: { textAlign: 'center' }}
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("itineraries", "activity", e.target.value, itinerary.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.itinerary_hotel}
                                            helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.itinerary_hotel}
                                            label="飯店資訊"
                                            variant="standard"
                                            value={
                                                editData?.itineraries?.find(it => it.id === itinerary.id)?.itinerary_hotel === ''
                                                    ? ''
                                                    : editData?.itineraries?.find(it => it.id === itinerary.id)?.itinerary_hotel || itinerary?.itinerary_hotel
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                                inputProps: { style: { textAlign: 'center' }}
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("itineraries", "itinerary_hotel", e.target.value, itinerary.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.itinerary_notes}
                                            helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.itinerary_notes}
                                            label="備註"
                                            variant="standard"
                                            value={
                                                editData?.itineraries?.find(it => it.id === itinerary.id)?.itinerary_notes === ''
                                                    ? ''
                                                    : editData?.itineraries?.find(it => it.id === itinerary.id)?.itinerary_notes || itinerary?.itinerary_notes
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("itineraries", "itinerary_notes", e.target.value, itinerary.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {!editMode && (
                            <Box />
                        )}

                        {editData?.newItineraries?.map((itinerary, index) => (
                            <div key={itinerary.identifier}>
                                <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                    <Chip className={styles['chip-text']} label={"當前新增行程安排 #" + (index + 1)} onDelete={() => handleDeleteClick('newItinerary', itinerary)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                                </Divider>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                            helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                            label="日期"
                                            variant="standard"
                                            value={itinerary.itinerary_date}
                                            required
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newItineraries", "itinerary_date", e.target.value || null, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                            helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                            label="接待時間"
                                            variant="standard"
                                            value={itinerary.reception_hours}
                                            type='time'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newItineraries", "reception_hours", e.target.value || null, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={options?.itinerary_options?.room_reservation}
                                            getOptionLabel={(option) => option.label}
                                            value={options?.itinerary_options?.room_reservation.find(option => option.value === itinerary.room_reservation)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                    helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                    label="訂房資訊"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("newItineraries", "room_reservation", value?.value, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            options={options?.itinerary_options?.service_info}
                                            getOptionLabel={(option) => option.label}
                                            value={options?.itinerary_options?.service_info.find(option => option.value === itinerary.service_info)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                    helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                    label="服務內容"
                                                    variant="standard"
                                                    required
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("newItineraries", "service_info", value?.value, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                            helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                            label="行程內容"
                                            variant="standard"
                                            value={itinerary.activity}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                                inputProps: { style: { textAlign: 'center' }}
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newItineraries", "activity", e.target.value, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                            helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                            label="飯店資訊"
                                            variant="standard"
                                            value={itinerary.itinerary_hotel}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                                inputProps: { style: { textAlign: 'center' }}
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newItineraries", "itinerary_hotel", e.target.value, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                            helperText={errors?.itineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                            label="備註"
                                            variant="standard"
                                            value={itinerary.itinerary_notes}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newItineraries", "itinerary_notes", e.target.value, itinerary.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editMode && (
                            <Box className={styles['add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewItinerary}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增行程安排
                                </Button>
                            </Box>
                        )}

                        {!editMode && data?.itineraries.length === 0 && !editData?.newItineraries && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <SearchingImg style={{ width: '300px', height: '300px' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何行程資訊</Typography>
                                </Grid>
                            </Grid>
                        )}

                    </Box>
                )}

                {tab === 2 && data && (
                    <Box className={styles['tab-main-box']}>
                        
                        {data?.passengers.map((passenger, index) => (
                            <div key={passenger.id}>
                                <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                    <Chip
                                        className={styles['chip-text']}
                                        label={"旅客資料 #" + (index + 1)}
                                        onDelete={editMode ? null : () => handleDeleteClick('Passenger', passenger)}
                                        deleteIcon={editMode ? null : <DeleteForever className={styles['delete-icon']}/>}
                                    />
                                </Divider>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.chinese_name}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.chinese_name}
                                            label="中文姓名"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.chinese_name === ''
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.chinese_name || passenger?.chinese_name
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "chinese_name", e.target.value, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.english_name}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.english_name}
                                            label="英文姓名"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.english_name === ''
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.english_name || passenger?.english_name
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "english_name", e.target.value, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Autocomplete
                                            options={options?.passenger_options?.gender}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                options?.passenger_options?.gender.find(option => option.value === editData?.passengers?.find(p => p.id === passenger.id)?.gender)
                                                    || options?.passenger_options?.gender.find(option => option.value === passenger?.gender)
                                            }
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.passengers?.find(error => error.id === String(passenger.id)?.gender)}
                                                    helperText={errors?.passengers?.find(error => error.id === String(passenger.id)?.gender)}
                                                    label="性別"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("passengers", "gender", value?.value, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.id_number}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.id_number}
                                            label="身份證字號"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.id_number === ''
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.id_number || passenger?.id_number
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "id_number", e.target.value, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.birth_date}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.birth_date}
                                            label="生日"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.birth_date === null
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.birth_date || passenger?.birth_date
                                            }
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "birth_date", e.target.value || null, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.passport_number}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.passport_number}
                                            label="護照號碼"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.passport_number === ''
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.passport_number || passenger?.passport_number
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "passport_number", e.target.value, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.passport_issue_date}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.passport_issue_date}
                                            label="護照發行日期"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.passport_issue_date === null
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.passport_issue_date || passenger?.passport_issue_date
                                            }
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "passport_issue_date", e.target.value || null, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.passport_expiry_date}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.passport_expiry_date}
                                            label="護照到期日期"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.passport_expiry_date === null
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.passport_expiry_date || passenger?.passport_expiry_date
                                            }
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "passport_expiry_date", e.target.value || null, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.room_allocation}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.room_allocation}
                                            label="分房代號"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.room_allocation === ''
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.room_allocation || passenger?.room_allocation
                                            }
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "room_allocation", e.target.value, passenger.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.id === String(passenger.id))?.passenger_notes}
                                            helperText={errors?.passengers?.find(error => error.id === String(passenger.id))?.passenger_notes}
                                            label="備註"
                                            variant="standard"
                                            value={
                                                editData?.passengers?.find(p => p.id === passenger.id)?.passenger_notes === ''
                                                    ? ''
                                                    : editData?.passengers?.find(p => p.id === passenger.id)?.passenger_notes || passenger?.passenger_notes
                                            }
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("passengers", "passenger_notes", e.target.value, passenger.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {!editMode && (
                            <Box />
                        )}

                        {editData?.newPassengers?.map((passenger, index) => (
                            <div key={passenger.identifier}>
                                <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                    <Chip className={styles['chip-text']} label={"當前新增旅客資料 #" + (index + 1)} onDelete={() => handleDeleteClick('newPassenger', passenger)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                                </Divider>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={2}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.chinese_name}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.chinese_name}
                                            label="中文姓名"
                                            variant="standard"
                                            value={passenger.chinese_name}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "chinese_name", e.target.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.english_name}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.english_name}
                                            label="英文姓名"
                                            variant="standard"
                                            value={passenger.english_name}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "english_name", e.target.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Autocomplete
                                            options={options?.passenger_options?.gender}
                                            getOptionLabel={(option) => option.label}
                                            value={options?.passenger_options?.gender.find(option => option.value === passenger.gender)}
                                            size='small'
                                            fullWidth
                                            disableClearable
                                            readOnly={!editMode}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.gender}
                                                    helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.gender}
                                                    label="性別"
                                                    variant="standard"
                                                    focused={editMode}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            onChange={(e, value) => handleFieldChange("newPassengers", "gender", value?.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.id_number}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.id_number}
                                            label="身份證字號"
                                            variant="standard"
                                            value={passenger.id_number}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "id_number", e.target.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.birth_date}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.birth_date}
                                            label="生日"
                                            variant="standard"
                                            value={passenger.birth_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "birth_date", e.target.value || null, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passport_number}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passport_number}
                                            label="護照號碼"
                                            variant="standard"
                                            value={passenger.passport_number}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "passport_number", e.target.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passport_issue_date}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passport_issue_date}
                                            label="護照發行日期"
                                            variant="standard"
                                            value={passenger.passport_issue_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "passport_issue_date", e.target.value || null, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passport_expiry_date}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passport_expiry_date}
                                            label="護照到期日期"
                                            variant="standard"
                                            value={passenger.passport_expiry_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "passport_expiry_date", e.target.value || null, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.room_allocation}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.room_allocation}
                                            label="分房代號"
                                            variant="standard"
                                            value={passenger.room_allocation}
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "room_allocation", e.target.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passenger_notes}
                                            helperText={errors?.passengers?.find(error => error.identifier === String(passenger.identifier))?.passenger_notes}
                                            label="備註"
                                            variant="standard"
                                            value={passenger.passenger_notes}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={editMode}
                                            InputProps={{
                                                readOnly: !editMode,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleFieldChange("newPassengers", "passenger_notes", e.target.value, passenger.identifier, true)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {editMode && (
                            <Box className={styles['add-item-box']}>
                                <Button className={styles['add-item-button']} onClick={handleAddNewPassenger}>
                                    <AddCircle sx={{ width: '20px', height: '20px' }} />
                                    新增旅客資料
                                </Button>
                            </Box>
                        )}

                        {!editMode && data?.passengers.length === 0 && !editData?.newPassengers && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <SearchingImg style={{ width: '300px', height: '300px' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何旅客資料</Typography>
                                </Grid>
                            </Grid>
                        )}
                        
                    </Box>
                )}

                {tab === 3 && data && (
                    <Box className={styles['tab-main-box']}>
                            
                            {data?.additionalitems.map((item, index) => (
                                <div key={item.id}>
                                    <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                        <Chip
                                            className={styles['chip-text']}
                                            label={"追加項目 #" + (index + 1)}
                                            onDelete={editMode ? null : () => handleDeleteClick('AdditionalItem', item)}
                                            deleteIcon={editMode ? null : <DeleteForever className={styles['delete-icon']}/>}
                                        />
                                    </Divider>
                                    <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_name}
                                                helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_name}
                                                label="項目名稱"
                                                variant="standard"
                                                value={
                                                    editData?.additionalitems?.find(a => a.id === item.id)?.item_name === ''
                                                        ? ''
                                                        : editData?.additionalitems?.find(a => a.id === item.id)?.item_name || item?.item_name
                                                }
                                                required
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("additionalitems", "item_name", e.target.value, item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Autocomplete
                                                options={options?.additional_item?.item_currency}
                                                getOptionLabel={(option) => option.label}
                                                value={options?.additional_item?.item_currency.find(option => option.value === editData?.additionalitems?.find(a => a.id === item.id)?.item_currency) || options?.additional_item?.item_currency.find(option => option.value === item.item_currency)}
                                                size='small'
                                                fullWidth
                                                disableClearable
                                                readOnly={!editMode}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        error={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_currency}
                                                        helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_currency}
                                                        label="幣別"
                                                        variant="standard"
                                                        required
                                                        focused={editMode}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                }
                                                onChange={(e, value) => handleFieldChange("additionalitems", "item_currency", value?.value, item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_price}
                                                helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_price}
                                                label="價格"
                                                variant="standard"
                                                value={
                                                    editData?.additionalitems?.find(a => a.id === item.id)?.item_price === ''
                                                        ? ''
                                                        : editData?.additionalitems?.find(a => a.id === item.id)?.item_price || parseFloat(item?.item_price).toFixed(2).replace(/\.00$/, '')
                                                }
                                                required
                                                type='number'
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                        '-webkit-appearance': 'none',
                                                        margin: 0
                                                    },
                                                    '& input[type="number"]': {
                                                        '-moz-appearance': 'textfield'
                                                    }
                                                }}
                                                onChange={(e) => handleFieldChange("additionalitems", "item_price", e.target.value, item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_due_date}
                                                helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_due_date}
                                                label="到期日期"
                                                variant="standard"
                                                value={
                                                    editData?.additionalitems?.find(a => a.id === item.id)?.item_due_date === null
                                                        ? ''
                                                        : editData?.additionalitems?.find(a => a.id === item.id)?.item_due_date || item?.item_due_date
                                                }
                                                type='date'
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("additionalitems", "item_due_date", e.target.value || null, item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_description}
                                                helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.item_description}
                                                label="項目內容"
                                                variant="standard"
                                                value={
                                                    editData?.additionalitems?.find(a => a.id === item.id)?.item_description === ''
                                                        ? ''
                                                        : editData?.additionalitems?.find(a => a.id === item.id)?.item_description || item?.item_description
                                                }
                                                multiline
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("additionalitems", "item_description", e.target.value, item.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={styles['additionalitems-itineraries-box']}>
                                                
                                                {item.additionalitemitineraries.map((itinerary, index) => (
                                                    <div key={itinerary.id}>
                                                        <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                                                            <Chip
                                                                className={styles['chip-text']}
                                                                label={"追加項目行程安排 #" + (index + 1)}
                                                                onDelete={editMode ? null : () => handleDeleteClick('AdditionalItemItinerary', itinerary)}
                                                                deleteIcon={editMode ? null : <DeleteForever className={styles['delete-icon']}/>}
                                                            />
                                                        </Divider>
                                                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.itinerary_date}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.itinerary_date}
                                                                    label="日期"
                                                                    variant="standard"
                                                                    value={
                                                                        editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.itinerary_date === null
                                                                            ? null
                                                                            : editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.itinerary_date || itinerary?.itinerary_date
                                                                    }
                                                                    required
                                                                    type='date'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "itinerary_date", e.target.value || null, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.reception_hours}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.reception_hours}
                                                                    label="接待時間"
                                                                    variant="standard"
                                                                    value={
                                                                        editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.reception_hours === null
                                                                            ? null
                                                                            : editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.reception_hours || itinerary?.reception_hours
                                                                    }
                                                                    type='time'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "reception_hours", e.target.value || null, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.room_reservation}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.room_reservation.find(option => option.value === editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.room_reservation) || options?.additional_item_itinerary?.room_reservation.find(option => option.value === itinerary?.room_reservation)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.room_reservation}
                                                                            helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.room_reservation}
                                                                            label="訂房資訊"
                                                                            variant="standard"
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("additionalitems", "room_reservation", value?.value, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.service_info}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.service_info.find(option => option.value === editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.service_info) || options?.additional_item_itinerary?.service_info.find(option => option.value === itinerary?.service_info)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.service_info}
                                                                            helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.service_info}
                                                                            label="服務內容"
                                                                            variant="standard"
                                                                            required
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("additionalitems", "service_info", value?.value, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.activity}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.activity}
                                                                    label="行程內容"
                                                                    variant="standard"
                                                                    value={
                                                                        editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.activity === ''
                                                                            ? ''
                                                                            : editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.activity || itinerary?.activity
                                                                    }
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "activity", e.target.value, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.itinerary_hotel}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.itinerary_hotel}
                                                                    label="飯店資訊"
                                                                    variant="standard"
                                                                    value={
                                                                        editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.itinerary_hotel === ''
                                                                            ? ''
                                                                            : editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.itinerary_hotel || itinerary?.itinerary_hotel
                                                                    }
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "itinerary_hotel", e.target.value, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.itinerary_notes}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.id === String(itinerary.id))?.itinerary_notes}
                                                                    label="備註"
                                                                    variant="standard"
                                                                    value={
                                                                        editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.itinerary_notes === ''
                                                                            ? ''
                                                                            : editData?.additionalitems?.find(a => a.id === item.id)?.additionalitemitineraries?.find(i => i.id === itinerary.id)?.itinerary_notes || itinerary?.itinerary_notes
                                                                    }
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "itinerary_notes", e.target.value, itinerary.id, false, "additionalitemitineraries", item.id)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                                {!editMode && (
                                                    <Box />
                                                )}

                                                {editData?.additionalitems?.find(a => a.id === item.id)?.newAdditionalItemItineraries?.map((itinerary, index) => (
                                                    <div key={itinerary.identifier}>
                                                        <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                                                            <Chip className={styles['chip-text']} label={"新增追加項目行程安排 #" + (index + 1)} onDelete={() => handleDeleteClick('o-newAdditionalItemItinerary', itinerary)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                                                        </Divider>
                                                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                                                    label="日期"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_date}
                                                                    required
                                                                    type='date'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "itinerary_date", e.target.value || null, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                                                    label="接待時間"
                                                                    variant="standard"
                                                                    value={itinerary.reception_hours}
                                                                    type='time'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "reception_hours", e.target.value || null, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.room_reservation}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.room_reservation.find(option => option.value === itinerary.room_reservation)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                                            helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                                            label="訂房資訊"
                                                                            variant="standard"
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("additionalitems", "room_reservation", value?.value, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.service_info}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.service_info.find(option => option.value === itinerary.service_info)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                                            helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                                            label="服務內容"
                                                                            variant="standard"
                                                                            required
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("additionalitems", "service_info", value?.value, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                                                    label="行程內容"
                                                                    variant="standard"
                                                                    value={itinerary.activity}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "activity", e.target.value, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                                                    label="飯店資訊"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_hotel}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "itinerary_hotel", e.target.value, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                                                    helperText={errors?.additionalitems?.find(error => error.id === String(item.id))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                                                    label="備註"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_notes}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("additionalitems", "itinerary_notes", e.target.value, itinerary.identifier, false, "newAdditionalItemItineraries", item.id, true)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                                {editMode && (
                                                    <Box className={styles['sub-add-item-box']} sx={{ marginTop: '10px'}}>
                                                        <Button className={styles['add-item-button']} onClick={() => handleAddNewAdditionalItemItinerary(item.id)}>
                                                            <AddCircle sx={{ width: '20px', height: '20px' }} />
                                                            新增追加項目行程安排
                                                        </Button>
                                                    </Box>
                                                )}

                                                {!editMode && item.additionalitemitineraries.length === 0 && !editData?.additionalitems?.find(a => a.id === item.id)?.newAdditionalItemItineraries && (
                                                    <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 10px 16px' }}>
                                                        <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <SearchingImg style={{ width: '150px', height: '150px' }} />
                                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此追加項目中，沒有任何行程安排</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}

                            {!editMode && (
                                <Box />
                            )}

                            {editData?.newAdditionalItems?.map((item, index) => (
                                <div key={item.identifier}>
                                    <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                        <Chip className={styles['chip-text']} label={"當前新增追加項目 #" + (index + 1)} onDelete={() => handleDeleteClick('newAdditionalItem', item)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                                    </Divider>
                                    <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_name}
                                                helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_name}
                                                label="項目名稱"
                                                variant="standard"
                                                value={item.item_name}
                                                required
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("newAdditionalItems", "item_name", e.target.value, item.identifier, true)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Autocomplete
                                                options={options?.additional_item?.item_currency}
                                                getOptionLabel={(option) => option.label}
                                                value={options?.additional_item?.item_currency.find(option => option.value === item.item_currency)}
                                                size='small'
                                                fullWidth
                                                disableClearable
                                                readOnly={!editMode}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_currency}
                                                        helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_currency}
                                                        label="幣別"
                                                        variant="standard"
                                                        required
                                                        focused={editMode}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                }
                                                onChange={(e, value) => handleFieldChange("newAdditionalItems", "item_currency", value?.value, item.identifier, true)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_price}
                                                helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_price}
                                                label="價格"
                                                variant="standard"
                                                value={item.item_price}
                                                required
                                                type='number'
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{
                                                    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                                        '-webkit-appearance': 'none',
                                                        margin: 0
                                                    },
                                                    '& input[type="number"]': {
                                                        '-moz-appearance': 'textfield'
                                                    }
                                                }}
                                                onChange={(e) => handleFieldChange("newAdditionalItems", "item_price", e.target.value, item.identifier, true)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_due_date}
                                                helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_due_date}
                                                label="到期日期"
                                                variant="standard"
                                                value={item.item_due_date}
                                                type='date'
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("newAdditionalItems", "item_due_date", e.target.value || null, item.identifier, true)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_description}
                                                helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.item_description}
                                                label="項目內容"
                                                variant="standard"
                                                value={item.item_description}
                                                multiline
                                                size='small'
                                                fullWidth
                                                focused={editMode}
                                                InputProps={{
                                                    readOnly: !editMode,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("newAdditionalItems", "item_description", e.target.value, item.identifier, true)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={styles['additionalitems-itineraries-box']}>
                                                {item.additionalitemitineraries.map((itinerary, index) => (
                                                    <div key={itinerary.identifier}>
                                                        <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                                                            <Chip className={styles['chip-text']} label={"新增追加項目行程安排 #" + (index + 1)} onDelete={() => handleDeleteClick('n-newAdditionalItemItinerary', itinerary)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                                                        </Divider>
                                                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                                                    label="日期"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_date}
                                                                    required
                                                                    type='date'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "itinerary_date", e.target.value || null, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                                                    label="接待時間"
                                                                    variant="standard"
                                                                    value={itinerary.reception_hours}
                                                                    type='time'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "reception_hours", e.target.value || null, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.room_reservation}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.room_reservation.find(option => option.value === itinerary.room_reservation)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                                            helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                                            label="訂房資訊"
                                                                            variant="standard"
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("newAdditionalItems", "room_reservation", value?.value, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.service_info}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.service_info.find(option => option.value === itinerary.service_info)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                                            helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                                            label="服務內容"
                                                                            variant="standard"
                                                                            required
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("newAdditionalItems", "service_info", value?.value, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                                                    label="行程內容"
                                                                    variant="standard"
                                                                    value={itinerary.activity}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "activity", e.target.value, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                                                    label="飯店資訊"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_hotel}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "itinerary_hotel", e.target.value, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                                                    label="備註"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_notes}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "itinerary_notes", e.target.value, itinerary.identifier, true, "additionalitemitineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                                {!editMode && (
                                                    <Box />
                                                )}

                                                {item?.newAdditionalItemItineraries?.map((itinerary, index) => (
                                                    <div key={itinerary.identifier}>
                                                        <Divider variant='middle' textAlign='center' sx={{ marginTop: '10px' }}>
                                                            <Chip className={styles['chip-text']} label={"新增追加項目行程安排 #" + (index + 1)} onDelete={() => handleDeleteClick('n-newAdditionalItemItinerary', itinerary)} deleteIcon={<DeleteForever className={styles['delete-icon']}/>}/>
                                                        </Divider>
                                                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_date}
                                                                    label="日期"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_date}
                                                                    required
                                                                    type='date'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "itinerary_date", e.target.value || null, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.reception_hours}
                                                                    label="接待時間"
                                                                    variant="standard"
                                                                    value={itinerary.reception_hours}
                                                                    type='time'
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "reception_hours", e.target.value || null, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.room_reservation}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.room_reservation.find(option => option.value === itinerary.room_reservation)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                                            helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.room_reservation}
                                                                            label="訂房資訊"
                                                                            variant="standard"
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("newAdditionalItems", "room_reservation", value?.value, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    options={options?.additional_item_itinerary?.service_info}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={options?.additional_item_itinerary?.service_info.find(option => option.value === itinerary.service_info)}
                                                                    size='small'
                                                                    fullWidth
                                                                    disableClearable
                                                                    readOnly={!editMode}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params}
                                                                            error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                                            helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.service_info}
                                                                            label="服務內容"
                                                                            variant="standard"
                                                                            required
                                                                            focused={editMode}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        />
                                                                    }
                                                                    onChange={(e, value) => handleFieldChange("newAdditionalItems", "service_info", value?.value, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.activity}
                                                                    label="行程內容"
                                                                    variant="standard"
                                                                    value={itinerary.activity}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "activity", e.target.value, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_hotel}
                                                                    label="飯店資訊"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_hotel}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                        inputProps: { style: { textAlign: 'center' }}
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "itinerary_hotel", e.target.value, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    error={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                                                    helperText={errors?.additionalitems?.find(error => error.identifier === String(item.identifier))?.additionalitemitineraries?.find(error => error.identifier === String(itinerary.identifier))?.itinerary_notes}
                                                                    label="備註"
                                                                    variant="standard"
                                                                    value={itinerary.itinerary_notes}
                                                                    multiline
                                                                    size='small'
                                                                    fullWidth
                                                                    focused={editMode}
                                                                    InputProps={{
                                                                        readOnly: !editMode,
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={(e) => handleFieldChange("newAdditionalItems", "itinerary_notes", e.target.value, itinerary.identifier, true, "newAdditionalItemItineraries", item.identifier, true)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                                {editMode && (
                                                    <Box className={styles['sub-add-item-box']} sx={{ marginTop: '10px'}}>
                                                        <Button className={styles['add-item-button']} onClick={() => handleAddNewAdditionalItemItinerary(item.identifier, true)}>
                                                            <AddCircle sx={{ width: '20px', height: '20px' }} />
                                                            新增追加項目行程安排
                                                        </Button>
                                                    </Box>
                                                )}

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}

                            {editMode && (
                                <Box className={styles['add-item-box']}>
                                    <Button className={styles['add-item-button']} onClick={handleAddNewAdditionalItem}>
                                        <AddCircle sx={{ width: '20px', height: '20px' }} />
                                        新增追加項目
                                    </Button>
                                </Box>
                            )}

                            {!editMode && data?.additionalitems?.length === 0 && !editData?.newAdditionalItems && (
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SearchingImg style={{ width: '300px', height: '300px' }} />
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何追加項目</Typography>
                                    </Grid>
                                </Grid>
                            )}

                    </Box>
                )}

                {!data && (
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {loading ? (
                            <CircularProgress color="info" />
                        ) : (
                            <>
                                <NoDataImg style={{ width: '300px', height: '300px' }} />
                                無法取得訂單資料或發生錯誤: {errors?.detail}
                            </>
                        )}
                    </Box>
                )}

            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Box className={styles['action-box']}>
                {editMode ? (
                    <>
                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                            取消
                        </Button>
                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                            保存
                        </Button>
                    </>
                ) : (
                    <Button className={styles['edit-button']} onClick={handleEditClick}>
                        <EditNote sx={{ width: '22px', height: '22px' }} />
                        編輯
                    </Button>
                )}
            </Box>

            <Dialog disableEscapeKeyDown open={deleteCheckOpen} onClose={handleDeletCancelClick} sx={{ '& .MuiPaper-root.MuiDialog-paper': {maxWidth: '100vw'} }}>
                <DialogTitle sx={{ color: '#cb3737', width: '500px', fontSize: '25px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', padding: '15px'}}>
                    <Warning sx={{ width: '28px', height: '28px' }} />
                    刪除確認
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '15px', textAlign: 'center', '&.MuiDialogContent-root': { paddingTop: '15px' } }}>
                    <DialogContentText sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {deleteItem?.deleteType === 'Itinerary' && (
                            `確定要刪除這筆於 ${deleteItem?.itinerary_date || '未設置日期'} 的行程安排資訊嗎？`
                        )}
                        {deleteItem?.deleteType === 'newItinerary' && (
                            `確定要刪除這筆新增於 ${deleteItem?.itinerary_date || '未設置日期'} 的行程安排資訊嗎？`
                        )}
                        {deleteItem?.deleteType === 'Passenger' && (
                            `確定要刪除 ${deleteItem?.chinese_name || deleteItem?.english_name || '未設置姓名'} 的旅客資訊嗎？`
                        )}
                        {deleteItem?.deleteType === 'newPassenger' && (
                            `確定要刪除 ${deleteItem?.chinese_name || deleteItem?.english_name || '未設置姓名'} 的新增旅客資訊嗎？`
                        )}
                        {deleteItem?.deleteType === 'AdditionalItem' && (
                            `確定要刪除 ${deleteItem?.item_name || '未設置項目名稱'} 的追加項目嗎？`
                        )}
                        {deleteItem?.deleteType === 'newAdditionalItem' && (
                            `確定要刪除 ${deleteItem?.item_name || '未設置項目名稱'} 的新增追加項目嗎？`
                        )}
                        {deleteItem?.deleteType === 'AdditionalItemItinerary' && (
                            `確定要刪除這筆於 ${deleteItem?.itinerary_date || '未設置日期'} 的追加項目行程安排資訊嗎？`
                        )}
                        {deleteItem?.deleteType === 'o-newAdditionalItemItinerary' && (
                            `確定要刪除這筆新增於 ${deleteItem?.itinerary_date || '未設置日期'} 的追加項目行程安排資訊嗎？`
                        )}
                        {deleteItem?.deleteType === 'n-newAdditionalItemItinerary' && (
                            `確定要刪除這筆新增於 ${deleteItem?.itinerary_date || '未設置日期'} 的追加項目行程安排資訊嗎？`
                        )}
                        <br />此操作無法復原，請謹慎處理。
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ width: '500px', justifyContent: 'center', gap: '15px', padding: '15px' }}>
                    <Button className={styles['delete-cancel-button']} onClick={handleDeletCancelClick}>
                        <DoDisturbOn sx={{ width: '20px', height: '20px' }} />
                        取消
                    </Button>
                    <Button className={styles['delete-confirm-button']} onClick={handleDeleteConfirmClick}>
                        確認
                        <Delete sx={{ width: '20px', height: '20px' }} />
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

export default RequestTab;