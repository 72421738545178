import React, { useEffect } from 'react';
import { useUnsavedChanges } from '../contexts/UnsavedChangesContext';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import styles from './css/NotFound.module.css';
import { ReactComponent as NotFoundImg } from './img/NotFoundImg.svg';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: `calc(100vh - 64px - 40px - 48px)`,
  textAlign: 'center',
});

function NotFoundPage() {
  const { setHasUnsavedChanges } = useUnsavedChanges();
  
  useEffect(() => {
    document.title = '404 - 找不到頁面';
    setHasUnsavedChanges(false);
  }, [setHasUnsavedChanges]);

  return (
    <StyledContainer component="main">
      <NotFoundImg style={{ maxWidth: "300px", marginBottom: "20px"}} />
      <Typography className={styles['text-h1']} variant="h5" component="h2" gutterBottom>
        頁 面 不 存 在
      </Typography>
      <Typography className={styles['text-p']} variant="body1" color="error" gutterBottom>
        正在尋找的頁面可能已經被刪除、更名或目前暫時無法使用。
      </Typography>
      <Box mt={3}>
        <Button className={styles['text-button']} variant="contained" color="primary" component={Link} to="/Announcement">
          返回主頁
        </Button>
      </Box>
    </StyledContainer>
  );
}

export default NotFoundPage;
