// ChangLogs頁面
import React, { useState, useEffect } from 'react';
import api from '../config/axiosConfig';
import styles from './css/ChangeLog.module.css';
import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { AutoFixHigh, BugReport, Construction, Delete, Grade, LayersClear, Settings, TaskAlt } from '@mui/icons-material';

function ChangeLog() {
  const [changeLogs, setChangeLogs] = useState([]);

  useEffect(() => {
    document.title = '變更日誌';

    const fetchChangeLogs = async () => {
      try {
        const response = await api.get('/core/api/ChangeLog/');
        setChangeLogs(response.data);
      } catch (error) {
        console.error('Fetch Change Logs Error:', error);
      }
    };

    fetchChangeLogs();
  }, []);

  return (
    <div>
      <Container>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography className={styles['title-tip']}>變更日誌</Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Typography className={styles.title}>系統更新及修復</Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Typography className={styles.description}>
            這裡將會詳細記錄系統每次更新的內容，包括新增功能、問題修復和其他改進。
            這些變更日誌旨在讓使用者能更快速的了解系統的最新狀況，以及每個版本所做的具體變更。
            如果有任何疑問或需要進一步了解的地方，請隨時聯繫相關負責人。
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {changeLogs.map((changeLog, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  color="textSecondary"
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography className={styles['log-date']}>
                      {new Date(changeLog.created_at).toLocaleDateString('zh-TW', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Typography>
                    <Chip className={styles['log-version']} size='small' label={changeLog.version}/>
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className={styles['timeline-dot']}/>
                  <TimelineConnector className={styles['timeline-connector']} />
                </TimelineSeparator>
                <TimelineContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: '20px', mb: '64px' }}>
                    <Typography className={styles['log-title']}>{changeLog.title}</Typography>
                    <Typography className={styles['log-content']}>{changeLog.log_content}</Typography>

                    {changeLog.change_descriptions.some((description) => description.change_type === '新增') && (
                      <Box>
                        <Box className={styles['log-description-title']}>
                          <Typography className={styles['log-description-title-text']}>
                            <Grade className={styles['grade-icon']}/>
                            新增功能
                          </Typography>
                          <Typography className={styles['log-description-description']}>
                            提供新加入的功能或事項的詳細說明，以及這些新增將如何幫助用戶提升效率或體驗：
                          </Typography>
                        </Box>

                        <Box className={styles['log-description-add-part']}>
                          <Grid container sx={{ padding: '24px 24px 14px 24px'}}>
                            {changeLog.change_descriptions.filter((description) => description.change_type === '新增').map((description, index) => (
                            <Grid item xs={12} key={index} className={styles['log-description']}>
                              <TaskAlt className={styles['task-icon']}/>
                              {description.change_description}
                            </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    )}

                    {changeLog.change_descriptions.some((description) => description.change_type === '改進') && (
                      <Box>
                        <Box className={styles['log-description-title']}>
                          <Typography className={styles['log-description-title-text']}>
                            <Settings className={styles['settings-icon']}/>
                            功能改進
                          </Typography>
                          <Typography className={styles['log-description-description']}>
                            說明對現有功能進行的改良點，包括效能提升或用戶界面的優化細節：
                          </Typography>
                        </Box>

                        <Box className={styles['log-description-improve-part']}>
                          <Grid container sx={{ padding: '24px 24px 14px 24px'}}>
                            {changeLog.change_descriptions.filter((description) => description.change_type === '改進').map((description, index) => (
                            <Grid item xs={12} key={index} className={styles['log-description']}>
                              <Construction className={styles['construction-icon']}/>
                              {description.change_description}
                            </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    )}

                    {changeLog.change_descriptions.some((description) => description.change_type === '修復') && (
                      <Box>
                        <Box className={styles['log-description-title']}>
                          <Typography className={styles['log-description-title-text']}>
                            <BugReport className={styles['bugreport-icon']}/>
                            功能修復
                          </Typography>
                          <Typography className={styles['log-description-description']}>
                            列出已修復的錯誤或問題，並詳述這些修復如何影響用戶的操作流程：
                          </Typography>
                        </Box>

                        <Box className={styles['log-description-fix-part']}>
                          <Grid container sx={{ padding: '24px 24px 14px 24px'}}>
                            {changeLog.change_descriptions.filter((description) => description.change_type === '修復').map((description, index) => (
                            <Grid item xs={12} key={index} className={styles['log-description']}>
                              <AutoFixHigh className={styles['autofixhigh-icon']}/>
                              {description.change_description}
                            </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    )}

                    {changeLog.change_descriptions.some((description) => description.change_type === '刪除') && (
                      <Box>
                        <Box className={styles['log-description-title']}>
                          <Typography className={styles['log-description-title-text']}>
                            <Delete className={styles['delete-icon']}/>
                            功能刪除或棄用
                          </Typography>
                          <Typography className={styles['log-description-description']}>
                            說明哪些功能或事項被移除或停用，包括原因和對用戶的可能影響：
                          </Typography>
                        </Box>

                        <Box className={styles['log-description-remove-part']}>
                          <Grid container sx={{ padding: '24px 24px 14px 24px'}}>
                            {changeLog.change_descriptions.filter((description) => description.change_type === '刪除').map((description, index) => (
                            <Grid item xs={12} key={index} className={styles['log-description']}>
                              <LayersClear className={styles['layersclear-icon']}/>
                              {description.change_description}
                            </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    )}

                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Container>

      <footer className={styles['default-footer']}>
          © 2023 - {new Date().getFullYear()} 小滿科技有限公司 版權所有
      </footer>
    </div>
  );
};

export default ChangeLog;
